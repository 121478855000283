import React from 'react';
import FormField from './FormField';
import { observer } from 'mobx-react';
import Tag, { TagType } from '../domain/Tag';
import ProjectTag from '../domain/ProjectTag';
import Grid from '../ui/SimpleGrid';
import Heading from '../ui/Heading';
import HStack from '../ui/HStack';
import Stack from '../ui/Stack';
import IconButton from '../ui/IconButton';
import Input from '../ui/Input';
import DeleteIcon from '../ui/icons/DeleteIcon';
import PlusIcon from '../ui/icons/PlusIcon';
import TagSelectWithHandle from './TagSelectWithHandle';
import { useTranslation } from 'react-i18next';

export interface ProjectPositionsFieldProps {
  name?: string;
  values: Partial<ProjectTag>[];
  availablePositions: Tag[];
  onAppend: (projectTag: Partial<ProjectTag>) => void;
  onRemove: (index: number) => void;
}

const ProjectPositionsField: React.FC<ProjectPositionsFieldProps> = ({
  values,
  name,
  availablePositions,
  onAppend,
  onRemove,
}) => {
  const { t } = useTranslation();

  const filteredAvailablePositions = availablePositions.filter(
    (tag) => !values.find((v) => v.tagId === tag.id),
  );
  const usedPositions = availablePositions.filter(
    (tag) => !!values.find((v) => v.tagId === tag.id),
  );

  return (
    <div>
      <Stack spacing={4}>
        <HStack justifyContent="space-between">
          <Heading size="sm">Positions</Heading>
          <TagSelectWithHandle
            type={TagType.POSITION}
            tags={filteredAvailablePositions}
            disabledTags={usedPositions}
            onSelect={(tagId) => {
              tagId && onAppend({ tagId, capacity: 1 });
            }}
            forbiddenCreatableText={(inputValue) =>
              `"${inputValue}" ${t('is already defined')}`
            }
            handle={
              <IconButton
                type="button"
                aria-label={t('Add position')}
                size="sm"
                icon={<PlusIcon />}
              />
            }
          />
        </HStack>
        {values.map((item, index) => (
          <Grid
            key={item.id}
            alignItems="center"
            templateColumns="1fr min-content min-content"
            gap={2}
          >
            <div>
              {availablePositions.find((t) => t.id === item.tagId)?.name}

              <FormField
                name={`${name}[${index}].tagId`}
                defaultValue={item.tagId}
              >
                <input type="hidden" />
              </FormField>
            </div>
            <FormField
              name={`${name}[${index}].capacity`}
              defaultValue={item.capacity}
            >
              <Input placeholder={t('Capacity')} type="number" width="20" />
            </FormField>
            <IconButton
              aria-label={t('Remove')}
              icon={<DeleteIcon />}
              onClick={() => onRemove(index)}
            />
          </Grid>
        ))}
      </Stack>
    </div>
  );
};

ProjectPositionsField.defaultProps = {
  name: 'positions',
};

export default observer(ProjectPositionsField);
