import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import useQueryValue from '../hooks/useQueryValue';
import ProjectDeleteDrawer from '../components/ProjectDeleteDrawer';
import ProjectStore from '../store/ProjectStore';

export interface ConnectedProjectDeleteDrawerProps {
  name: string;
}

const ConnectedProjectDeleteDrawer: React.FC<
  ConnectedProjectDeleteDrawerProps
> = ({ name }) => {
  const projectStore = useResolver(ProjectStore);
  const projectId = useQueryValue(name);

  if (!projectId) {
    return null;
  }

  const handleSubmit = async () => {
    await projectStore.delete(projectId);
  };

  const project = projectStore.getById(projectId);

  return (
    <ProjectDeleteDrawer
      name={name}
      project={project}
      onSubmit={handleSubmit}
    />
  );
};

export default observer(ConnectedProjectDeleteDrawer);
