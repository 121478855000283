import { injectable } from 'tsyringe';
import XLSX from 'xlsx';

export class Sheet {
  public cursor: SheetCell;

  public constructor(
    public cells: SheetCell[] = [],
    public columns: SheetColumn[] = [],
  ) {
    this.cursor = cells[cells.length - 1];
  }

  public static fromArray(data: string[][]): Sheet {
    const sheet = new Sheet();

    for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
      for (
        let columnIndex = 0;
        columnIndex < data[rowIndex].length;
        columnIndex++
      ) {
        sheet.setValue(columnIndex, rowIndex, data[rowIndex][columnIndex]);
      }
    }

    return sheet;
  }

  public toArray(): string[][] {
    const result: string[][] = [];

    this.cells.forEach((cell) => {
      result[cell.y] = result[cell.y] || [];
      result[cell.y][cell.x] = cell.value;
    });

    return result;
  }

  public setValue(x: number, y: number, value: string): Sheet {
    let cell = this.getCell(x, y);

    if (cell) {
      cell.value = value;
    } else {
      cell = new SheetCell(x, y, value);
      this.cells.push(cell);
    }

    this.cursor = cell;

    return this;
  }

  public moveTo(x: number, y: number): Sheet {
    const cell = this.getCell(x, y);

    if (cell) {
      this.cursor = cell;
    } else {
      this.setValue(x, y, '');
    }

    return this;
  }

  public resetX(): Sheet {
    const [, y] = this.getCursorPosition();

    return this.moveTo(0, y);
  }

  public resetY(): Sheet {
    const [x] = this.getCursorPosition();

    return this.moveTo(x, 0);
  }

  public getCell(x: number, y: number): SheetCell {
    return this.cells.find((c) => c.x === x && c.y === y) || null;
  }

  public setCurrentCell(value: string): Sheet {
    const [x, y] = this.getCursorPosition();

    return this.setValue(x, y, value);
  }

  public setNextRowCell(value: string): Sheet {
    const [x, y] = this.getCursorPosition();

    return this.setValue(x, y + 1, value);
  }

  public setNextRowCells(values: string[]): Sheet {
    const [x, y] = this.getCursorPosition();
    const [firstValue, ...otherValues] = values;

    this.setNextRowCell(firstValue || '');
    otherValues.forEach((value) => {
      this.setNextColumnCell(value);
    });

    // Keep cursor on first element
    this.moveTo(x, y + 1);

    return this;
  }

  public setNextColumnCell(value: string): Sheet {
    const x = this.cursor?.x || 0;
    const y = this.cursor?.y || 0;

    return this.setValue(x + 1, y, value);
  }

  public getCursorPosition(): [number, number] {
    return [this.cursor?.x || 0, this.cursor?.y || 0];
  }

  public setColumnWidths(widths: number[]): Sheet {
    this.columns = widths.map((width) => new SheetColumn(width));

    return this;
  }
}

export class SheetColumn {
  public constructor(public width?: number) {}
}

export class SheetCell {
  public constructor(
    public x: number,
    public y: number,
    public value: string,
  ) {}

  public toString() {
    return this.value;
  }
}

@injectable()
export default class FileExportService {
  public async exportXls(
    data: string[][] | Sheet,
    sheetName: string = 'Sheet1',
    fileName = 'File',
  ) {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(
      Array.isArray(data) ? data : data.toArray(),
    );

    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    if (data instanceof Sheet) {
      if (data.columns.length > 0) {
        ws['!cols'] = data.columns;
      }
    }

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
}
