import React, { useState } from 'react';
import Markdown from '../ui/Markdown';
import Box from '../ui/Box';
import RichTextarea from '../ui/RichTextarea';
import HStack from '../ui/HStack';
import Button from '../ui/Button';
import IconButton from '../ui/IconButton';
import EditIcon from '../ui/icons/EditIcon';
import Form from './Form';
import FormField from './FormField';
import FormSubmitButton from './FormSubmitButton';
import NoContent from '../ui/NoContent';
import { t } from '../i18n';

export interface RichTextProps {
  children: string | null;
  onChange?: (newValue: string) => void | Promise<void>;
}

const RichText: React.FC<RichTextProps> = ({ onChange, children }) => {
  const editable = !!onChange;
  const [editing, setEditing] = useState<boolean>(false);

  const handleSubmit = async ({ text }: { text: string }) => {
    await onChange?.(text);
    setEditing(!editing);
  };

  const handleCancel = () => {
    setEditing(!editing);
  };

  return (
    <Box>
      {editable && !editing && (
        <Box float="right">
          <IconButton
            variant="ghost"
            aria-label={t('Edit')}
            tooltip
            icon={<EditIcon />}
            onClick={() => setEditing(!editing)}
          />
        </Box>
      )}
      <Box maxW={editing ? '100%' : '97%'}>
        {!editing ? (
          children ? (
            <Markdown>{children}</Markdown>
          ) : (
            <NoContent>{t('No content here')}</NoContent>
          )
        ) : (
          <Form onSubmit={handleSubmit} defaultValues={{ text: children }}>
            <FormField name="text">
              <RichTextarea />
            </FormField>
            <HStack pt="10px" float="right">
              <Button onClick={handleCancel}>{t('Cancel')}</Button>
              <FormSubmitButton colorScheme="brand">
                {t('Save')}
              </FormSubmitButton>
            </HStack>
          </Form>
        )}
      </Box>
    </Box>
  );
};

export default RichText;
