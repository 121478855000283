import FirebaseProvider from './FirebaseProvider';
import { formatDate } from '../util/date';
import { injectable } from 'tsyringe';

@injectable()
export default class AttachmentUploadService {
  public constructor(private readonly firebaseProvider: FirebaseProvider) {}

  public async upload(file: File): Promise<string> {
    const snapshot = await this.firebaseProvider
      .getFirebaseStorage()
      .ref()
      .child(`${formatDate(new Date(), 'yyyy/MM/dd')}/${file.name}`)
      .put(file);

    return await snapshot.ref.getDownloadURL();
  }
}
