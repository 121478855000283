import React from 'react';
import { FiNavigation } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface LocationIconProps extends IconProps {}

const LocationIcon: React.FC<LocationIconProps> = ({ ...props }) => {
  return <Icon as={FiNavigation} {...props} />;
};

export default LocationIcon;
