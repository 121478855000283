import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import PersonSkillStore from '../store/PersonSkillStore';
import TagStore from '../store/TagStore';
import GroupedTags from '../components/GroupedTags';

export interface ConnectedPersonTagsProps {
  personId: string;
}

const ConnectedPersonTags: React.FC<ConnectedPersonTagsProps> = ({
  personId,
}) => {
  const personSkillStore = useResolver(PersonSkillStore);
  const tagStore = useResolver(TagStore);

  useEffect(() => {
    personSkillStore.loadByPerson(personId);
  }, [personId]);

  useEffect(() => {
    tagStore.loadByCurrentOrganization();
  }, []);

  return (
    <GroupedTags
      tags={personSkillStore
        .getItemsByPerson(personId)
        .map((p) => tagStore.getById(p.tagId))
        .filter(Boolean)}
    />
  );
};

export default observer(ConnectedPersonTags);
