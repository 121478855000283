import React, { useState } from 'react';
import DrawerForm from './DrawerForm';
import FormField from './FormField';
import DateInput from '../ui/DateInput';
import { addMonths, isThisMonth, startOfToday } from 'date-fns';
import Organization from '../domain/Organization';
import { getCurrencySymbol } from '../util/currency';
import Input from '../ui/Input';
import Price, { PriceType } from '../domain/Price';
import _ from 'lodash';
import useMonthPicker from '../hooks/useMonthPicker';
import Stack from '../ui/Stack';
import useQueryValue from '../hooks/useQueryValue';
import { t } from '../i18n';
import Checkbox from '../ui/Checkbox';

export interface AdditionalCostFormDrawerProps {
  currentOrganization?: Organization;
  onSubmit: (data: Partial<Price>) => void;
}

const AdditionalCostFormDrawer: React.FC<AdditionalCostFormDrawerProps> = ({
  currentOrganization,
  onSubmit,
}) => {
  const [allowMonthly, setAllowMonthly] = useState<boolean>(false);
  const month = useMonthPicker();
  const defaultStartDate =
    month && !isThisMonth(month) ? month : startOfToday();
  const type =
    useQueryValue<'cost' | 'revenue'>('additionalType') === 'revenue'
      ? 'revenue'
      : 'cost';

  return (
    <DrawerForm
      header={
        <>
          {t('Add')} {type === 'revenue' ? t('revenue') : t('cost')}
        </>
      }
      name="add.additional"
      onSubmit={({ value, ...values }) => {
        onSubmit({
          ...values,
          value: type === 'revenue' ? -value : value,
          type: allowMonthly ? PriceType.ONCE_A_MONTH : PriceType.FIXED,
        });
      }}
      submitButton={{ text: t('Add') }}
    >
      <Stack spacing={2}>
        <FormField
          name="value"
          label={type === 'revenue' ? t('Revenue') : t('Cost')}
          defaultValue=""
          min={0}
        >
          <Input
            placeholder={getCurrencySymbol(
              _.get(currentOrganization, ['currency']),
            )}
            autoFocus
            type="number"
            step="any"
          />
        </FormField>
        <FormField name="description" label={t('Description')} defaultValue="">
          <Input />
        </FormField>
        <FormField
          name="dateStart"
          defaultValue={defaultStartDate}
          label={t('Date')}
        >
          <DateInput />
        </FormField>
        <Checkbox
          name="checked"
          onChange={() => {
            setAllowMonthly(!allowMonthly);
          }}
          isChecked={allowMonthly}
        >
          {t('Monthly')} {type === 'revenue' ? t('revenue') : t('cost')}
        </Checkbox>
        {allowMonthly && (
          <FormField
            name="dateEnd"
            defaultValue={addMonths(defaultStartDate, 1)}
            label={t('End Date')}
          >
            <DateInput min={addMonths(defaultStartDate, 1)} />
          </FormField>
        )}
      </Stack>
    </DrawerForm>
  );
};

export default AdditionalCostFormDrawer;
