import React from 'react';
import useResolver from '../hooks/useResolver';
import AcceptButton from '../ui/AcceptButton';
import RequestStore from '../store/RequestStore';

interface ParticipationAcceptButtonProps {
  requestId: string;
}

const ConnectedRequestAcceptButton: React.FC<
  ParticipationAcceptButtonProps
> = ({ requestId }) => {
  const requestStore = useResolver(RequestStore);

  return (
    <AcceptButton onClick={() => requestStore.approveRequest(requestId)} />
  );
};

export default ConnectedRequestAcceptButton;
