import React from 'react';
import { RequestAction } from '../domain/Request';
import { requestActionIcons, requestActionTexts } from '../util/request';
import {
  BookIcon,
  Button,
  ChevronIcon,
  HashtagIcon,
  Menu,
  MenuItem,
  MusicIcon,
  PlaneIcon,
  PlusIcon,
  UserIcon,
  LinkIcon,
  TaskIcon,
} from '../ui';
import { t } from '../i18n';

export interface QuickActionsProps {
  manager: boolean;
}

const QuickActions: React.FC<QuickActionsProps> = ({ manager }) => {
  return (
    <Menu
      handle={
        <Button
          isFullWidth
          colorScheme="primary"
          leftIcon={<PlusIcon />}
          rightIcon={<ChevronIcon direction="down" />}
        >
          {manager ? t('Create new') : t('Create request')}
        </Button>
      }
    >
      {manager && (
        <>
          <MenuItem
            to="?managed-organization"
            preserveQuery
            icon={<LinkIcon />}
          >
            {t('Client')}
          </MenuItem>
          <MenuItem to="?project" preserveQuery icon={<PlaneIcon />}>
            {t('Project')}
          </MenuItem>
          <MenuItem to="?event" preserveQuery icon={<MusicIcon />}>
            {t('Event')}
          </MenuItem>
          <MenuItem to="?learning" preserveQuery icon={<BookIcon />}>
            {t('Learning')}
          </MenuItem>
          <MenuItem to="?person" preserveQuery icon={<UserIcon />}>
            {t('Profile')}
          </MenuItem>
          <MenuItem to="?task" preserveQuery icon={<TaskIcon />}>
            {t('Task')}
          </MenuItem>
          <MenuItem to="?request" preserveQuery icon={<HashtagIcon />}>
            {t('Request')}
          </MenuItem>
        </>
      )}
      {!manager &&
        (Object.keys(requestActionTexts) as RequestAction[]).map((action) => {
          const RequestIcon = requestActionIcons[action];

          return (
            <MenuItem
              to={`?request&action=${action}`}
              preserveQuery
              icon={<RequestIcon />}
            >
              {requestActionTexts[action]}
            </MenuItem>
          );
        })}
    </Menu>
  );
};

export default QuickActions;
