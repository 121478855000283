import { toDate } from './date';
import Participation from '../domain/Participation';

export function getUtilizationHours(
  personTimesheets: Array<{
    participation?: Participation;
    entries?: Array<{
      date: string;
      loggedTimeInSeconds: number;
      predictedTimeInSeconds: number;
      timeInSeconds: number;
      workday?: boolean;
    }>;
  }>,
): number {
  return personTimesheets
    ?.map((f) =>
      f.entries
        .filter((e) => toDate(e.date) <= new Date())
        .reduce((total, current) => total + current.timeInSeconds / 3600, 0),
    )
    .reduce((partialSum, a) => partialSum + a, 0);
}

export function getAllocationHours(
  personTimesheets: Array<{
    participation?: Participation;
    entries?: Array<{
      date: string;
      loggedTimeInSeconds: number;
      predictedTimeInSeconds: number;
      timeInSeconds: number;
      workday?: boolean;
    }>;
  }>,
): number {
  return personTimesheets
    ?.map((f) =>
      f.entries.reduce(
        (total, current) => total + current.predictedTimeInSeconds / 3600,
        0,
      ),
    )
    .reduce((partialSum, a) => partialSum + a, 0);
}
