import React, { useEffect, useRef, useState } from 'react';
import Box, { BoxProps } from './Box';

export interface SeamlessNumberInputProps extends SeamlessInputProps {
  type?: 'number';
  onChange?: (value: number) => void;
}

export interface SeamlessInputProps extends BoxProps {
  onChange?: (value: any) => void;
  type?: string;
  defaultValue: any;
  suffix?: string;
  displayValue?: string;
}

const SeamlessInput: React.FC<
  SeamlessInputProps | SeamlessNumberInputProps
> = ({ type, onChange, defaultValue, suffix, displayValue, ...props }) => {
  const spanRef = useRef(null);
  const hasDisplayValue = typeof displayValue === 'string';
  const [showDisplayValue, setShowDisplayValue] = useState(hasDisplayValue);

  useEffect(() => {
    setShowDisplayValue(hasDisplayValue);
  }, [displayValue]);

  const handleBlur = (event: any) => {
    let value = event.target.innerText;

    if (type === 'number') {
      const inputValueText = value.replace(/,/g, '.');
      const keepFloatNumbers = (
        inputValueText.match(/\d+(\.\d+)?/g) || []
      ).join('');
      const split = keepFloatNumbers.split('.');
      value = parseFloat(split.shift() + '.' + split.join(''));

      if (!value) {
        event.target.innerText = '';
        onChange(null);
      } else if (isNaN(value)) {
        event.target.innerText = defaultValue;
        return;
      } else {
        event.target.innerText = value.toString();
        onChange(value);
      }
    }

    if (hasDisplayValue) {
      setShowDisplayValue(true);
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      spanRef?.current?.blur();
    }
  };

  return (
    <Box
      as="span"
      _hover={onChange && { bg: 'brand.100' }}
      display="flex"
      cursor={onChange ? 'pointer' : 'default'}
      position="relative"
      onClick={() => {
        if (!onChange) {
          return;
        }

        if (spanRef?.current) {
          spanRef.current.focus();
          window.getSelection().selectAllChildren(spanRef.current);
        }

        if (showDisplayValue) {
          setShowDisplayValue(false);
        }
      }}
      {...props}
    >
      {showDisplayValue && <span>{displayValue}</span>}
      <span
        ref={spanRef}
        contentEditable={!!onChange}
        suppressContentEditableWarning={true}
        onKeyPress={handleKeyPress}
        onBlur={onChange && handleBlur}
        style={{
          outline: 'none',
          opacity: showDisplayValue ? 0 : 1,
          position: 'absolute',
        }}
      >
        {defaultValue}
      </span>
      {suffix}
    </Box>
  );
};

SeamlessInput.defaultProps = {
  type: 'text',
};

export default SeamlessInput;
