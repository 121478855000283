import React from 'react';
import {
  MenuItem as ChakraMenuItem,
  MenuItemProps as ChakraMenuItemProps,
} from '@chakra-ui/react';
import Link from './Link';

export interface MenuItemProps extends ChakraMenuItemProps {
  to?: string;
  preserveQuery?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ children, ...props }) => {
  if (props.to) {
    props.as = Link;
  }

  return (
    <ChakraMenuItem
      fontSize="sm"
      {...props}
      _hover={{ background: 'gray.100' }}
    >
      {children}
    </ChakraMenuItem>
  );
};

MenuItem.defaultProps = {};

MenuItem.displayName = 'MenuItem';

export default MenuItem;
