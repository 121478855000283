import PersonSkill from '../domain/PersonSkill';
import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';

@injectable()
export default class PersonSkillService extends AbstractRestfulHttpService<PersonSkill> {
  public constructor(apiService: ApiService) {
    super(PersonSkill, apiService);
  }

  public getAllByPerson(personId: string): Promise<PersonSkill[]> {
    return this.getMany({ personId });
  }

  public getAllByOrganization(organizationId: string): Promise<PersonSkill[]> {
    return this.getMany({ organizationId });
  }
}
