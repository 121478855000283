import React, { RefObject } from 'react';
import Popover from '../ui/Popover';
import Form from './Form';
import FormSubmitButton from './FormSubmitButton';
import CheckIcon from '../ui/icons/CheckIcon';
import HStack from '../ui/HStack';
import Stack from '../ui/Stack';

export interface PopoverFormProps {
  handle: React.ReactNode;
  onSubmit: (values: any) => void;
  initialFocusRef?: RefObject<any>;
  inline?: boolean;
  width?: string | number;
  disabled?: boolean;
}

const PopoverForm: React.FC<PopoverFormProps> = ({
  handle,
  onSubmit,
  children,
  initialFocusRef,
  inline,
  width,
  disabled = false,
}) => {
  return (
    <Popover
      initialFocusRef={initialFocusRef}
      handle={handle}
      width={width}
      disabled={disabled}
    >
      {({ onClose }) => {
        const handleSubmit = async (values: object) => {
          await onSubmit(values);
          onClose();
        };

        return (
          <Form onSubmit={handleSubmit}>
            {inline ? (
              <HStack spacing={2}>
                <div>{children}</div>
                <FormSubmitButton>
                  <CheckIcon />
                </FormSubmitButton>
              </HStack>
            ) : (
              <Stack spacing={2}>
                <div>{children}</div>
                <FormSubmitButton>
                  <CheckIcon />
                </FormSubmitButton>
              </Stack>
            )}
          </Form>
        );
      }}
    </Popover>
  );
};

PopoverForm.defaultProps = {
  inline: true,
};

export default PopoverForm;
