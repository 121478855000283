import React from 'react';
import { observer } from 'mobx-react';
import InvitationFormDrawer from '../components/InvitationFormDrawer';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import InvitationStore from '../store/InvitationStore';
import Invitation from '../domain/Invitation';
import useQueryValue from '../hooks/useQueryValue';

export interface ConnectedInvitationFormDrawerProps {
  name?: string;
}

const ConnectedInvitationFormDrawer: React.FC<ConnectedInvitationFormDrawerProps> =
  ({ name }) => {
    const personStore = useResolver(PersonStore);
    const invitationStore = useResolver(InvitationStore);
    const personId = useQueryValue(name);
    const person = personId && personStore.getByIdOrLoad(personId);

    const handleSubmit = async ({ email }: Partial<Invitation>) => {
      await invitationStore.create(
        new Invitation().fromObject({
          email,
          personId,
        }),
      );
    };

    if (!person) {
      return null;
    }

    const invitation = invitationStore.getLatestPersonInvitation(person.id);

    return (
      <InvitationFormDrawer
        onSubmit={handleSubmit}
        person={person}
        name={name}
        invitation={invitation}
      />
    );
  };

ConnectedInvitationFormDrawer.defaultProps = {
  name: InvitationFormDrawer.defaultProps.name,
};

export default observer(ConnectedInvitationFormDrawer);
