import React, { useState } from 'react';
import MobileLayout, { MobileLayoutProps } from '../ui/MobileLayout';
import { useLocation } from 'react-router-dom';

export interface AuthenticatedMobileLayoutProps
  extends Omit<MobileLayoutProps, 'menuOpen' | 'setMenuOpen'> {}

const AuthenticatedMobileLayout: React.FC<AuthenticatedMobileLayoutProps> = ({
  children,
  ...props
}) => {
  const location = useLocation();
  const fullPath = `${location.pathname}${location.search}`;
  const [menuOpenedAt, setMenuOpenedAt] = useState<string>(null);

  return (
    <MobileLayout
      {...props}
      menuOpen={menuOpenedAt === fullPath}
      setMenuOpen={(value: boolean) => setMenuOpenedAt(value ? fullPath : null)}
    >
      {children}
    </MobileLayout>
  );
};

export default AuthenticatedMobileLayout;
