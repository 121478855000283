import React from 'react';
import { FiMusic } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface BookIconProps extends IconProps {}

const MusicIcon: React.FC<BookIconProps> = ({ ...props }) => {
  return <Icon as={FiMusic} {...props} />;
};

export default MusicIcon;
