import React from 'react';
import {
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react';

export interface TextareaProps extends ChakraTextareaProps {}

const Textarea: React.FC<TextareaProps> = ({ ...props }) => {
  return <ChakraTextarea {...props} />;
};

Textarea.defaultProps = {};

export default Textarea;
