import React from 'react';
import { FiAward } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface AwardIconProps extends IconProps {}

const AwardIcon: React.FC<AwardIconProps> = ({ ...props }) => {
  return <Icon as={FiAward} {...props} />;
};

export default AwardIcon;
