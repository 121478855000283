import React from 'react';
import { observer } from 'mobx-react';
import { Box, Card, Tab, Text } from '../ui';
import RouterTabs from '../components/RouterTabs';
import { t } from '../i18n';
import ConnectedAppearanceForm from '../containers/ConnectedAppearanceForm';
import ConnectedAccessTokens from '../containers/ConnectedAccessTokens';

const MyPreferences: React.FC = () => {
  return (
    <Card>
      <RouterTabs>
        <Tab title={t('Appearance')}>
          <Text mb={8} fontSize="sm">
            {t('Control settings related to appearance of application')}
          </Text>
          <Box maxW={400}>
            <ConnectedAppearanceForm />
          </Box>
        </Tab>

        <Tab title={t('Personal access tokens')}>
          <ConnectedAccessTokens />
        </Tab>
      </RouterTabs>
    </Card>
  );
};

export default observer(MyPreferences);
