import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import useQueryValue from '../hooks/useQueryValue';
import RootStore from '../store/RootStore';

const SwitchPerson: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const personStore = useResolver(PersonStore);
  const rootStore = useResolver(RootStore);
  const redirectUrl = useQueryValue('redirect', '/app');

  const id = match.params.id;

  useEffect(() => {
    const currentOrganizationId = personStore.currentPerson?.organizationId;
    const newOrganizationId = personStore.getById(id)?.organizationId;

    personStore.selectPerson(id);

    if (currentOrganizationId !== newOrganizationId) {
      rootStore.clearAll();
    }

    history.replace(redirectUrl);
  }, [id]);

  return <></>;
};

export default SwitchPerson;
