import React from 'react';
import { FiTrash } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface DeleteIconProps extends IconProps {}

const DeleteIcon: React.FC<DeleteIconProps> = ({ ...props }) => {
  return <Icon as={FiTrash} {...props} />;
};

export default DeleteIcon;
