import React from 'react';
import Trend from '../ui/Trend';
import Stack from '../ui/Stack';
import ConnectedLabeledPriceValue from '../containers/ConnectedLabeledPriceValue';
import { t } from '../i18n';

export interface BudgetRowCalculatedValuesProps {
  revenuePrognosis: number;
  costPrognosis: number;
  marginPrognosis?: number;
  centered?: boolean;
  hideRevenue?: boolean;
}

const BudgetRowCalculatedValues: React.FC<BudgetRowCalculatedValuesProps> = ({
  revenuePrognosis,
  costPrognosis,
  marginPrognosis,
  centered,
  hideRevenue,
}) => {
  return (
    <>
      {!hideRevenue && (
        <ConnectedLabeledPriceValue
          label={t('Revenue prognosis')}
          value={revenuePrognosis}
          digits={2}
          centered={centered}
        />
      )}
      <ConnectedLabeledPriceValue
        label={t('Cost prognosis')}
        value={costPrognosis}
        digits={2}
        centered={centered}
      />
      {!hideRevenue && marginPrognosis !== undefined && (
        <>
          <Stack spacing={0} alignItems={centered ? 'center' : null}>
            <ConnectedLabeledPriceValue
              label={t('Margin prognosis')}
              value={marginPrognosis}
              digits={2}
              centered={centered}
            />
            {!!marginPrognosis && (
              <Trend
                expectedValue={costPrognosis}
                value={Math.round(marginPrognosis + costPrognosis)}
              />
            )}
          </Stack>
        </>
      )}
    </>
  );
};

export default BudgetRowCalculatedValues;
