import User from '../domain/User';
import FirebaseProvider from './FirebaseProvider';
import firebase from 'firebase';
import { injectable } from 'tsyringe';
import AuthenticationService from './AuthenticationService';

@injectable()
export default class FirebaseAuthenticationService
  implements AuthenticationService
{
  private loadedPromise: Promise<boolean>;
  private forceTokenRefresh: boolean = true;

  public constructor(private readonly firebaseProvider: FirebaseProvider) {
    this.resetLoaded();
  }

  public async login(email: string, password: string): Promise<void> {
    let result: firebase.auth.UserCredential;

    try {
      result = await this.firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
    } catch (error: any) {
      if (
        error.code === 'auth/user-not-found' ||
        error.code === 'auth/wrong-password'
      ) {
        throw new Error('Invalid email or password');
      }
      throw error;
    }

    if (!result.user.emailVerified) {
      throw new Error('Email is not verified');
    }

    this.resetLoaded();
  }

  private async socialLogin(provider: firebase.auth.AuthProvider) {
    await this.firebase.auth().signInWithPopup(provider);
    this.resetLoaded();
  }

  public async loginByGoogle(): Promise<void> {
    await this.socialLogin(this.firebaseProvider.getGoogleAuthProvider());
  }

  public async loginByGithub(): Promise<void> {
    await this.socialLogin(this.firebaseProvider.getGithubAuthProvider());
  }

  public async loginByFacebook(): Promise<void> {
    await this.socialLogin(this.firebaseProvider.getFacebookAuthProvider());
  }

  public async loginByMicrosoft(): Promise<void> {
    await this.socialLogin(this.firebaseProvider.getMicrosoftAuthProvider());
  }

  public async register(
    email: string,
    password: string,
    redirectUrl?: string,
  ): Promise<void> {
    const result = await this.firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    await result.user.sendEmailVerification({
      url: redirectUrl,
    });
    this.resetLoaded();
  }

  public async sendPasswordResetEmail(
    email: string,
    redirectUrl?: string,
  ): Promise<void> {
    await this.firebase.auth().sendPasswordResetEmail(email, {
      url: redirectUrl,
    });
  }

  public async confirmPasswordReset(
    code: string,
    newPassword: string,
  ): Promise<void> {
    await this.firebase.auth().confirmPasswordReset(code, newPassword);
  }

  public async getAccount(): Promise<User | null> {
    await this.loadedPromise;
    let currentUser = this.firebase.auth().currentUser;

    // Try reloading user when email is not verified
    if (currentUser && !currentUser.emailVerified) {
      await currentUser.reload();
      currentUser = this.firebase.auth().currentUser;
    }

    console.log(currentUser);

    if (
      !currentUser ||
      (!currentUser.emailVerified &&
        currentUser.providerData?.length === 1 &&
        currentUser.providerData[0].providerId === 'password')
    ) {
      return null;
    }

    return FirebaseAuthenticationService.createUserFromFirebaseUser(
      currentUser,
    );
  }

  private static createUserFromFirebaseUser(firebaseUser: firebase.User) {
    if (!firebaseUser) {
      return null;
    }

    const user = new User();
    user.id = firebaseUser.email;
    user.email = firebaseUser.email;
    user.name = firebaseUser.displayName;

    return user;
  }

  public async logout(): Promise<void> {
    await this.firebase.auth().signOut();
  }

  public async getToken(): Promise<string | null> {
    const forceRefresh = this.forceTokenRefresh;
    if (forceRefresh) {
      this.forceTokenRefresh = false;
    }

    return this.firebaseProvider
      .getFirebase()
      .auth()
      .currentUser?.getIdToken(forceRefresh);
  }

  private get firebase() {
    return this.firebaseProvider.getFirebase();
  }

  private resetLoaded() {
    this.loadedPromise = new Promise((resolve) => {
      this.firebase.auth().onAuthStateChanged(() => {
        resolve(true);
      });
    });
  }
}
