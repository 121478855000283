import React from 'react';
import TimeSheet from '../ui/TimeSheet';
import MonthPicker from '../ui/MonthPicker';
import HStack from '../ui/HStack';
import {
  formatDate,
  getAllDatesInMonth,
  getAllWorkdaysInMonth,
  toDate,
} from '../util/date';
import DocumentExport from '../ui/DocumentExport';
import ProjectTimesheetDocument from '../containers/ProjectTimesheetDocument';
import NoContent from '../ui/NoContent';
import Stack from '../ui/Stack';
import { Timesheet, timesheetToSheet } from '../util/worklog';
import useResolver from '../hooks/useResolver';
import FileExportService from '../services/FileExportService';
import { MenuItem } from '../ui/Menu';
import MoreActionsMenu from '../ui/MoreActionsMenu';
import ProjectLink from './ProjectLink';
import { getProjectColor } from '../util/project';
import ParticipationQuickInfo from './ParticipationQuickInfo';
import ConnectedPersonUser from '../containers/ConnectedPersonUser';
import {
  calculateHoursFromCapacity,
  capacityToDayHours,
} from '../util/capacity';
import TagStore from '../store/TagStore';
import RouterMonthPicker from './RouterMonthPicker';
import useMonthPicker from '../hooks/useMonthPicker';
import Text from '../ui/Text';
import TimesheetStat from './TimesheetStat';
import Worklog from '../domain/Worklog';
import Participation from '../domain/Participation';
import PersonStore from '../store/PersonStore';
import WorklogStore from '../store/WorklogStore';
import { isWeekend } from 'date-fns';
import Person from '../domain/Person';
import ProjectStore from '../store/ProjectStore';
import Project from '../domain/Project';
import { observer } from 'mobx-react';
import CapacityMultiCircle from './CapacityMultiCircle';
import TaskStore from '../store/TaskStore';
import Task from '../domain/Task';

export interface ParticipationTimeSheetProps {
  title: string;
  data: Array<{
    participations: Participation[];
    person?: Person;
    projectAsRowHead?: boolean;
    entries: Array<{
      date: string;
      loggedTimeInSeconds: number;
      predictedTimeInSeconds: number;
      timeInSeconds: number;
      workday?: boolean;
    }>;
    taskEntries?: {
      [id: string]: Array<{
        date: string;
        loggedTimeInSeconds: number;
        predictedTimeInSeconds: number;
        timeInSeconds: number;
        workday?: boolean;
      }>;
    };
    end?: React.ReactNode;
  }>;
  exportable?: boolean;
  monthPicker?: boolean;
  positionsCount?: number;
  compact?: boolean;
  variant: 'worklog' | 'timeline';
  onCreateWorklog?: (worklog: Worklog) => void;
  editable?: boolean;
  projectAsRowHead?: boolean;
}

const ParticipationTimeSheet: React.FC<ParticipationTimeSheetProps> = ({
  title,
  data,
  exportable,
  monthPicker,
  compact,
  variant,
  positionsCount,
  onCreateWorklog,
  editable,
  projectAsRowHead: initialProjectAsRowHead,
}) => {
  const personStore = useResolver(PersonStore);
  const projectStore = useResolver(ProjectStore);
  const taskStore = useResolver(TaskStore);
  const tagStore = useResolver(TagStore);
  const fileExportService = useResolver(FileExportService);
  const month = useMonthPicker();
  const fileName = `${title} (${formatDate(month, 'LLL, yyyy')})`;
  const worklogStore = useResolver(WorklogStore);

  const labels = getAllDatesInMonth(month).map((date) => {
    return {
      value: date.getDate().toString().padStart(2, '0'),
      date,
    };
  });

  const timesheet: Timesheet = {
    labels,
    rows: data.flatMap(
      ({
        person: initialPerson,
        participations,
        entries,
        taskEntries,
        projectAsRowHead = initialProjectAsRowHead,
        end,
      }) => {
        const person =
          initialPerson ??
          personStore.getByIdOrLoad(participations[0]?.personId);
        const project: Project | null = projectAsRowHead
          ? projectStore.getByIdOrLoad(participations[0]?.projectId)
          : null;

        const tasks = taskStore
          .getProjectTasks(participations[0]?.projectId)
          .filter((t) => t.personId === person.id);

        let taskReturn;

        if (taskEntries && tasks.length > 0) {
          taskReturn = tasks.map((task: Task) => {
            const filteredTaskEntries = taskEntries[task.id] || [];
            return {
              head: task.name,
              type: 'task',
              worked: filteredTaskEntries
                .filter((e) => toDate(e.date) <= new Date())
                .reduce(
                  (total, current) =>
                    total + current?.loggedTimeInSeconds / 3600,
                  0,
                ),
              planned: 0,
              total: filteredTaskEntries.reduce(
                (total, current) => total + current?.loggedTimeInSeconds / 3600,
                0,
              ),
              end,
              participations: [],
              columns: labels.map((label, labelIndex) => {
                const entry = filteredTaskEntries[labelIndex];
                return {
                  value: entry?.loggedTimeInSeconds
                    ? entry?.loggedTimeInSeconds / 3600
                    : 0,
                  date: label.date,
                  future: label.date > new Date(),
                  weekend: isWeekend(label.date),
                  participations: [],
                  onChange: async (v: number) => {
                    const worklogEntry = entries[labelIndex];
                    let tasklog: Worklog = new Worklog().fromObject({
                      personId: person?.id,
                      projectId: participations[0]?.projectId,
                      participationId: participations[0]?.id,
                      taskId: task.id,
                      date: toDate(label.date),
                    });
                    tasklog.timeInSeconds =
                      Math.round((v || 0) * 3600) -
                      (entry?.loggedTimeInSeconds || 0);
                    await worklogStore.save(tasklog);
                    onCreateWorklog?.(tasklog);
                    //when there is worklog withouth manual entry then add new entry time to remove task time
                    if (
                      worklogEntry &&
                      worklogEntry.loggedTimeInSeconds === 0
                    ) {
                      let worklog: Worklog = new Worklog().fromObject({
                        personId: person?.id,
                        projectId: participations[0]?.projectId,
                        participationId: participations[0]?.id,
                        date: toDate(label.date),
                      });
                      const timeLeft =
                        worklogEntry?.predictedTimeInSeconds / 3600 - (v || 0);
                      worklog.timeInSeconds =
                        timeLeft > 0 ? timeLeft * 3600 : 0;

                      await worklogStore.save(worklog);
                      onCreateWorklog?.(worklog);
                    }
                  },
                };
              }),
            };
          });
        }

        const returnLine = {
          head: projectAsRowHead ? project?.name : person?.name,
          type: 'default',
          ...(projectAsRowHead ? { project } : { person }),
          worked: entries
            .filter((e) => toDate(e.date) <= new Date())
            .reduce(
              (total, current) => total + current.timeInSeconds / 3600,
              0,
            ),
          planned: entries.reduce(
            (total, current) => total + current.predictedTimeInSeconds / 3600,
            0,
          ),
          total: entries.reduce(
            (total, current) => total + current.timeInSeconds / 3600,
            0,
          ),
          end,
          participations,
          columns: labels.map((label, labelIndex) => {
            const entry = entries[labelIndex];

            if (!entry) {
              return {
                value: 0,
                date: label.date,
                future: label.date > new Date(),
                weekend: isWeekend(label.date),
                participations: [],
              };
            }

            const date = toDate(entry.date);

            return {
              value: entry.timeInSeconds / 3600,
              date,
              worklog: null,
              future: date > new Date(),
              weekend: !entry.workday,
              participations,
              onChange: editable
                ? async (v: number) => {
                    let worklog: Worklog = new Worklog().fromObject({
                      personId: person?.id,
                      projectId: participations[0]?.projectId,
                      participationId: participations[0]?.id,
                      date: toDate(entry.date),
                    });
                    worklog.timeInSeconds = Math.round(
                      (v || 0) * 3600 - entry.loggedTimeInSeconds,
                    );

                    await worklogStore.save(worklog);

                    onCreateWorklog?.(worklog);
                  }
                : null,
            };
          }),
        };

        if (taskReturn?.length > 0) {
          return [returnLine, ...taskReturn];
        }

        return returnLine;
      },
    ),
  };

  if (!timesheet.rows.length) {
    return (
      <div>
        <HStack justifyContent="center">
          {monthPicker && <RouterMonthPicker />}
        </HStack>
        <NoContent>
          No participations in {formatDate(month, 'LLLL yyyy')}
        </NoContent>
      </div>
    );
  }

  return (
    <Stack>
      <HStack justifyContent="space-between">
        <div />
        <div>
          {monthPicker && (
            <MonthPicker
              value={month}
              linkTo={(m) => `?month=${formatDate(m, 'MM/yyyy')}`}
            />
          )}
        </div>
        <div>
          {exportable && (
            <>
              <DocumentExport
                handle={<></>}
                orientation="landscape"
                filename={fileName}
              >
                <ProjectTimesheetDocument month={month} title={title}>
                  <ParticipationTimeSheet
                    title={title}
                    data={data}
                    compact
                    variant={variant}
                  />
                </ProjectTimesheetDocument>
              </DocumentExport>
              <MoreActionsMenu>
                <MenuItem to="?export=pdf" preserveQuery>
                  Export as PDF
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    const sheet = timesheetToSheet(title, month, timesheet);

                    fileExportService.exportXls(
                      sheet,
                      'Monthly timesheet',
                      fileName,
                    );
                  }}
                >
                  Export as XLS
                </MenuItem>
              </MoreActionsMenu>
            </>
          )}
        </div>
      </HStack>
      <TimeSheet
        rows={timesheet.rows.map((row, i) => {
          const capacityHours = calculateHoursFromCapacity(
            row?.person?.capacity,
            month,
          );

          return {
            id: `${row.person?.id}-${row.project?.id}-${i}`,
            head: compact ? (
              row.head
            ) : row.type && row.type === 'task' ? (
              <Text fontSize="small" sx={{ float: 'right' }}>
                {row.head}
              </Text>
            ) : (
              <div>
                {row.person && (
                  <HStack spacing={4} justifyContent="space-between" mr={4}>
                    <ConnectedPersonUser
                      maxWidth="280px"
                      truncated
                      linkToProfile
                      hideOrganization
                      personId={row.person?.id}
                      {...(variant === 'worklog' &&
                      row.participations.length === 1
                        ? {
                            subTitle: tagStore.getByIdOrLoad(
                              row.participations[0].tagId,
                            )?.name,
                          }
                        : {})}
                    />
                    <CapacityMultiCircle
                      personCapacity={row.person.capacity}
                      personAllocation={row.planned / capacityHours}
                      personUtilization={row.worked / capacityHours}
                      size={'s'}
                    />
                  </HStack>
                )}
                {row.project && (
                  <Text fontSize="small">
                    <ProjectLink project={row.project} />
                  </Text>
                )}
              </div>
            ),
            total: variant === 'timeline' ? null : row.total,
            end: row.end,
            columns: row.columns.map((column) => {
              return {
                ...column,
                highlighted: column.weekend,
                ...(variant === 'timeline'
                  ? {
                      future: false,
                      value: null,
                      fills: column.weekend
                        ? []
                        : column.participations.map((p) => {
                            return {
                              color: getProjectColor(p.projectId),
                              volume: p.capacity,
                              tooltip: (
                                <ParticipationQuickInfo participation={p} />
                              ),
                            };
                          }),
                    }
                  : {}),
              };
            }),
          };
        })}
        labels={timesheet.labels}
      />
      {variant !== 'timeline' && (
        <HStack justifyContent="flex-end" pt={4}>
          {compact ? (
            <HStack spacing={2}>
              <Text fontSize="sm">Total</Text>
              <Text fontWeight="bold">
                {
                  +timesheet.rows
                    .reduce((total, row) => total + row.worked, 0)
                    .toFixed(1)
                }
                h
              </Text>
            </HStack>
          ) : (
            <TimesheetStat
              nominal={
                positionsCount
                  ? capacityToDayHours(
                      getAllWorkdaysInMonth(month).length * positionsCount,
                    )
                  : null
              }
              planned={
                +timesheet.rows
                  .reduce((total, row) => total + row.total, 0)
                  .toFixed(1)
              }
              worked={
                +timesheet.rows
                  .reduce((total, row) => total + row.worked, 0)
                  .toFixed(1)
              }
              contractual={
                +timesheet.rows
                  .reduce((total, row) => total + row.planned, 0)
                  .toFixed(1)
              }
            />
          )}
        </HStack>
      )}
    </Stack>
  );
};

export default observer(ParticipationTimeSheet);
