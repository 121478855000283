import AbstractResourceStore from './AbstractResourceStore';
import { singleton } from 'tsyringe';
import AccountStore from './AccountStore';
import OrganizationStore from './OrganizationStore';
import PaymentSchedule from '../domain/PaymentSchedule';
import PaymentScheduleService from '../services/PaymentScheduleService';

@singleton()
export default class PaymentScheduleStore extends AbstractResourceStore<PaymentSchedule> {
  public constructor(
    private readonly paymentScheduleService: PaymentScheduleService,
    private readonly organizationStore: OrganizationStore,
    accountStore: AccountStore,
  ) {
    super(paymentScheduleService, accountStore);
  }

  public async loadByProjectId(projectId: string) {
    await this.setManyEventually(
      this.paymentScheduleService.getAllByProject(projectId),
    );
  }

  public getItemByProjectId(
    projectId: string,
    date?: Date,
  ): PaymentSchedule | null {
    return (
      this.items
        .filter(
          (i) => i.projectId === projectId && (!date || date >= i.dateStart),
        )
        .sort((a, b) => {
          return +b.createdAt - +a.createdAt;
        })[0] || null
    );
  }
}
