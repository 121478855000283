import React from 'react';
import IconButton, { IconButtonProps } from './IconButton';
import { FaTimes } from 'react-icons/fa';

export interface RejectButtonProps extends Partial<IconButtonProps> {}

const RejectButton: React.FC<RejectButtonProps> = (props) => {
  return (
    <IconButton
      size="sm"
      variant="ghost"
      aria-label="Reject"
      icon={<FaTimes />}
      color="red.500"
      tooltip
      {...props}
    />
  );
};

export default RejectButton;
