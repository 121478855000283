import React from 'react';
import { BudgetSheetRow } from '../lib/BudgetSheetBuilder';
import ResponsiveGrid from '../ui/ResponsiveGrid';
import PersonUser from './PersonUser';
import CapacityLabel from './CapacityLabel';
import AutoGrid from '../ui/AutoGrid';
import ConnectedPricePopover from '../containers/ConnectedPricePopover';
import Box from '../ui/Box';
import BudgetRowCalculatedValues from './BudgetRowCalculatedValues';
import ConnectedProjectLink from '../containers/ConnectedProjectLink';
import ConnectedTagWithIcon from '../containers/ConnectedTagWithIcon';
import Text from '../ui/Text';
import { PriceType } from '../domain/Price';
import ConnectedLabeledPriceValue from '../containers/ConnectedLabeledPriceValue';
import { t } from '../i18n';
import CapacityMultiCircle from './CapacityMultiCircle';

export interface BudgetRowProps extends BudgetSheetRow {
  variant?: 'person' | 'project';
  hidePersonCost?: boolean;
  hidePositionPrice?: boolean;
  editable?: boolean;
  showMultiRings?: boolean;
  multRingSize?: 's' | 'm' | 'l';
  allocation?: number;
  utilization?: number;
}

const BudgetRow: React.FC<BudgetRowProps> = ({
  variant,
  person,
  capacity,
  position,
  positionPrice,
  personCost,
  costPrognosis,
  marginPrognosis,
  revenuePrognosis,
  hidePersonCost,
  hidePositionPrice,
  editable,
  showMultiRings,
  multRingSize,
  allocation,
  utilization,
}) => {
  return (
    <ResponsiveGrid
      templateColumns="211px 139px 1fr"
      alignItems="center"
      spacing={4}
    >
      {variant === 'person' && (
        <PersonUser hideOrganization linkToProfile person={person} />
      )}
      {variant === 'project' && (
        <div>
          {position ? (
            <>
              <Text isTruncated fontSize="sm">
                <ConnectedProjectLink projectId={position?.projectId} />
              </Text>
              <Text isTruncated>
                <ConnectedTagWithIcon tagId={position?.tagId} />
              </Text>
            </>
          ) : (
            <Text fontSize="sm" fontStyle="italic" color="gray.400">
              {costPrognosis < 0
                ? t('Overallocated capacity return')
                : t('Unallocated capacity')}
            </Text>
          )}
        </div>
      )}
      {showMultiRings ? (
        <CapacityMultiCircle
          personCapacity={person.capacity}
          personAllocation={allocation}
          personUtilization={utilization}
          size={multRingSize ? multRingSize : 'm'}
        />
      ) : (
        <CapacityLabel capacity={capacity} />
      )}
      <AutoGrid childWidth="150px" rowGap={2}>
        {!hidePositionPrice && (
          <ConnectedPricePopover
            externalId={position?.id}
            price={positionPrice || null}
            disabled={!editable}
            handle={
              <Box
                p={2}
                borderRadius="md"
                bg={editable ? 'gray.50' : undefined}
                m={-2}
              >
                <ConnectedLabeledPriceValue
                  label="Revenue"
                  price={positionPrice}
                  defaultType={PriceType.HOURLY}
                />
              </Box>
            }
          />
        )}
        {!hidePersonCost && (
          <ConnectedPricePopover
            externalId={person?.id}
            price={personCost || null}
            disabled={!editable}
          />
        )}
        <BudgetRowCalculatedValues
          revenuePrognosis={revenuePrognosis}
          costPrognosis={costPrognosis}
          marginPrognosis={marginPrognosis}
          hideRevenue={!position}
        />
      </AutoGrid>
    </ResponsiveGrid>
  );
};

BudgetRow.defaultProps = {
  variant: 'person',
};

export default BudgetRow;
