import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from 'react-router-dom';
import { mergeQueryStrings } from '../util/url';

export interface LinkProps extends Omit<RouterLinkProps, 'to'> {
  preserveQuery?: boolean;
  to: string;
  external?: boolean;
  newWindow?: boolean;
}

const Link: React.FC<LinkProps> = ({
  to: initialTo,
  preserveQuery,
  external,
  newWindow,
  ...props
}) => {
  const location = useLocation();

  let to: string = initialTo;

  if (preserveQuery) {
    const [pathname, newSearch] = initialTo.split('?');
    to = `${pathname || ''}?${mergeQueryStrings(location.search, newSearch)}`;
  }

  if (!to) {
    return <span {...props} />;
  }

  if (external) {
    return (
      <a
        href={to}
        {...(newWindow === false
          ? {}
          : { target: '_blank', rel: 'noreferrer' })}
        {...props}
      >
        {props.children}
      </a>
    );
  }

  return <RouterLink to={to} {...props} />;
};

export default Link;
