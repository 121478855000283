import Invoice from '../domain/Invoice';
import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';

@injectable()
export default class InvoiceService extends AbstractRestfulHttpService<Invoice> {
  public constructor(apiService: ApiService) {
    super(Invoice, apiService);
  }

  public getAllByOrganization(organizationId: string): Promise<Invoice[]> {
    return this.getMany({ organizationId });
  }
}
