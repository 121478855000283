import React from 'react';
import Page from '../components/Page';
import ConnectedOrganizationPersonsTimeline from '../containers/ConnectedOrganizationPersonsTimeline';
import RouterTabs from '../components/RouterTabs';
import Tab from '../ui/Tab';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import { Role } from '../domain/Person';
import ConnectedOrganizationPersonsBudget from '../containers/ConnectedOrganizationPersonsBudget';
import { t } from '../i18n';

const OrganizationPeople: React.FC = () => {
  const personStore = useResolver(PersonStore);
  const viewer = personStore.currentPerson;

  return (
    <RouterTabs preserveQuery>
      <Tab title={t('Timeline')}>
        <Page>
          <ConnectedOrganizationPersonsTimeline />
        </Page>
      </Tab>
      {viewer.hasPermission(Role.ADMIN) && (
        <Tab title={t('Finances')}>
          <Page>
            <ConnectedOrganizationPersonsBudget />
          </Page>
        </Tab>
      )}
    </RouterTabs>
  );
};

export default OrganizationPeople;
