import React from 'react';
import Icon, { IconProps } from '../Icon';
import { FiList } from 'react-icons/all';

export interface ListIconProps extends IconProps {}

const ListIcon: React.FC<ListIconProps> = ({ ...props }) => {
  return <Icon as={FiList} {...props} />;
};

export default ListIcon;
