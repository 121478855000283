import React, { Fragment, ReactNode } from 'react';
import { AutoGrid } from '../index';

export interface DataGridField<Item, Value = unknown> {
  title?: string;
  accessor: (
    item: Item,
    index: number,
  ) => Value | { value: Value; node: ReactNode };
  hidden?: boolean;
  hiddenInExport?: boolean;
}

function processAccessor<Value = unknown>(
  value: Value | { value: Value; node: ReactNode },
): { value: Value; node: ReactNode } {
  if (value && typeof value === 'object' && 'value' in value) {
    return value;
  }

  return {
    value: value as Value,
    node: value,
  };
}

export interface DataGridItemsProps<Item, Key extends string> {
  data: Item[];
  fields: Record<Key, DataGridField<Item>>;
  render: (input: {
    item: Item;
    index: number;
    getField: (key: Key) => ReactNode;
  }) => ReactNode;
}

export const DataGridItems = <Item, Key extends string>({
  data,
  render,
  fields,
}: DataGridItemsProps<Item, Key>) => {
  return (
    <AutoGrid childWidth="320px" spacing={4} alignItems="start" role="list">
      {data.map((item, index) => {
        return (
          <Fragment key={index}>
            {render({
              item,
              index,
              getField: (key) =>
                processAccessor(fields[key].accessor(item, index)).node,
            })}
          </Fragment>
        );
      })}
    </AutoGrid>
  );
};
