import React from 'react';
import DrawerForm, { DrawerFormProps } from './DrawerForm';
import Stack from '../ui/Stack';
import FormField from './FormField';
import DateInput from '../ui/DateInput';
import { useTranslation } from 'react-i18next';
import { Input } from '../ui';

export interface AccessTokenFormValues {
  name: string;
  expireDate?: string | null;
}

export interface AccessTokenFormDrawerProps
  extends Omit<DrawerFormProps, 'children'> {
  name: string;
  tokenResult?: string;
}

const AccessTokenFormDrawer: React.FC<AccessTokenFormDrawerProps> = ({
  tokenResult,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <DrawerForm
      header={t('Create personal access token')}
      {...props}
      submitButton={{ text: t('Create') }}
      autoClose={false}
    >
      <Stack spacing={4}>
        <FormField name="name" label={t('Name')} required>
          <Input autoFocus />
        </FormField>

        <FormField name="expireDate" label={t('Expire date (optional)')}>
          <DateInput placeholder={t('Optional')} />
        </FormField>
      </Stack>
    </DrawerForm>
  );
};

export default AccessTokenFormDrawer;
