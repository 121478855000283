import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import PaymentMethodStore from '../store/PaymentMethodStore';
import CardsManagement from '../components/CardsManagement';

export interface ConnectedCardsManagementProps {}

const ConnectedCardsManagement: React.FC<
  ConnectedCardsManagementProps
> = () => {
  const paymentMethodStore = useResolver(PaymentMethodStore);

  useEffect(() => {
    paymentMethodStore.loadByCurrentOrganization().catch(() => null);
  }, []);

  return (
    <CardsManagement
      cards={paymentMethodStore.currentOrganizationItems}
      loading={paymentMethodStore.pending}
    />
  );
};

export default observer(ConnectedCardsManagement);
