import React from 'react';
import { observer } from 'mobx-react';
import DeleteDrawer from '../components/DeleteDrawer';
import useResolver from '../hooks/useResolver';
import { t } from '../i18n';
import PriceStore from '../store/PriceStore';
import useQueryValue from '../hooks/useQueryValue';
import { getHumanReadablePriceType } from '../util/price';

export interface ConnectedPriceDeleteDrawerProps {
  name: string;
  onChange?: () => void;
}

const ConnectedPriceDeleteDrawer: React.FC<ConnectedPriceDeleteDrawerProps> = ({
  name,
  onChange,
}) => {
  const priceStore = useResolver(PriceStore);
  const priceId = useQueryValue(name);

  if (!priceId) {
    return null;
  }

  const handleSubmit = async () => {
    await priceStore.delete(priceId);
    onChange && onChange();
  };

  const price = priceStore.getById(priceId);

  return (
    <DeleteDrawer
      name={name}
      onSubmit={handleSubmit}
      confirmationText={null}
      header={`${t('Delete')} ${getHumanReadablePriceType(price?.type)}`}
    />
  );
};

export default observer(ConnectedPriceDeleteDrawer);
