import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import OrganizationStore from '../store/OrganizationStore';
import PersonStore from '../store/PersonStore';
import PersonUser, { PersonUserProps } from '../components/PersonUser';

export interface ConnectedUserProps extends Omit<PersonUserProps, 'person'> {
  userId: string;
}

const ConnectedUser: React.FC<ConnectedUserProps> = ({ userId, ...props }) => {
  const organizationStore = useResolver(OrganizationStore);
  const personStore = useResolver(PersonStore);

  if (!userId) {
    return null;
  }

  const persons = personStore.getFilteredItems({
    userId,
    organizationId: organizationStore.currentOrganizationId,
  });

  if (persons.length <= 0) {
    return null;
  }

  return <PersonUser person={persons[0]} {...props} />;
};

export default observer(ConnectedUser);
