import {
  Modal as ChModal,
  ModalProps as ChModalProps,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/react';
import { ReactNode, FC } from 'react';

export interface ModalProps extends ChModalProps {
  header?: ReactNode;
  footer?: ReactNode;
}

const Modal: FC<ModalProps> = ({ header, footer, children, ...props }) => {
  return (
    <ChModal isCentered {...props}>
      <ModalOverlay />
      <ModalContent>
        {header && <ModalHeader>{header}</ModalHeader>}
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </ChModal>
  );
};

export default Modal;
