import Resource from '../decorators/Resource';
import AbstractDomain from './AbstractDomain';
import { formatDate, toDate } from '../util/date';
import { t } from '../i18n';

export enum RequestAction {
  CHANGE_PROJECT = 'CHANGE_PROJECT',
  TALK = 'TALK',
  BREAK = 'BREAK',
  CHANGE_CAPACITY = 'CHANGE_CAPACITY',
  NEED_MORE_PEOPLE = 'NEED_MORE_PEOPLE',
  TASK = 'TASK',
}

export enum RequestTiming {
  ASAP = 'ASAP',
  NEXT_WEEK = 'NEXT_WEEK',
  NEXT_MONTH = 'NEXT_MONTH',
  NEXT_YEAR = 'NEXT_YEAR',
  CUSTOM_DATE = 'CUSTOM_DATE',
}

export enum RequestState {
  REQUESTED = 'REQUESTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
}

export const stringifiedRequestTiming: { [key in RequestTiming]: string } = {
  ASAP: t('As soon as possible'),
  NEXT_MONTH: t('Next month'),
  NEXT_WEEK: t('Next week'),
  NEXT_YEAR: t('Next year'),
  CUSTOM_DATE: t('Custom date'),
};

@Resource.Name('requests')
export default class Request extends AbstractDomain {
  public action: RequestAction;
  public description: string;
  public personId: string;
  public timing?: RequestTiming;
  public customDate?: Date;
  public toProjectId?: string;
  public toTagId?: string;
  public fromParticipationId?: string;
  public capacity?: number;
  public state: RequestState;
  public targetPersonId?: string;

  fromObject(payload: Partial<Request>): this {
    super.fromObject(payload);

    this.action = payload.action;
    this.description = payload.description;
    this.personId = payload.personId;
    this.timing = payload.timing;
    this.customDate = toDate(payload.customDate);
    this.toProjectId = payload.toProjectId;
    this.toTagId = payload.toTagId;
    this.fromParticipationId = payload.fromParticipationId;
    this.capacity = payload.capacity;
    this.state = payload.state;
    this.targetPersonId = payload.targetPersonId;

    return this;
  }

  toObject(): Partial<Request> {
    return {
      ...super.toObject(),
      action: this.action,
      description: this.description,
      personId: this.personId,
      timing: this.timing,
      customDate: this.customDate,
      toProjectId: this.toProjectId,
      toTagId: this.toTagId,
      fromParticipationId: this.fromParticipationId,
      capacity: this.capacity,
      state: this.state,
      targetPersonId: this.targetPersonId,
    };
  }

  getStringifedTiming(): string {
    if (this.customDate) {
      return formatDate(this.customDate);
    }

    if (!this.timing) {
      return null;
    }

    return stringifiedRequestTiming[this.timing];
  }
}
