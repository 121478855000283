import React from 'react';
import { Container, HStack, IconButton, Stack } from '@chakra-ui/react';
import Drawer from './Drawer';
import Card from './Card';
import Logo from './Logo';
import Menu from './Menu';
import VStack from './VStack';
import BurgerIcon from './icons/BurgerIcon';
import MenuList from './MenuList';

export interface MobileLayoutProps {
  navigation: React.ReactNode;
  notificationsHandle?: React.ReactNode;
  currentUser?: React.ReactNode;
  secondaryUsers?: React.ReactNode;
  userNavigation?: React.ReactNode;
  quickActions?: React.ReactNode;
  menuOpen: boolean;
  setMenuOpen: (value: boolean) => void;
  search?: React.ReactNode;
}

const MobileLayout: React.FC<MobileLayoutProps> = ({
  children,
  navigation,
  currentUser,
  userNavigation,
  notificationsHandle,
  secondaryUsers,
  quickActions,
  menuOpen,
  setMenuOpen,
  search,
}) => {
  return (
    <div>
      <Card px={2} py={1} borderRadius={null}>
        <HStack pl={4} justifyContent="space-between" alignItems="center">
          <Logo size="sm" />
          <HStack spacing={4}>
            {notificationsHandle}
            <IconButton
              onClick={() => setMenuOpen(!menuOpen)}
              icon={<BurgerIcon />}
              variant="ghost"
              aria-label="Menu"
            />
          </HStack>
        </HStack>
      </Card>
      <Drawer
        header={
          <Menu placement="bottom-start" handle={currentUser} withChevron>
            <MenuList>{secondaryUsers}</MenuList>
          </Menu>
        }
        onClose={() => setMenuOpen(false)}
        isOpen={menuOpen}
      >
        <Stack
          p={4}
          spacing={8}
          justifyContent="space-between"
          height="100%"
          overflow="auto"
        >
          <div>
            <VStack>
              <div>{search}</div>
              <div>{quickActions}</div>
            </VStack>
            <div>{navigation}</div>
          </div>
          <Stack alignItems="flex-start">{userNavigation}</Stack>
        </Stack>
      </Drawer>
      <Container mt={8}>{children}</Container>
    </div>
  );
};

MobileLayout.defaultProps = {};

export default MobileLayout;
