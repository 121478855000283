import React from 'react';
import { FiDownload } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface DownloadIconProps extends IconProps {}

const DownloadIcon: React.FC<DownloadIconProps> = ({ ...props }) => {
  return <Icon as={FiDownload} {...props} />;
};

export default DownloadIcon;
