import React from 'react';
import Input from '../ui/Input';
import VStack from '../ui/VStack';
import Form from './Form';
import FormField from './FormField';
import IndustrySelect from './IndustrySelect';
import FormSubmitButton from './FormSubmitButton';
import { t } from '../i18n';

export interface OrganizationCreateFormPayload {
  name: string;
  industry: string;
  person: { name: string };
}

export interface OrganizationCreateFormProps {
  onSubmit: (data: OrganizationCreateFormPayload) => void;
  currentPersonName?: string;
}

const OrganizationCreateForm: React.FC<OrganizationCreateFormProps> = ({
  onSubmit,
  currentPersonName,
}) => {
  return (
    <Form onSubmit={onSubmit}>
      <VStack spacing={8}>
        <FormField name="name" required label={t('Company Name')}>
          <Input autoFocus />
        </FormField>

        <FormField
          name="person.name"
          required
          defaultValue={currentPersonName || ''}
          label={t('Full name')}
        >
          <Input name="person.name" />
        </FormField>

        <FormField name="industry" required label={t('Field')}>
          <IndustrySelect />
        </FormField>
        <FormSubmitButton width="100%">{t('Next')}</FormSubmitButton>
      </VStack>
    </Form>
  );
};

export default OrganizationCreateForm;
