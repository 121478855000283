import React from 'react';
import Link, { LinkProps } from '../ui/Link';
import { Route } from '../routes';

export interface RouteLinkProps extends Omit<LinkProps, 'to'> {
  route: Route;
}

const RouteLink: React.FC<RouteLinkProps> = ({ route, children, ...props }) => {
  return (
    <Link
      to={route.path}
      external={route.hardLink}
      newWindow={false}
      {...props}
    >
      {children}
    </Link>
  );
};

export default RouteLink;
