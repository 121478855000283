import React from 'react';
import FormField from './FormField';
import { observer } from 'mobx-react';
import Tag, { TagType } from '../domain/Tag';
import { stringifyTagType } from '../util/tag';
import ConnectedTagsSelect from '../containers/ConnectedTagsSelect';
import { t } from '../i18n';

export interface TagsFieldProps {
  availableTags: Tag[];
  assignedTags: Tag[];
  groupByType?: false | TagType[];
  name?: string;
  label?: string;
  type?: TagType;
  context?: string;
}

const TagsField: React.FC<TagsFieldProps> = ({
  availableTags,
  assignedTags,
  groupByType,
  name,
  label,
  type,
  context,
}) => {
  let defaultValue: null | { value: string; label: string }[] = null;

  if (assignedTags?.length > 0) {
    defaultValue = assignedTags
      .filter((tag) => availableTags.find((t) => tag.id === t.id))
      .map((tag) => ({
        value: tag.id,
        label: tag.name,
        tag,
      }));
  }

  if (groupByType) {
    return (
      <>
        {groupByType.map((type) => {
          const availableTagsForType = availableTags.filter(
            (t) => t.type === type,
          );

          return (
            <TagsField
              name={`${name}.${type}`}
              label={stringifyTagType(type)}
              availableTags={availableTagsForType}
              assignedTags={assignedTags.filter((tag) =>
                availableTagsForType.find((t) => t.id === tag.id),
              )}
              type={type}
            />
          );
        })}
      </>
    );
  }

  return (
    <FormField
      key={assignedTags.length}
      name={name}
      label={label}
      defaultValue={defaultValue}
    >
      <ConnectedTagsSelect type={type} context={context} tags={availableTags} />
    </FormField>
  );
};

TagsField.defaultProps = {
  name: 'tags',
  label: t('Tags'),
};

export default observer(TagsField);
