import { useToast as chakraUseToast, UseToastOptions } from '@chakra-ui/react';

export default function useToast() {
  const toast = chakraUseToast();

  return (options: UseToastOptions) =>
    toast({
      isClosable: true,
      ...options,
    });
}
