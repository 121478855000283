import React from 'react';
import {
  VStack as ChakraVStack,
  StackProps as ChakraVStackProps,
} from '@chakra-ui/react';

export interface VStackProps extends ChakraVStackProps {}

const VStack: React.FC<VStackProps> = ({ ...props }) => {
  return <ChakraVStack {...props} />;
};

VStack.defaultProps = {};

export default VStack;
