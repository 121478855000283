import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import OrganizationStore from '../store/OrganizationStore';
import Organization from '../domain/Organization';

export interface ConnectedOrganizationProps {
  children: (organization?: Organization) => any;
  organizationId: string;
}

const ConnectedOrganization: React.FC<ConnectedOrganizationProps> = ({
  children,
  organizationId,
}) => {
  const organizationStore = useResolver(OrganizationStore);

  return <>{children(organizationStore.getByIdOrLoad(organizationId))}</>;
};

export default observer(ConnectedOrganization);
