import { useEffect, useState } from 'react';

interface HistoricalItem<T> {
  value: T;
  deletedAt?: Date;
  createdAt: Date;
}

export default function useHistoricalArray<T extends { id: string }>(
  value: T[],
): HistoricalItem<T>[] {
  const [current, setCurrent] = useState<HistoricalItem<T>[]>([]);

  useEffect(() => {
    const newValue: HistoricalItem<T>[] = [];
    const existingIds: string[] = [];

    // Loop through supplied values
    value.forEach((item) => {
      const existing = current.find((c) => c.value.id === item.id);

      // Update existing values
      if (existing) {
        existingIds.push(existing.value.id);
        newValue.push({
          ...existing,
          value: item,
          deletedAt: null,
        });
        // Add new values
      } else {
        newValue.push({
          value: item,
          createdAt: new Date(),
        });
      }
    });

    // Mark all removed values as deleted
    current.forEach((item, index) => {
      if (existingIds.includes(item.value.id)) {
        return;
      }

      newValue.splice(index, 0, {
        ...item,
        deletedAt: new Date(),
      });
    });

    setCurrent(newValue);
  }, [value]);

  return current;
}
