import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';
import Invitation from '../domain/Invitation';

@injectable()
export default class InvitationService extends AbstractRestfulHttpService<Invitation> {
  public constructor(apiService: ApiService) {
    super(Invitation, apiService);
  }

  public async accept(hash: string) {
    await this.client.put(`/${this.resource}/${hash}`);
  }

  public async getAllByOrganization(
    organizationId: string,
  ): Promise<Invitation[]> {
    return this.getMany({ organizationId });
  }
}
