export function equalsOrIsInArray<T>(
  value: T | T[],
  expectedValue: T,
): boolean {
  if (Array.isArray(value)) {
    return value.includes(expectedValue);
  }

  return value === expectedValue;
}
