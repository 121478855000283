import React from 'react';
import ConnectedProjectList from '../containers/ConnectedProjectList';
import { ProjectType } from '../domain/Project';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';

const MyTrainings: React.FC = () => {
  const personStore = useResolver(PersonStore);

  return (
    <>
      <ConnectedProjectList
        editable={false}
        filters={{
          type: ProjectType.TRAINING,
          personId: personStore.currentPerson.id,
        }}
      />
    </>
  );
};

export default MyTrainings;
