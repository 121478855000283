import React from 'react';
import { addDays, addMonths } from 'date-fns';
import Stack from '../ui/Stack';
import Sparkline, { SparklineProps } from '../ui/Sparkline';
import HStack from '../ui/HStack';
import Text from '../ui/Text';
import { formatDate } from '../util/date';
import { getCapacityByDate } from '../util/capacity';
import Participation from '../domain/Participation';

export interface AvailabilityLineChartProps
  extends Omit<SparklineProps, 'points'> {
  participations: Participation[];
  displayDates?: boolean;
  date?: Date;
}

const AvailabilityLineChart: React.FC<AvailabilityLineChartProps> = ({
  participations,
  displayDates,
  date: suppliedDate,
  ...props
}) => {
  const capacities: number[] = [];
  let date = suppliedDate || new Date();
  let maxDate = addMonths(date, 3);

  while (date < maxDate) {
    capacities.push(getCapacityByDate(participations, date));
    date = addDays(date, 1);
  }

  return (
    <Stack width="100%">
      <Sparkline points={capacities} referenceLine={1} {...props} />
      {displayDates && (
        <HStack justifyContent="space-between">
          <Text fontSize="xs" color="brand.500" fontWeight="bold">
            {formatDate(new Date())}
          </Text>
          <Text fontSize="xs" color="brand.500" fontWeight="bold">
            {formatDate(maxDate)}
          </Text>
        </HStack>
      )}
    </Stack>
  );
};

export default AvailabilityLineChart;
