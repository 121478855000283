import React from 'react';
import {
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
} from '@chakra-ui/react';

export interface HeadingProps extends ChakraHeadingProps {}

const Heading: React.FC<HeadingProps> = ({ ...props }) => {
  return <ChakraHeading {...props} />;
};

Heading.defaultProps = {};

export default Heading;
