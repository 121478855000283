import React from 'react';
import { FiGrid } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface GridIconProps extends IconProps {}

const GridIcon: React.FC<GridIconProps> = ({ ...props }) => {
  return <Icon as={FiGrid} {...props} />;
};

export default GridIcon;
