import AbstractDomain from './AbstractDomain';
import Resource from '../decorators/Resource';

@Resource.Name('project-histories')
export default class HistoryEvent extends AbstractDomain {
  public userId?: string;
  public text: string;
  public projectId: string;

  public toObject(): Partial<HistoryEvent> {
    return {
      ...super.toObject(),
      userId: this.userId,
      text: this.text,
      projectId: this.projectId,
    };
  }

  public fromObject(payload: Partial<HistoryEvent>): this {
    super.fromObject(payload);

    this.userId = payload.userId;
    this.text = payload.text;
    this.projectId = payload.projectId;

    return this;
  }
}
