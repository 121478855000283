import React from 'react';
import { observer } from 'mobx-react';
import OrganizationCreateSkills from '../components/OrganizationCreateSkills';
import useResolver from '../hooks/useResolver';
import OrganizationStore from '../store/OrganizationStore';
import { useHistory } from 'react-router-dom';
import Tag from '../domain/Tag';
import { getPredefinedTagsByIndustry } from '../util/tag';
import TagStore from '../store/TagStore';

export interface ConnectedOrganizationCreateSkillsProps {
  nextStep?: string;
  organizationId?: string;
}

const ConnectedOrganizationCreateSkills: React.FC<
  ConnectedOrganizationCreateSkillsProps
> = ({ nextStep, organizationId }) => {
  const organizationStore = useResolver(OrganizationStore);
  const tagStore = useResolver(TagStore);
  const history = useHistory();

  const organization = organizationId
    ? organizationStore.getById(organizationId)
    : organizationStore.currentOrganization;

  if (!organization) {
    return null;
  }

  const handleSubmit = (tags: Tag[]) => {
    tagStore.createMany(
      tags.map((tag) => {
        tag.organizationId = organization.id;
        return tag;
      }),
    );
    nextStep && history.push(nextStep);
  };

  const currentOrganizationTags = tagStore.getFilteredItems({
    organizationId: organization.id,
  });
  const availableTags = getPredefinedTagsByIndustry(
    organization.industry,
  ).filter((tag) => {
    return !currentOrganizationTags.find(
      (t) => t.name === tag.name && t.type === tag.type,
    );
  });

  return (
    <OrganizationCreateSkills
      onSubmit={handleSubmit}
      availableTags={availableTags}
      organization={organization}
      organizationTags={currentOrganizationTags}
      nextStep={nextStep}
    />
  );
};

export default observer(ConnectedOrganizationCreateSkills);
