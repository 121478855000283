import React from 'react';
import Stack from '../ui/Stack';
import VStack from '../ui/VStack';
import ProjectPeriod from './ProjectPeriod';
import ConnectedProjectTagsGrid from '../containers/ConnectedProjectTagsGrid';
import Project from '../domain/Project';
import ConnectedPersonUser from '../containers/ConnectedPersonUser';
import Text from '../ui/Text';
import ConnectedProjectCapacityChart from '../containers/ConnectedProjectCapacityChart';
import Box from '../ui/Box';
import { getTextsByType } from '../util/project';
import ConnectedProjectLink from '../containers/ConnectedProjectLink';
import { useTranslation } from 'react-i18next';
import ConnectedDynamicFieldCardValues from '../features/dynamic-field/components/ConnectedDynamicFieldCardValues';
import { DYNAMIC_FIELD_RESOURCE_MAP } from './ProjectFormDrawer';

export interface ProjectQuickInfoProps {
  project: Project;
  displayOwnerPerson?: boolean;
  displayCapacityChart?: boolean;
  limitedTags?: boolean;
  displayRelatedProject?: boolean;
  displayDynamicFields?: boolean;
}

const ProjectQuickInfo: React.FC<ProjectQuickInfoProps> = ({
  project,
  displayOwnerPerson,
  displayCapacityChart,
  limitedTags,
  displayRelatedProject,
  displayDynamicFields,
}) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2} px={2} width="80%">
      {(project.dateStart || project.dateEnd) && (
        <VStack>
          <ProjectPeriod project={project} />
        </VStack>
      )}

      {displayRelatedProject && project.relatedToProjectId && (
        <>
          <Text fontSize="sm" fontWeight="bold" color="brand.500" pt={4}>
            {t('Relates to')}
          </Text>
          <ConnectedProjectLink projectId={project.relatedToProjectId} />
        </>
      )}

      {displayOwnerPerson && project.ownerPersonId && (
        <>
          <Text fontSize="sm" fontWeight="bold" color="brand.500" mb={2} pt={2}>
            {getTextsByType(project.type).owner}
          </Text>
          <ConnectedPersonUser
            hideOrganization
            personId={project.ownerPersonId}
          />
        </>
      )}

      <Stack pt={6}>
        <ConnectedProjectTagsGrid
          compact={limitedTags}
          projectId={project.id}
        />
      </Stack>

      {displayDynamicFields && (
        <ConnectedDynamicFieldCardValues
          dynamic={project.dynamic}
          tags={DYNAMIC_FIELD_RESOURCE_MAP[project.type]}
        />
      )}

      {displayCapacityChart && (
        <Box width="100%" pt={6}>
          <ConnectedProjectCapacityChart displayDates projectId={project.id} />
        </Box>
      )}
    </Stack>
  );
};

export default ProjectQuickInfo;
