import AbstractResourceStore from './AbstractResourceStore';
import Notification from '../domain/Notification';
import NotificationService from '../services/NotificationService';
import { computed } from 'mobx';
import { toDate } from '../util/date';
import PersonStore from './PersonStore';
import AccountStore from './AccountStore';
import { singleton } from 'tsyringe';

@singleton()
export default class NotificationStore extends AbstractResourceStore<Notification> {
  public constructor(
    private readonly notificationService: NotificationService,
    private readonly personStore: PersonStore,
    accountStore: AccountStore,
  ) {
    super(notificationService, accountStore);
  }

  public async loadByPerson(personId: string): Promise<void> {
    await this.setManyEventually(
      this.notificationService.getAllByPerson(personId),
    );
  }

  public async loadByActivePerson(): Promise<void> {
    const personId = this.personStore.currentPerson?.id;

    if (!personId) {
      throw new Error('No person selected');
    }

    return await this.loadByPerson(personId);
  }

  @computed
  public get currentPersonNotifications(): Notification[] {
    const personId = this.personStore.currentPerson?.id;

    if (!personId) {
      throw new Error('No person selected');
    }

    const notifications = this.getItems().filter(
      (item) => item.personId === personId,
    );

    notifications.sort(
      (a, b) => toDate(b.createdAt).getTime() - toDate(a.createdAt).getTime(),
    );

    return notifications;
  }

  @computed
  public get currentPersonUnreadNotificationsCount(): number {
    return this.currentPersonNotifications.filter((n) => !n.readAt).length;
  }

  public async markAsRead(notification: Notification) {
    await this.update(notification);
  }
}
