import React from 'react';
import Person from '../domain/Person';
import Menu from '../ui/Menu';
import routes, { Placement } from '../routes';
import { Link } from 'react-router-dom';
import HStack from '../ui/HStack';
import Text from '../ui/Text';
import PersonUser from './PersonUser';
import User from '../ui/User';
import UserDomain from '../domain/User';
import RouteLink from './RouteLink';

export interface UserMenuProps {
  currentPerson?: Person;
  otherPersons?: Person[];
  user?: UserDomain;
}

const UserMenu: React.FC<UserMenuProps> = ({
  currentPerson,
  otherPersons,
  user,
}) => {
  if (!user && !currentPerson) {
    return null;
  }
  const currentUser = currentPerson ? (
    <PersonUser align="right" person={currentPerson} />
  ) : (
    <User name={user?.getDisplayName()} />
  );

  const secondaryUsers = otherPersons.length > 0 && (
    <>
      {otherPersons.map((person) => (
        <Link key={person.id} to={`/switch-person/${person.id}`}>
          <PersonUser person={person} />
        </Link>
      ))}
    </>
  );

  const userNavigation = (
    <>
      {routes
        .filter((route) => route.placements?.includes(Placement.USER_MENU))
        .map((route) => (
          <RouteLink key={route.path} route={route}>
            <HStack spacing={2}>
              {route.icon}
              <Text fontSize="sm" pr={2}>
                {route.title}
              </Text>
            </HStack>
          </RouteLink>
        ))}
    </>
  );

  return (
    <Menu handle={currentUser} p={4}>
      {secondaryUsers}
      {userNavigation}
    </Menu>
  );
};

export default UserMenu;
