import React from 'react';
import { FaRegPaperPlane } from 'react-icons/fa';
import Icon, { IconProps } from '../Icon';

export interface PlaneIconProps extends IconProps {}

const PlaneIcon: React.FC<PlaneIconProps> = ({ ...props }) => {
  return <Icon as={FaRegPaperPlane} {...props} />;
};

export default PlaneIcon;
