import React, { useContext } from 'react';
import { Text } from '@chakra-ui/react';
import Box from './Box';
import { SidebarContext } from './Sidebar';

export interface NavItemGroupProps {
  title: string;
  'aria-label': string;
}

const NavItemGroup: React.FC<NavItemGroupProps> = ({
  title,
  children,
  ...rest
}) => {
  const sidebarContext = useContext(SidebarContext);

  return (
    <Box my={5} role="navigation" {...rest}>
      <Text
        mb={1}
        color="gray.400"
        fontSize="xs"
        casing="uppercase"
        visibility={sidebarContext.collapsed ? 'hidden' : undefined}
        maxWidth="212px"
      >
        {title}
      </Text>
      {children}
    </Box>
  );
};

export default NavItemGroup;
