import React from 'react';
import { observer } from 'mobx-react';
import PersonUser, { PersonUserProps } from '../components/PersonUser';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import OrganizationStore from '../store/OrganizationStore';

export interface ConnectedPersonUserProps
  extends Partial<Omit<PersonUserProps, 'person'>> {
  personId: string;
}

const ConnectedPersonUser: React.FC<ConnectedPersonUserProps> = ({
  personId,
  ...props
}) => {
  const personStore = useResolver(PersonStore);
  const organizationStore = useResolver(OrganizationStore);

  if (!personId) {
    return null;
  }

  const person = personStore.getByIdOrLoad(personId);

  if (
    person &&
    person.organizationId !== organizationStore.currentOrganization.id
  ) {
    person.organization = organizationStore.getById(person.organizationId);
  }

  return (
    <PersonUser
      currentUser={personStore.isCurrentPerson(personId)}
      person={personStore.getByIdOrLoad(personId)}
      hideOrganization={
        person?.organizationId === organizationStore.currentOrganization.id
      }
      {...props}
    />
  );
};

export default observer(ConnectedPersonUser);
