import React, { useEffect } from 'react';
import useResolver from '../../../hooks/useResolver';
import DynamicFieldStore from '../store/DynamicFieldStore';
import { observer } from 'mobx-react';
import DynamicFields, { DynamicFieldsProps } from './DynamicFields';

export interface ConnectedOrganizationDynamicFieldsProps
  extends Omit<DynamicFieldsProps, 'fields'> {
  organizationId: string;
}

const ConnectedOrganizationDynamicFields: React.FC<
  ConnectedOrganizationDynamicFieldsProps
> = ({ organizationId, ...props }) => {
  const dynamicFieldStore = useResolver(DynamicFieldStore);

  useEffect(() => {
    dynamicFieldStore.loadByOrganization(organizationId);
  }, [organizationId]);

  return (
    <DynamicFields
      fields={dynamicFieldStore.getFilteredItems({ organizationId })}
      {...props}
    />
  );
};

export default observer(ConnectedOrganizationDynamicFields);
