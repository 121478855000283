import React from 'react';
import Text from './Text';
import Tooltip from './Tooltip';

export interface MoreItemsProps {
  limit: number;
}

const MoreItems: React.FC<MoreItemsProps> = ({ children, limit }) => {
  const childrenArray = React.Children.toArray(children);

  const visibleItems = childrenArray.slice(0, limit);
  const hiddenItems = childrenArray.slice(limit);

  return (
    <>
      {visibleItems}
      {hiddenItems.length > 0 && (
        <Tooltip bg="white" color="black" label={hiddenItems}>
          <div>
            <Text color="gray.400" mt={1} fontSize="xs" cursor="zoom-in">
              {`+${hiddenItems.length} more`}
            </Text>
          </div>
        </Tooltip>
      )}
    </>
  );
};

MoreItems.defaultProps = {};

export default MoreItems;
