import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { singleton } from 'tsyringe';
import config from '../config';

const firebaseConfig = {
  authDomain: `${config.firebase.projectId}.firebaseapp.com`,
  storageBucket: `${config.firebase.projectId}.appspot.com`,
  ...config.firebase,
};

@singleton()
export default class FirebaseProvider {
  private static app: firebase.app.App = null;

  public getFirebase() {
    if (FirebaseProvider.app) {
      return FirebaseProvider.app;
    }

    FirebaseProvider.app = firebase.initializeApp(firebaseConfig);

    return FirebaseProvider.app;
  }

  public getFirebaseStorage() {
    return this.getFirebase().storage();
  }

  public getGoogleAuthProvider() {
    return new firebase.auth.GoogleAuthProvider();
  }

  public getGithubAuthProvider() {
    return new firebase.auth.GithubAuthProvider();
  }

  public getFacebookAuthProvider() {
    return new firebase.auth.FacebookAuthProvider();
  }

  public getMicrosoftAuthProvider() {
    return new firebase.auth.OAuthProvider('microsoft.com');
  }
}
