import React from 'react';
import {
  Image as ChakraImage,
  ImageProps as ChakraImageProps,
} from '@chakra-ui/react';

export interface ImageProps extends ChakraImageProps {}

const Image: React.FC<ImageProps> = ({ ...props }) => {
  return <ChakraImage {...props} />;
};

Image.defaultProps = {};

export default Image;
