import React from 'react';
import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export interface AcceptButtonProps extends Partial<IconButtonProps> {}

const AcceptButton: React.FC<AcceptButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <IconButton
      size="sm"
      variant="ghost"
      aria-label={t('Accept')}
      icon={<FaCheck />}
      color="green.500"
      tooltip
      {...props}
    />
  );
};

export default AcceptButton;
