import React from 'react';
import Input from '../ui/Input';
import Stack from '../ui/Stack';
import FormField from './FormField';
import Organization, { OrganizationCurrency } from '../domain/Organization';
import FormImageField from './FormImageField';
import DrawerForm from './DrawerForm';
import { isEmail } from '../util/string';
import IndustrySelect from './IndustrySelect';
import Select from '../ui/Select';
import { getHumanReadableCurrency } from '../util/currency';
import { t } from '../i18n';

export interface OrganizationEditFormProps {
  onSubmit: (data: any) => void;
  organization: Organization;
}

const OrganizationFormDrawer: React.FC<OrganizationEditFormProps> = ({
  onSubmit,
  organization,
}) => {
  return (
    <DrawerForm
      header={<>Edit organization</>}
      name="organization"
      onSubmit={onSubmit}
      defaultValues={organization}
      successMessage={t('Organization saved successfully!')}
    >
      <Stack spacing={4}>
        <FormField name="name" required label="Organization name">
          <Input />
        </FormField>

        <FormImageField label={t('Logo')} name={'logo'} />

        <FormField name="address" label={t('Physical address')}>
          <Input />
        </FormField>

        <FormField name="vatNumber" label={t('VAT number')}>
          <Input />
        </FormField>

        <FormField name="industry" label={t('Industry')}>
          <IndustrySelect />
        </FormField>

        <FormField name="registryCode" label={t('Registry code')}>
          <Input />
        </FormField>

        <FormField
          name="email"
          label={t('Contact e-mail address')}
          validate={(value) => !value || isEmail(value) || 'Invalid email'}
        >
          <Input type="email" />
        </FormField>

        <FormField name="phone" label={t('Contact phone number')}>
          <Input />
        </FormField>
        <FormField name="currency" label={t('Currency')}>
          <Select>
            <option value={OrganizationCurrency.EUR}>
              {getHumanReadableCurrency(OrganizationCurrency.EUR)}
            </option>
            <option value={OrganizationCurrency.AUD}>
              {getHumanReadableCurrency(OrganizationCurrency.AUD)}
            </option>
            <option value={OrganizationCurrency.USD}>
              {getHumanReadableCurrency(OrganizationCurrency.USD)}
            </option>
          </Select>
        </FormField>
      </Stack>
    </DrawerForm>
  );
};

export default OrganizationFormDrawer;
