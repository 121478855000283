import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { FaGoogle } from 'react-icons/fa';
import Tooltip from './Tooltip';

export interface GoogleButtonProps {
  onClick: () => void;
}

const GoogleButton: React.FC<GoogleButtonProps> = ({ onClick }) => {
  return (
    <Tooltip label="Sign in with Google">
      <IconButton
        colorScheme="red"
        icon={<FaGoogle />}
        onClick={onClick}
        aria-label="Google"
      />
    </Tooltip>
  );
};

GoogleButton.defaultProps = {};

export default GoogleButton;
