import React from 'react';
import useResolver from '../hooks/useResolver';
import AccountStore, { SocialLoginProvider } from '../store/AccountStore';
import GithubButton from '../ui/GithubButton';

export interface ConnectedGithubLoginProps {}

const ConnectedGithubLogin: React.FC<ConnectedGithubLoginProps> = () => {
  const accountStore = useResolver(AccountStore);

  return (
    <GithubButton
      onClick={() => accountStore.socialLogin(SocialLoginProvider.GITHUB)}
    />
  );
};

export default ConnectedGithubLogin;
