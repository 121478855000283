import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import et from './localization/et.json';
import en from './localization/en.json';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: true,
    keySeparator: false,
    nsSeparator: false,
    lng: localStorage.getItem('language') || 'en',
    // lang: localStorage.getItem('language'),
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: en,
      },
      et: {
        translation: et,
      },
    },
  });

export default i18n;

export function t(value: string): string {
  return i18n.t(value);
}
