import React from 'react';
import {
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';

export interface PercentageInputProps extends NumberInputProps {}

const PercentageInput: React.FC<PercentageInputProps> = ({
  value,
  onChange,
  ...props
}) => {
  const format = (val: string | number) => +(+val * 100).toFixed(6);
  const parse = (val: string | number) =>
    +(+val.toString().replace(/%$/, '') / 100).toFixed(6);

  return (
    <Flex>
      <NumberInput
        maxW="100px"
        mr="2rem"
        value={format(value)}
        onChange={(valueString) =>
          onChange(parse(valueString).toString(), parse(valueString))
        }
        min={0}
        {...props}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Slider
        max={Math.max(100, (+value || 0) * 100)}
        min={0}
        flex="1"
        focusThumbOnChange={false}
        value={format(value)}
        onChange={(v) => onChange(parse(v).toString(), parse(v))}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb
          fontSize="xs"
          boxSize="32px"
          children={`${format(value)}%`}
        />
      </Slider>
    </Flex>
  );
};

PercentageInput.defaultProps = {};

export default PercentageInput;
