import React from 'react';
import Stack from '../ui/Stack';
import Person from '../domain/Person';
import Card from '../ui/Card';
import HStack from '../ui/HStack';
import Text from '../ui/Text';
import ConnectedPersonTags from '../containers/ConnectedPersonTags';
import ResponsiveGrid from '../ui/ResponsiveGrid';
import ConnectedPersonTimesheet from '../containers/ConnectedPersonTimesheet';
import Tab from '../ui/Tab';
import ConnectedPersonBudget from '../containers/ConnectedPersonBudget';
import RouterTabs from './RouterTabs';
import Alert from '../ui/Alert';
import Organization from '../domain/Organization';
import Link from '../ui/Link';
import Image from '../ui/Image';
import { useTranslation } from 'react-i18next';
import ConnectedProjectList from '../containers/ConnectedProjectList';
import { ProjectState, ProjectType } from '../domain/Project';
import CapacityMultiCircle from './CapacityMultiCircle';
import PersonUser from './PersonUser';
import { Center } from '@chakra-ui/react';
import { MenuItem } from '../ui/Menu';
import EditIcon from '../ui/icons/EditIcon';
import { MoreActionsMenu } from '../ui';
import ConnectedPersonDeactivateDrawer from '../containers/ConnectedPersonDeactivateDrawer';
import CloseIcon from '../ui/icons/CloseIcon';
import ConnectedPersonDescription from '../containers/ConnectedPersonDescription';
import ConnectedDynamicFieldCardValues from '../features/dynamic-field/components/ConnectedDynamicFieldCardValues';
import { Resources } from '../features/dynamic-field';

export interface PersonProfileProps {
  person: Person;
  editable?: boolean;
  showBudget?: boolean;
  organization?: Organization;
  allocation?: number;
  utilization?: number;
}

const PersonProfile: React.FC<PersonProfileProps> = ({
  person,
  editable,
  showBudget,
  organization,
  allocation,
  utilization,
}) => {
  const { t } = useTranslation();

  if (!person) {
    return null;
  }

  if (person.isDeleted()) {
    return <Alert status="error">{t('Person no longer exists')}</Alert>;
  }

  return (
    <>
      <ConnectedPersonDeactivateDrawer name="person.deactivate" />
      <Stack spacing={4}>
        <ResponsiveGrid
          spacing={4}
          alignItems="start"
          templateColumns="minmax(0, 1fr) 310px"
        >
          <RouterTabs>
            <Tab title={t('Projects')}>
              <ConnectedProjectList
                filters={{
                  state: [ProjectState.PUBLISHED, ProjectState.DRAFT],
                  type: ProjectType.PROJECT,
                  personId: person.id,
                }}
              />
            </Tab>
            <Tab title={t('Events')}>
              <ConnectedProjectList
                filters={{
                  state: [ProjectState.PUBLISHED, ProjectState.DRAFT],
                  type: ProjectType.EVENT,
                  personId: person.id,
                }}
              />
            </Tab>
            <Tab title={t('Learning')}>
              <ConnectedProjectList
                filters={{
                  state: [ProjectState.PUBLISHED, ProjectState.DRAFT],
                  type: ProjectType.TRAINING,
                  personId: person.id,
                }}
              />
            </Tab>
            <Tab title={t('Time management')}>
              <Card>
                <ConnectedPersonTimesheet
                  editable={editable}
                  exportable
                  monthPicker
                  personId={person.id}
                />
              </Card>
            </Tab>
            {showBudget && (
              <Tab title={t('Finances')}>
                <Card>
                  <ConnectedPersonBudget personId={person.id} />
                </Card>
              </Tab>
            )}
            <Tab title={t('Overview')}>
              <Card>
                <ConnectedPersonDescription
                  person={person}
                  editable={editable}
                />
              </Card>
            </Tab>
          </RouterTabs>
          <Card>
            <Stack spacing={4}>
              <HStack justifyContent="space-between" alignItems="start">
                <PersonUser person={person} size="lg" />
                {editable && (
                  <MoreActionsMenu label={`${person.name} ${t('actions')}`}>
                    <MenuItem icon={<EditIcon />} to={`?person=${person.id}`}>
                      {t('Edit')}
                    </MenuItem>
                    <MenuItem
                      icon={<CloseIcon />}
                      to={`?person.deactivate=${person.id}`}
                    >
                      {t('Deactivate')}
                    </MenuItem>
                  </MoreActionsMenu>
                  // <EditButton scope={null} to={`?person=${person.id}`} />
                )}
              </HStack>

              <Center>
                <CapacityMultiCircle
                  personCapacity={person.capacity}
                  personAllocation={allocation}
                  personUtilization={utilization}
                  size="l"
                />
              </Center>

              <Stack spacing={4}>
                {organization && (
                  <div>
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      color="brand.500"
                      mb={2}
                    >
                      {t('Organization')}
                    </Text>
                    <Link to={`/clients/view/${organization.id}`}>
                      <HStack>
                        {organization.logo && (
                          <Image
                            src={organization.logo}
                            maxH="30px"
                            maxW="50px"
                          />
                        )}
                        <Text fontSize="xs" py={1}>
                          {organization.name}
                        </Text>
                      </HStack>
                    </Link>
                  </div>
                )}
                {(person.contactEmail || person.userId) && (
                  <div>
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      color="brand.500"
                      mb={2}
                    >
                      {t('Email')}
                    </Text>
                    <Text fontSize="xs" py={1}>
                      {person.contactEmail || person.userId}
                    </Text>
                  </div>
                )}

                {person.contactPhone && (
                  <div>
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      color="brand.500"
                      mb={2}
                    >
                      {t('Phone')}
                    </Text>
                    <Text fontSize="xs" py={1}>
                      {person.contactPhone}
                    </Text>
                  </div>
                )}
              </Stack>

              <ConnectedPersonTags personId={person.id} />

              <ConnectedDynamicFieldCardValues
                dynamic={person.dynamic}
                tags={[Resources.PERSONS]}
              />
            </Stack>
          </Card>
        </ResponsiveGrid>
      </Stack>
    </>
  );
};

export default PersonProfile;
