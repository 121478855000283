import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import RequestStore from '../store/RequestStore';
import TabbedRequests from '../components/TabbedRequests';

export interface ConnectedAllParticipationRequestsProps {}

const ConnectedAllRequests: React.FC<ConnectedAllParticipationRequestsProps> =
  () => {
    const requestStore = useResolver(RequestStore);
    const personStore = useResolver(PersonStore);

    useEffect(() => {
      requestStore.loadByCurrentOrganization();
    }, []);

    return (
      <TabbedRequests
        viewer={personStore.currentPerson}
        requests={requestStore.currentOrganizationRequests}
      />
    );
  };

export default observer(ConnectedAllRequests);
