import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import ParticipationTimeSheet from '../components/ParticipationTimeSheet';
import ProjectStore from '../store/ProjectStore';
import TagStore from '../store/TagStore';
import OrganizationPeopleFilters from '../components/OrganizationPeopleFilters';
import useFilteredPersons from '../hooks/useFilteredPersons';
import Stack from '../ui/Stack';
import useMonthPicker from '../hooks/useMonthPicker';
import HStack from '../ui/HStack';
import useSWR from 'swr';
import { endOfMonth, startOfMonth } from 'date-fns';
import TimesheetService from '../services/TimesheetService';
import SkeletonLoader from '../ui/SkeletonLoader';
import Alert from '../ui/Alert';
import { formatDate } from '../util/date';
import useParticipationFilter from '../hooks/useParticipationFilter';
import useAllocationFilter from '../hooks/useAllocationFilter';
import { ChevronIcon, IconButton } from '../ui';
import orderBy from 'lodash/orderBy';
import OrganizationStore from '../store/OrganizationStore';

export interface ConnectedProjectTimesheetProps {}

const ConnectedProjectTimesheet: React.FC<
  ConnectedProjectTimesheetProps
> = () => {
  const organizationStore = useResolver(OrganizationStore);
  const timesheetService = useResolver(TimesheetService);
  const personStore = useResolver(PersonStore);
  const tagStore = useResolver(TagStore);
  const projectStore = useResolver(ProjectStore);

  const date = useMonthPicker();
  const [selectedPersonId, setSelectedPersonId] = useState<string | null>(null);

  useEffect(() => {
    projectStore.loadByActiveOrganization();
    personStore.loadCurrentOrganizationPersons();
    tagStore.loadByCurrentOrganization();
  }, []);

  const organizationId = organizationStore.currentOrganizationId;

  const { data: timesheets, error: timesheetsError } = useSWR(
    `ConnectedOrganizationPersonsTimeline:${organizationId}|${formatDate(
      date,
    )}`,
    () =>
      timesheetService.getDaily({
        organizationId,
        dateStart: startOfMonth(date),
        dateEnd: endOfMonth(date),
        includeRelatedOrganizationsPersons: false,
      }),
  );

  const persons = personStore.currentOrganizationPersons;

  for (const timesheet of timesheets || []) {
    if (
      !timesheet.participation ||
      persons.find((p) => p.id === timesheet.participation.personId)
    ) {
      continue;
    }

    const person = personStore.getByIdOrLoad(timesheet.participation.personId);

    if (!person || person.isDeleted()) {
      continue;
    }

    persons.push(person);
  }

  const { persons: filteredPersons } = useFilteredPersons(persons || []);
  const participationFilter = useParticipationFilter();
  const allocationFilter = useAllocationFilter();

  if (timesheetsError) {
    return <Alert status="error">{String(timesheetsError)}</Alert>;
  }

  if (!timesheets || !persons) {
    return <SkeletonLoader />;
  }

  const showActiveParticipations =
    !!participationFilter.parameters.projectFilter ||
    participationFilter.parameters.participationTagFilter;

  return (
    <div>
      <Stack spacing={4}>
        <HStack justifyContent="space-between">
          <OrganizationPeopleFilters
            persons={persons}
            projects={projectStore.currentOrganizationProjects}
            positions={tagStore.positions}
            date={date}
            simple
          />
        </HStack>

        <ParticipationTimeSheet
          data={orderBy(filteredPersons, 'name')
            .map((person) => {
              const personTimesheets = timesheets.filter(
                (timesheet) => timesheet.participation.personId === person.id,
              );

              if (
                !allocationFilter.apply(
                  personTimesheets.map((t) => t.participation),
                )
              ) {
                return null;
              }

              // Don't show external persons if they don't have any active participations
              if (
                person.organizationId !==
                  organizationStore.currentOrganizationId &&
                personTimesheets.length < 0
              ) {
                return null;
              }

              const filteredTimesheets = personTimesheets.filter((timesheet) =>
                participationFilter.apply(timesheet.participation),
              );

              if (showActiveParticipations && !filteredTimesheets.length) {
                return null;
              }

              const entries: Array<{
                date: string;
                loggedTimeInSeconds: number;
                predictedTimeInSeconds: number;
                timeInSeconds: number;
              }> = [];

              filteredTimesheets.forEach((timesheet) => {
                timesheet.entries.forEach((entry, index) => {
                  const {
                    timeInSeconds = 0,
                    predictedTimeInSeconds = 0,
                    loggedTimeInSeconds = 0,
                  } = entries[index] || {};

                  entries[index] = {
                    ...entry,
                    timeInSeconds: timeInSeconds + entry.timeInSeconds,
                    loggedTimeInSeconds:
                      loggedTimeInSeconds + entry.loggedTimeInSeconds,
                    predictedTimeInSeconds:
                      predictedTimeInSeconds + entry.predictedTimeInSeconds,
                  };
                });
              });

              return [
                {
                  entries,
                  participations: filteredTimesheets.map(
                    (t) => t.participation,
                  ),
                  person,
                  end: (
                    <IconButton
                      disabled={!filteredTimesheets.length}
                      variant="ghost"
                      aria-label="Expand"
                      onClick={() => {
                        setSelectedPersonId(
                          selectedPersonId === person.id ? null : person.id,
                        );
                      }}
                    >
                      <ChevronIcon
                        direction={
                          selectedPersonId === person.id ? 'up' : 'down'
                        }
                      />
                    </IconButton>
                  ),
                },
                ...(person.id === selectedPersonId
                  ? timesheets
                      .filter(
                        (timesheet) =>
                          timesheet.participation.personId === selectedPersonId,
                      )
                      .map((timesheet) => ({
                        entries: timesheet.entries,
                        participations: [timesheet.participation],
                        projectAsRowHead: true,
                      }))
                  : []),
              ];
            })
            .flat()
            .filter(Boolean)}
          monthPicker
          title="Timeline"
          variant="worklog"
        />
      </Stack>
    </div>
  );
};

export default observer(ConnectedProjectTimesheet);
