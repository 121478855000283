import React from 'react';
import { observer } from 'mobx-react';
import AccessTokenList from '../components/AccessTokenList';
import useSWR from 'swr';
import useResolver from '../hooks/useResolver';
import PersonalAccessTokenService from '../services/PersonalAccessTokenService';
import ConnectedAccessTokenFormDrawer from './ConnectedAccessTokenFormDrawer';

export interface ConnectedAccessTokensProps {}

const ConnectedAccessTokens: React.FC<ConnectedAccessTokensProps> = () => {
  const personalAccessTokenService = useResolver(PersonalAccessTokenService);
  const { data, mutate } = useSWR('personal-access-tokens', () =>
    personalAccessTokenService.getAll(),
  );

  return (
    <>
      <ConnectedAccessTokenFormDrawer
        onCreate={() => mutate()}
        name="create-access-token"
      />
      <AccessTokenList
        personalAccessTokens={data?.filter((token) => !token.isExpired()) ?? []}
      />
    </>
  );
};

export default observer(ConnectedAccessTokens);
