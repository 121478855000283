import React from 'react';
import { Redirect } from 'react-router-dom';
import ConnectedAuthenticationGuard from '../containers/ConnectedAuthenticationGuard';
import ConnectedLoginForm from '../containers/ConnectedLoginForm';
import CleanLayout from '../ui/CleanLayout';
import useQueryValue from '../hooks/useQueryValue';

const SignIn: React.FC = () => {
  return (
    <CleanLayout size="md">
      <ConnectedLoginForm />
    </CleanLayout>
  );
};

const GuardedSignIn: React.FC = () => {
  const redirectUrl = useQueryValue('redirect', '/app');

  return (
    <ConnectedAuthenticationGuard forbiddenAction={<SignIn />}>
      <Redirect to={redirectUrl} />
    </ConnectedAuthenticationGuard>
  );
};

export default GuardedSignIn;
