import Resource from '../decorators/Resource';

export enum SearchResourceEnum {
  PROJECT = 'PROJECT',
  ORGANIZATION = 'ORGANIZATION',
  PERSON = 'PERSON',
}

export interface SearchResource {
  name: SearchResourceEnum;
  value: string;
  image?: string;
}

@Resource.Name('search')
export default class Search {
  public title: string;
  public description: string;
  public resource: SearchResource;

  fromObject(payload: Partial<Search>): this {
    this.title = payload.title;
    this.description = payload.description;
    this.resource = payload.resource;
    return this;
  }

  toObject(): Partial<Search> {
    return {
      title: this.title,
      description: this.description,
      resource: this.resource,
    };
  }
}
