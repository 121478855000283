import Resource from '../decorators/Resource';
import AbstractDomain from './AbstractDomain';
import { toDate } from '../util/date';

@Resource.Name('personal-access-tokens')
export default class PersonalAccessToken extends AbstractDomain {
  public name: string;
  public expireDate?: Date;
  public token?: string;

  public toObject(): Partial<PersonalAccessToken> {
    return {
      ...super.toObject(),
      name: this.name,
      expireDate: this.expireDate,
      token: this.token,
    };
  }

  public fromObject(payload: Partial<PersonalAccessToken>): this {
    super.fromObject(payload);

    this.name = payload.name;
    this.expireDate = toDate(payload.expireDate);
    this.token = payload.token;

    return this;
  }

  public isExpired(date: Date = new Date()): boolean {
    return this.expireDate && this.expireDate < date;
  }
}
