import AbstractDomain from './AbstractDomain';
import Person from './Person';
import Tag from './Tag';
import Resource from '../decorators/Resource';

@Resource.Name('person-skills')
export default class PersonSkill extends AbstractDomain {
  public tagId: string;
  public personId: string;
  public level?: number;
  protected _person?: Person;
  protected _tag?: Tag;

  get tag(): Tag {
    return this._tag;
  }

  set tag(value: Tag) {
    this._tag = value;
  }

  get person(): Person {
    return this._person;
  }

  set person(value: Person) {
    this._person = value;
  }

  public fromObject(payload: Partial<PersonSkill>): this {
    super.fromObject(payload);

    this.tagId = payload.tagId;
    this.personId = payload.personId;
    this.level = payload.level;

    return this;
  }

  public toObject(): Partial<PersonSkill> {
    return {
      ...super.toObject(),
      tagId: this.tagId,
      personId: this.personId,
      level: this.level,
    };
  }
}
