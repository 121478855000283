import React from 'react';
import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';

export interface FormControlProps extends ChakraFormControlProps {
  labelText?: string | React.ReactNode;
  helperText?: string | React.ReactNode;
  errorText?: string | React.ReactNode;
}

const FormControl: React.FC<FormControlProps> = ({
  labelText,
  errorText,
  helperText,
  children,
  ...props
}) => {
  return (
    <ChakraFormControl {...props}>
      {labelText && <FormLabel requiredIndicator={null}>{labelText}</FormLabel>}
      {children}
      {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </ChakraFormControl>
  );
};

FormControl.defaultProps = {};

export default FormControl;
