import React from 'react';
import { Accordion as ChAccordion } from '@chakra-ui/react';

export interface AccordionProps {}

const Accordion: React.FC<AccordionProps> = ({ children }) => {
  return <ChAccordion allowMultiple>{children}</ChAccordion>;
};

Accordion.defaultProps = {};

export default Accordion;
