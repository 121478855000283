import React, { cloneElement, ReactElement } from 'react';
import useResolver from '../hooks/useResolver';
import OrganizationRelationStore from '../store/OrganizationRelationStore';
import OrganizationRelation from '../domain/OrganizationRelation';
import useToast from '../hooks/useToast';
import { t } from '../i18n';

export interface ConnectedManagedOrganizationUpdateTriggerProps {
  children: ReactElement;
  organizationRelation: OrganizationRelation;
  values: () => Partial<OrganizationRelation>;
}

const ConnectedManagedOrganizationUpdateTrigger: React.FC<
  ConnectedManagedOrganizationUpdateTriggerProps
> = ({ organizationRelation, children, values }) => {
  const organizationRelationStore = useResolver(OrganizationRelationStore);
  const toast = useToast();

  return cloneElement(children, {
    onClick: async () => {
      try {
        await organizationRelationStore.update({
          id: organizationRelation.id,
          ...values(),
        });

        toast({
          status: 'success',
          description: t('Client successfully updated!'),
        });
      } catch (e) {
        console.error(e);
        toast({ status: 'error', description: t('Something went wrong') });
      }
    },
  });
};

export default ConnectedManagedOrganizationUpdateTrigger;
