import React from 'react';
import Card from '../ui/Card';
import { Center, Grid } from '@chakra-ui/react';
import Box from '../ui/Box';
import Image from '../ui/Image';
import Button from '../ui/Button';
import { FiFolderPlus } from 'react-icons/fi';
import { CgUserAdd } from 'react-icons/cg';
import newProjectLogo from '../ui/assets/new_project_logo.png';
import newPeopleLogo from '../ui/assets/new_people_logo.png';
import { t } from '../i18n';

const Welcome: React.FC = () => {
  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <Card>
          <Center pb="6" pt="5">
            <Box>
              <Center>
                <Image
                  src={newProjectLogo}
                  alt="first project"
                  width={140}
                  height={140}
                />
              </Center>
              <Box
                mt="1"
                pt="6"
                pb="6"
                fontWeight="500"
                fontSize="24px"
                as="h2"
              >
                {t('Add your first project')}
              </Box>
              <Center>
                <Button
                  to={`?project`}
                  rightIcon={<FiFolderPlus />}
                  colorScheme="brand"
                >
                  {t('Add project')}
                </Button>
              </Center>
            </Box>
          </Center>
        </Card>
        <Card>
          <Center pb="6" pt="5">
            <Box>
              <Center>
                <Image
                  src={newPeopleLogo}
                  alt="add people"
                  width={140}
                  height={140}
                />
              </Center>
              <Box
                mt="1"
                pt="6"
                pb="6"
                fontWeight="500"
                fontSize="24px"
                as="h2"
              >
                {t('Add people and roles')}
              </Box>
              <Center>
                <Button
                  rightIcon={<CgUserAdd />}
                  to={'?person'}
                  colorScheme="brand"
                >
                  {t('Add people')}
                </Button>
              </Center>
            </Box>
          </Center>
        </Card>
      </Grid>
    </>
  );
};

export default Welcome;
