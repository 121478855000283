import React from 'react';
import { observer } from 'mobx-react';
import OrganizationCreateForm, {
  OrganizationCreateFormPayload,
} from '../components/OrganizationCreateForm';
import useResolver from '../hooks/useResolver';
import OrganizationStore from '../store/OrganizationStore';
import PersonStore from '../store/PersonStore';
import { useHistory } from 'react-router-dom';
import RootStore from '../store/RootStore';
import AccountStore from '../store/AccountStore';
import { t } from '../i18n';

export interface ConnectedOrganizationCreateFormProps {
  nextStep: string;
}

const ConnectedOrganizationCreateForm: React.FC<
  ConnectedOrganizationCreateFormProps
> = ({ nextStep }) => {
  const organizationStore = useResolver(OrganizationStore);
  const personStore = useResolver(PersonStore);
  const accountStore = useResolver(AccountStore);
  const rootStore = useResolver(RootStore);
  const history = useHistory();

  const onSubmit = async (organization: OrganizationCreateFormPayload) => {
    const org = {
      ...organization,
      email: accountStore.authenticatedUser.email,
    };
    const createdOrganization = await organizationStore.create(org);
    const persons = await personStore.loadByOrganization(
      createdOrganization.id,
    );

    if (!persons.length) {
      throw new Error(t('Something went wrong'));
    }

    personStore.selectPerson(persons[0].id);
    rootStore.clearAll();

    history.push(nextStep);
  };

  return (
    <OrganizationCreateForm
      onSubmit={onSubmit}
      currentPersonName={
        accountStore.authenticatedUser.name || personStore.currentPerson?.name
      }
    />
  );
};

export default observer(ConnectedOrganizationCreateForm);
