import AbstractDomain from '../../../domain/AbstractDomain';
import Resource from '../../../decorators/Resource';

export enum DynamicFieldType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  SELECT = 'SELECT',
  MULTISELECT = 'MULTISELECT',
}

@Resource.Name('dynamic-fields')
export default class DynamicField extends AbstractDomain {
  name: string;
  label: string;
  description?: string;
  organizationId: string;
  tags: string[];
  defaultValue?: any;
  type: DynamicFieldType;
  options?: string[];
  required?: boolean;

  public toObject(): Partial<DynamicField> {
    return {
      ...super.toObject(),
      name: this.name,
      label: this.label,
      description: this.description,
      organizationId: this.organizationId,
      tags: this.tags,
      defaultValue: this.defaultValue,
      type: this.type,
      options: this.options,
      required: this.required,
    };
  }

  public fromObject(payload: Partial<DynamicField>): this {
    super.fromObject(payload);

    this.name = payload.name;
    this.label = payload.label;
    this.description = payload.description;
    this.organizationId = payload.organizationId;
    this.tags = payload.tags;
    this.defaultValue = payload.defaultValue;
    this.type = payload.type;
    this.options = payload.options;
    this.required = payload.required;

    return this;
  }
}
