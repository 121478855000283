import React from 'react';
import Tag, { TagType } from '../domain/Tag';
import groupBy from 'lodash/groupBy';
import Stack from '../ui/Stack';
import Text from '../ui/Text';
import { stringifyTagType } from '../util/tag';
import TagWithIcon from './TagWithIcon';

export interface GroupedTagsProps {
  tags: Tag[];
}

const GroupedTags: React.FC<GroupedTagsProps> = ({ tags }) => {
  const customTags = tags.filter(
    (tag) => tag.type === TagType.CUSTOM && tag.context,
  );
  const regularTags = tags.filter((tag) => tag.type !== TagType.CUSTOM);

  const grouped = {
    ...groupBy(regularTags, 'type'),
    ...groupBy(customTags, 'context'),
  } as { [key in TagType]: Tag[] };
  const iconed = [TagType.TECHNOLOGY, TagType.LANGUAGE];

  return (
    <Stack spacing={4}>
      {(Object.keys(grouped) as TagType[]).sort().map((type) => (
        <div key={type}>
          <Text fontSize="sm" fontWeight="bold" color="brand.500" mb={2}>
            {stringifyTagType(type)}
          </Text>
          {grouped[type].map((tag) => {
            return (
              <div key={tag.id}>
                {iconed.includes(type) ? (
                  <TagWithIcon {...tag} />
                ) : (
                  <Text fontSize="xs" py={1}>
                    {tag.name}
                  </Text>
                )}
              </div>
            );
          })}
        </div>
      ))}
    </Stack>
  );
};

export default GroupedTags;
