import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ProjectSelect, { ProjectSelectProps } from '../components/ProjectSelect';
import useResolver from '../hooks/useResolver';
import ProjectStore from '../store/ProjectStore';
import ProjectAccessStore from '../store/ProjectAccessStore';
import { ProjectPeriod, ProjectState, ProjectType } from '../domain/Project';

export interface ConnectedProjectSelectProps
  extends Omit<ProjectSelectProps, 'projects'> {
  relatedToCurrentPerson?: boolean;
  state?: ProjectState | ProjectState[];
  period?: ProjectPeriod | ProjectPeriod[];
  type?: ProjectType;
}

const ConnectedProjectSelect: React.FC<ConnectedProjectSelectProps> = ({
  relatedToCurrentPerson,
  state,
  period,
  type,
  ...props
}) => {
  const projectStore = useResolver(ProjectStore);
  const projectAccessStore = useResolver(ProjectAccessStore);

  useEffect(() => {
    projectStore.loadByActiveOrganization();
  }, []);

  const projects = projectAccessStore.getFilteredProjects({
    currentOrganization: true,
    currentPerson: relatedToCurrentPerson,
    state,
    period,
    type,
  });

  return <ProjectSelect projects={projects} {...props} />;
};

export default observer(ConnectedProjectSelect);
