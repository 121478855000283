import React from 'react';
import {
  Stack as ChakraStack,
  StackProps as ChakraStackProps,
} from '@chakra-ui/react';
import Link from './Link';

export interface StackProps extends ChakraStackProps {
  to?: string;
}

const Stack: React.FC<StackProps> = ({ ...props }) => {
  if (props.to) {
    props.as = Link;
  }

  return <ChakraStack {...props} />;
};

Stack.defaultProps = {};

export default Stack;
