import { action, computed, makeObservable, observable } from 'mobx';
import { ResourceId } from '../types';
import AccountStore from './AccountStore';
import RestfulService from '../services/RestfulService';
import AbstractDomain from '../domain/AbstractDomain';

export enum ResourceStoreStatus {
  IDLE,
  PENDING,
  RESOLVED,
  REJECTED,
}

export default abstract class AbstractResourceStore<T extends AbstractDomain> {
  @observable private byId: { [key: string]: T } = {};
  @observable private _status: ResourceStoreStatus = ResourceStoreStatus.IDLE;
  private promiseCache: { [key: string]: Promise<any> } = {};

  protected constructor(
    protected readonly service: RestfulService<T>,
    protected readonly accountStore: AccountStore,
  ) {
    makeObservable(this);
  }

  public async loadAll() {
    await this.setManyEventually(
      this.cachePromise('loadAll', () => this.service.getMany()),
    );
  }

  public async loadOne(id: ResourceId) {
    if (!id) {
      console.warn('No id provided');
      return;
    }

    await this.setOneEventually(
      this.cachePromise(`loadOne_${id}`, () => this.service.getOne(id)),
    );
  }

  private cachePromise<T>(key: string, retrieve: () => Promise<T>): Promise<T> {
    if (this.promiseCache[key]) {
      return this.promiseCache[key];
    }

    const promise = retrieve();

    this.promiseCache[key] = promise;

    promise.then((result) => {
      setTimeout(() => {
        this.promiseCache.hasOwnProperty(key) && delete this.promiseCache[key];
      }, 1000);

      return result;
    });

    return promise;
  }

  public async save(item: T | Partial<T>): Promise<T> {
    if (!item.id) {
      return this.create(item);
    } else {
      return this.update(item);
    }
  }

  @action
  public clear(filters?: Partial<T>): void {
    if (!filters) {
      this.byId = {};

      return;
    } else {
      this.getFilteredItems(filters).forEach((item) => {
        delete this.byId[item.id];
      });
    }

    this.promiseCache = {};
  }

  public async update(item: Partial<T>): Promise<T> {
    const existingItem: T = this.getById(item.id);

    if (!existingItem) {
      throw new Error('Could not retrieve item ' + item.id);
    }

    const existingObject = existingItem.toObject();

    this.storeItem(existingItem.fromObject(item));

    try {
      const promise = this.service.update(
        item.id,
        this.sanitizeBeforeSave(item),
      );

      await this.setOneEventually(promise);

      return await promise;
    } catch (e: any) {
      // Replace with previous on failure
      this.storeItem(existingItem.fromObject(existingObject));

      throw e;
    }
  }

  public async create(item: Partial<Omit<T, 'id'>>): Promise<T> {
    const promise = this.service.create(this.sanitizeBeforeSave(item));

    await this.setOneEventually(promise);

    return await promise;
  }

  public async delete(id: ResourceId) {
    this.setStatus(ResourceStoreStatus.PENDING);
    try {
      await this.service.delete(id);

      this.remove(id);

      this.setStatus(ResourceStoreStatus.RESOLVED);
    } catch (e: any) {
      this.setStatus(ResourceStoreStatus.REJECTED);
      throw e;
    }
  }

  public remove(id: ResourceId) {
    delete this.byId[id.toString()];
  }

  public getById(id: ResourceId): T {
    if (!id) {
      console.warn('No id provided');

      return null;
    }

    return this.byId[id.toString()] || null;
  }

  public getByIdOrLoad(id: ResourceId): T {
    const item = this.getById(id);

    if (!item) {
      this.loadOne(id);
    }

    return item;
  }

  protected getItems(): T[] {
    return Object.values(this.byId)
      .map((item) => this.prepareForRetrieving(item))
      .filter((item) => item && !item.isDeleted());
  }

  public getFilteredItems(filters: Partial<T>): T[] {
    return this.items.filter((item) => {
      for (const filter in filters) {
        if (!filters.hasOwnProperty(filter)) {
          continue;
        }

        if (item[filter] !== filters[filter]) {
          return false;
        }
      }

      return true;
    });
  }

  @computed
  public get items(): T[] {
    return this.getItems();
  }

  @action
  public storeItem(...items: T[]) {
    items.forEach((item) => {
      this.byId[item.id.toString()] = this.prepareForStoring(item);
    });
  }

  protected prepareForStoring(item: T): T {
    return item;
  }

  protected prepareForRetrieving(item: T): T {
    return item;
  }

  protected sanitizeBeforeSave(item: T | any): T {
    return item;
  }

  @action
  private setStatus(status: ResourceStoreStatus) {
    this._status = status;
  }

  @computed
  public get status(): ResourceStoreStatus {
    return this._status;
  }

  @computed
  public get pending(): boolean {
    return this._status === ResourceStoreStatus.PENDING;
  }

  protected async setManyEventually(promise: Promise<T[]>) {
    this.setStatus(ResourceStoreStatus.PENDING);

    try {
      const items = await promise;

      this.storeItem(...items);

      this.setStatus(ResourceStoreStatus.RESOLVED);
    } catch (e: any) {
      // Log user out when 401 received
      if (e.response?.status === 401) {
        if (
          window.confirm(`Seems like your session has expired. Please reload`)
        ) {
          window.location.reload();
        }
      }
      this.setStatus(ResourceStoreStatus.REJECTED);
      throw e;
    }
  }

  protected async setOneEventually(promise: Promise<T>) {
    await this.setManyEventually(promise.then((item) => [item]));
  }
}
