import React from 'react';
import simpleIcons from 'simple-icons';
import Icon from '../ui/Icon';
import HashtagIcon from '../ui/icons/HashtagIcon';

interface TextToIconProps {
  name: string;
  fallback?: React.ReactNode;
}

const iconByText: { [key: string]: React.ReactNode } = {
  estonian: '🇪🇪',
  english: '🇬🇧',
};

const TextToIcon: React.FC<TextToIconProps> = ({ name, fallback }) => {
  const lowercasedName: string = name ? name.toLocaleLowerCase() : '';

  if (iconByText[lowercasedName]) {
    return <>{iconByText[lowercasedName] || fallback}</>;
  }
  const simpleIcon = simpleIcons.Get(lowercasedName);

  if (simpleIcon) {
    return (
      <Icon as="img" color={`#${simpleIcon.hex}`} alt={name}>
        <path fill="currentColor" d={simpleIcon.path} />
      </Icon>
    );
  }

  return <>{fallback}</>;
};

TextToIcon.defaultProps = {
  fallback: <HashtagIcon />,
};

export default TextToIcon;
