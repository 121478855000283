import AbstractResourceStore from './AbstractResourceStore';
import Subscription from '../domain/Subscription';
import SubscriptionService from '../services/SubscriptionService';
import AccountStore from './AccountStore';
import { singleton } from 'tsyringe';
import OrganizationStore from './OrganizationStore';
import { computed } from 'mobx';

@singleton()
export default class SubscriptionStore extends AbstractResourceStore<Subscription> {
  public constructor(
    private readonly subscriptionService: SubscriptionService,
    private readonly organizationStore: OrganizationStore,
    accountStore: AccountStore,
  ) {
    super(subscriptionService, accountStore);
  }

  public async loadByOrganization(organizationId: string) {
    await this.setManyEventually(
      this.subscriptionService.getAllByOrganization(organizationId),
    );
  }

  public async loadByCurrentOrganization() {
    return this.loadByOrganization(
      this.organizationStore.currentOrganizationId,
    );
  }

  public getItemsByOrganization(organizationId: string): Subscription[] {
    return this.getFilteredItems({ organizationId });
  }

  @computed
  public get currentOrganizationItems(): Subscription[] {
    return this.getItemsByOrganization(
      this.organizationStore.currentOrganizationId,
    );
  }

  @computed
  public get currentOrganizationActiveSubscription(): Subscription | null {
    return this.currentOrganizationItems.find((s) => s.isActive()) || null;
  }
}
