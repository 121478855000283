import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { FaMicrosoft } from 'react-icons/fa';
import Tooltip from './Tooltip';

export interface MicrosoftButtonProps {
  onClick: () => void;
}

const MicrosoftButton: React.FC<MicrosoftButtonProps> = ({ onClick }) => {
  return (
    <Tooltip label="Sign in with Microsoft">
      <IconButton
        colorScheme="green"
        icon={<FaMicrosoft />}
        onClick={onClick}
        aria-label="Microsoft"
      />
    </Tooltip>
  );
};

MicrosoftButton.defaultProps = {};

export default MicrosoftButton;
