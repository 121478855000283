import React from 'react';
import DrawerForm, { DrawerFormProps } from './DrawerForm';
import Stack from '../ui/Stack';
import FormField from './FormField';
import Select from '../ui/Select';
import DateInput from '../ui/DateInput';
import PercentageInput from '../ui/PercentageInput';
import ConnectedCurrentPersonParticipationSelect from '../containers/ConnectedCurrentPersonParticipationSelect';
import { RequestAction, RequestTiming } from '../domain/Request';
import Textarea from '../ui/Textarea';
import ConnectedProjectSelect from '../containers/ConnectedProjectSelect';
import ConnectedProjectPositionSelect from '../containers/ConnectedProjectPositionSelect';
import { requestActionTexts } from '../util/request';
import RequestTimingSelect from './RequestTimingSelect';
import { ProjectPeriod, ProjectState } from '../domain/Project';
import ConnectedOrganizationPersonSelect from '../containers/ConnectedOrganizationPersonSelect';
import { Role } from '../domain/Person';
import { useTranslation } from 'react-i18next';

export interface RequestFormDrawerProps
  extends Omit<DrawerFormProps, 'children'> {}

const RequestFormDrawer: React.FC<RequestFormDrawerProps> = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <DrawerForm
      header={t('New request')}
      {...props}
      submitButton={{ text: t('Send') }}
    >
      {({ formContext: { watch } }) => {
        const action: RequestAction = watch('action');
        const timing: RequestTiming = watch('timing');

        return (
          <Stack spacing={4}>
            <FormField name="action" label={t('I want to...')} required>
              <Select nullable>
                <option value={RequestAction.CHANGE_PROJECT}>
                  {requestActionTexts[RequestAction.CHANGE_PROJECT]}
                </option>
                <option value={RequestAction.TALK}>
                  {requestActionTexts[RequestAction.TALK]}
                </option>
                <option value={RequestAction.BREAK}>
                  {requestActionTexts[RequestAction.BREAK]}
                </option>
                <option value={RequestAction.CHANGE_CAPACITY}>
                  {requestActionTexts[RequestAction.CHANGE_CAPACITY]}
                </option>
                <option value={RequestAction.NEED_MORE_PEOPLE}>
                  {requestActionTexts[RequestAction.NEED_MORE_PEOPLE]}
                </option>
              </Select>
            </FormField>

            {[
              RequestAction.CHANGE_CAPACITY,
              RequestAction.CHANGE_PROJECT,
            ].includes(action) && (
              <FormField
                name="fromParticipationId"
                label={
                  action === RequestAction.CHANGE_PROJECT
                    ? t('Leave from')
                    : t('Participation')
                }
              >
                <ConnectedCurrentPersonParticipationSelect />
              </FormField>
            )}

            {[RequestAction.CHANGE_PROJECT].includes(action) && (
              <>
                <FormField name="toProjectId" label={t('Join to')} required>
                  <ConnectedProjectSelect
                    state={ProjectState.PUBLISHED}
                    period={[ProjectPeriod.UPCOMING, ProjectPeriod.ONGOING]}
                  />
                </FormField>

                {watch('toProjectId') && (
                  <FormField name="toTagId" label={t('As')}>
                    <ConnectedProjectPositionSelect
                      projectId={watch('toProjectId')}
                      nullable
                    />
                  </FormField>
                )}
              </>
            )}

            {[RequestAction.NEED_MORE_PEOPLE].includes(action) && (
              <FormField name="toProjectId" label={t('Project')}>
                <ConnectedProjectSelect relatedToCurrentPerson />
              </FormField>
            )}

            {!!action && action !== RequestAction.TALK && (
              <FormField
                name="timing"
                label={t('Date')}
                defaultValue={RequestTiming.ASAP}
              >
                <RequestTimingSelect />
              </FormField>
            )}

            {timing === RequestTiming.CUSTOM_DATE && (
              <FormField name="customDate" required>
                <DateInput />
              </FormField>
            )}

            {[
              RequestAction.CHANGE_CAPACITY,
              RequestAction.CHANGE_PROJECT,
            ].includes(action) && (
              <FormField name="capacity" label={t('Capacity')} defaultValue={1}>
                <PercentageInput />
              </FormField>
            )}

            {!!action && (
              <FormField name="description" label={t('Notes')}>
                <Textarea />
              </FormField>
            )}

            <FormField name="person" label={t('Send to')}>
              <ConnectedOrganizationPersonSelect
                excludeCurrentPerson
                nullable
                roles={[
                  Role.ADMIN,
                  Role.FINANCIAL_MANAGER,
                  Role.OPERATIONAL_MANAGER,
                ]}
                placeholder={t('Managers and admins')}
                onCreate={null}
              />
            </FormField>
          </Stack>
        );
      }}
    </DrawerForm>
  );
};

export default RequestFormDrawer;
