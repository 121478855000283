import React from 'react';
import { VStack } from '@chakra-ui/react';
import HStack from '../ui/HStack';
import isNumber from 'lodash/isNumber';

interface CapacityMultiCircleProps {
  personCapacity?: number;
  personAllocation?: number;
  personUtilization?: number;
  size?: 'l' | 'm';
}

const LegendTooltip: React.FC<CapacityMultiCircleProps> = ({
  personCapacity,
  personAllocation,
  personUtilization,
}) => {
  return (
    <VStack align="left">
      {isNumber(personCapacity) && (
        <HStack>
          <div
            style={{
              borderRadius: 8,
              height: 16,
              width: 16,
              backgroundColor: '#7a6eee',
            }}
          />
          <span>Capacity: {(personCapacity * 100).toFixed(0)}%</span>
        </HStack>
      )}
      {isNumber(personAllocation) && (
        <HStack>
          <div
            style={{
              borderRadius: 8,
              height: 16,
              width: 16,
              backgroundColor: '#ff9f43',
            }}
          />
          <span>Allocation: {(personAllocation * 100).toFixed(0)}%</span>
        </HStack>
      )}
      {personUtilization && (
        <HStack>
          <div
            style={{
              borderRadius: 8,
              height: 16,
              width: 16,
              backgroundColor: '#00bea4',
            }}
          />
          <span>
            <span>Utilization: {(personUtilization * 100).toFixed(0)}%</span>
          </span>
        </HStack>
      )}
    </VStack>
  );
};

export default LegendTooltip;
