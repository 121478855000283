import { injectable } from 'tsyringe';
import User from '../domain/User';
import LocalStorageService from './LocalStorageService';
import jwt from 'jwt-simple';
import AuthenticationService from './AuthenticationService';

@injectable()
export default class LocalAuthenticationService
  implements AuthenticationService
{
  private static LOCAL_STORAGE_KEY = 'token';
  private static SECRET = 'xxx';

  public constructor(
    private readonly localStorageService: LocalStorageService<string>,
  ) {}

  public async login(email: string, password: string): Promise<void> {
    const token = jwt.encode(
      {
        sub: email,
        email,
      },
      LocalAuthenticationService.SECRET,
    );

    this.localStorageService.setItem(
      LocalAuthenticationService.LOCAL_STORAGE_KEY,
      token,
    );
  }

  public async loginByGoogle(): Promise<void> {
    throw new Error('Operation not supported');
  }

  public async loginByGithub(): Promise<void> {
    throw new Error('Operation not supported');
  }

  loginByFacebook(): Promise<void> {
    throw new Error('Operation not supported');
  }

  loginByMicrosoft(): Promise<void> {
    throw new Error('Operation not supported');
  }

  public async register(email: string, password: string): Promise<void> {
    await this.login(email, password);
  }

  public async sendPasswordResetEmail(email: string): Promise<void> {
    // Do nothing
  }

  public async confirmPasswordReset(
    code: string,
    newPassword: string,
  ): Promise<void> {
    // Do nothing
  }

  public async getAccount(): Promise<User | null> {
    const token = await this.getToken();

    if (!token) {
      return null;
    }

    const decodedToken = jwt.decode(token, LocalAuthenticationService.SECRET);

    const user = new User();
    user.email = decodedToken.email;
    user.id = decodedToken.sub;

    return user;
  }

  public async logout(): Promise<void> {
    this.localStorageService.removeItem(
      LocalAuthenticationService.LOCAL_STORAGE_KEY,
    );
  }

  public async getToken(): Promise<string | null> {
    return this.localStorageService.getItem(
      LocalAuthenticationService.LOCAL_STORAGE_KEY,
    );
  }
}
