import React, { useEffect } from 'react';
import useQueryValue from '../hooks/useQueryValue';
import { useHistory, useLocation } from 'react-router-dom';
import { mergeQueryStrings } from '../util/url';
import DropdownSelect, {
  DropdownSelectProps,
  Value,
} from '../ui/DropdownSelect';

export interface RouterDropdownSelectProps
  extends Omit<DropdownSelectProps, 'value'> {
  name: string;
  defaultValue?: string | string[];
  replaceUrl?: boolean;
}

const RouterDropdownSelect: React.FC<RouterDropdownSelectProps> = ({
  name,
  isMulti,
  defaultValue,
  replaceUrl = true,
  ...props
}) => {
  const queryValue = useQueryValue(name);
  const history = useHistory();
  const location = useLocation();

  const mergeUrl = (newValue: Value | Value[]) => {
    return `?${mergeQueryStrings(
      location.search,
      `${name}=${
        Array.isArray(newValue)
          ? newValue.join(',')
          : encodeURIComponent(newValue)
      }`,
    )}`;
  };

  useEffect(() => {
    if (
      replaceUrl &&
      !queryValue &&
      !isMulti &&
      (defaultValue || props.options[0]?.value)
    ) {
      history.replace(mergeUrl(defaultValue || props.options[0]?.value));
    }
  }, [queryValue, isMulti]);

  let value: string[] | string =
    queryValue !== null ? queryValue : defaultValue;

  if (isMulti) {
    value = queryValue ? queryValue.split(',') : [];
  }

  return (
    <DropdownSelect
      {...props}
      isMulti={isMulti}
      value={value}
      onChange={(newValue) => {
        history.push(mergeUrl(newValue));
      }}
    />
  );
};

export default RouterDropdownSelect;
