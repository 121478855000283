import React from 'react';
import _ from 'lodash';
import SimpleGrid from '../ui/SimpleGrid';
import Stack from '../ui/Stack';
import FormField from './FormField';
import { observer } from 'mobx-react';
import DrawerForm from './DrawerForm';
import Project, { ProjectType } from '../domain/Project';
import { getTextsByType } from '../util/project';
import Select from '../ui/Select';
import { useTranslation } from 'react-i18next';

export interface ProjectChangeTypeDrawerProps {
  name: string;
  type: ProjectType;
  onSubmit: (values: Partial<Project>) => void | Promise<void>;
}

const ProjectChangeTypeDrawer: React.FC<ProjectChangeTypeDrawerProps> = ({
  name,
  type,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <DrawerForm
      size="md"
      variant={'modal'}
      name={name}
      onSubmit={onSubmit}
      header={<>{t('Edit project type')}</>}
      defaultValues={{
        type,
      }}
    >
      <SimpleGrid minChildWidth="300px" spacing={4}>
        <Stack spacing={4}>
          <FormField name="type" label={t('Project type')}>
            <Select>
              {_.values(ProjectType).map((type) => (
                <option key={type} value={type}>
                  {getTextsByType(type).name}
                </option>
              ))}
            </Select>
          </FormField>
        </Stack>
      </SimpleGrid>
    </DrawerForm>
  );
};

export default observer(ProjectChangeTypeDrawer);
