import AbstractResourceStore from './AbstractResourceStore';
import AccountStore from './AccountStore';
import { singleton } from 'tsyringe';
import OrganizationStore from './OrganizationStore';
import PersonStore from './PersonStore';
import { computed } from 'mobx';
import ParticipationStore from './ParticipationStore';
import Task, { TaskState } from '../domain/Task';
import TaskService from '../services/TaskService';

@singleton()
export default class TaskStore extends AbstractResourceStore<Task> {
  public constructor(
    private readonly taskService: TaskService,
    private readonly organizationStore: OrganizationStore,
    private readonly personStore: PersonStore,
    private readonly participationStore: ParticipationStore,
    accountStore: AccountStore,
  ) {
    super(taskService, accountStore);
  }

  public async completeRequest(requestId: string): Promise<void> {
    await this.changeRequestState(requestId, TaskState.DONE);
  }

  public async startRequest(requestId: string): Promise<void> {
    await this.changeRequestState(requestId, TaskState.IN_PROGRESS);
  }

  public async changeRequestState(
    requestId: string,
    state: TaskState,
  ): Promise<void> {
    const request = this.getByIdOrLoad(requestId);

    if (!request) {
      throw new Error('Failed to load');
    }

    request.state = state;

    await this.update(request);
  }

  public async loadByPerson(personId: string) {
    await this.setManyEventually(this.taskService.getAllByPerson(personId));
  }

  public async loadByCreatorPerson(personId: string) {
    await this.setManyEventually(this.taskService.getAllByCreator(personId));
  }

  public async loadByCurrentPerson() {
    await this.loadByPerson(this.personStore.currentPerson.id);
  }

  public async loadByCurrentCreatorPerson() {
    await this.loadByCreatorPerson(this.personStore.currentPerson.id);
  }

  public async loadByProject(projectId: string) {
    await this.setManyEventually(this.taskService.getAllByProject(projectId));
  }

  @computed
  public get currentOrganizationTasks(): Task[] {
    const currentPerson = this.personStore.currentPerson;

    return this.items.filter((item) => {
      const person = this.personStore.getByIdOrLoad(item.personId);
      const correctOrganization =
        person?.organizationId === currentPerson.organizationId;

      return correctOrganization && item.personId === currentPerson?.id;
    });
  }

  @computed
  public get currentPersonTasks(): Task[] {
    return this.getFilteredItems({
      personId: this.personStore.currentPerson.id,
    });
  }

  public getTaskFromProject(projectId: string): Task[] {
    return this.items.filter((item) => {
      if (item.projectId === projectId) {
        return true;
      }

      return false;
    });
  }

  public getProjectTasks(projectId: string): Task[] {
    return this.getTaskFromProject(projectId);
  }
}
