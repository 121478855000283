import React from 'react';
import {
  Badge as ChakraBadge,
  BadgeProps as ChakraBadgeProps,
} from '@chakra-ui/react';

export interface BadgeProps extends ChakraBadgeProps {}

const Badge: React.FC<BadgeProps> = (props) => {
  return (
    <ChakraBadge
      variant="outline"
      fontWeight="500"
      borderRadius="md"
      textTransform="unset"
      backgroundColor="white"
      {...props}
    />
  );
};

export default Badge;
