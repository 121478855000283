import { injectable } from 'tsyringe';
import ApiService from './ApiService';
import PersonalAccessToken from '../domain/PersonalAccessToken';

@injectable()
export default class PersonalAccessTokenService {
  public constructor(private client: ApiService) {}

  public async create(payload: {
    name: string;
    expireDate?: string;
  }): Promise<PersonalAccessToken> {
    const { data } = await this.client.post<PersonalAccessToken>(
      '/personal-access-tokens',
      payload,
    );

    return new PersonalAccessToken().fromObject(data);
  }

  public async getAll(): Promise<PersonalAccessToken[]> {
    const { data } = await this.client.get<PersonalAccessToken[]>(
      '/personal-access-tokens',
    );

    return data.map((item) => new PersonalAccessToken().fromObject(item));
  }
}
