import React, { useState } from 'react';
import Tag, { TagType } from '../domain/Tag';
import ConnectedTagsSelect from '../containers/ConnectedTagsSelect';
import Box from '../ui/Box';
import { RichSelectOption } from '../ui/RichSelect';
import { t } from '../i18n';

export interface TagSelectWithHandleProps {
  onSelect: (tagId: string) => void;
  handle: React.ReactNode;
  tags: Tag[];
  disabledTags?: Tag[];
  disabled?: boolean;
  type?: TagType;
  forbiddenCreatableText?: (inputValue: string) => string;
}

const TagSelectWithHandle: React.FC<TagSelectWithHandleProps> = ({
  onSelect,
  handle,
  tags,
  disabled,
  type,
  disabledTags,
  forbiddenCreatableText,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box position="relative">
      <Box
        display="flex"
        justifyContent="flex-end"
        onClick={disabled ? null : () => setOpen(!open)}
      >
        {handle}
      </Box>
      {open && (
        <Box minW="250px" position="absolute" right={0} zIndex={2}>
          <ConnectedTagsSelect
            tags={tags}
            onMenuClose={() => setOpen(false)}
            onChange={(value: RichSelectOption | RichSelectOption[]) => {
              const tagId = Array.isArray(value)
                ? value[0]?.value
                : value.value;

              onSelect(tagId || null);
            }}
            forbiddenCreatables={disabledTags.map((t) => t.name)}
            forbiddenCreatableText={forbiddenCreatableText}
            onBlur={() => setOpen(false)}
            autoFocus
            menuIsOpen
            controlShouldRenderValue={false}
            clearable={false}
            placeholder={t('Search...')}
            type={type}
            dropdownIndicator={false}
          />
        </Box>
      )}
    </Box>
  );
};

export default TagSelectWithHandle;
