import React from 'react';
import { FiPlus } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface PlusIconProps extends IconProps {}

const PlusIcon: React.FC<PlusIconProps> = ({ ...props }) => {
  return <Icon as={FiPlus} {...props} />;
};

export default PlusIcon;
