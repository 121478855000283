import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import { endOfMonth, startOfMonth } from 'date-fns';
import TimesheetService from '../services/TimesheetService';
import useMonthPicker from '../hooks/useMonthPicker';
import useSWR from 'swr';
import SkeletonLoader from '../ui/SkeletonLoader';
import ParticipationTimeSheet, {
  ParticipationTimeSheetProps,
} from '../components/ParticipationTimeSheet';
import Alert from '../ui/Alert';
import Participation from '../domain/Participation';
import { formatDate } from '../util/date';

export interface ConnectedPersonTimesheetProps
  extends Omit<ParticipationTimeSheetProps, 'title' | 'data' | 'variant'> {
  personId: string;
}

const ConnectedPersonTimesheet: React.FC<ConnectedPersonTimesheetProps> = ({
  personId,
  ...props
}) => {
  const personStore = useResolver(PersonStore);
  const timesheetService = useResolver(TimesheetService);

  const person = personStore.getByIdOrLoad(personId);

  const month = useMonthPicker();
  const { data, mutate, error } = useSWR(
    person ? `ConnectedPersonTimesheet:${personId}|${formatDate(month)}` : null,
    () =>
      timesheetService.getDaily({
        organizationId: person.organizationId,
        personId,
        dateStart: startOfMonth(month),
        dateEnd: endOfMonth(month),
      }),
  );

  if (error) {
    return <Alert status="error">{String(error)}</Alert>;
  }

  if (!person || !data) {
    return <SkeletonLoader />;
  }

  return (
    <ParticipationTimeSheet
      title={person.name}
      data={data.map(({ participation, entries }) => ({
        entries,
        participations: [new Participation().fromObject(participation)],
      }))}
      variant="worklog"
      onCreateWorklog={() => {
        mutate();
      }}
      projectAsRowHead
      {...props}
    />
  );
};

export default observer(ConnectedPersonTimesheet);
