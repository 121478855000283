import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import Box from './Box';
import { useTheme } from '@chakra-ui/react';
import get from 'lodash/get';

export interface PieProps {
  values: Array<{
    title: string;
    value: number;
    color?: string;
  }>;
  totalValue?: number;
  label?: string | number;
}

const Pie: React.FC<PieProps> = ({ values, totalValue, label }) => {
  const theme = useTheme();
  return (
    <Box width="32px">
      <PieChart
        labelPosition={0}
        label={label && (() => label)}
        lineWidth={26}
        totalValue={totalValue}
        labelStyle={{
          fontSize: '24px',
          fill: get(theme.colors, 'gray.600'),
        }}
        data={values.map((v, i) => ({
          color: get(theme.colors, `blue.${((i + 1) * 3) % 10}00`),
          ...v,
        }))}
      />
    </Box>
  );
};

Pie.defaultProps = {};

export default Pie;
