import React from 'react';
import useResolver from '../../../hooks/useResolver';
import useQueryValue from '../../../hooks/useQueryValue';
import DynamicFieldStore from '../store/DynamicFieldStore';
import DynamicFieldDeleteForm from './DynamicFieldDeleteForm';
import { observer } from 'mobx-react';
import { DELETE_FORM_QUERY_PARAM_NAME } from '../constants';

export interface ConnectedDynamicFieldDeleteFormProps {}

const ConnectedDynamicFieldDeleteForm: React.FC<
  ConnectedDynamicFieldDeleteFormProps
> = () => {
  const dynamicFieldId = useQueryValue(DELETE_FORM_QUERY_PARAM_NAME);
  const dynamicFieldStore = useResolver(DynamicFieldStore);
  const dynamicField =
    dynamicFieldId && dynamicFieldStore.getByIdOrLoad(dynamicFieldId);

  if (!dynamicField) {
    return null;
  }

  const handleSubmit = async () => {
    await dynamicFieldStore.delete(dynamicFieldId);
  };

  return (
    <DynamicFieldDeleteForm
      onSubmit={handleSubmit}
      dynamicField={dynamicField}
    />
  );
};

export default observer(ConnectedDynamicFieldDeleteForm);
