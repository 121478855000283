import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import Person from '../domain/Person';
import PersonStore from '../store/PersonStore';
import RichText from '../components/RichText';

export interface ConnectedPersonDescriptionProps {
  person: Person;
  editable: boolean;
}

const ConnectedPersonDescription: React.FC<ConnectedPersonDescriptionProps> = ({
  person,
  editable,
}) => {
  const personStore = useResolver(PersonStore);

  const handleChange = async (description: string) => {
    await personStore.update({
      id: person.id,
      description,
    });
  };

  return (
    <RichText
      children={person.description}
      onChange={editable && handleChange}
    />
  );
};

export default observer(ConnectedPersonDescription);
