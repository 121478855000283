import React, { ReactNode } from 'react';
import { InteractiveTimeline } from '../Timeline';
import { DataGridField } from './DataGrid.types';
import { processAccessor } from './DataGrid.utils';

export interface DataGridTimelineSpecificProps<Item, Key extends string> {
  getStartDate: (item: Item) => Date | null;
  getEndDate: (item: Item) => Date | null;
  getTitle: (item: Item, getField: (key: Key) => ReactNode) => ReactNode;
  getContent: (item: Item, getField: (key: Key) => ReactNode) => ReactNode;
}

export interface DataGridTimelineProps<Item, Key extends string>
  extends DataGridTimelineSpecificProps<Item, Key> {
  data: Item[];
  fields: Record<Key, DataGridField<Item>>;
}

export const DataGridTimeline = <Item, Key extends string>({
  data,
  fields,
  getStartDate,
  getEndDate,
  getTitle,
  getContent,
}: DataGridTimelineProps<Item, Key>) => {
  return (
    <InteractiveTimeline
      data={data.map((item, index) => ({
        title: getTitle(
          item,
          (key) => processAccessor(fields[key].accessor(item, index)).node,
        ),
        content: getContent(
          item,
          (key) => processAccessor(fields[key].accessor(item, index)).node,
        ),
        dateStart: getStartDate(item),
        dateEnd: getEndDate(item),
      }))}
    />
  );
};
