import React from 'react';
import ConnectedCreateAccountForm from '../containers/ConnectedCreateAccountForm';
import CleanLayout from '../ui/CleanLayout';
import ConnectedAuthenticationGuard from '../containers/ConnectedAuthenticationGuard';
import { Redirect } from 'react-router-dom';

const CreateAccount = () => {
  return (
    <ConnectedAuthenticationGuard
      forbiddenAction={
        <CleanLayout size="md">
          <ConnectedCreateAccountForm />
        </CleanLayout>
      }
    >
      <Redirect to="/app" />
    </ConnectedAuthenticationGuard>
  );
};

export default CreateAccount;
