import AbstractDomain from './AbstractDomain';
import Project from './Project';
import Tag from './Tag';
import Resource from '../decorators/Resource';

@Resource.Name('project-tags')
export default class ProjectTag extends AbstractDomain {
  public tagId: string;
  public projectId: string;
  private _capacity?: number;
  protected _project?: Project;
  protected _tag?: Tag;

  get capacity(): number {
    return this._capacity;
  }

  set capacity(value: number) {
    this._capacity = value ? +value : null;
  }

  get tag(): Tag {
    return this._tag;
  }

  set tag(value: Tag) {
    this._tag = value;
  }

  get project(): Project {
    return this._project;
  }

  set project(value: Project) {
    this._project = value;
  }

  public fromObject(payload: Partial<ProjectTag>): this {
    super.fromObject(payload);

    this.tagId = payload.tagId;
    this.projectId = payload.projectId;
    this.capacity = payload.capacity;

    return this;
  }

  public toObject(): Partial<ProjectTag> {
    return {
      ...super.toObject(),
      tagId: this.tagId,
      projectId: this.projectId,
      capacity: this.capacity,
    };
  }
}
