import React from 'react';
import { Box as ChakraBox, BoxProps as ChakraBoxProps } from '@chakra-ui/react';
import Link from './Link';

export interface BoxProps extends ChakraBoxProps {
  to?: string;
}

const Box: React.FC<BoxProps> = ({ ...props }) => {
  if (props.to) {
    props.as = Link;
  }

  return <ChakraBox {...props} />;
};

Box.defaultProps = {};

export default Box;
