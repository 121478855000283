import React from 'react';
import ConnectedTagFormDrawer from '../containers/ConnectedTagFormDrawer';
import ConnectedTagDeleteDrawer from '../containers/ConnectedTagDeleteDrawer';
import { humanReadableTypes } from './TagTypeSelect';
import PlusIcon from '../ui/icons/PlusIcon';
import Tag, { TagType } from '../domain/Tag';
import TagWithIcon from './TagWithIcon';
import AvatarGroup from '../ui/AvatarGroup';
import PersonAvatar from './PersonAvatar';
import MoreActionsMenu from '../ui/MoreActionsMenu';
import { MenuItem } from '../ui/Menu';
import EditIcon from '../ui/icons/EditIcon';
import DeleteIcon from '../ui/icons/DeleteIcon';
import useQueryValue from '../hooks/useQueryValue';
import Person from '../domain/Person';
import Badge from '../ui/Badge';
import Link from '../ui/Link';
import { t } from '../i18n';
import { DataGrid } from '../ui';
import { useHistory } from 'react-router-dom';

export interface OrganizationTagsProps {
  editable?: boolean;
  personsByTagId: { [tagId: string]: Person[] };
  tags: Tag[];
}

const OrganizationTags: React.FC<OrganizationTagsProps> = ({
  editable,
  personsByTagId,
  tags,
}) => {
  const history = useHistory();
  const type = (useQueryValue('type') as TagType) || TagType.POSITION;

  return (
    <div>
      {editable && (
        <>
          <ConnectedTagFormDrawer />
          <ConnectedTagDeleteDrawer name="tag.delete" />
        </>
      )}
      <DataGrid
        title={t('Roles and skills')}
        data={tags}
        actions={[
          {
            label: t('Add skill'),
            icon: <PlusIcon />,
            onClick: () => history.push(`?type=${type}&tag`),
          },
        ]}
        filters={[
          {
            key: 'type',
            label: t('Type'),
            options: [
              TagType.POSITION,
              TagType.LANGUAGE,
              TagType.TECHNOLOGY,
              TagType.LOCATION,
              TagType.CUSTOM,
            ].map((tagType) => ({
              value: tagType,
              label: humanReadableTypes[tagType],
            })),
            apply: (value, tag) => tag.type === value,
          },
        ]}
        fields={{
          name: {
            title: t('Name'),
            accessor: (tag) => ({
              value: tag.name,
              node: <TagWithIcon {...tag} />,
            }),
          },
          type: {
            title: t('Type'),
            accessor: (tag) => ({
              value: tag.type,
              node: (
                <Badge>
                  {tag.type === TagType.CUSTOM
                    ? `${humanReadableTypes[tag.type]}: ${tag.context}`
                    : humanReadableTypes[tag.type]}
                </Badge>
              ),
            }),
          },
          people: {
            title: t('People'),
            accessor: (tag) => {
              return {
                value: personsByTagId[tag.id]?.length,
                node: (
                  <AvatarGroup>
                    {personsByTagId[tag.id]?.map((p) => (
                      <PersonAvatar person={p} key={p.id} />
                    ))}
                  </AvatarGroup>
                ),
              };
            },
          },
          actions: {
            title: '',
            hiddenInExport: true,
            accessor: (tag) => {
              return (
                <MoreActionsMenu label={t('Skill actions')}>
                  {editable && (
                    <>
                      <MenuItem
                        preserveQuery
                        icon={<EditIcon />}
                        as={Link}
                        to={`?tag=${tag.id}`}
                      >
                        {t('Edit')}
                      </MenuItem>
                      <MenuItem
                        preserveQuery
                        icon={<DeleteIcon />}
                        as={Link}
                        to={`?tag.delete=${tag.id}`}
                      >
                        {t('Delete')}
                      </MenuItem>
                    </>
                  )}
                </MoreActionsMenu>
              );
            },
          },
        }}
      />
    </div>
  );
};

export default OrganizationTags;
