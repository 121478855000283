import React from 'react';
import { observer } from 'mobx-react';
import LoginForm from '../components/LoginForm';
import useResolver from '../hooks/useResolver';
import AccountStore from '../store/AccountStore';
import RootStore from '../store/RootStore';

export interface ConnectedLoginFormProps {}

const ConnectedLoginForm: React.FC<ConnectedLoginFormProps> = () => {
  const accountStore = useResolver(AccountStore);
  const rootStore = useResolver(RootStore);

  return (
    <LoginForm
      onSubmit={({ email, password }) => {
        // No need to await, clearAll can be ran while login request is sent
        const result = accountStore.login(email, password);
        rootStore.clearAll();

        return result;
      }}
    />
  );
};

export default observer(ConnectedLoginForm);
