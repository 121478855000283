import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import TaskStore from '../store/TaskStore';
import Tasks from '../components/Tasks';
import ConnectedTaskDeleteDrawer from './ConnectedTaskDeleteDrawer';
import ConnectedTaskFormDrawer from './ConnectedTaskFormDrawer';

export interface ConnectedTasksAssignedToMeProps {}

const ConnectedTasksAssignedToMe: React.FC<
  ConnectedTasksAssignedToMeProps
> = () => {
  const taskStore = useResolver(TaskStore);
  const personStore = useResolver(PersonStore);

  useEffect(() => {
    taskStore.loadByCurrentCreatorPerson();
  }, []);

  return (
    <div>
      <ConnectedTaskDeleteDrawer name="task.delete" />
      <ConnectedTaskFormDrawer name="task.edit" />
      <Tasks
        showProjectInList
        tasks={taskStore.getFilteredItems({
          creatorId: personStore.currentPerson.id,
        })}
        editable={true}
      />
    </div>
  );
};

export default observer(ConnectedTasksAssignedToMe);
