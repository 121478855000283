import React from 'react';
import { observer } from 'mobx-react';
import Task from '../domain/Task';
import useResolver from '../hooks/useResolver';
import TaskStore from '../store/TaskStore';
import TaskStateSelect from '../components/TaskStateSelect';

export interface ConnectedTaskStateSelectProps {
  task: Task;
}

const ConnectedTaskStateSelect: React.FC<ConnectedTaskStateSelectProps> = ({
  task,
}) => {
  const taskStore = useResolver(TaskStore);

  const handleChange = async (state: Task['state']) => {
    await taskStore.update({ ...task, state });
  };

  return <TaskStateSelect value={task.state} onChange={handleChange} />;
};

export default observer(ConnectedTaskStateSelect);
