import React from 'react';
import Project, {
  ProjectPeriod,
  ProjectState,
  ProjectType,
} from '../domain/Project';
import ProjectCard from './ProjectCard';
import useQueryValue from '../hooks/useQueryValue';
import ConnectedProjectFormDrawer from '../containers/ConnectedProjectFormDrawer';
import ConnectedProjectDeleteDrawer from '../containers/ConnectedProjectDeleteDrawer';
import PlusIcon from '../ui/icons/PlusIcon';
import Participation from '../domain/Participation';
import Box from '../ui/Box';
import { useTranslation } from 'react-i18next';
import {
  AddUserIcon,
  Card,
  DataGrid,
  HStack,
  Image,
  TextLink,
  Tooltip,
} from '../ui';
import { getHumanReadableProjectType, getTextsByType } from '../util/project';
import ConnectedProjectEndDrawer from '../containers/ConnectedProjectEndDrawer';
import ConnectedProjectMoreActionsMenu from '../containers/ConnectedProjectMoreActionsMenu';
import { useLocation } from 'react-router-dom';
import { mergeQueryStrings } from '../util/url';
import ProjectStateBadge from './ProjectStateBadge';
import {
  DEFAULT_DATE_FORMAT,
  formatDate,
  formatDatePeriod,
} from '../util/date';
import ProjectLink from './ProjectLink';
import ConnectedPersonUser from '../containers/ConnectedPersonUser';
import Text from '../ui/Text';
import { Flex } from '@chakra-ui/react';
import ConnectedProjectChangeTypeDrawer from '../containers/ConnectedProjectChangeTypeDrawer';

export interface ProjectListProps {
  projects: Project[];
  type: ProjectType;
  editable?: boolean;
  participations?: Participation[];
  relatedToProjectId?: string;
  personId?: string;
}

const ProjectList: React.FC<ProjectListProps> = ({
  projects,
  type,
  editable,
  participations,
  relatedToProjectId,
  personId,
}) => {
  const location = useLocation();
  const highlightedProjectId = useQueryValue('highlighted-project-id');
  const typeName = type ? getHumanReadableProjectType(type) : 'project';
  const textsByType = getTextsByType(type);
  const { t } = useTranslation();

  return (
    <Box mb={2}>
      {editable && (
        <ConnectedProjectFormDrawer
          name={`${typeName}.add`}
          type={type}
          relatedToProjectId={relatedToProjectId}
        />
      )}
      {editable && <ConnectedProjectDeleteDrawer name={`${typeName}.delete`} />}
      {editable && <ConnectedProjectEndDrawer name={`${typeName}.end`} />}
      {editable && (
        <ConnectedProjectChangeTypeDrawer name={`${typeName}.changeType`} />
      )}

      <DataGrid
        title={textsByType.pluralName}
        data={projects}
        exportable={editable}
        filters={[
          {
            key: 'period',
            label: t('Period'),
            apply: (value, project) => {
              // If person is not provided or person is project owner, show only projects with the same period
              if (
                !personId ||
                (personId && project.ownerPersonId === personId)
              ) {
                return project.period === value;
              }

              // If person id is provided, show only projects with the same period and where the person has participation
              return participations.some(
                (participation) =>
                  participation.projectId === project.id &&
                  participation.period === value &&
                  participation.personId === personId,
              );
            },
            options: [
              {
                label: t('Ongoing'),
                value: ProjectPeriod.ONGOING,
                appliedByDefault: true,
              },
              {
                label: t('Upcoming'),
                value: ProjectPeriod.UPCOMING,
                appliedByDefault: true,
              },
              { label: t('Ended'), value: ProjectPeriod.ENDED },
            ],
          },
          {
            key: 'status',
            label: t('Status'),
            apply: (value, project) => project.state === value,
            options: [
              {
                label: t('Published'),
                value: ProjectState.PUBLISHED,
                appliedByDefault: true,
              },
              {
                label: t('Draft'),
                value: ProjectState.DRAFT,
              },
              {
                label: t('Archived'),
                value: ProjectState.ARCHIVED,
              },
            ],
          },
        ]}
        actions={[
          editable &&
            !personId && {
              label: `Create ${typeName}`,
              icon: <PlusIcon />,
              to: '?' + mergeQueryStrings(location.search, `${typeName}.add`),
            },
          editable &&
            personId && {
              label: `Add to ${typeName}`,
              icon: <AddUserIcon />,
              to:
                '?' +
                mergeQueryStrings(
                  location.search,
                  `participation&personId=${personId}&projectId=&projectType=${type}`,
                ),
            },
        ]}
        grid={{
          render: ({ item }) => (
            <ProjectCard
              quickAction
              highlighted={highlightedProjectId === item.id}
              key={item.id}
              project={item}
              editable={editable}
              limitedTags
              displayRelatedProject
              participations={participations?.filter(
                (p) => p.projectId === item.id,
              )}
            />
          ),
        }}
        timeline={{
          getStartDate: (project) => project.dateStart,
          getEndDate: (project) => project.dateEnd,
          getTitle: (project) => (
            <ProjectLink project={project}>
              <TextLink>{project.name}</TextLink>
            </ProjectLink>
          ),
          getContent: (project) => {
            const datePeriod = formatDatePeriod(
              project.dateStart,
              project.dateEnd,
              DEFAULT_DATE_FORMAT,
            );

            return (
              <Flex alignItems="center" gap={2}>
                <ConnectedPersonUser
                  avatarOnly
                  avatarShadow="md"
                  personId={project.ownerPersonId}
                />

                <Tooltip label={datePeriod}>
                  <div>
                    <Text fontSize="xs">{datePeriod}</Text>
                  </div>
                </Tooltip>
              </Flex>
            );
          },
        }}
        fields={{
          name: {
            title: t('Project'),
            accessor: (project) => ({
              value: project.name,
              node: (
                <ProjectLink project={project}>
                  <HStack>
                    {project.image && (
                      <Image
                        maxH={20}
                        maxW={20}
                        src={project.image}
                        alt={project.name}
                      />
                    )}
                    <TextLink>{project.name}</TextLink>
                  </HStack>
                </ProjectLink>
              ),
            }),
          },
          dateStart: {
            title: t('Date start'),
            accessor: (p) => formatDate(p.dateStart),
          },
          end: {
            title: t('Date end'),
            accessor: (p) => formatDate(p.dateEnd),
          },
          state: {
            title: t('Status'),
            accessor: (p) => ({
              value: p.state,
              node: <ProjectStateBadge state={p.state} />,
            }),
          },
          actions: {
            title: '',
            hidden: !editable,
            hiddenInExport: true,
            accessor: (project) => (
              <ConnectedProjectMoreActionsMenu project={project} />
            ),
          },
        }}
        wrapper={Card}
      />
    </Box>
  );
};

export default ProjectList;
