import React from 'react';
import DrawerForm, { DrawerFormProps } from './DrawerForm';
import Stack from '../ui/Stack';
import FormField from './FormField';
import DateInput from '../ui/DateInput';
import Project, {
  ProjectPeriod,
  ProjectState,
  ProjectType,
} from '../domain/Project';
import ConnectedProjectPositionSelect from '../containers/ConnectedProjectPositionSelect';
import ConnectedOrganizationPersonSelect from '../containers/ConnectedOrganizationPersonSelect';
import Participation from '../domain/Participation';
import ConnectedPersonUser from '../containers/ConnectedPersonUser';
import PercentageInput from '../ui/PercentageInput';
import { t } from '../i18n';
import { RichSelectOption } from '../ui/RichSelect';
import ConnectedProjectSelect from '../containers/ConnectedProjectSelect';

export interface ParticipationDrawerProps
  extends Omit<DrawerFormProps, 'children'> {
  project?: Project;
  participation?: Participation;
  userIsSelectable?: boolean;
  personId?: string;
  projectType?: ProjectType;
}

const ParticipationDrawer: React.FC<ParticipationDrawerProps> = ({
  project,
  defaultValues = {},
  userIsSelectable,
  participation,
  personId,
  projectType,
  ...props
}) => {
  const dateStart = new Date(Math.max(+project?.dateStart || 0, Date.now()));
  defaultValues.personIds = [personId];

  return (
    <DrawerForm
      defaultValues={defaultValues}
      header={participation ? t('Edit person') : t('Add person')}
      {...props}
    >
      {({ formContext: { watch, setValue } }) => (
        <Stack spacing={4}>
          {participation && (
            <ConnectedPersonUser
              hideOrganization
              personId={participation.personId}
            />
          )}
          {userIsSelectable && (
            <>
              <FormField name="personIds" label={t('Person')} required>
                <ConnectedOrganizationPersonSelect
                  includeRelatedOrganizations
                  multiple={!personId}
                  onSelect={(value: RichSelectOption[]) => {
                    const personIds = value.map((value) => value.value);
                    setValue('personIds', personIds);
                  }}
                />
              </FormField>
            </>
          )}
          {!project && (
            <FormField name="projectId" label={t('Project')} required>
              <ConnectedProjectSelect
                state={ProjectState.PUBLISHED}
                period={[ProjectPeriod.UPCOMING, ProjectPeriod.ONGOING]}
                type={projectType}
              />
            </FormField>
          )}

          <FormField
            name="dateStart"
            label={t('Start at')}
            defaultValue={dateStart}
          >
            <DateInput
              withTime={project?.datesIncludeTime}
              min={project?.dateStart}
            />
          </FormField>

          <FormField
            name="dateEnd"
            label={t('End at')}
            defaultValue={project?.dateEnd}
          >
            <DateInput
              withTime={project?.datesIncludeTime}
              min={watch('dateStart')}
              max={project?.dateEnd}
            />
          </FormField>

          <FormField name="tagId" label={t('Position')}>
            <ConnectedProjectPositionSelect
              projectId={project ? project.id : watch('projectId')}
            />
          </FormField>

          <FormField name="capacity" label={t('Capacity')} defaultValue={1}>
            <PercentageInput />
          </FormField>
        </Stack>
      )}
    </DrawerForm>
  );
};

ParticipationDrawer.defaultProps = {
  name: 'participation',
};

export default ParticipationDrawer;
