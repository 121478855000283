import React from 'react';
import { addPriceSuffix } from '../util/price';
import { ValueFormat } from '../ui/Value';
import LabeledValue, { LabeledValueProps } from '../ui/LabeledValue';
import Price, { PriceType } from '../domain/Price';

export interface LabeledPriceValueProps extends Partial<LabeledValueProps> {
  label: string;
  price?: Partial<Price>;
  defaultType?: PriceType;
  preUnit?: boolean;
  unit?: string;
}

const LabeledPriceValue: React.FC<LabeledPriceValueProps> = ({
  label,
  price,
  defaultType,
  preUnit,
  unit,
  ...props
}) => {
  return (
    <LabeledValue
      label={addPriceSuffix(label, price?.type || defaultType)}
      value={price?.value}
      unit={unit}
      preUnit={preUnit}
      format={ValueFormat.MONEY}
      {...props}
    />
  );
};

export default LabeledPriceValue;
