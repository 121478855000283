import { ReactNode, useState } from 'react';
import {
  addDays,
  addMonths,
  addYears,
  endOfDecade,
  endOfMonth,
  endOfYear,
  startOfDecade,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { getAllDatesWithinDates } from '../../util/date';
import Box from '../Box';
import { Flex } from '@chakra-ui/react';
import Timeline from './Timeline';
import TimelineRow from './TimelineRow';
import TimelineLeftCell from './TimelineLeftCell';
import TimelineDateCell from './TimelineDateCell';
import Text from '../Text';
import TimelineCell from './TimelineCell';
import TimelineItem from './TimelineItem';

interface Item {
  title: ReactNode;
  dateStart: Date | null;
  dateEnd: Date | null;
  content: ReactNode;
}

type Format = 'day' | 'month' | 'year';

export interface InteractiveTimelineProps {
  data: Item[];
}

const incrementMap: Record<Format, (date: Date) => Date> = {
  day: (date: Date) => addDays(date, 1),
  month: (date: Date) => addMonths(date, 1),
  year: (date: Date) => addYears(date, 1),
};

function getStartAndEndDates(format: Format, date = new Date()) {
  if (format === 'day') {
    return { startDate: startOfMonth(date), endDate: endOfMonth(date) };
  }

  if (format === 'month') {
    return { startDate: startOfYear(date), endDate: endOfYear(date) };
  }

  if (format === 'year') {
    return { startDate: startOfDecade(date), endDate: endOfDecade(date) };
  }

  throw new Error(`Unknown format: ${format}`);
}

function getLowerFormat(format: Format): Format {
  switch (format) {
    case 'day':
      return 'month';
    case 'month':
      return 'year';
    case 'year':
      return 'year';
  }
}

function getHigherFormat(format: Format): Format {
  switch (format) {
    case 'day':
      return 'day';
    case 'month':
      return 'day';
    case 'year':
      return 'month';
  }
}

export default function InteractiveTimeline({
  data,
}: InteractiveTimelineProps) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedFormat, setSelectedFormat] = useState<Format>('month');
  const incrementer = incrementMap[selectedFormat];
  const { startDate, endDate } = getStartAndEndDates(
    selectedFormat,
    selectedDate,
  );
  const dates = getAllDatesWithinDates(startDate, endDate, incrementer);

  const lowerFormat = getLowerFormat(selectedFormat);
  const higherFormat = getHigherFormat(selectedFormat);

  return (
    <Box py={12}>
      <Timeline>
        <TimelineRow>
          <TimelineLeftCell />
          {lowerFormat !== selectedFormat ? (
            <TimelineDateCell
              colSpan={dates.length}
              format={lowerFormat}
              date={selectedDate}
              onClick={() => {
                setSelectedFormat(lowerFormat);
              }}
            />
          ) : (
            <TimelineCell colSpan={dates.length}>&nbsp;</TimelineCell>
          )}
        </TimelineRow>
        <TimelineRow>
          <TimelineLeftCell />
          {dates.map((date) => (
            <TimelineDateCell
              key={date.toISOString()}
              highlightCurrentDate
              format={selectedFormat}
              date={date}
              onClick={
                higherFormat !== selectedFormat &&
                (() => {
                  setSelectedDate(date);
                  setSelectedFormat(higherFormat);
                })
              }
            />
          ))}
        </TimelineRow>
        {data.map((item, index) => {
          const notVisible =
            (item.dateEnd && item.dateEnd < startDate) ||
            (item.dateStart && item.dateStart > endDate);

          if (notVisible) {
            return (
              <TimelineRow key={index}>
                <TimelineLeftCell>{item.title}</TimelineLeftCell>
                <TimelineCell height={40} colSpan={dates.length}>
                  <Flex
                    justifyContent={item.dateStart > endDate ? 'end' : 'start'}
                  >
                    <Text color="gray.500" position="relative" zIndex={3}>
                      {item.content}
                    </Text>
                  </Flex>
                </TimelineCell>
              </TimelineRow>
            );
          }

          const startOutBounds = !item.dateStart || item.dateStart < startDate;
          const endOutBounds = !item.dateEnd || item.dateEnd > endDate;

          const visibleStart = startOutBounds ? startDate : item.dateStart;
          const visibleEnd = endOutBounds ? endDate : item.dateEnd;

          const daysBefore = getAllDatesWithinDates(
            startDate,
            visibleStart,
            incrementer,
          );

          const daysWithin = getAllDatesWithinDates(
            visibleStart,
            visibleEnd,
            incrementer,
          );
          const daysAfter = getAllDatesWithinDates(
            visibleEnd,
            endDate,
            incrementer,
          );

          return (
            <TimelineRow key={index}>
              <TimelineLeftCell>{item.title}</TimelineLeftCell>
              {daysBefore.length > 1 && (
                <TimelineCell height={40} colSpan={daysBefore.length - 1} />
              )}
              {daysWithin.length > 0 && (
                <TimelineCell height={40} colSpan={daysWithin.length}>
                  <TimelineItem
                    openStart={startOutBounds}
                    openEnd={endOutBounds}
                  >
                    {item.content}
                  </TimelineItem>
                </TimelineCell>
              )}
              {daysAfter.length > 0 && (
                <TimelineCell height={40} colSpan={daysAfter.length} />
              )}
            </TimelineRow>
          );
        })}
      </Timeline>
    </Box>
  );
}
