import { container } from 'tsyringe';
import AuthenticationService from './services/AuthenticationService';
import LocalAuthenticationService from './services/LocalAuthenticationService';
import config from './config';
import FirebaseAuthenticationService from './services/FirebaseAuthenticationService';

container.register(AuthenticationService, {
  useClass:
    config.authStrategy === 'unsafe'
      ? LocalAuthenticationService
      : FirebaseAuthenticationService,
});
