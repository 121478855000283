import React from 'react';
import Tooltip from '../ui/Tooltip';
import LegendTooltip from './CapacityMultiCircleLegend';
import CapacityCircle from './CapacityCircle';

interface CapacityMultiCircleProps {
  personCapacity?: number;
  personAllocation?: number;
  personUtilization?: number;
  size?: 's' | 'l' | 'm';
}

enum outerRingRadius {
  'l' = 40,
  'm' = 22,
  's' = 13,
}

enum middleRingRadius {
  'l' = 30,
  'm' = 16,
  's' = 10,
}

enum innerRingRadius {
  'l' = 20,
  'm' = 10,
  's' = 7,
}

enum svgWidth {
  'l' = 150,
  'm' = 100,
  's' = 70,
}

enum svgHeight {
  'l' = 100,
  'm' = 75,
  's' = 35,
}

const CapacityMultiCircle: React.FC<CapacityMultiCircleProps> = ({
  personCapacity,
  personAllocation,
  personUtilization,
  size,
}) => {
  if (!personCapacity && !personAllocation && !personUtilization) {
    return <></>;
  }

  return (
    <Tooltip
      label={
        <LegendTooltip
          personCapacity={personCapacity}
          personAllocation={personAllocation}
          personUtilization={personUtilization}
        />
      }
    >
      <svg width={svgWidth[size]} height={svgHeight[size]}>
        {personCapacity && (
          <g transform={`rotate(-90 ${'100 100'})`}>
            <CapacityCircle
              colour="#7a6eee"
              percentage={personCapacity * 100}
              r={outerRingRadius[size]}
              size={size}
            />
          </g>
        )}
        {personAllocation && (
          <g transform={`rotate(-90 ${'100 100'})`}>
            <CapacityCircle
              colour="#ff9f43"
              percentage={personAllocation * 100}
              r={middleRingRadius[size]}
              size={size}
            />
          </g>
        )}
        {personUtilization && (
          <g transform={`rotate(-90 ${'100 100'})`}>
            <CapacityCircle
              colour="#00bea4"
              percentage={personUtilization * 100}
              r={innerRingRadius[size]}
              size={size}
            />
          </g>
        )}
      </svg>
    </Tooltip>
  );
};

CapacityMultiCircle.defaultProps = {
  size: 'm',
};

export default CapacityMultiCircle;
