import React from 'react';
import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
} from '@chakra-ui/react';
import { t } from '../i18n';

export interface SelectProps extends ChakraSelectProps {
  nullable?: boolean | string;
}

const Select: React.FC<SelectProps> = ({ children, nullable, ...props }) => {
  return (
    <ChakraSelect {...props}>
      {nullable && (
        <option value={''}>
          {nullable === true ? t('Select..') : nullable}
        </option>
      )}
      {children}
    </ChakraSelect>
  );
};

Select.defaultProps = {};

export default Select;
