import Resource from '../decorators/Resource';
import AbstractDomain from './AbstractDomain';

export enum TaskState {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_HOLD = 'ON_HOLD',
  DONE = 'DONE',
}

export enum TaskPriority {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

@Resource.Name('tasks')
export default class Task extends AbstractDomain {
  public name: string;
  public projectId?: string;
  public dateStart?: Date;
  public dateEnd?: Date;
  public personId?: string;

  public participationId?: string;
  public creatorId: string;
  public allocation?: number;
  public priority: TaskPriority;
  public notes: string;
  public state: TaskState;

  fromObject(payload: Partial<Task>): this {
    super.fromObject(payload);

    this.name = payload.name;
    this.projectId = payload.projectId;
    this.dateStart = payload.dateStart;
    this.dateEnd = payload.dateEnd;
    this.personId = payload.personId;
    this.participationId = payload.participationId;
    this.creatorId = payload.creatorId;
    this.allocation = payload.allocation;
    this.priority = payload.priority;
    this.notes = payload.notes;
    this.state = payload.state;

    return this;
  }

  toObject(): Partial<Request> {
    return {
      ...super.toObject(),
      name: this.name,
      projectId: this.projectId,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      participationId: this.participationId,
      personId: this.personId,
      creatorId: this.creatorId,
      allocation: this.allocation,
      priority: this.priority,
      notes: this.notes,
      state: this.state,
    };
  }
}
