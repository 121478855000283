import React from 'react';
import Project from '../domain/Project';
import Animation from '../ui/Animation';
import VStack from '../ui/VStack';
import Badge from '../ui/Badge';
import ProjectQuickInfo from './ProjectQuickInfo';
import Participation from '../domain/Participation';
import ConnectedProjectCardAction from '../containers/ConnectedProjectCardAction';
import { getProjectDetailViewUri } from '../util/project';
import FlagIcon from '../ui/icons/FlagIcon';
import Tooltip from '../ui/Tooltip';
import ConnectedProjectMoreActionsMenu from '../containers/ConnectedProjectMoreActionsMenu';
import Request, { RequestState } from '../domain/Request';
import ConnectedOrganization from '../containers/ConnectedOrganization';
import MediaCard from '../ui/MediaCard';
import { Flex } from '@chakra-ui/react';
import PriorityIcon from '../ui/icons/PriorityIcon';
import { t } from '../i18n';

export interface ProjectCardProps {
  project: Project;
  highlighted?: boolean;
  editable?: boolean;
  participations?: Participation[];
  requests?: Request[];
  quickAction?: boolean;
  displayOwnerPerson?: boolean;
  displayCapacityChart?: boolean;
  noLink?: boolean;
  maxHeight?: number | string;
  limitedTags?: boolean;
  displayRelatedProject?: boolean;
  displayDynamicFields?: boolean;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  project,
  highlighted,
  editable,
  participations,
  requests,
  quickAction,
  displayCapacityChart,
  displayOwnerPerson,
  limitedTags,
  noLink,
  maxHeight,
  displayRelatedProject,
  displayDynamicFields,
}) => {
  const hasRequests = !!requests?.find(
    (r) => r.state === RequestState.REQUESTED,
  );

  const content = (
    <MediaCard
      linkTo={noLink ? null : getProjectDetailViewUri(project?.id)}
      image={project.getImage()}
      title={
        <Flex px={4}>
          <div>
            <span>
              {' '}
              {!!project?.priority && <PriorityIcon />}
              {project.name} &nbsp;
            </span>
            {project.isArchived() && (
              <>
                <Tooltip label={t('Project is no longer available')}>
                  <div style={{ display: 'inline-block' }}>
                    <Badge>{t('Archived')}</Badge>
                  </div>
                </Tooltip>
                &nbsp;
              </>
            )}
            {project.isDraft() && (
              <>
                <Tooltip label={t('Project has not been published yet')}>
                  <div style={{ display: 'inline-block' }}>
                    <Badge>{t('Draft')}</Badge>
                  </div>
                </Tooltip>
                &nbsp;
              </>
            )}
            {project.relatedOrganizationId && (
              <ConnectedOrganization
                organizationId={project.relatedOrganizationId}
              >
                {(organization) =>
                  organization ? (
                    <Badge colorScheme="brand" size="xs">
                      {organization.name}
                    </Badge>
                  ) : null
                }
              </ConnectedOrganization>
            )}
          </div>
        </Flex>
      }
      action={
        quickAction && (
          <ConnectedProjectCardAction
            project={project}
            participations={participations}
          />
        )
      }
      topRight={
        editable && <ConnectedProjectMoreActionsMenu project={project} />
      }
      topLeft={
        hasRequests && (
          <Tooltip label={t('Pending requests')}>
            <div>
              <FlagIcon color="red.500" />
            </div>
          </Tooltip>
        )
      }
      maxHeight={maxHeight}
    >
      <VStack justifyContent="center" width="100%">
        <ProjectQuickInfo
          project={project}
          displayCapacityChart={displayCapacityChart}
          displayOwnerPerson={displayOwnerPerson}
          limitedTags={limitedTags}
          displayRelatedProject={displayRelatedProject}
          displayDynamicFields={displayDynamicFields}
        />
      </VStack>
    </MediaCard>
  );

  if (!highlighted) {
    return content;
  }

  return <Animation variant="scale-fade">{content}</Animation>;
};

export default ProjectCard;
