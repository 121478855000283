import React from 'react';
import Card from '../ui/Card';
import RouterTabs from './RouterTabs';
import Tab from '../ui/Tab';
import Stack from '../ui/Stack';
import ResponsiveGrid from '../ui/ResponsiveGrid';
import Alert from '../ui/Alert';
import Organization from '../domain/Organization';
import OrganizationRelation from '../domain/OrganizationRelation';
import ConnectedManagedOrganizationFormDrawer from '../containers/ConnectedManagedOrganizationFormDrawer';
import ConnectedManagedOrganizationDeleteDrawer from '../containers/ConnectedManagedOrganizationDeleteDrawer';
import ManagedOrganizationCard from './ManagedOrganizationCard';
import ConnectedOrganizationPersonList from '../containers/ConnectedOrganizationPersonList';
import ConnectedProjectList from '../containers/ConnectedProjectList';
import { ProjectType } from '../domain/Project';
import Box from '../ui/Box';
import ConnectedManagedOrganizationAbout from '../containers/ConnectedManagedOrganizationAbout';
import { getCurrentOrganizationRelation } from '../util/organization';
import { t } from '../i18n';
import ConnectedManagedOrganizationBudget from '../containers/ConnectedManagedOrganizationBudget';
import ConnectedManagedOrganizationDeactivateDrawer from '../containers/ConnectedManagedOrganizationDeactivateDrawer';
import Person, { Role } from '../domain/Person';

interface ManagedOrganizationOverviewProps {
  organization: Organization;
  organizationRelations: OrganizationRelation[];
  editable?: boolean;
  currentPerson: Person;
}

const ManagedOrganizationOverview: React.FC<
  ManagedOrganizationOverviewProps
> = ({ organization, organizationRelations, editable, currentPerson }) => {
  if (!organization || organization.isDeleted()) {
    return <Alert status="error">{t('Clients not found')}</Alert>;
  }

  const currentRelation = getCurrentOrganizationRelation(organizationRelations);

  return (
    <>
      {editable && (
        <>
          <ConnectedManagedOrganizationFormDrawer name="managed-organization" />
          <ConnectedManagedOrganizationDeleteDrawer name="managed-organization.delete" />
          <ConnectedManagedOrganizationDeactivateDrawer name="managed-organization.deactivate" />
        </>
      )}
      <ResponsiveGrid
        alignItems="start"
        templateColumns="minmax(0, 1fr) 310px"
        spacing={4}
        maxWidth="100%"
      >
        <Stack spacing={4}>
          <RouterTabs>
            <Tab title={t('Contacts')}>
              <Card>
                <ConnectedOrganizationPersonList
                  compact
                  organizationId={organization.id}
                  title={t('List of contacts')}
                />
              </Card>
            </Tab>
            <Tab title={t('Projects')}>
              <Box mx={3}>
                <ConnectedProjectList
                  editable={false}
                  filters={{
                    type: ProjectType.PROJECT,
                    relatedOrganizationId: organization.id,
                  }}
                />
              </Box>
            </Tab>
            <Tab title={t('Events')}>
              <Box mx={3}>
                <ConnectedProjectList
                  editable={false}
                  filters={{
                    type: ProjectType.EVENT,
                    relatedOrganizationId: organization.id,
                  }}
                />
              </Box>
            </Tab>
            <Tab title={t('Learning')}>
              <Box mx={3}>
                <ConnectedProjectList
                  editable={false}
                  filters={{
                    type: ProjectType.TRAINING,
                    relatedOrganizationId: organization.id,
                  }}
                />
              </Box>
            </Tab>
            {currentPerson.hasPermission(Role.ADMIN) && (
              <Tab title={t('Finances')}>
                <Card px={3}>
                  <ConnectedManagedOrganizationBudget
                    organizationId={organization.id}
                  />
                </Card>
              </Tab>
            )}
            {currentRelation && (
              <Tab title={t('Overview')}>
                <Card>
                  <ConnectedManagedOrganizationAbout
                    editable={editable}
                    organizationRelation={currentRelation}
                  />
                </Card>
              </Tab>
            )}
          </RouterTabs>
        </Stack>
        <ManagedOrganizationCard
          compact
          displayDynamicFields
          editable={editable}
          organization={organization}
          organizationRelation={currentRelation}
        />
      </ResponsiveGrid>
    </>
  );
};

export default ManagedOrganizationOverview;
