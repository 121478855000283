import React from 'react';
import FacebookButton from '../ui/FacebookButton';
import useResolver from '../hooks/useResolver';
import AccountStore, { SocialLoginProvider } from '../store/AccountStore';

export interface ConnectedFacebookLoginProps {}

const ConnectedFacebookLogin: React.FC<ConnectedFacebookLoginProps> = () => {
  const accountStore = useResolver(AccountStore);

  return (
    <FacebookButton
      onClick={() => accountStore.socialLogin(SocialLoginProvider.FACEBOOK)}
    />
  );
};

export default ConnectedFacebookLogin;
