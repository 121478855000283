import React from 'react';
import { FiCoffee } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface CoffeeIconProps extends IconProps {}

const CoffeeIcon: React.FC<CoffeeIconProps> = ({ ...props }) => {
  return <Icon as={FiCoffee} {...props} />;
};

export default CoffeeIcon;
