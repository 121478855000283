import Price from '../domain/Price';
import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';

@injectable()
export default class PriceService extends AbstractRestfulHttpService<Price> {
  public constructor(apiService: ApiService) {
    super(Price, apiService);
  }

  public async getAllByOrganization(organizationId: string): Promise<Price[]> {
    return this.getMany({ organizationId });
  }

  public async getAllByOrganizationAndExternalId(
    organizationId: string,
    externalId: string,
  ): Promise<Price[]> {
    return this.getMany({ organizationId, externalId });
  }
}
