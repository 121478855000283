import AbstractDomain from './AbstractDomain';
import Person from './Person';
import Project, { ProjectPeriod as ParticipationPeriod } from './Project';
import Resource from '../decorators/Resource';
import { toDate } from '../util/date';
import Tag from './Tag';

export enum ParticipationState {
  REQUESTED = 'REQUESTED',
  INVITED = 'INVITED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

@Resource.Name('participations')
export default class Participation extends AbstractDomain {
  public personId: string;
  public person?: Person;
  public projectId: string;
  public project?: Project;
  public state: ParticipationState;
  public capacity: number;
  public dateStart?: Date;
  public dateEnd?: Date;
  public fromParticipationId?: string;
  public fromParticipation?: Participation;
  public tagId?: string;
  public tag?: Tag;

  public hasEnded(date: Date = new Date()): boolean {
    return this.dateEnd && this.dateEnd < date;
  }

  public hasStarted(date: Date = new Date()): boolean {
    return !this.dateStart || this.dateStart <= date;
  }

  public isOngoing(date: Date = new Date()): boolean {
    if (!this.hasStarted(date)) {
      return false;
    }

    if (this.hasEnded(date)) {
      return false;
    }

    return true;
  }

  public isBetweenDates(date1: Date, date2: Date): boolean {
    if (this.hasEnded(date1)) {
      return false;
    }

    if (!this.hasStarted(date2)) {
      return false;
    }

    return true;
  }

  public get period(): ParticipationPeriod {
    if (this.isOngoing()) {
      return ParticipationPeriod.ONGOING;
    }

    if (!this.hasStarted()) {
      return ParticipationPeriod.UPCOMING;
    }

    if (this.hasEnded()) {
      return ParticipationPeriod.ENDED;
    }

    return ParticipationPeriod.ENDED;
  }

  public fromObject(payload: Partial<Participation>): this {
    super.fromObject(payload);

    this.personId = payload.personId;
    if (payload.person) {
      this.person = new Person().fromObject(payload.person);
    }
    this.projectId = payload.projectId;
    if (payload.project) {
      this.project = new Project().fromObject(payload.project);
    }
    this.state = payload.state;
    this.capacity = payload.capacity;
    this.dateStart = toDate(payload.dateStart);
    this.dateEnd = toDate(payload.dateEnd);
    this.fromParticipationId = payload.fromParticipationId;
    this.fromParticipation = payload.fromParticipation;
    this.tagId = payload.tagId;

    return this;
  }

  public toObject(): Partial<Participation> {
    return {
      ...super.toObject(),
      personId: this.personId,
      person: this.person,
      projectId: this.projectId,
      project: this.project,
      state: this.state,
      capacity: this.capacity,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      fromParticipationId: this.fromParticipationId,
      fromParticipation: this.fromParticipation,
      tagId: this.tagId,
    };
  }
}
