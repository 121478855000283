import React from 'react';
import RouterTabs from './RouterTabs';
import Tab from '../ui/Tab';
import Requests, { ParticipationRequestsProps } from './Requests';
import Request, { RequestState } from '../domain/Request';
import { t } from '../i18n';

export interface TabbedRequestsProps extends ParticipationRequestsProps {}

const TabbedRequests: React.FC<TabbedRequestsProps> = ({
  requests,
  ...props
}) => {
  const solved: Request[] = [];
  const pending: Request[] = [];

  requests.forEach((request) => {
    if (request.state === RequestState.REQUESTED) {
      pending.push(request);
    } else {
      solved.push(request);
    }
  });

  return (
    <RouterTabs>
      <Tab title={t('Pending')} badge={pending.length || null}>
        <Requests {...props} requests={pending} />
      </Tab>
      <Tab title={t('Solved')}>
        <Requests {...props} requests={solved} />
      </Tab>
    </RouterTabs>
  );
};

export default TabbedRequests;
