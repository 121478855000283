import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useMonthPicker from '../hooks/useMonthPicker';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import ParticipationStore from '../store/ParticipationStore';
import PriceStore from '../store/PriceStore';
import ProjectTagStore from '../store/ProjectTagStore';
import WorklogStore from '../store/WorklogStore';
import { addMonths, endOfMonth, startOfMonth, subMonths } from 'date-fns';
import PersonBudget from '../components/PersonBudget';
import useSWR from 'swr';
import { formatDate } from '../util/date';
import SkeletonLoader from '../ui/SkeletonLoader';
import FinanceService from '../services/FinanceService';
import Alert from '../ui/Alert';
import ConnectedAdditionalCostDrawer from './ConnectedAdditionalCostDrawer';

export interface ConnectedPersonBudgetProps {
  personId: string;
}

const ConnectedPersonBudget: React.FC<ConnectedPersonBudgetProps> = ({
  personId,
}) => {
  const month = useMonthPicker();
  const financeService = useResolver(FinanceService);
  const personStore = useResolver(PersonStore);
  const participationStore = useResolver(ParticipationStore);
  const priceStore = useResolver(PriceStore);
  const projectTagStore = useResolver(ProjectTagStore);
  const worklogStore = useResolver(WorklogStore);

  const person = personStore.getByIdOrLoad(personId);
  const monthStart = startOfMonth(month);
  const monthEnd = endOfMonth(month);
  const participations = participationStore.items;
  const projectIds = Array.from(
    new Set(participations.map((p) => p.projectId)),
  );

  useEffect(() => {
    participationStore.loadByPerson(personId);
    priceStore.loadForCurrentOrganization();
  }, [personId]);

  useEffect(() => {
    projectTagStore.loadByProjects(projectIds);
  }, [projectIds.join(',')]);

  useEffect(() => {
    worklogStore.loadByPerson(
      personId,
      subMonths(monthStart, 1),
      addMonths(monthEnd, 1),
    );
  }, [monthStart.toISOString(), personId]);

  const {
    data: finances,
    error,
    mutate,
  } = useSWR(`ConnectedPersonBudget:${personId}|${formatDate(month)}`, () =>
    financeService.getFinances({
      organizationId: person?.organizationId,
      personId,
      dateStart: startOfMonth(month),
      dateEnd: endOfMonth(month),
    }),
  );

  if (error) {
    return <Alert status="error">{String(error)}</Alert>;
  }

  if (!person || !finances) {
    return <SkeletonLoader />;
  }

  return (
    <>
      <PersonBudget
        person={person}
        finances={finances}
        onPriceChange={() => mutate()}
      />
      <ConnectedAdditionalCostDrawer
        externalId={personId}
        onSuccess={() => mutate()}
      />
    </>
  );
};

export default observer(ConnectedPersonBudget);
