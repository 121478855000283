import AbstractDomain from './AbstractDomain';
import Resource from '../decorators/Resource';

export enum OrganizationState {
  ACTIVE = 'ACTIVE',
  SUBSCRIPTION_EXPIRED = 'SUBSCRIPTION_EXPIRED',
  DISABLED = 'DISABLED',
}

export enum OrganizationCurrency {
  EUR = 'EUR',
  AUD = 'AUD',
  USD = 'USD',
}

@Resource.Name('organizations')
export default class Organization extends AbstractDomain {
  public name: string;
  public logo?: string;
  public address?: string;
  public vatNumber?: string;
  public email?: string;
  public phone?: string;
  public registryCode?: string;
  public industry?: string;
  public stripePaymentMethodId?: string;
  public state: OrganizationState;
  public currency: OrganizationCurrency;
  public managedByOrganizationId?: string;

  public fromObject(payload: Partial<Organization>): this {
    super.fromObject(payload);

    this.name = payload.name;
    this.logo = payload.logo;
    this.address = payload.address;
    this.vatNumber = payload.vatNumber;
    this.email = payload.email;
    this.phone = payload.phone;
    this.registryCode = payload.registryCode;
    this.industry = payload.industry;
    this.stripePaymentMethodId = payload.stripePaymentMethodId;
    this.state = payload.state;
    this.currency = payload.currency;
    this.managedByOrganizationId = payload.managedByOrganizationId;

    return this;
  }

  public toObject(): Partial<Organization> {
    return {
      ...super.toObject(),
      name: this.name,
      logo: this.logo,
      address: this.address,
      vatNumber: this.vatNumber,
      email: this.email,
      phone: this.phone,
      registryCode: this.registryCode,
      industry: this.industry,
      stripePaymentMethodId: this.stripePaymentMethodId,
      state: this.state,
      currency: this.currency,
      managedByOrganizationId: this.managedByOrganizationId,
    };
  }
}
