import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from '../../../services/AbstractRestfulHttpService';
import ApiService from '../../../services/ApiService';
import DynamicField from '../domain/DynamicField';

@injectable()
export default class DynamicFieldService extends AbstractRestfulHttpService<DynamicField> {
  public constructor(apiService: ApiService) {
    super(DynamicField, apiService);
  }

  public async getAllByOrganization(
    organizationId: string,
  ): Promise<DynamicField[]> {
    return this.getMany({ organizationId });
  }
}
