import React from 'react';
import {
  HStack as ChakraHStack,
  StackProps as ChakraHStackProps,
} from '@chakra-ui/react';

export interface HStackProps extends ChakraHStackProps {}

const HStack: React.FC<HStackProps> = ({ ...props }) => {
  return <ChakraHStack {...props} />;
};

HStack.defaultProps = {};

export default HStack;
