import React, { useEffect } from 'react';
import Image from '../ui/Image';
import Input from '../ui/Input';
import FormField from './FormField';
import { useFormContext } from 'react-hook-form';
import AttachmentUploadService from '../services/AttachmentUploadService';
import useResolver from '../hooks/useResolver';

interface FormImageFieldProps {
  label?: string;
  name: string;
}

const FormImageField: React.FC<FormImageFieldProps> = ({
  label,
  children,
  name,
}) => {
  const attachmentUploadService = useResolver(AttachmentUploadService);
  const { setValue, watch } = useFormContext();

  useEffect(() => {}, [attachmentUploadService]);

  return (
    <div>
      <FormField name={name} label={label} />
      <label style={{ cursor: 'pointer' }}>
        <Image
          src={watch(name)}
          w="100%"
          h="150px"
          objectFit="contain"
          bg="gray.200"
        />
        <Input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={async (e) => {
            const file = e.target.files[0];

            if (!file) {
              return;
            }

            const url = await attachmentUploadService.upload(file);

            setValue(name, url, { shouldDirty: true });
          }}
        />
      </label>
    </div>
  );
};

export default FormImageField;
