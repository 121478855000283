import React, { ReactNode } from 'react';
import SimpleGrid from './SimpleGrid';
import Box from './Box';
import SeamlessInput from './SeamlessInput';
import Text from './Text';
import { isToday } from 'date-fns';
import Tooltip from './Tooltip';

type Value = React.ReactNode | string | number;

export interface TimeSheetColumn {
  value: Value;
  highlighted?: boolean;
  future?: boolean;
  onChange?: (newValue: number) => void;
  fills?: Array<{ volume: number; color: string; tooltip: any }>;
}

export interface TimeSheetRow {
  id: string;
  head: Value;
  columns: TimeSheetColumn[];
  total: number | any;
  end?: ReactNode;
}

export interface TimesheetLabel {
  date?: Date;
  value: string;
}

export interface TimeSheetProps {
  rows: TimeSheetRow[];
  labels: TimesheetLabel[];
}

const TimeSheet: React.FC<TimeSheetProps> = ({ rows, labels }) => {
  return (
    <SimpleGrid
      templateColumns={`auto repeat(${labels.length}, auto) auto 1fr`}
      gap="2px"
      alignItems="center"
      maxWidth="100%"
      py={4}
      overflowX="auto"
      overflowY="hidden"
    >
      <div />
      {labels.map(({ date, value }, index) => {
        return (
          <Text
            key={index}
            fontSize="xs"
            display="flex"
            justifyContent="center"
            as="div"
          >
            <Box
              textAlign="center"
              borderWidth="2px"
              borderColor={date && isToday(date) ? 'red.500' : 'transparent'}
              borderRadius="50%"
              width="22px"
              height="22px"
            >
              {value}
            </Box>
          </Text>
        );
      })}
      <div />
      <div />
      {rows.map((row, i) => {
        return (
          <React.Fragment key={row.id}>
            <Box mr={4}>{row.head}</Box>
            {labels.map((label, j) => {
              const column = row.columns[j];

              let displayValue: string = column.value
                ? column.value.toString()
                : null;

              if (typeof column.value === 'number' && column.value) {
                const fixedValue = column.value.toFixed();

                displayValue =
                  displayValue === fixedValue ? displayValue : `~${fixedValue}`;
              }

              return (
                <Box
                  key={j}
                  minW={22}
                  _focusWithin={{
                    w: '40px',
                  }}
                  minH={33}
                  bg={column.highlighted ? 'blue.100' : 'gray.100'}
                  position="relative"
                >
                  <Box
                    width="100%"
                    height={
                      33 *
                      Math.max(
                        0,
                        1 -
                          column.fills?.reduce(
                            (acc, curr) => acc + curr.volume,
                            0,
                          ),
                      )
                    }
                  />
                  {column.fills?.map((bg) => {
                    return (
                      <div key={bg.color}>
                        <Tooltip bg="white" color="black" label={bg.tooltip}>
                          <div>
                            <Box
                              key={bg.color}
                              bg={bg.color}
                              width="100%"
                              height={`${33 * bg.volume}px`}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    );
                  })}
                  <SeamlessInput
                    w="100%"
                    h="100%"
                    position="absolute"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    fontSize="sm"
                    type="number"
                    onChange={column.onChange}
                    defaultValue={column.value || null}
                    displayValue={displayValue}
                    overflow="hidden"
                    opacity={column.future ? 0.5 : 1}
                  />
                </Box>
              );
            })}
            {row.total !== null ? (
              <Text fontWeight="bold" ml={4}>
                {+row.total.toFixed(1)}h
              </Text>
            ) : (
              <div />
            )}
            <div>{row.end}</div>
          </React.Fragment>
        );
      })}
      <div />
      {labels.map((label, i) => {
        return <div key={i} />;
      })}
    </SimpleGrid>
  );
};

TimeSheet.defaultProps = {};

export default TimeSheet;
