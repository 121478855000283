import React from 'react';
import Organization from '../domain/Organization';
import Text from '../ui/Text';
import OrganizationRelation from '../domain/OrganizationRelation';
import { getHumanReadableOrganizationRelationType } from '../util/organization';
import DatePeriod from '../ui/DatePeriod';
import MediaCard from '../ui/MediaCard';
import Badge from '../ui/Badge';
import { Flex } from '@chakra-ui/react';
import PriorityIcon from '../ui/icons/PriorityIcon';
import { t } from '../i18n';
import Markdown from '../ui/Markdown';
import ResponsiveGrid from '../ui/ResponsiveGrid';
import ConnectedDynamicFieldCardValues from '../features/dynamic-field/components/ConnectedDynamicFieldCardValues';
import { Resources } from '../features/dynamic-field';
import ManagedOrganizationActions from './ManagedOrganizationActions';

export interface ManagedOrganizationCardProps {
  organization: Organization;
  organizationRelation?: OrganizationRelation;
  editable?: boolean;
  compact?: boolean;
  linkTo?: string;
  displayDynamicFields?: boolean;
}

const ManagedOrganizationCard: React.FC<ManagedOrganizationCardProps> = ({
  organization,
  organizationRelation,
  editable,
  compact,
  linkTo,
  displayDynamicFields,
}) => {
  const data = [
    [t('Industry'), organization.industry],
    [t('Registry code'), organization.registryCode],
    [t('VAT number'), organization.vatNumber],
    [t('Physical address'), organization.address],
    [t('Contact e-mail'), organization.email],
    [t('Contact phone'), organization.phone],
  ].filter(([, value]) => !!value);

  return (
    <MediaCard
      linkTo={linkTo}
      image={organization.logo}
      title={
        <Flex px={4}>
          <div>
            <span style={{ fontSize: '1.125rem' }}>
              {!!organizationRelation?.priority && <PriorityIcon />}
              {organization.name} &nbsp;
            </span>
            <Badge backgroundColor="brand.500" color="white">
              {getHumanReadableOrganizationRelationType(
                organizationRelation?.relationType,
              )}
            </Badge>
          </div>
        </Flex>
      }
      topRight={
        editable && (
          <ManagedOrganizationActions
            organization={organization}
            organizationRelation={organizationRelation}
          />
        )
      }
    >
      {(organizationRelation?.dateStart || organizationRelation?.dateEnd) && (
        <DatePeriod {...organizationRelation} />
      )}
      <Text
        px={4}
        fontSize="sm"
        maxW="100%"
        height={compact ? '48px' : 'auto'}
        overflow="hidden"
        alignSelf="start"
        pointerEvents="none"
        style={{
          WebkitMaskImage:
            compact && 'linear-gradient(180deg, #000 50%, transparent)',
        }}
      >
        {organizationRelation?.description && (
          <Markdown>{organizationRelation.description}</Markdown>
        )}
      </Text>

      {displayDynamicFields && (
        <ConnectedDynamicFieldCardValues
          dynamic={organizationRelation?.dynamic}
          tags={[Resources.ORGANIZATION_RELATIONS]}
        />
      )}

      {!linkTo && (
        <ResponsiveGrid templateColumns="1fr" gap={8} columnGap={16} mt={8}>
          {data.map(([label, value]) => (
            <div key={label}>
              <Text fontWeight="bold" color="brand.500" fontSize="xs">
                {label}
              </Text>
              <div>{value || ''}</div>
            </div>
          ))}
        </ResponsiveGrid>
      )}
    </MediaCard>
  );
};

export default ManagedOrganizationCard;
