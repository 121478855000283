import { ClassType } from 'jackson-js/dist/@types';
import AbstractDomain from '../domain/AbstractDomain';

export function parse<T>(input: string | T | any, type?: ClassType<T>): T {
  let objectValue = typeof input === 'string' ? JSON.parse(input) : input;

  if (Array.isArray(objectValue)) {
    return objectValue.map((i: any) => parse(i, type)) as any;
  }

  if (!type) {
    return objectValue;
  }

  // @ts-ignore
  const result = new type();

  if (result instanceof AbstractDomain) {
    result.fromObject(objectValue);
  }

  return result;
}

export function stringify<T>(input: T): string {
  return JSON.stringify(input);
}

export function toObject<T>(input: T): Partial<T> {
  if (typeof (input as any).toObject === 'function') {
    return (input as any).toObject();
  }

  return { ...input };
}
