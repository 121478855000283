import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import ParticipationStore from '../store/ParticipationStore';
import TagStore from '../store/TagStore';
import PersonStore from '../store/PersonStore';
import PersonSelect, { PersonSelectProps } from '../components/PersonSelect';
import Person from '../domain/Person';
import { formatDatePeriod } from '../util/date';
import Participation from '../domain/Participation';

export interface ConnectedProjectParticipationSelectProps
  extends Omit<PersonSelectProps, 'persons' | 'value'> {
  projectId: string;
  value?: string;
}

const ConnectedProjectParticipationSelect: React.FC<
  ConnectedProjectParticipationSelectProps
> = ({ projectId, value, ...props }) => {
  const participationStore = useResolver(ParticipationStore);
  const personStore = useResolver(PersonStore);
  const tagStore = useResolver(TagStore);

  useEffect(() => {
    if (projectId) {
      participationStore.loadByProject(projectId);
    }
  }, [projectId]);

  const participations = participationStore
    .getFilteredItems({
      projectId,
    })
    .filter((p) => !p.hasEnded());

  participations.forEach((participation) => {
    participation.person = personStore.getByIdOrLoad(participation.personId);
    participation.tag = tagStore.getByIdOrLoad(participation.tagId);
  });

  return (
    <PersonSelect
      persons={participations
        .map((participation) => {
          return participationToPerson(participation);
        })
        .filter(Boolean)}
      value={
        value &&
        participationToPerson(participations.find((p) => p.id === value))
      }
      {...props}
    />
  );
};

function participationToPerson(participation: Participation): Person | null {
  return participation?.person
    ? new Person().fromObject({
        ...participation.person,
        id: participation.id,
        position: `${participation.tag?.name} (${formatDatePeriod(
          participation.dateStart,
          participation.dateEnd,
        )})`,
      })
    : null;
}

export default observer(ConnectedProjectParticipationSelect);
