import React from 'react';
import Person from '../domain/Person';
import DeleteDrawer from './DeleteDrawer';
import { t } from '../i18n';
import { Checkbox, Text } from '../ui';
import FormField from './FormField';
import Organization from '../domain/Organization';

export interface ManagedOrganizationDeactivateInput {
  deactivate: {
    [participationId: string]: boolean;
  };
}

export interface ManagedOrganizationDeactivateDrawerProps {
  name: string;
  onSubmit: (input: ManagedOrganizationDeactivateInput) => void | Promise<void>;
  managedOrganization: Organization;
  persons: Person[];
  readOnly?: boolean;
  buttonText?: string;
  header?: string;
  successMessage?: string;
}

const ManagedOrganizationDeactivateDrawer: React.FC<
  ManagedOrganizationDeactivateDrawerProps
> = ({
  name,
  onSubmit,
  managedOrganization,
  persons,
  readOnly = true,
  buttonText,
  header,
  successMessage,
}) => {
  return (
    <DeleteDrawer
      name={name}
      onSubmit={onSubmit}
      confirmationText={null}
      buttonText={buttonText || t('Delete')}
      successMessage={`${managedOrganization.name} ${successMessage}`}
      header={header || t('Confirm deletion')}
    >
      {persons.length <= 0 && (
        <Text>
          <strong>{managedOrganization.name}</strong>{' '}
          {t('does not have any active contacts')}
        </Text>
      )}
      {persons.length > 0 && (
        <Text>
          {t(
            'Following contacts will be disabled and their active projects will be ended',
          )}{' '}
        </Text>
      )}
      {!readOnly && persons.length > 0 && (
        <Text fontSize="xs" color="gray.500">
          {t('Deselect to keep contact active')}
        </Text>
      )}
      {persons.map((person) => {
        return (
          <FormField defaultValue={true} name={`deactivate.${person.id}`}>
            <Checkbox isReadOnly={readOnly} key={person.id}>
              {person.name}
            </Checkbox>
          </FormField>
        );
      })}
    </DeleteDrawer>
  );
};

export default ManagedOrganizationDeactivateDrawer;
