import { toDate } from '../util/date';

export default abstract class AbstractDomain {
  public id: string;
  public createdAt: Date = new Date();
  public deletedAt?: Date;
  public updatedAt?: Date;

  public fromObject(payload: { [key: string]: any }): this {
    this.id = payload.id;
    this.createdAt = toDate(payload.createdAt);
    this.deletedAt = toDate(payload.deletedAt);
    this.updatedAt = toDate(payload.updatedAt);

    return this;
  }

  public toObject<T>(): T | any {
    return {
      id: this.id,
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
    };
  }

  /**
   * JSON.stringify looks for this method to stringify object
   */
  public toJSON<T>(): T | any {
    return this.toObject();
  }

  public isDeleted(): boolean {
    return this.deletedAt && this.deletedAt < new Date();
  }
}
