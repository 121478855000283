import AbstractResourceStore from './AbstractResourceStore';
import Invitation from '../domain/Invitation';
import InvitationService from '../services/InvitationService';
import AccountStore from './AccountStore';
import { singleton } from 'tsyringe';
import OrganizationStore from './OrganizationStore';

@singleton()
export default class InvitationStore extends AbstractResourceStore<Invitation> {
  public constructor(
    private readonly invitationService: InvitationService,
    private readonly organizationStore: OrganizationStore,
    accountStore: AccountStore,
  ) {
    super(invitationService, accountStore);
  }

  public async createForPerson(personId: string): Promise<Invitation> {
    return await this.create(
      new Invitation().fromObject({
        personId,
      }),
    );
  }

  public async loadByOrganization(organizationId: string) {
    await this.setManyEventually(
      this.invitationService.getAllByOrganization(organizationId),
    );
  }

  public async loadByCurrentOrganization() {
    return this.loadByOrganization(
      this.organizationStore.currentOrganizationId,
    );
  }

  public getLatestPersonInvitation(personId: string): Invitation | null {
    const invites = this.getFilteredItems({ personId });

    return invites.sort((a, b) => +b.expireDate - +a.expireDate)[0] || null;
  }
}
