import React from 'react';
import { FiActivity } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface ActivityIconProps extends IconProps {}

const ActivityIcon: React.FC<ActivityIconProps> = ({ ...props }) => {
  return <Icon as={FiActivity} {...props} />;
};

export default ActivityIcon;
