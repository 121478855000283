import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import Project from '../domain/Project';
import ProjectStore from '../store/ProjectStore';
import RichText from '../components/RichText';

export interface ConnectedProjectAboutProps {
  project: Project;
  editable: boolean;
}

const ConnectedProjectAbout: React.FC<ConnectedProjectAboutProps> = ({
  project,
  editable,
}) => {
  const projectStore = useResolver(ProjectStore);

  const handleChange = async (description: string) => {
    await projectStore.update({
      id: project.id,
      description,
    });
  };

  return (
    <RichText
      children={project.description}
      onChange={editable && handleChange}
    />
  );
};

export default observer(ConnectedProjectAbout);
