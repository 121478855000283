import ProjectTag from '../domain/ProjectTag';
import Participation from '../domain/Participation';
import { PricingType, ProjectType } from '../domain/Project';
import { getColorById } from './color';
import { t } from '../i18n';

export function getProjectDetailViewUri(projectId: string) {
  return `/projects/view/${projectId}`;
}

export function getProjectColor(projectId: string) {
  return getColorById(projectId);
}

export function getTagIdsRelatedToProject(
  projectTags: ProjectTag[],
  participations: Participation[],
): Set<string> {
  return new Set(
    [...projectTags, ...participations]
      .map((participationOrProjectTag) => {
        return participationOrProjectTag.tagId;
      })
      .filter(Boolean),
  );
}

const textsByType: {
  [key in ProjectType]: {
    name: string;
    pluralName: string;
    owner: string;
    team: string;
    client: string;
  };
} = {
  [ProjectType.PROJECT]: {
    name: t('Project'),
    pluralName: t('Projects'),
    owner: t('Project lead'),
    team: t('Team'),
    client: t('Client'),
  },
  [ProjectType.TRAINING]: {
    name: t('Learning'),
    pluralName: t('Learnings'),
    owner: t('Organizer'),
    team: t('Participants'),
    client: t('Provider'),
  },
  [ProjectType.EVENT]: {
    name: t('Event'),
    pluralName: t('Events'),
    owner: t('Organizer'),
    team: t('Participants'),
    client: t('Provider'),
  },
};

export function getTextsByType(type: ProjectType) {
  return textsByType[type] || textsByType[ProjectType.PROJECT];
}

export function getProjectPricingTypeLabel(type: PricingType) {
  switch (type) {
    case PricingType.BUDGET:
      return t('Potential income');
    case PricingType.TIME_AND_MATERIAL:
      return t('Time and material');
    case PricingType.POTENTIAL_COST:
      return t('Potential cost');
    default:
      return type;
  }
}

export function getHumanReadableProjectType(type: ProjectType) {
  switch (type) {
    case ProjectType.TRAINING:
      return 'learning';
    case ProjectType.EVENT:
      return 'event';
    default:
      return 'project';
  }
}
