import { getAllWorkdaysInMonth } from './date';
import { capacityToDayHours } from './capacity';
import Price, { PriceType } from '../domain/Price';
import { BudgetSheet } from '../lib/BudgetSheetBuilder';
import { FinanceResult } from '../services/FinanceService';

export function calculateMonthPrice(
  price: Price,
  month: Date,
  capacity: number,
  durationInMonths: number = 1,
): number {
  if (!price?.value) {
    return 0;
  }

  if (price.type === PriceType.MONTHLY) {
    return price.value * capacity;
  }

  if (price.type === PriceType.FIXED) {
    return price.value / durationInMonths;
  }

  if (price.type === PriceType.HOURLY) {
    const workdaysInMonth = getAllWorkdaysInMonth(month).length;

    return price.value * workdaysInMonth * capacityToDayHours(capacity);
  }

  return null;
}

export function toNumberOrNull(budget?: string | number): number | null {
  if (typeof budget === 'number') {
    return budget;
  }

  if (typeof budget === 'string' && budget) {
    return Number(budget);
  }

  return null;
}

export function initializeBudgetSheet(): BudgetSheet {
  return {
    revenuePrognosis: 0,
    costPrognosis: 0,
    marginPrognosis: 0,
    rows: [],
  };
}

export function calculateTotals(finances: FinanceResult[]): {
  revenuePrognosis: number;
  costPrognosis: number;
  marginPrognosis: number;
} {
  return {
    revenuePrognosis: finances.reduce(
      (total, current) => total + current.revenuePrognosis,
      0,
    ),
    costPrognosis: finances.reduce(
      (total, current) => total + current.costPrognosis,
      0,
    ),
    marginPrognosis: finances.reduce(
      (total, current) =>
        total + current.revenuePrognosis - current.costPrognosis,
      0,
    ),
  };
}
