import OrganizationRelation, {
  OrganizationRelationType,
} from '../domain/OrganizationRelation';
import { t } from '../i18n';

export function getHumanReadableOrganizationRelationType(
  relationType: OrganizationRelationType,
): string {
  switch (relationType) {
    case OrganizationRelationType.CLIENT:
      return t('Client');
    case OrganizationRelationType.PARTNER:
      return t('Partner');
    case OrganizationRelationType.SALESLEAD:
      return t('Sales lead');
    case OrganizationRelationType.INTERNAL:
      return t('Internal');
    case OrganizationRelationType.SPONSOR:
      return t('Sponsor');
  }

  return '';
}

export function getCurrentOrganizationRelation(
  relations: OrganizationRelation[],
): OrganizationRelation | null {
  if (relations?.length <= 0) {
    return null;
  }

  return relations[0];
}
