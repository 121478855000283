import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import PersonProfile, { PersonProfileProps } from '../components/PersonProfile';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import { Role } from '../domain/Person';
import OrganizationStore from '../store/OrganizationStore';
import useMonthPicker from '../hooks/useMonthPicker';
import ParticipationStore from '../store/ParticipationStore';
import { calculateHoursFromCapacity } from '../util/capacity';
import useSWR from 'swr';
import { formatDate } from '../util/date';
import { endOfMonth, startOfMonth } from 'date-fns';
import TimesheetService from '../services/TimesheetService';
import Participation from '../domain/Participation';
import { getAllocationHours, getUtilizationHours } from '../util/timesheet';

export interface ConnectedPersonProfileProps
  extends Omit<PersonProfileProps, 'person'> {
  personId: string;
}

const ConnectedPersonProfile: React.FC<ConnectedPersonProfileProps> = ({
  personId,
  ...props
}) => {
  const personStore = useResolver(PersonStore);
  const organizationStore = useResolver(OrganizationStore);
  const month = useMonthPicker();
  const participationStore = useResolver(ParticipationStore);
  const timesheetService = useResolver(TimesheetService);

  useEffect(() => {
    personStore.loadOne(personId);
    participationStore.loadByPerson(personId);
  }, [personId]);

  const person = personStore.getById(personId);

  const { data } = useSWR(
    person
      ? `ConnectedOrganizationPersonTimeline:${[personId]}|${formatDate(month)}`
      : null,
    () =>
      timesheetService.getDaily({
        organizationId: person.organizationId,
        personId: [personId],
        dateStart: startOfMonth(month),
        dateEnd: endOfMonth(month),
      }),
  );

  const timesheet = data?.map(({ participation, entries }) => ({
    entries,
    participations: [new Participation().fromObject(participation)],
  }));

  const utilizationHours = getUtilizationHours(timesheet);

  const allocationHours = getAllocationHours(timesheet);

  const viewer = personStore.currentPerson;
  const organization =
    person?.organizationId &&
    person.organizationId !== viewer.organizationId &&
    organizationStore.getByIdOrLoad(person.organizationId);
  const capacityHours = person
    ? calculateHoursFromCapacity(person.capacity, month)
    : 0;

  return (
    <PersonProfile
      person={person}
      editable={viewer.hasEditPermission()}
      showBudget={viewer.hasPermission(Role.ADMIN)}
      organization={organization}
      allocation={allocationHours / capacityHours}
      utilization={utilizationHours / capacityHours}
      {...props}
    />
  );
};

export default observer(ConnectedPersonProfile);
