import React from 'react';
import { useFormContext } from 'react-hook-form';
import Button, { ButtonProps } from '../ui/Button';

export interface FormSubmitButtonProps extends ButtonProps {}

const FormSubmitButton: React.FC<FormSubmitButtonProps> = ({
  children,
  disabled,
  ...props
}) => {
  const {
    formState: { isSubmitting, isSubmitSuccessful },
  } = useFormContext();

  return (
    <Button
      type="submit"
      colorScheme="brand"
      isLoading={isSubmitting}
      disabled={isSubmitting || isSubmitSuccessful || disabled}
      {...props}
    >
      {children}
    </Button>
  );
};

export default FormSubmitButton;
