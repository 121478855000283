import React from 'react';
import {
  Tab as ChakraTab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as ChakraTabs,
  TabsProps as ChakraTabsProps,
} from '@chakra-ui/react';
import HStack from './HStack';
import Tab from './Tab';
import { flattenChildren } from '../util/react';

export interface TabsProps
  extends Partial<
    Pick<ChakraTabsProps, 'defaultIndex' | 'index' | 'onChange'>
  > {
  actions?: any;
}

const Tabs: React.FC<TabsProps> = ({ children, actions, ...props }) => {
  const childElements = flattenChildren(children);

  return (
    <ChakraTabs colorScheme="brand" variant="unstyled" isLazy {...props}>
      <HStack justifyContent="space-between" maxWidth="100%" overflow="auto">
        <TabList>
          {childElements.map((child) => {
            return (
              <ChakraTab
                mr={1}
                rounded="md"
                fontSize="sm"
                fontWeight="500"
                userSelect="none"
                px={3}
                height={8}
                display="flex"
                alignItems="center"
                whiteSpace="nowrap"
                transition="background-color .2s"
                _selected={{
                  color: 'white',
                  background:
                    'linear-gradient(46.62deg, #7367F0 0%, #9E95F5 93.64%)',
                }}
                key={child.props.title}
              >
                <Tab {...child.props} />
              </ChakraTab>
            );
          })}
        </TabList>
        <div>{actions}</div>
      </HStack>
      <TabPanels>
        {childElements.map((child) => {
          return (
            <TabPanel px={0} key={child.props.title}>
              {child.props.children}
            </TabPanel>
          );
        })}
      </TabPanels>
    </ChakraTabs>
  );
};

export default Tabs;
