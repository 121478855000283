import React from 'react';
import { observer } from 'mobx-react';
import ProjectPublishButton from '../components/ProjectPublishButton';
import Project, { ProjectState } from '../domain/Project';
import useResolver from '../hooks/useResolver';
import ProjectStore from '../store/ProjectStore';

export interface ConnectedProjectPublishButtonProps {
  project: Project;
}

const ConnectedProjectPublishButton: React.FC<ConnectedProjectPublishButtonProps> =
  ({ project }) => {
    const projectStore = useResolver(ProjectStore);

    return (
      <ProjectPublishButton
        project={project}
        onPublish={() =>
          projectStore.updateState(project.id, ProjectState.PUBLISHED)
        }
      />
    );
  };

export default observer(ConnectedProjectPublishButton);
