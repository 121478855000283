import React from 'react';
import { Link as ChakraLink } from '@chakra-ui/react';
import Link, { LinkProps } from './Link';

export interface TextLinkProps extends Omit<LinkProps, 'to'> {
  to?: string;
}

const TextLink: React.FC<TextLinkProps> = ({ children, ...props }) => {
  return (
    <ChakraLink as={props.to ? Link : 'span'} color="tertiary" {...props}>
      {children}
    </ChakraLink>
  );
};

TextLink.defaultProps = {};

export default TextLink;
