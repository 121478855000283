import React from 'react';
import { Fade, ScaleFade } from '@chakra-ui/react';

export interface AnimationProps {
  variant: 'fade' | 'scale-fade';
  fullWidth?: boolean;
}

const Animation: React.FC<AnimationProps> = ({
  variant,
  fullWidth,
  children,
}) => {
  const props = {
    in: true,
    style: fullWidth ? { width: '100%' } : null,
  };

  if (variant === 'fade') {
    return <Fade {...props}>{children}</Fade>;
  }

  if (variant === 'scale-fade') {
    return <ScaleFade {...props}>{children}</ScaleFade>;
  }

  return <>{children}</>;
};

Animation.defaultProps = {};

export default Animation;
