import React from 'react';
import Avatar from '../ui/Avatar';
import AvatarBadge from '../ui/AvatarBadge';
import Tooltip from '../ui/Tooltip';
import Link from '../ui/Link';
import { observer } from 'mobx-react';
import AlertIcon from '../ui/icons/AlertIcon';
import { t } from '../i18n';

export interface NotificationsHandleProps {
  count: number;
}

const NotificationsHandle: React.FC<NotificationsHandleProps> = ({ count }) => {
  const label =
    count > 0
      ? `${count} unread notification${count !== 1 ? 's' : ''}`
      : t('No unread notifications');

  return (
    <Tooltip label={label}>
      <div>
        <Link to="?notifications" preserveQuery>
          <Avatar
            size="sm"
            bg="white"
            color="black"
            aria-label={t('Notifications')}
            icon={<AlertIcon fontSize="1.5rem" />}
          >
            {count > 0 && (
              <AvatarBadge borderColor="papayawhip" bg="tomato" boxSize="1em" />
            )}
          </Avatar>
        </Link>
      </div>
    </Tooltip>
  );
};

export default observer(NotificationsHandle);
