import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import PriceStore from '../store/PriceStore';
import OrganizationStore from '../store/OrganizationStore';
import AdditionalCostFormDrawer from '../components/AdditionalCostFormDrawer';

export interface ConnectedProjectCostDrawerProps {
  externalId: string;
  organizationId?: string;
  onSuccess?: () => void;
}

const ConnectedAdditionalCostDrawer: React.FC<
  ConnectedProjectCostDrawerProps
> = ({ externalId, organizationId: suppliedOrganizationId, onSuccess }) => {
  const priceStore = useResolver(PriceStore);
  const organizationStore = useResolver(OrganizationStore);

  const organizationId =
    suppliedOrganizationId || organizationStore.currentOrganizationId;

  return (
    <AdditionalCostFormDrawer
      currentOrganization={organizationStore.getByIdOrLoad(organizationId)}
      onSubmit={async (values) => {
        await priceStore.create({
          ...values,
          organizationId,
          externalId,
          value: Number(values.value),
        });

        onSuccess?.();
      }}
    />
  );
};
export default observer(ConnectedAdditionalCostDrawer);
