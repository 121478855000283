import React from 'react';
import NoContent from '../ui/NoContent';
import Page from '../components/Page';
import ConnectedOrganizationBilling from '../containers/ConnectedOrganizationBilling';
import { t } from '../i18n';

const OrganizationUnsubscribed: React.FC = () => {
  return (
    <Page>
      <NoContent>{t('Organization subscription has expired')}</NoContent>
      <ConnectedOrganizationBilling noPermissionsView={<></>} />
    </Page>
  );
};

export default OrganizationUnsubscribed;
