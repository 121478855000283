import React, { useState } from 'react';
import { observer } from 'mobx-react';
import CreateAccountForm from '../components/CreateAccountForm';
import AuthenticationService from '../services/AuthenticationService';
import useResolver from '../hooks/useResolver';
import useQueryValue from '../hooks/useQueryValue';
import useInterval from '../hooks/useInterval';

export interface ConnectedCreateAccountFormProps {}

const ConnectedCreateAccountForm: React.FC<ConnectedCreateAccountFormProps> =
  () => {
    const redirectUrl = useQueryValue('redirect', '/');
    const accountService = useResolver(AuthenticationService);
    const [submitted, setSubmitted] =
      useState<{ email: string } | false>(false);

    useInterval(() => {
      accountService.getAccount().then((account) => {
        if (account) {
          window.location.href = `${window.location.origin}${redirectUrl}`;
        }
      });
    }, 5000);

    return (
      <CreateAccountForm
        submitted={submitted}
        redirectUrl={redirectUrl}
        onSubmit={async ({ email, password }) => {
          await accountService.register(
            email,
            password,
            `${window.location.origin}${redirectUrl}`,
          );

          setSubmitted({ email });
        }}
      />
    );
  };

export default observer(ConnectedCreateAccountForm);
