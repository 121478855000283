import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import UserMenu from '../components/UserMenu';
import AccountStore from '../store/AccountStore';

export interface ConnectedUserMenuProps {
  hidePersons?: boolean;
}

const ConnectedUserMenu: React.FC<ConnectedUserMenuProps> = ({
  hidePersons,
}) => {
  const personStore = useResolver(PersonStore);
  const accountStore = useResolver(AccountStore);
  const { currentUserNonActivePersons, currentPerson } = personStore;
  const user = accountStore.authenticatedUser;

  return (
    <UserMenu
      currentPerson={hidePersons ? null : currentPerson}
      otherPersons={hidePersons ? [] : currentUserNonActivePersons}
      user={user}
    />
  );
};

export default observer(ConnectedUserMenu);
