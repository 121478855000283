import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import OrganizationBilling from '../components/OrganizationBilling';
import PersonStore from '../store/PersonStore';
import { Role } from '../domain/Person';
import NoContent from '../ui/NoContent';

export interface ConnectedOrganizationBillingProps {
  noPermissionsView?: any;
}

const ConnectedOrganizationBilling: React.FC<ConnectedOrganizationBillingProps> =
  ({ noPermissionsView }) => {
    const personStore = useResolver(PersonStore);

    if (personStore.currentPerson.role !== Role.ADMIN) {
      return (
        noPermissionsView || (
          <NoContent>
            You have insufficient permissions to manage billing
          </NoContent>
        )
      );
    }

    return <OrganizationBilling />;
  };

export default observer(ConnectedOrganizationBilling);
