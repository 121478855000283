import { textToInteger } from './string';

export function getColorById(id: string) {
  const colors = [
    '#FBD38D',
    '#FEB2B2',
    '#FAF089',
    '#9AE6B4',
    '#81E6D9',
    '#90CDF4',
    '#9DECF9',
    '#D6BCFA',
    '#FBB6CE',
    '#fbb6f2',
    '#FBB6CE',
    '#b6cafb',
    '#b6ddfb',
    '#bdfbb6',
    '#fbf2b6',
    '#fbccb6',
    '#fbb8b6',
    '#b6d2fb',
    '#b6fbd5',
    '#a48e95',
    '#a48ea3',
    '#908ea4',
    '#8ea49c',
    '#92a48e',
    '#9ca48e',
    '#a49e8e',
    '#a4948e',
    '#a4918e',
  ];

  return colors[textToInteger(id, colors.length - 1)];
}
