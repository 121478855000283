import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';

export interface ContentBoxProps {
  title?: string | React.ReactNode;
  actions?: React.ReactNode | any;
  borderWidth?: string;
}

const ContentBox: React.FC<ContentBoxProps> = ({
  title,
  actions,
  borderWidth,
  children,
}) => {
  return (
    <Box borderWidth={borderWidth} p={2} rounded="md">
      <HStack justifyContent="space-between" alignItems="center">
        <div>
          {title && (
            <Text
              color="gray.500"
              fontSize="xs"
              fontWeight="500"
              casing="uppercase"
            >
              {title}
            </Text>
          )}
        </div>
        {actions && <div>{actions}</div>}
      </HStack>
      {children}
    </Box>
  );
};

ContentBox.defaultProps = {
  borderWidth: '1px',
};

export default ContentBox;
