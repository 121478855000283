import React from 'react';
import ListTransformer from '../lib/ListTransformer';
import Project from '../domain/Project';
import Person from '../domain/Person';
import Tag from '../domain/Tag';
import { isBefore, startOfMonth } from 'date-fns';
import ListFilters, { ListFilter, ListFiltersProps } from './ListFilters';
import { t } from '../i18n';

export interface OrganizationPeopleFiltersProps {
  persons: Person[];
  projects: Project[];
  positions: Tag[];
  finance?: boolean;
  date: Date;
  simple?: boolean;
  children?: ListFiltersProps<Person>['children'];
}

export enum IAllocationFilter {
  ALL = '',
  OVER = 'OVER',
  UNDER = 'UNDER',
}

const OrganizationPeopleFilters: React.FC<OrganizationPeopleFiltersProps> = ({
  projects,
  persons,
  positions,
  children,
  date,
  simple,
}) => {
  const filters: ListFilter<Person>[] = simple
    ? [
        {
          type: 'select',
          name: 'resource',
          label: t('Resource'),
          defaultValue: 'internal',
          options: [
            {
              value: 'all',
              label: t('All'),
            },
            {
              value: 'internal',
              label: t('Internal'),
            },
            {
              value: 'external',
              label: t('External'),
            },
          ],
        },
      ]
    : [
        {
          type: 'select',
          name: 'projectId',
          multiple: true,
          label: t('Project'),
          placeholder: t('All projects'),
          options: new ListTransformer(projects)
            .sortAlphabetically()
            .toArray()
            .filter(
              (p) => !p.dateEnd || isBefore(startOfMonth(date), p.dateEnd),
            )
            .map((p) => {
              return {
                label: p.name,
                value: p.id,
              };
            }),
        },
        {
          type: 'select',
          name: `participation.tagId`,
          multiple: true,
          label: t('Positions'),
          options: new ListTransformer(positions)
            .sortAlphabetically()
            .toArray()
            .map((p) => {
              return {
                label: p.name,
                value: p.id,
              };
            }),
        },
        {
          type: 'select',
          name: 'allocation',
          label: t('Allocation'),
          options: [
            {
              value: '',
              label: t('All'),
            },
            {
              value: '<0.8',
              label: t('Under 80%'),
            },
            {
              value: '>1',
              label: t('Over 100%'),
            },
          ],
        },
      ];
  return (
    <ListFilters clearable items={persons} filters={filters}>
      {children}
    </ListFilters>
  );
};

export default OrganizationPeopleFilters;
