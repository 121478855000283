import React from 'react';
import { Divider as ChakraDivider, HStack } from '@chakra-ui/react';

export interface DividerProps {}

const Divider: React.FC<DividerProps> = ({ children }) => {
  return (
    <HStack width="100%">
      <ChakraDivider />
      {children && (
        <>
          <div>{children}</div>
          <ChakraDivider />
        </>
      )}
    </HStack>
  );
};

Divider.defaultProps = {};

export default Divider;
