import React from 'react';
import {
  Avatar,
  AvatarBadge,
  HStack,
  StackProps,
  Text,
} from '@chakra-ui/react';
import Badge from './Badge';
import { useTranslation } from 'react-i18next';
import { Tooltip } from './index';
import { AvatarProps } from './Avatar';

export interface UserProps extends StackProps {
  name: string;
  subTitle?: string | React.ReactNode;
  image?: string;
  align?: 'left' | 'right';
  badge?: string;
  isLink?: boolean;
  inactive?: false | string;
  currentUser?: boolean;
  size?: 'md' | 'lg';
  fontSize?: 'md' | 'lg';
  truncated?: boolean;
  avatarOnly?: boolean;
  avatarShadow?: AvatarProps['boxShadow'];
}

const User: React.FC<UserProps> = ({
  isLink,
  name,
  subTitle,
  image,
  align,
  badge,
  inactive,
  currentUser,
  size,
  fontSize,
  truncated,
  avatarOnly,
  avatarShadow,
  ...stackProps
}) => {
  const { t } = useTranslation();
  const avatar = (
    <Avatar
      name={name}
      src={image}
      size={size === 'md' ? 'sm' : 'md'}
      {...(image ? { bg: 'white' } : {})}
      boxShadow={avatarShadow}
    >
      {badge && <AvatarBadge boxSize="1.25em" bg={badge} />}
    </Avatar>
  );

  const nameNode = (
    <Text as="div" align={align} isTruncated={truncated}>
      <Text as="div" fontSize={size === 'md' ? 'sm' : 'md'}>
        {name}

        {inactive && (
          <Badge ml={1} colorScheme="gray">
            {inactive}
          </Badge>
        )}

        {currentUser && (
          <Badge ml={1} colorScheme="yellow">
            {t('You')}
          </Badge>
        )}
      </Text>
      <Text as="div" fontSize="xs" color="gray.400" isTruncated={truncated}>
        {subTitle}
      </Text>
    </Text>
  );

  if (avatarOnly) {
    return <Tooltip label={nameNode}>{avatar}</Tooltip>;
  }

  return (
    <HStack spacing={2} {...stackProps}>
      {align === 'left' && avatar}
      {nameNode}
      {align === 'right' && avatar}
    </HStack>
  );
};

User.defaultProps = {
  align: 'left',
  size: 'md',
  fontSize: 'md',
};

export default User;
