import React from 'react';
import useResolver from '../../hooks/useResolver';
import Card from '../../ui/Card';
import RouterTabs from '../../components/RouterTabs';
import Tab from '../../ui/Tab';
import { observer } from 'mobx-react';
import OrganizationStore from '../../store/OrganizationStore';
import ConnectedOrganizationBilling from '../../containers/ConnectedOrganizationBilling';
import ResponsiveGrid from '../../ui/ResponsiveGrid';
import OrganizationCard from '../../components/OrganizationCard';
import PersonStore from '../../store/PersonStore';
import ConnectedOrganizationPersonList from '../../containers/ConnectedOrganizationPersonList';
import ConnectedOrganizationTags from '../../containers/ConnectedOrganizationTags';
import { DynamicFieldsManager } from '../../features/dynamic-field';
import { t } from '../../i18n';

const OrganizationProfile: React.FC = () => {
  const organizationStore = useResolver(OrganizationStore);
  const personStore = useResolver(PersonStore);
  const { currentOrganization } = organizationStore;

  if (!currentOrganization) {
    return null;
  }

  return (
    <ResponsiveGrid templateColumns="1fr 310px" alignItems="start" spacing={4}>
      <Card>
        <RouterTabs>
          <Tab title={t('Roles & skills')}>
            <ConnectedOrganizationTags />
          </Tab>

          <Tab title={t('Users & access')}>
            <ConnectedOrganizationPersonList />
          </Tab>

          <Tab title={t('Dynamic fields')}>
            <DynamicFieldsManager
              organizationId={currentOrganization.id}
              editable
            />
          </Tab>

          <Tab title={t('Billing')}>
            <ConnectedOrganizationBilling />
          </Tab>
        </RouterTabs>
      </Card>
      <OrganizationCard
        organization={currentOrganization}
        editable={personStore.currentPerson?.hasEditPermission()}
      />
    </ResponsiveGrid>
  );
};

export default observer(OrganizationProfile);
