import React from 'react';
import DynamicField from '../domain/DynamicField';
import { DELETE_FORM_QUERY_PARAM_NAME } from '../constants';
import DeleteDrawer from '../../../components/DeleteDrawer';

export interface DynamicFieldDeleteFormProps {
  dynamicField: DynamicField;
  onSubmit: () => void | Promise<void>;
}
const DynamicFieldDeleteForm: React.VFC<DynamicFieldDeleteFormProps> = ({
  dynamicField,
  onSubmit,
}) => {
  return (
    <DeleteDrawer
      name={DELETE_FORM_QUERY_PARAM_NAME}
      onSubmit={onSubmit}
      confirmationText={dynamicField.name}
      header={`Delete ${dynamicField?.name}?`}
    />
  );
};

export default DynamicFieldDeleteForm;
