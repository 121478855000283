import React from 'react';
import Person from '../domain/Person';
import Participation from '../domain/Participation';
import useMonthPicker from '../hooks/useMonthPicker';
import HStack from '../ui/HStack';
import RouterMonthPicker from './RouterMonthPicker';
import Box from '../ui/Box';
import ResponsiveGrid from '../ui/ResponsiveGrid';
import BudgetRowCalculatedValues from './BudgetRowCalculatedValues';
import Stack from '../ui/Stack';
import BudgetRow from './BudgetRow';
import AutoGrid from '../ui/AutoGrid';
import Accordion from '../ui/Accordion';
import AccordionItem from '../ui/AccordionItem';
import { calculateTotals } from '../util/budget';
import { FinanceResult } from '../services/FinanceService';
import Text from '../ui/Text';
import ProjectLink from './ProjectLink';
import { formatDate } from '../util/date';
import { t } from '../i18n';
import ConnectedPersonUser from '../containers/ConnectedPersonUser';
import ConnectedProjectCashflowValue from '../containers/ConnectedProjectCashflowValue';
import { endOfMonth, startOfMonth } from 'date-fns';
import CapacityMultiCircle from './CapacityMultiCircle';
import { calculateHoursFromCapacity } from '../util/capacity';
import { getAllocationHours, getUtilizationHours } from '../util/timesheet';
import IconButton from '../ui/IconButton';
import { DeleteIcon } from '../ui';
import ConnectedPriceDeleteDrawer from '../containers/ConnectedPriceDeleteDrawer';

export interface PersonsBudgetProps {
  persons: Person[];
  finances: FinanceResult[];
  timesheet: Array<{
    participations: Participation[];
    person?: Person;
    entries: Array<{
      date: string;
      loggedTimeInSeconds: number;
      predictedTimeInSeconds: number;
      timeInSeconds: number;
      workday?: boolean;
    }>;
  }>;
  onChange: () => void;
}

const PersonsBudget: React.FC<PersonsBudgetProps> = ({
  persons,
  finances,
  timesheet,
  onChange,
}) => {
  const month = useMonthPicker();
  const additionalFinances = finances.filter((f) => !f.person);
  const mergedPersons = [...persons];

  // Add additional persons (like customer persons)
  finances.forEach((f) => {
    if (f.person && !mergedPersons.find((p) => p.id === f.person.id)) {
      mergedPersons.push(f.person);
    }
  });

  return (
    <div>
      <ConnectedPriceDeleteDrawer name={'price.delete'} onChange={onChange} />
      <HStack justifyContent="center">
        <RouterMonthPicker />
      </HStack>
      <Box py={12}>
        <ResponsiveGrid columns={4} textAlign="center" rowGap={8}>
          <ConnectedProjectCashflowValue
            dateStart={startOfMonth(month)}
            dateEnd={endOfMonth(month)}
            centered
          />
          <BudgetRowCalculatedValues centered {...calculateTotals(finances)} />
        </ResponsiveGrid>
      </Box>
      <Stack spacing={8}>
        <Accordion>
          {mergedPersons.map((person) => {
            const personFinances = finances.filter(
              (f) => f.person?.id === person.id,
            );

            const personTimesheets = timesheet.filter(
              (t) => t.person?.id === person.id,
            );

            const utilizationHours = getUtilizationHours(personTimesheets);
            const allocationHours = getAllocationHours(personTimesheets);

            const capacityHours = calculateHoursFromCapacity(
              person.capacity,
              month,
            );

            return (
              <AccordionItem
                title={
                  <ResponsiveGrid
                    templateColumns="200px 35px 100px 1fr"
                    alignItems="center"
                    spacing={4}
                    py={4}
                    px={4}
                  >
                    <ConnectedPersonUser personId={person.id} linkToProfile />
                    <div>
                      <CapacityMultiCircle
                        personCapacity={person.capacity}
                        personAllocation={allocationHours / capacityHours}
                        personUtilization={utilizationHours / capacityHours}
                      />
                    </div>

                    <div />

                    <AutoGrid childWidth="150px" rowGap={2}>
                      <BudgetRowCalculatedValues
                        {...calculateTotals(personFinances)}
                      />
                    </AutoGrid>
                  </ResponsiveGrid>
                }
              >
                <Stack spacing={2}>
                  {personFinances.map((result, i) => {
                    const filteredSubParticipation = timesheet.filter(
                      (t) =>
                        t.person?.id === person?.id &&
                        t.participations[0]?.id === result?.participation?.id,
                    );

                    const subUtilizationHours = getUtilizationHours(
                      filteredSubParticipation,
                    );

                    const subaAllocationHours = getAllocationHours(
                      filteredSubParticipation,
                    );

                    if (!result.participation) {
                      return (
                        <ResponsiveGrid
                          key={i}
                          templateColumns="220px 50px 1fr"
                          alignItems="center"
                          spacing={4}
                        >
                          <div>{result.description}</div>
                          <div />
                          <AutoGrid childWidth="150px" rowGap={2}>
                            <BudgetRowCalculatedValues
                              revenuePrognosis={result.revenuePrognosis}
                              costPrognosis={result.costPrognosis}
                            />
                          </AutoGrid>
                        </ResponsiveGrid>
                      );
                    }

                    return (
                      <BudgetRow
                        key={i}
                        hidePersonCost
                        hidePositionPrice
                        variant="project"
                        capacity={result.participation?.capacity}
                        costPrognosis={result.costPrognosis}
                        revenuePrognosis={result.revenuePrognosis}
                        marginPrognosis={result.marginPrognosis}
                        person={person}
                        personCost={null}
                        position={result.projectTag}
                        positionPrice={null}
                        showMultiRings
                        multRingSize={'s'}
                        allocation={subaAllocationHours / capacityHours}
                        utilization={subUtilizationHours / capacityHours}
                      />
                    );
                  })}
                  {personFinances.length <= 0 && (
                    <Box
                      display="inline-block"
                      borderRadius="md"
                      p={2}
                      bg="gray.50"
                      fontSize="xs"
                    >
                      {t('No participations at specified time')}
                    </Box>
                  )}
                </Stack>
              </AccordionItem>
            );
          })}
          <AccordionItem
            title={
              <ResponsiveGrid
                templateColumns="220px 130px 1fr"
                alignItems="center"
                spacing={4}
                py={4}
                px={4}
              >
                <div>{t('Additional costs/revenues')}</div>
                <div />

                <AutoGrid childWidth="150px" rowGap={2}>
                  <BudgetRowCalculatedValues
                    {...calculateTotals(additionalFinances)}
                  />
                </AutoGrid>
              </ResponsiveGrid>
            }
          >
            <Stack spacing={2}>
              {additionalFinances.map((result, i) => (
                <ResponsiveGrid
                  key={i}
                  templateColumns="220px 50px 1fr 50px"
                  alignItems="center"
                  spacing={4}
                >
                  <div>
                    <div>{result.description}</div>
                    <Text fontSize="xs">
                      {`${result.dateStart && formatDate(result.dateStart)} ${
                        result.dateEnd ? ` - ${formatDate(result.dateEnd)}` : ''
                      }`}
                      {result.project && (
                        <span>
                          {' in '}
                          <strong>
                            <ProjectLink project={result.project} />
                          </strong>
                        </span>
                      )}
                    </Text>
                  </div>
                  <div />
                  <AutoGrid childWidth="150px" rowGap={2}>
                    <BudgetRowCalculatedValues
                      revenuePrognosis={result.revenuePrognosis}
                      costPrognosis={result.costPrognosis}
                    />
                  </AutoGrid>
                  {result.priceId && (
                    <IconButton
                      icon={<DeleteIcon />}
                      variant="ghost"
                      tooltip
                      aria-label={`Delete`}
                      to={`?price.delete=${result.priceId}`}
                    />
                  )}
                </ResponsiveGrid>
              ))}
            </Stack>
          </AccordionItem>
        </Accordion>
      </Stack>
    </div>
  );
};

export default PersonsBudget;
