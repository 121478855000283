import { useState } from 'react';
import {
  DataGridAppliedFilter,
  DataGridField,
  DataGridFilter,
} from './DataGrid.types';
import {
  applyFilters,
  mergeFilterValues,
  processAccessor,
} from './DataGrid.utils';

export function useFilters<Item>(availableFilters: DataGridFilter<Item>[]) {
  const [filterValues, setFilterValues] = useState<
    Pick<DataGridAppliedFilter<Item>, 'key' | 'value'>[]
  >([]);
  const appliedFilters = mergeFilterValues(availableFilters, filterValues);

  return {
    appliedFilters,
    filterValues,
    setFilterValues,
    applyFilters: (data: Item[]) => applyFilters(appliedFilters, data),
    addFilterValue: (key: string, value: unknown) =>
      setFilterValues((prev) => [...prev, { key, value }]),
    removeFilterValue: (key: string, value: unknown) =>
      setFilterValues((prev) =>
        prev.filter((filter) => filter.key !== key || filter.value !== value),
      ),
  };
}

export function useSearch<Item>(fields: DataGridField<Item>[]) {
  const [searchTerm, setSearchTerm] = useState('');
  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  return {
    searchTerm,
    setSearchTerm,
    applySearch: (data: Item[]) => {
      if (!searchTerm) {
        return data;
      }

      return data.filter((item, index) =>
        fields.some((field) => {
          const value = processAccessor(field.accessor(item, index)).value;

          return String(value).toLowerCase().includes(lowerCaseSearchTerm);
        }),
      );
    },
  };
}
