import React from 'react';
import SimpleGrid, { SimpleGridProps } from './SimpleGrid';
import useDevice from '../hooks/useDevice';

export interface ResponsiveGridProps extends SimpleGridProps {
  reverseWhenCollapsed?: boolean;
}

const ResponsiveGrid: React.FC<ResponsiveGridProps> = ({
  reverseWhenCollapsed,
  ...props
}) => {
  const device = useDevice();

  if (device.isMobile) {
    props.templateColumns = 'minmax(0, 100vw)';
    props.columns = 1;

    if (reverseWhenCollapsed) {
      props.children = React.Children.toArray(props.children).reverse();
    }
  }

  return (
    <SimpleGrid
      columns={device.isMobile ? 1 : React.Children.count(props.children)}
      {...props}
    />
  );
};

ResponsiveGrid.defaultProps = {};

export default ResponsiveGrid;
