import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import TagStore from '../store/TagStore';
import PersonStore from '../store/PersonStore';
import OrganizationStore from '../store/OrganizationStore';
import OrganizationRelationStore from '../store/OrganizationRelationStore';
import ManagedOrganizationOverview from '../components/ManagedOrganizationOverview';

export interface ConnectedManagedOrganizationOverviewProps {
  organizationId: string;
}

const ConnectedManagedOrganizationOverview: React.FC<
  ConnectedManagedOrganizationOverviewProps
> = ({ organizationId }) => {
  const organizationStore = useResolver(OrganizationStore);
  const organizationRelationStore = useResolver(OrganizationRelationStore);
  const tagStore = useResolver(TagStore);
  const personStore = useResolver(PersonStore);
  const organization = organizationStore.getById(organizationId);
  const organizationRelations =
    organizationRelationStore.getCurrentOrganizationRelationsToOrganization(
      organizationId,
    );
  const currentPerson = personStore.currentPerson;

  useEffect(() => {
    organizationStore.loadOne(organizationId);
    organizationRelationStore.loadByCurrentOrganization(); // TODO: load by relatedOrganizationId
    tagStore.loadByCurrentOrganization();
  }, [organizationId]);

  const editable = currentPerson?.hasEditPermission();

  return (
    <ManagedOrganizationOverview
      organization={organization}
      organizationRelations={organizationRelations}
      editable={editable}
      currentPerson={currentPerson}
    />
  );
};

export default observer(ConnectedManagedOrganizationOverview);
