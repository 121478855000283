import React from 'react';
import { FiBell } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface AlertIconProps extends IconProps {}

const AlertIcon: React.FC<AlertIconProps> = ({ ...props }) => {
  return <Icon as={FiBell} {...props} />;
};

export default AlertIcon;
