import React from 'react';
import { observer } from 'mobx-react';
import Stack from '../ui/Stack';
import Heading from '../ui/Heading';
import DatePeriod from '../ui/DatePeriod';
import { endOfMonth, startOfMonth } from 'date-fns';
import Project from '../domain/Project';
import Box from '../ui/Box';
import Person from '../domain/Person';
import { t } from '../i18n';

export interface ProjectTimesheetDocumentProps {
  month: Date;
  projectOrPerson?: Project | Person;
  title?: string;
}

const ProjectTimesheetDocument: React.FC<ProjectTimesheetDocumentProps> = ({
  month,
  projectOrPerson,
  children,
  title,
}) => {
  const dateStart = startOfMonth(month);
  const dateEnd = endOfMonth(month);

  return (
    <Stack>
      <Heading>{title || projectOrPerson?.name}</Heading>
      <Heading size="md">{t('Monthly timesheet')}</Heading>
      <DatePeriod dateStart={dateStart} dateEnd={dateEnd} />
      <Box mt={4}>{children}</Box>
    </Stack>
  );
};

export default observer(ProjectTimesheetDocument);
