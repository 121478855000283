import Tag from '../domain/Tag';
import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';

@injectable()
export default class TagService extends AbstractRestfulHttpService<Tag> {
  public constructor(apiService: ApiService) {
    super(Tag, apiService);
  }

  public async getAllByOrganization(organizationId: string): Promise<Tag[]> {
    return this.getMany({ organizationId });
  }
}
