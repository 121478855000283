import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import AsyncSelect from 'react-select/async';
import Box from '../ui/Box';
import OrganizationStore from '../store/OrganizationStore';
import SearchService from '../services/SearchService';
import Search, { SearchResource } from '../domain/Search';
import { Avatar, Text } from '@chakra-ui/react';
import VStack from '../ui/VStack';
import { components } from 'react-select';
import SearchIcon from '../ui/icons/SearchIcon';
import HStack from '../ui/HStack';
import {
  getLinkFromSearchResource,
  humanReadableSearchResource,
} from '../util/search';
import { useHistory } from 'react-router-dom';
import { t } from '../i18n';

export interface ConnectedSearchProps {}

const searchToOption = (search: Search): AsyncSelectOption => {
  const avatar = (
    <Avatar name={search.title} src={search.resource.image} size={'sm'} />
  );
  return {
    value: search.resource,
    label: (
      <HStack justifyContent="space-between" cursor="pointer">
        <VStack justifyContent="flex-start">
          <HStack marginRight="auto">
            {avatar}
            <Text as="div" fontSize={'md'}>
              {search.title}
            </Text>
          </HStack>
          <Text
            as="div"
            fontSize="xs"
            color="gray.400"
            noOfLines={2}
            marginRight="auto"
            width="100%"
          >
            {search.description}
          </Text>
        </VStack>
        <Text as="div" fontSize="s" color="gray.400">
          {humanReadableSearchResource(search.resource.name)}
        </Text>
      </HStack>
    ),
  };
};

export interface AsyncSelectOption {
  value: SearchResource;
  label: React.ReactElement;
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const ConnectedSearch: React.FC<ConnectedSearchProps> = () => {
  const history = useHistory();
  const organizationStore = useResolver(OrganizationStore);
  const { currentOrganization } = organizationStore;
  const searchService = useResolver(SearchService);

  const filterOptions = (inputValue: Search[]): AsyncSelectOption[] => {
    return inputValue.map((e) => searchToOption(e));
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<AsyncSelectOption[]>(async (resolve) => {
      resolve(
        filterOptions(
          await searchService.searchByOrganizationAndTerm(
            currentOrganization.id,
            inputValue,
          ),
        ),
      );
    });

  return (
    <Box width="264px">
      <AsyncSelect
        loadOptions={promiseOptions}
        components={{ DropdownIndicator }}
        minMenuHeight={50}
        value={null}
        placeholder={t('Search...')}
        noOptionsMessage={() => null}
        onChange={({ value: resource }: { value: SearchResource }) => {
          history.push(getLinkFromSearchResource(resource));
        }}
      />
    </Box>
  );
};

export default observer(ConnectedSearch);
