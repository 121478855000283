import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import { endOfMonth, startOfMonth } from 'date-fns';
import ProjectStore from '../store/ProjectStore';
import ProjectTagStore from '../store/ProjectTagStore';
import TimesheetService from '../services/TimesheetService';
import useMonthPicker from '../hooks/useMonthPicker';
import useSWR from 'swr';
import ParticipationTimeSheet, {
  ParticipationTimeSheetProps,
} from '../components/ParticipationTimeSheet';
import SkeletonLoader from '../ui/SkeletonLoader';
import Alert from '../ui/Alert';
import Participation from '../domain/Participation';
import { formatDate } from '../util/date';
import TaskStore from '../store/TaskStore';

export interface ConnectedProjectTimesheetProps
  extends Omit<ParticipationTimeSheetProps, 'title' | 'data' | 'variant'> {
  projectId: string;
  editable?: boolean;
}

const ConnectedProjectTimesheet: React.FC<ConnectedProjectTimesheetProps> = ({
  projectId,
  editable,
  ...props
}) => {
  const projectStore = useResolver(ProjectStore);
  const taskStore = useResolver(TaskStore);
  const projectTagStore = useResolver(ProjectTagStore);

  const project = projectStore.getByIdOrLoad(projectId);

  const timesheetService = useResolver(TimesheetService);
  const month = useMonthPicker();

  useEffect(() => {
    taskStore.loadByProject(projectId);
  }, []);

  const { data, mutate, error } = useSWR(
    project
      ? `ConnectedProjectTimesheet:${projectId}|${formatDate(month)}`
      : null,
    () =>
      timesheetService.getDaily({
        organizationId: project.organizationId,
        projectId,
        dateStart: startOfMonth(month),
        dateEnd: endOfMonth(month),
      }),
  );

  if (error) {
    return <Alert status="error">{String(error)}</Alert>;
  }

  if (!project || !data) {
    return <SkeletonLoader />;
  }
  return (
    <ParticipationTimeSheet
      title={project.name}
      data={data.map(({ participation, entries, taskEntries }) => ({
        entries,
        taskEntries,
        participations: [new Participation().fromObject(participation)],
      }))}
      variant="worklog"
      positionsCount={projectTagStore.getProjectPositionsCount(projectId)}
      onCreateWorklog={() => {
        mutate();
      }}
      editable={editable}
      {...props}
    />
  );
};

export default observer(ConnectedProjectTimesheet);
