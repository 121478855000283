import { ReactNode } from 'react';
import Box from '../Box';

export interface TimelineItemProps {
  openStart?: boolean;
  openEnd?: boolean;
  children?: ReactNode;
}

export default function TimelineItem({
  children,
  openStart,
  openEnd,
}: TimelineItemProps) {
  return (
    <Box
      as="div"
      width="100%"
      bg="primary"
      color="white"
      fontSize="sm"
      height="32px"
      display="flex"
      alignItems="center"
      borderLeftRadius={openStart ? 'none' : '3xl'}
      borderRightRadius={openEnd ? 'none' : '3xl'}
      overflow="hidden"
      zIndex={3}
      position="relative"
      whiteSpace="nowrap"
    >
      {children}
    </Box>
  );
}
