import React from 'react';
import AllProjects from './views/AllProjects';
import OrganizationProfile from './views/OrganizationProfile';
import MyProfile from './views/MyProfile';
import AllTrainings from './views/AllTrainings';
import AllRequests from './views/AllRequests';
import { Role } from './domain/Person';
import MyProjects from './views/MyProjects';
import MyTrainings from './views/MyTrainings';
import MyRequests from './views/MyRequests';
import PersonProfile from './views/PersonProfile';
import SettingsIcon from './ui/icons/SettingsIcon';
import HashtagIcon from './ui/icons/HashtagIcon';
import ClipboardIcon from './ui/icons/CliboardIcon';
import AwardIcon from './ui/icons/AwardIcon';
import FlagIcon from './ui/icons/FlagIcon';
import UserIcon from './ui/icons/UserIcon';
import PlaneIcon from './ui/icons/PlaneIcon';
import OrganizationSuspended from './views/OrganizationSuspended';
import ProjectView from './views/ProjectView';
import Welcome from './views/Welcome';
import BookIcon from './ui/icons/BookIcon';
import OrganizationUnsubscribed from './views/OrganizationUnsubscribed';
import OrganizationPeople from './views/OrganizationPeople';
import UsersIcon from './ui/icons/UsersIcon';
import AllEvents from './views/AllEvents';
import MusicIcon from './ui/icons/MusicIcon';
import RelatedOrganizations from './views/RelatedOrganizations';
import LinkIcon from './ui/icons/LinkIcon';
import ManagedOrganizationView from './views/ManagedOrganizationView';
import MyEvents from './views/MyEvents';
import ActivityIcon from './ui/icons/ActivityIcon';
import { t } from './i18n';
import PreferenceIcon from './ui/icons/PreferenceIcon';
import MyTasks from './views/MyTasks';
import TaskIcon from './ui/icons/TaskIcon';
import MyPreferences from './views/MyPreferences';

export enum Placement {
  USER_MENU = 'User menu',
  PERSONAL = 'Personal',
  MARKETPLACE = 'Marketplace',
  ORGANIZATION = 'Organization',
}

export enum Layout {
  AUTHENTICATED,
  PLAIN,
}

export interface Route {
  path: string;
  title: string;
  layout: Layout;
  placements?: Placement[];
  icon?: React.ReactNode;
  component?: React.FC;
  scope?: Role[];
  hardLink?: boolean;
  disabled?: boolean;
}

const routes: Route[] = [
  {
    path: '/personal/projects',
    title: t('My projects'),
    component: MyProjects,
    icon: <ClipboardIcon />,
    placements: [Placement.PERSONAL],
    layout: Layout.AUTHENTICATED,
  },
  {
    path: '/personal/events',
    title: t('My events'),
    component: MyEvents,
    icon: <ActivityIcon />,
    placements: [Placement.PERSONAL],
    layout: Layout.AUTHENTICATED,
  },
  {
    path: '/personal/learnings',
    title: t('My learnings'),
    component: MyTrainings,
    icon: <AwardIcon />,
    placements: [Placement.PERSONAL],
    layout: Layout.AUTHENTICATED,
  },
  {
    path: '/personal/tasks',
    title: t('My tasks'),
    component: MyTasks,
    icon: <TaskIcon />,
    placements: [Placement.PERSONAL],
    layout: Layout.AUTHENTICATED,
  },
  {
    path: '/personal/requests',
    title: t('My requests'),
    component: MyRequests,
    icon: <FlagIcon />,
    placements: [Placement.PERSONAL],
    layout: Layout.AUTHENTICATED,
  },
  {
    path: '/profile/me',
    title: t('My profile'),
    component: MyProfile,
    icon: <UserIcon />,
    placements: [Placement.PERSONAL],
    layout: Layout.AUTHENTICATED,
  },
  {
    path: '/profile/view/:id',
    title: t('User profile'),
    component: PersonProfile,
    layout: Layout.AUTHENTICATED,
    scope: [Role.FINANCIAL_MANAGER, Role.OPERATIONAL_MANAGER, Role.ADMIN],
  },
  {
    path: '/clients/view/:id',
    title: t('View client'),
    component: ManagedOrganizationView,
    layout: Layout.AUTHENTICATED,
    scope: [Role.FINANCIAL_MANAGER, Role.OPERATIONAL_MANAGER, Role.ADMIN],
  },
  {
    path: '/projects/view/:id',
    title: t('View project'),
    component: ProjectView,
    layout: Layout.AUTHENTICATED,
  },
  {
    path: '/organization/clients',
    title: t('Clients'),
    component: RelatedOrganizations,
    icon: <LinkIcon />,
    placements: [Placement.ORGANIZATION],
    layout: Layout.AUTHENTICATED,
    scope: [Role.FINANCIAL_MANAGER, Role.OPERATIONAL_MANAGER, Role.ADMIN],
  },
  {
    path: '/organization/projects',
    title: t('Projects'),
    component: AllProjects,
    icon: <PlaneIcon />,
    placements: [Placement.ORGANIZATION],
    layout: Layout.AUTHENTICATED,
  },
  {
    path: '/organization/events',
    title: t('Events'),
    component: AllEvents,
    icon: <MusicIcon />,
    placements: [Placement.ORGANIZATION],
    layout: Layout.AUTHENTICATED,
  },
  {
    path: '/organization/learnings',
    title: t('Learnings'),
    component: AllTrainings,
    icon: <BookIcon />,
    placements: [Placement.ORGANIZATION],
    layout: Layout.AUTHENTICATED,
  },
  {
    path: '/organization/team',
    title: t('Organization'),
    component: OrganizationPeople,
    icon: <UsersIcon />,
    placements: [Placement.ORGANIZATION],
    scope: [Role.FINANCIAL_MANAGER, Role.ADMIN],
    layout: Layout.AUTHENTICATED,
  },
  {
    path: '/organization/requests',
    title: t('Requests'),
    component: AllRequests,
    icon: <HashtagIcon />,
    placements: [Placement.ORGANIZATION],
    scope: [Role.FINANCIAL_MANAGER, Role.OPERATIONAL_MANAGER, Role.ADMIN],
    layout: Layout.AUTHENTICATED,
  },
  {
    path: '/profile/preferences',
    title: t('Preferences'),
    component: MyPreferences,
    icon: <PreferenceIcon />,
    placements: [Placement.USER_MENU],
    layout: Layout.AUTHENTICATED,
  },
  {
    path: '/organization/profile',
    title: t('Settings'),
    component: OrganizationProfile,
    icon: <SettingsIcon />,
    placements: [Placement.ORGANIZATION],
    layout: Layout.AUTHENTICATED,
    scope: [Role.FINANCIAL_MANAGER, Role.OPERATIONAL_MANAGER, Role.ADMIN],
  },
  {
    path: '/marketplace',
    title: t('Coming soon'),
    component: null,
    placements: [Placement.MARKETPLACE],
    layout: Layout.AUTHENTICATED,
    disabled: true,
  },
  {
    path: '/welcome',
    title: t('Welcome'),
    component: Welcome,
    placements: [],
    layout: Layout.AUTHENTICATED,
  },
  {
    path: '/create-organization',
    title: t('Create organization'),
    placements: [Placement.USER_MENU],
    layout: Layout.AUTHENTICATED,
  },
  {
    path: '/logout',
    title: t('Sign out'),
    placements: [Placement.USER_MENU],
    layout: Layout.AUTHENTICATED,
  },
  {
    path: '/organization-suspended',
    title: t('Organization suspended'),
    layout: Layout.AUTHENTICATED,
    component: OrganizationSuspended,
  },
  {
    path: '/subscription-expired',
    title: t('Subscription expired'),
    layout: Layout.AUTHENTICATED,
    component: OrganizationUnsubscribed,
  },
];
export default routes;
