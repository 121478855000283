import { Resources } from './resources';
import { t } from '../../i18n';
import { DynamicFieldType } from './domain/DynamicField';
import { ComponentType } from 'react';
import ClipboardIcon from '../../ui/icons/CliboardIcon';
import { ActivityIcon, AwardIcon, LinkIcon, UserIcon } from '../../ui';

export const EDIT_FORM_QUERY_PARAM_NAME = 'dynamic-field';
export const DELETE_FORM_QUERY_PARAM_NAME = 'dynamic-field.delete';

export const TAG_LABELS: Record<Resources, string> = {
  [Resources.PROJECTS]: t('Projects'),
  [Resources.EVENTS]: t('Events'),
  [Resources.TRAININGS]: t('Learnings'),
  [Resources.ORGANIZATION_RELATIONS]: t('Customers'),
  [Resources.PERSONS]: t('People'),
};

export const TAG_ICONS: Record<Resources, ComponentType> = {
  [Resources.PROJECTS]: ClipboardIcon,
  [Resources.EVENTS]: ActivityIcon,
  [Resources.TRAININGS]: AwardIcon,
  [Resources.ORGANIZATION_RELATIONS]: LinkIcon,
  [Resources.PERSONS]: UserIcon,
};

export const TYPE_LABELS: Record<DynamicFieldType, string> = {
  [DynamicFieldType.TEXT]: t('Text'),
  [DynamicFieldType.NUMBER]: t('Number'),
  [DynamicFieldType.SELECT]: t('Single option'),
  [DynamicFieldType.MULTISELECT]: t('Multiple options'),
};
