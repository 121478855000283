import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { FaFacebook } from 'react-icons/fa';
import Tooltip from './Tooltip';

export interface FacebookButtonProps {
  onClick: () => void;
}

const FacebookButton: React.FC<FacebookButtonProps> = ({ onClick }) => {
  return (
    <Tooltip label="Sign in with Facebook">
      <IconButton
        colorScheme="facebook"
        icon={<FaFacebook />}
        onClick={onClick}
        aria-label="Facebook"
      />
    </Tooltip>
  );
};

FacebookButton.defaultProps = {};

export default FacebookButton;
