import React from 'react';
import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
} from '@chakra-ui/react';

export interface CheckboxProps
  extends Omit<ChakraCheckboxProps, 'onChange' | 'value'> {
  onChange?: (newValue: boolean) => void;
  value?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({ onChange, value, ...props }) => {
  return (
    <ChakraCheckbox
      colorScheme="brand"
      isChecked={!!value}
      onChange={(e) => onChange?.(e.target.checked)}
      {...props}
    />
  );
};

Checkbox.defaultProps = {};

export default Checkbox;
