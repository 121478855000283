import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ProjectTag from '../domain/ProjectTag';
import { TagType } from '../domain/Tag';
import ProjectPositionsField from '../components/ProjectPositionsField';
import TagStore from '../store/TagStore';
import ProjectTagStore from '../store/ProjectTagStore';

export interface ConnectedProjectPositionsFieldProps {
  name?: string;
  projectId?: string;
}

const ConnectedProjectPositionsField: React.FC<ConnectedProjectPositionsFieldProps> =
  ({ projectId, name }) => {
    const tagStore = useResolver(TagStore);
    const projectTagStore = useResolver(ProjectTagStore);
    const { control, setValue } = useFormContext();
    const { fields, append, remove } = useFieldArray<ProjectTag>({
      control,
      name,
    });

    useEffect(() => {
      tagStore.loadByCurrentOrganization();
    }, [tagStore]);

    useEffect(() => {
      if (projectId) {
        projectTagStore.loadByProject(projectId);
      }
    }, [projectTagStore, projectId]);

    const projectPositions: ProjectTag[] = projectId
      ? projectTagStore
          .getItemsByProject(projectId)
          .filter((projectTag) => projectTag.tag?.type === TagType.POSITION)
      : [];

    useEffect(() => {
      if (projectPositions.length > 0) {
        setValue(
          name,
          projectPositions.map(({ tagId, capacity = 1, tag }) => ({
            tagId,
            tag,
            capacity,
          })),
        );
      }
    }, [projectPositions.length]);

    return (
      <ProjectPositionsField
        name={name}
        onAppend={append}
        onRemove={remove}
        availablePositions={tagStore.positions}
        values={fields}
      />
    );
  };

ConnectedProjectPositionsField.defaultProps = {
  name: ProjectPositionsField.defaultProps.name,
};

export default observer(ConnectedProjectPositionsField);
