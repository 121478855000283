import React from 'react';
import { Button, Stack, Text, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import useResolver from '../hooks/useResolver';
import { observer } from 'mobx-react';
import ConnectedAuthenticationGuard from '../containers/ConnectedAuthenticationGuard';
import AccountStore from '../store/AccountStore';
import ConnectedCleanLayout from '../containers/ConnectedCleanLayout';
import { t } from '../i18n';

const NoOrganization: React.FC = () => {
  const accountStore = useResolver(AccountStore);

  return (
    <ConnectedAuthenticationGuard>
      <ConnectedCleanLayout logoSize="lg">
        <VStack mt={4} spacing={4}>
          <Text>
            {t('You are now logged in as')}{' '}
            <strong>{accountStore.authenticatedUser.getDisplayName()}</strong>.
            {t('What would you like to do next?')}
          </Text>
          <Stack spacing={2}>
            <Button as={Link} to="/create-organization" colorScheme="brand">
              {t("I'd like to create an organization")}
            </Button>
            <Button as={Link} to="/logout">
              {t('Let me out of here')}
            </Button>
          </Stack>
        </VStack>
      </ConnectedCleanLayout>
    </ConnectedAuthenticationGuard>
  );
};

export default observer(NoOrganization);
