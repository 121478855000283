import Organization from '../domain/Organization';
import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';

@injectable()
export default class OrganizationService
  extends AbstractRestfulHttpService<Organization>
  implements OrganizationService
{
  public constructor(apiService: ApiService) {
    super(Organization, apiService);
  }
}
