import { action, makeObservable, observable } from 'mobx';
import AuthenticationService from '../services/AuthenticationService';
import User from '../domain/User';
import { singleton } from 'tsyringe';

export enum SocialLoginProvider {
  GOOGLE,
  GITHUB,
  MICROSOFT,
  FACEBOOK,
}

@singleton()
class AccountStore {
  @observable public authenticatedUser: User | null = null;
  @observable public authenticated: boolean | null = null;

  constructor(private readonly accountService: AuthenticationService) {
    makeObservable(this);
  }

  public async login(email: string, password: string) {
    await this.accountService.login(email, password);
    await this.checkAuthentication();
  }

  public async socialLogin(provider: SocialLoginProvider) {
    switch (provider) {
      case SocialLoginProvider.GITHUB:
        await this.accountService.loginByGithub();
        break;

      case SocialLoginProvider.GOOGLE:
        await this.accountService.loginByGoogle();
        break;

      case SocialLoginProvider.FACEBOOK:
        await this.accountService.loginByFacebook();
        break;

      case SocialLoginProvider.MICROSOFT:
        await this.accountService.loginByMicrosoft();
        break;
    }

    await this.checkAuthentication();
  }

  public async logout() {
    await this.accountService.logout();
    this.setAuthenticatedUser(null);
  }

  public async checkAuthentication() {
    const user = await this.accountService.getAccount();
    this.setAuthenticatedUser(user);
  }

  @action
  private setAuthenticatedUser(authenticatedUser: User) {
    this.authenticatedUser = authenticatedUser;
    this.authenticated = !!authenticatedUser;
  }
}

export default AccountStore;
