import AbstractResourceStore from './AbstractResourceStore';
import Organization from '../domain/Organization';
import { computed } from 'mobx';
import OrganizationService from '../services/OrganizationService';
import PersonStore from './PersonStore';
import AccountStore from './AccountStore';
import { singleton } from 'tsyringe';
import { ResourceId } from '../types';

@singleton()
export default class OrganizationStore extends AbstractResourceStore<Organization> {
  public constructor(
    private readonly organizationService: OrganizationService,
    private readonly personStore: PersonStore,
    accountStore: AccountStore,
  ) {
    super(organizationService, accountStore);
  }

  @computed
  public get currentOrganizationId(): string | null {
    return this.personStore.currentPerson?.organizationId;
  }

  @computed
  public get currentOrganization() {
    const organization =
      this.currentOrganizationId &&
      this.getByIdOrLoad(this.currentOrganizationId);

    if (!organization) {
      return null;
    }

    return organization.deletedAt ? null : organization;
  }

  public async delete(id: ResourceId): Promise<void> {
    if (id === this.currentOrganizationId) {
      this.personStore.currentUserPersons.forEach((person) => {
        this.personStore.remove(person.id);
      });
    }

    await super.delete(id);
  }

  public async loadManagedOrganizations(managedByOrganizationId?: string) {
    const organizationId =
      managedByOrganizationId || this.currentOrganizationId;

    await this.setManyEventually(
      this.organizationService.getMany({
        managedByOrganizationId: organizationId,
      }),
    );
  }

  @computed
  public get managedOrganizations(): Organization[] {
    if (!this.currentOrganizationId) {
      return [];
    }

    return this.getFilteredItems({
      managedByOrganizationId: this.currentOrganizationId,
    });
  }

  public async createManagedOrganization(
    payload: Pick<Organization, 'name'> & Omit<Partial<Organization>, 'name'>,
  ): Promise<Organization> {
    const { email, ...rest } = payload;
    return this.create(
      new Organization().fromObject({
        managedByOrganizationId: this.currentOrganizationId,
        email: email ? email : null,
        ...rest,
      }),
    );
  }
}
