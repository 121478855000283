import React from 'react';
import {
  Icon as ChakraIcon,
  IconProps as ChakraIconProps,
} from '@chakra-ui/react';

export interface IconProps extends ChakraIconProps {
  as?: any;
  alt?: string;
  src?: string;
}

const Icon: React.FC<IconProps> = ({ ...props }) => {
  return <ChakraIcon {...props} />;
};

Icon.defaultProps = {};

export default Icon;
