import React from 'react';
import Project from '../domain/Project';
import DeleteDrawer from './DeleteDrawer';
import { useTranslation } from 'react-i18next';

interface ProjectDeleteDrawerProps {
  name: string;
  onSubmit: () => void | Promise<void>;
  project: Project;
}

const ProjectDeleteDrawer: React.FC<ProjectDeleteDrawerProps> = ({
  name,
  onSubmit,
  project,
}) => {
  const { t } = useTranslation();

  if (!project?.id) {
    return null;
  }

  return (
    <DeleteDrawer
      name={name}
      onSubmit={onSubmit}
      confirmationText={null}
      header={`${t('Delete')} ${project.name}?`}
    >
      You can no longer access this {project.type.toLocaleLowerCase()}{' '}
      afterwards
    </DeleteDrawer>
  );
};

export default ProjectDeleteDrawer;
