import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import SubscriptionStore from '../store/SubscriptionStore';
import SubscriptionInfo from '../components/SubscriptionInfo';

export interface ConnectedSubscriptionInfoProps {}

const ConnectedSubscriptionInfo: React.FC<
  ConnectedSubscriptionInfoProps
> = () => {
  const subscriptionStore = useResolver(SubscriptionStore);

  useEffect(() => {
    subscriptionStore.loadByCurrentOrganization().catch(() => null);
  }, []);

  return (
    <SubscriptionInfo
      subscription={subscriptionStore.currentOrganizationActiveSubscription}
      loading={subscriptionStore.pending}
    />
  );
};

export default observer(ConnectedSubscriptionInfo);
