import AbstractResourceStore from './AbstractResourceStore';
import Price from '../domain/Price';
import PriceService from '../services/PriceService';
import { singleton } from 'tsyringe';
import AccountStore from './AccountStore';
import OrganizationStore from './OrganizationStore';
import { getActivePrice } from '../util/price';

@singleton()
export default class PriceStore extends AbstractResourceStore<Price> {
  public constructor(
    private readonly priceService: PriceService,
    private readonly organizationStore: OrganizationStore,
    accountStore: AccountStore,
  ) {
    super(priceService, accountStore);
  }

  public async loadByOrganization(organizationId: string) {
    await this.setManyEventually(
      this.priceService.getAllByOrganization(organizationId),
    );
  }

  public async loadByOrganizationAndExternalId(
    organizationId: string,
    externalId: string,
  ) {
    await this.setManyEventually(
      this.priceService.getAllByOrganizationAndExternalId(
        organizationId,
        externalId,
      ),
    );
  }

  public async loadForCurrentOrganizationByExternalId(externalId: string) {
    return this.loadByOrganizationAndExternalId(
      this.organizationStore.currentOrganizationId,
      externalId,
    );
  }

  public async loadForCurrentOrganization() {
    return this.loadByOrganization(
      this.organizationStore.currentOrganizationId,
    );
  }

  public getAscendingByExternalId(externalId: string): Price[] {
    return this.getFilteredItems({ externalId }).sort((a, b) => {
      return +a.createdAt - +b.createdAt;
    });
  }

  public getActiveByExternalId(
    externalId: string,
    date: Date = new Date(),
  ): Price | null {
    const items = this.getFilteredItems({ externalId });

    return getActivePrice(items, date);
  }
}
