import React from 'react';
import Card from '../ui/Card';

export interface PageProps {}

const Page: React.FC<PageProps> = ({ children }) => {
  return <Card>{children}</Card>;
};

export default Page;
