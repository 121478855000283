import { ClassType } from 'jackson-js/dist/@types';
import { parse, stringify } from '../util/json';
import { injectable } from 'tsyringe';

@injectable()
export default class LocalStorageService<T> {
  private static KEY_PREFIX = 'LSS/';

  public setItem(key: string, value: T): void {
    localStorage.setItem(this.getFullKey(key), stringify(value));
  }

  public getItem(key: string, type?: ClassType<T | any>): T {
    const result = localStorage.getItem(this.getFullKey(key));

    if (!result) {
      return null;
    }

    return parse(result, type);
  }

  public removeItem(key: string): void {
    localStorage.removeItem(this.getFullKey(key));
  }

  private getFullKey(key: string): string {
    return LocalStorageService.KEY_PREFIX + key;
  }
}
