import React from 'react';
import RequestPreview from './RequestPreview';
import useHistoricalArray from '../hooks/useHistoricalArray';
import Person from '../domain/Person';
import NoContent from '../ui/NoContent';
import Request from '../domain/Request';
import AutoGrid from '../ui/AutoGrid';
import { t } from '../i18n';

export interface ParticipationRequestsProps {
  requests: Request[];
  viewer?: Person;
  noContentText?: string;
}

const Requests: React.FC<ParticipationRequestsProps> = ({
  requests,
  viewer,
  noContentText,
}) => {
  const requestedItems = useHistoricalArray(requests);

  if (requestedItems.length <= 0) {
    return <NoContent>{noContentText}</NoContent>;
  }

  return (
    <AutoGrid childWidth="400px" spacing={4} alignItems="start">
      {requestedItems.map((request) => (
        <RequestPreview
          key={request.value.id}
          viewer={viewer}
          request={request.value}
        />
      ))}
    </AutoGrid>
  );
};

Requests.defaultProps = {
  noContentText: t('There are no requests at the moment'),
};

export default Requests;
