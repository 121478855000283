import orderBy from 'lodash/orderBy';

export default class ListTransformer<T> {
  private alphabeticalColumn: keyof T | string = 'name';
  private creationColumn: keyof T | string = 'createdAt';
  private output: T[];

  public constructor(private input: T[]) {
    this.output = [...input];
  }

  public sortAlphabetically(ascending = true): ListTransformer<T> {
    return this.sortBy(this.alphabeticalColumn, ascending);
  }

  public sortByCreation(ascending = true): ListTransformer<T> {
    return this.sortBy(this.creationColumn, ascending);
  }

  private sortBy(
    field: keyof T | string,
    ascending = true,
  ): ListTransformer<T> {
    this.output = orderBy(this.output, field, ascending ? 'asc' : 'desc');

    return this;
  }

  public toArray(): T[] {
    return this.output;
  }
}
