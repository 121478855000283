import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import ProjectTagStore from '../store/ProjectTagStore';
import ProjectPositionSelect, {
  ProjectPositionSelectProps,
} from '../components/ProjectPositionSelect';
import ParticipationStore from '../store/ParticipationStore';
import { TagType } from '../domain/Tag';

export interface ConnectedProjectPositionSelectProps
  extends Omit<ProjectPositionSelectProps, 'projectTags' | 'participations'> {
  projectId: string;
}

const ConnectedProjectPositionSelect: React.FC<
  ConnectedProjectPositionSelectProps
> = ({ projectId, ...props }) => {
  const projectTagStore = useResolver(ProjectTagStore);
  const participationStore = useResolver(ParticipationStore);

  useEffect(() => {
    if (projectId) {
      projectTagStore.loadByProject(projectId);
    }
  }, [projectId]);

  return (
    <ProjectPositionSelect
      projectTags={projectTagStore
        .getItemsByProject(projectId)
        .map((projectTag) => {
          if (projectTag.tag?.type !== TagType.POSITION) {
            return null;
          }

          return projectTag;
        })
        .filter(Boolean)}
      participations={participationStore.getItemsByProject(projectId)}
      {...props}
    />
  );
};

export default observer(ConnectedProjectPositionSelect);
