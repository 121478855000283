import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import { TagType } from '../domain/Tag';
import TagStore from '../store/TagStore';
import PersonSkillStore from '../store/PersonSkillStore';
import TagsField, { TagsFieldProps } from '../components/TagsField';
import PersonStore from '../store/PersonStore';

export interface ConnectedPersonTagsFieldProps
  extends Omit<TagsFieldProps, 'availableTags' | 'assignedTags'> {
  personId: string;
  suppliedOrganizationId?: string;
}

const ConnectedPersonTagsField: React.FC<ConnectedPersonTagsFieldProps> = ({
  personId,
  suppliedOrganizationId,
  ...props
}) => {
  const tagStore = useResolver(TagStore);
  const personSkillStore = useResolver(PersonSkillStore);
  const personStore = useResolver(PersonStore);
  const organizationId =
    personStore.getById(personId)?.organizationId || suppliedOrganizationId;

  useEffect(() => {
    if (organizationId) {
      tagStore.loadByOrganization(organizationId);
    }
  }, [tagStore, organizationId]);

  useEffect(() => {
    if (personId) {
      personSkillStore.loadByPerson(personId);
    }
  }, [personSkillStore, personId]);

  const personTagTypes = [
    TagType.POSITION,
    TagType.TECHNOLOGY,
    TagType.LANGUAGE,
  ];

  return (
    <TagsField
      groupByType={personTagTypes}
      availableTags={tagStore
        .getFilteredItems({ organizationId })
        .filter((t) => personTagTypes.includes(t.type))}
      assignedTags={personSkillStore
        .getItemsByPerson(personId)
        .map((pt) => tagStore.getById(pt.tagId))
        .filter(Boolean)}
      {...props}
    />
  );
};

export default observer(ConnectedPersonTagsField);
