import React from 'react';
import Invoice, { InvoiceState } from '../domain/Invoice';
import { formatDate } from '../util/date';
import Badge from '../ui/Badge';
import IconButton from '../ui/IconButton';
import DownloadIcon from '../ui/icons/DownloadIcon';
import { t } from '../i18n';
import { DataGrid } from '../ui';

export interface InvoiceListProps {
  invoices: Invoice[];
}

const InvoiceList: React.FC<InvoiceListProps> = ({ invoices }) => {
  if (invoices.length <= 0) {
    return null;
  }

  return (
    <DataGrid
      title={t('Invoices')}
      data={invoices}
      fields={{
        date: {
          title: t('Date'),
          accessor: (i) => formatDate(i.createdAt),
        },
        invoiceNumber: {
          title: t('Invoice number'),
          accessor: (i) => i.invoiceNumber,
        },
        amount: {
          title: t('Amount'),
          accessor: (i) => `${i.amount} ${i.currency.toLocaleUpperCase()}`,
        },
        status: {
          title: t('Status'),
          accessor: (i) => {
            const colorByState: { [key in InvoiceState]: string } = {
              [InvoiceState.PAID]: 'green',
              [InvoiceState.UNKNOWN]: 'gray',
              [InvoiceState.OPEN]: 'yellow',
            };

            return {
              node: (
                <Badge
                  colorScheme={
                    colorByState[i.state] || colorByState[InvoiceState.UNKNOWN]
                  }
                >
                  {i.state}
                </Badge>
              ),
              value: i.state,
            };
          },
        },
        action: {
          title: '',
          accessor: (i) => ({
            value: i.pdfUrl,
            node: (
              <IconButton
                icon={<DownloadIcon />}
                aria-label={t('Download')}
                onClick={() => {
                  window.open(i.pdfUrl.toString(), '_blank');
                }}
              />
            ),
          }),
        },
      }}
    />
  );
};

export default InvoiceList;
