import { RequestAction } from '../domain/Request';
import {
  CoffeeIcon,
  ClockIcon,
  SwitchIcon,
  AddUserIcon,
  CommunicationIcon,
} from '../ui';
import { t } from '../i18n';

export const requestActionIcons: { [key in RequestAction]: any } = {
  BREAK: CoffeeIcon,
  CHANGE_CAPACITY: ClockIcon,
  CHANGE_PROJECT: SwitchIcon,
  NEED_MORE_PEOPLE: AddUserIcon,
  TALK: CommunicationIcon,
  TASK: CommunicationIcon,
};

export const requestActionTexts: { [key in RequestAction]: string } = {
  CHANGE_PROJECT: t('I want to switch project'),
  TALK: t('I want to talk'),
  BREAK: t('I want a break'),
  NEED_MORE_PEOPLE: t('We need more people'),
  CHANGE_CAPACITY: t('I want to change my capacity'),
  TASK: t('Task'),
};
