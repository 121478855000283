import Worklog from '../domain/Worklog';
import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';
import { toISO } from '../util/date';

@injectable()
export default class WorklogService extends AbstractRestfulHttpService<Worklog> {
  public constructor(apiService: ApiService) {
    super(Worklog, apiService);
  }

  public getAllByPerson(
    personId: string,
    dateStart: Date,
    dateEnd: Date,
  ): Promise<Worklog[]> {
    return this.getMany({ personId, dateStart, dateEnd });
  }

  public getAllByProject(
    projectId: string,
    dateStart: Date,
    dateEnd: Date,
  ): Promise<Worklog[]> {
    return this.getMany({ projectId, dateStart, dateEnd });
  }

  public getAllByProjects(
    projectIds: string[],
    dateStart: Date,
    dateEnd: Date,
  ): Promise<Worklog[]> {
    return this.getMany({
      projectId: projectIds.join(','),
      dateStart,
      dateEnd,
    });
  }

  async getMany(params: any = {}): Promise<Worklog[]> {
    if (params.dateStart) {
      params.dateStart = toISO(params.dateStart);
    }

    if (params.dateEnd) {
      params.dateEnd = toISO(params.dateEnd);
    }

    return super.getMany(params);
  }
}
