import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import OrganizationStore from '../store/OrganizationStore';
import DeleteDrawer from '../components/DeleteDrawer';
import useQueryValue from '../hooks/useQueryValue';
import { t } from '../i18n';

export interface ConnectedManagedOrganizationDeleteDrawerProps {
  name: string;
}

const ConnectedManagedOrganizationDeleteDrawer: React.FC<
  ConnectedManagedOrganizationDeleteDrawerProps
> = ({ name }) => {
  const organizationStore = useResolver(OrganizationStore);
  const organizationId = useQueryValue(name);
  const organization = organizationStore.getByIdOrLoad(organizationId);

  if (!organization) {
    return null;
  }

  const handleSubmit = async () => {
    await organizationStore.delete(organization.id);
  };

  return (
    <DeleteDrawer
      name={name}
      onSubmit={handleSubmit}
      confirmationText={null}
      header={`${t('Delete')} ${organization.name}?`}
    />
  );
};

export default observer(ConnectedManagedOrganizationDeleteDrawer);
