import React from 'react';
import Card from '../ui/Card';
import HStack from '../ui/HStack';
import RouterTabs from './RouterTabs';
import Tab from '../ui/Tab';
import ConnectedProjectParticipants from '../containers/ConnectedProjectParticipants';
import ConnectedProjectParticipateButton from '../containers/ConnectedProjectParticipateButton';
import { observer } from 'mobx-react';
import ConnectedProjectTimesheet from '../containers/ConnectedProjectTimesheet';
import ConnectedProjectHistory from '../containers/ConnectedProjectHistory';
import Stack from '../ui/Stack';
import Project, { ProjectType } from '../domain/Project';
import ResponsiveGrid from '../ui/ResponsiveGrid';
import ProjectCard from './ProjectCard';
import ConnectedProjectRequests from '../containers/ConnectedProjectRequests';
import ConnectedProjectDeleteDrawer from '../containers/ConnectedProjectDeleteDrawer';
import Alert from '../ui/Alert';
import ConnectedProjectBudget from '../containers/ConnectedProjectBudget';
import { getTextsByType } from '../util/project';
import ConnectedProjectAbout from '../containers/ConnectedProjectAbout';
import ConnectedProjectList from '../containers/ConnectedProjectList';
import { useTranslation } from 'react-i18next';
import ConnectedProjectEndDrawer from '../containers/ConnectedProjectEndDrawer';
import ConnectedProjectTasks from '../containers/ConnectedProjectTasks';

interface ProjectOverviewProps {
  project: Project;
  editable?: boolean;
  showBudget?: boolean;
}

const ProjectOverview: React.FC<ProjectOverviewProps> = ({
  project,
  editable,
  showBudget,
}) => {
  const { t } = useTranslation();

  if (!project || project.isDeleted()) {
    return <Alert status="error">Project not found</Alert>;
  }

  return (
    <>
      {editable && (
        <>
          <ConnectedProjectDeleteDrawer
            name={`${project.type.toLowerCase()}.delete`}
          />
        </>
      )}
      {editable && (
        <>
          <ConnectedProjectEndDrawer
            name={`${project.type.toLowerCase()}.end`}
          />
        </>
      )}
      <ResponsiveGrid
        alignItems="start"
        templateColumns="minmax(0, 1fr) 310px"
        spacing={4}
        maxWidth="100%"
      >
        <Stack spacing={4}>
          <RouterTabs
            preserveQuery
            actions={
              <HStack>
                <ConnectedProjectParticipateButton projectId={project.id} />
              </HStack>
            }
          >
            <Tab title={getTextsByType(project.type)?.team}>
              <Card>
                <ConnectedProjectParticipants
                  projectId={project.id}
                  editable={editable && !project.isArchived()}
                />
              </Card>
            </Tab>
            <Tab title={t('Time management')}>
              <Card>
                <ConnectedProjectTimesheet
                  editable
                  exportable
                  monthPicker
                  projectId={project.id}
                />
              </Card>
            </Tab>
            <Tab title={t('Requests')}>
              <ConnectedProjectRequests projectId={project.id} />
            </Tab>
            <Tab title={t('Tasks')}>
              <Card>
                <ConnectedProjectTasks projectId={project.id} />
              </Card>
            </Tab>
            <Tab title={t('History')}>
              <Card>
                <ConnectedProjectHistory projectId={project.id} />
              </Card>
            </Tab>
            {project.type === ProjectType.PROJECT && (
              <>
                <Tab title={t('Events')}>
                  <ConnectedProjectList
                    filters={{
                      type: ProjectType.EVENT,
                      relatedToProjectId: project.id,
                    }}
                  />
                </Tab>
                <Tab title={t('Learnings')}>
                  <ConnectedProjectList
                    filters={{
                      type: ProjectType.TRAINING,
                      relatedToProjectId: project.id,
                    }}
                  />
                </Tab>
              </>
            )}
            {showBudget && (
              <Tab title={t('Finances')}>
                <Card>
                  <ConnectedProjectBudget projectId={project.id} />
                </Card>
              </Tab>
            )}
            <Tab title={t('Overview')}>
              <Card>
                <ConnectedProjectAbout editable={editable} project={project} />
              </Card>
            </Tab>
          </RouterTabs>
        </Stack>
        <ProjectCard
          editable={editable}
          project={project}
          displayOwnerPerson
          displayCapacityChart
          displayRelatedProject={!!project.relatedToProjectId}
          displayDynamicFields
          noLink
        />
      </ResponsiveGrid>
    </>
  );
};

export default observer(ProjectOverview);
