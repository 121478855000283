import React, { useEffect, useRef } from 'react';
import { Editor } from '@toast-ui/react-editor';

import '@toast-ui/editor/dist/toastui-editor.css';

export interface RichTextareaProps {
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  autoFocus?: boolean;
}

const RichTextarea: React.FC<RichTextareaProps> = ({
  value,
  onChange,
  onBlur,
  autoFocus,
}) => {
  const editorRef = useRef(null);

  const getValue = () => editorRef.current?.getInstance?.().getMarkdown();
  const setValue = (value: string) =>
    editorRef.current?.getInstance?.().setMarkdown(value);

  useEffect(() => {
    if (value !== getValue()) {
      setValue(value || '');
    }
  }, [value]);

  return (
    <Editor
      ref={editorRef}
      usageStatistics={false}
      hideModeSwitch
      autofocus={!!autoFocus}
      height="70vh"
      initialEditType="wysiwyg"
      onChange={() => onChange?.(getValue())}
      onBlur={() => onBlur?.(getValue())}
    />
  );
};

export default RichTextarea;
