import React from 'react';
import { FiMessageCircle } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface CommunicationIconProps extends IconProps {}

const CommunicationIcon: React.FC<CommunicationIconProps> = ({ ...props }) => {
  return <Icon as={FiMessageCircle} {...props} />;
};

export default CommunicationIcon;
