import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import ConnectedProjectOverview from '../containers/ConnectedProjectOverview';

const ProjectView: React.FC = () => {
  const match = useRouteMatch<{ id: string }>();
  const projectId = match.params.id;

  return <ConnectedProjectOverview projectId={projectId} />;
};

export default ProjectView;
