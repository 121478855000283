import React from 'react';
import Tag from '../domain/Tag';
import TagWithIcon from './TagWithIcon';
import RichSelect, {
  RichSelectOption,
  RichSelectProps,
} from '../ui/RichSelect';
import { t } from '../i18n';

export interface TagsSelectProps extends Omit<RichSelectProps, 'options'> {
  tags: Tag[];
  onCreate?: (tagName: string) => void;
  forbiddenCreatables?: string[];
  forbiddenCreatableText?: (inputValue: string) => string;
  multiple?: boolean;
}

const TagsSelect: React.FC<TagsSelectProps> = ({
  tags,
  onCreate,
  forbiddenCreatables,
  forbiddenCreatableText,
  value,
  multiple = true,
  ...props
}) => {
  const options = tags.map((tag) => ({
    value: tag.id,
    label: tag.name,
    component: <TagWithIcon name={tag.name} type={tag.type} />,
  }));

  return (
    <RichSelect
      multiple={multiple}
      onCreate={onCreate}
      validateCreate={(inputValue) =>
        forbiddenCreatables?.includes(inputValue) &&
        (forbiddenCreatableText(inputValue) ||
          `"${inputValue}" ${t('is unavailable')})`)
      }
      noOptionsMessage={
        onCreate
          ? () => t('No options. Start typing to create one')
          : () => t('No options')
      }
      options={options}
      value={
        value &&
        (Array.isArray(value)
          ? (value as RichSelectOption[]).map(
              (v) => options.find((o) => o.value === v.value) || v,
            )
          : value)
      }
      {...props}
    />
  );
};

export default TagsSelect;
