import { TagType } from '../../domain/Tag';

const it: { [key in TagType]: string[] } = {
  [TagType.POSITION]: [],
  [TagType.TECHNOLOGY]: [],
  [TagType.LOCATION]: ['On site', 'Remote'],
  [TagType.PROJECT_TYPE]: [],
  [TagType.LANGUAGE]: ['Estonian', 'English'],
  [TagType.CUSTOM]: [],
};

export default it;
