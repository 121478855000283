import React from 'react';
import MonthPicker from '../ui/MonthPicker';
import { formatDate } from '../util/date';
import useMonthPicker from '../hooks/useMonthPicker';

export interface RouterMonthPickerProps {}

const RouterMonthPicker: React.FC<RouterMonthPickerProps> = () => {
  const month = useMonthPicker();

  return (
    <MonthPicker
      value={month}
      linkTo={(m) => `?month=${formatDate(m, 'MM/yyyy')}`}
    />
  );
};

export default RouterMonthPicker;
