import React from 'react';
import ConnectedAuthenticationGuard from '../containers/ConnectedAuthenticationGuard';
import { Redirect } from 'react-router-dom';

const Home: React.FC = () => {
  return (
    <ConnectedAuthenticationGuard>
      <Redirect to="/app" />
    </ConnectedAuthenticationGuard>
  );
};

export default Home;
