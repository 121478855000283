import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ManagedOrganizationFormDrawer, {
  ManagedOrganizationFormData,
} from '../components/ManagedOrganizationFormDrawer';
import useQueryValue from '../hooks/useQueryValue';
import useResolver from '../hooks/useResolver';
import OrganizationStore from '../store/OrganizationStore';
import TagStore from '../store/TagStore';
import OrganizationRelationStore from '../store/OrganizationRelationStore';
import OrganizationRelation from '../domain/OrganizationRelation';
import { toDate } from '../util/date';
import { useHistory } from 'react-router-dom';

export interface ConnectedManagedOrganizationFormDrawerProps {
  name?: string;
}

const ConnectedManagedOrganizationFormDrawer: React.FC<
  ConnectedManagedOrganizationFormDrawerProps
> = ({ name, ...props }) => {
  const history = useHistory();
  const organizationStore = useResolver(OrganizationStore);
  const organizationRelationStore = useResolver(OrganizationRelationStore);
  const organizationId = useQueryValue(name);
  const organization = organizationId
    ? organizationStore.getByIdOrLoad(organizationId)
    : null;
  const tagStore = useResolver(TagStore);

  useEffect(() => {
    tagStore.loadByCurrentOrganization();
    organizationRelationStore.loadByCurrentOrganization();
  }, []);

  if (organizationId && !organization) {
    return null;
  }

  const organizationRelation =
    organizationRelationStore.getCurrentOrganizationRelationsToOrganization(
      organizationId,
    )?.[0];

  const handleSubmit = async (values: ManagedOrganizationFormData) => {
    const targetOrganizationRelation: OrganizationRelation =
      organizationRelation ||
      new OrganizationRelation().fromObject({
        organizationId: organizationStore.currentOrganizationId,
        relatedOrganizationId: organizationId,
      });

    if (organization) {
      Object.assign(organization, values.organization);

      await organizationStore.update(organization);
    } else {
      const createdOrganization =
        await organizationStore.createManagedOrganization({
          ...values.organization,
        });
      targetOrganizationRelation.relatedOrganizationId = createdOrganization.id;
    }
    targetOrganizationRelation.tags = Object.values(values.tags || {})
      .map((tags) => tags || [])
      .flat()
      .map((tag) => tag.value);
    targetOrganizationRelation.dateStart = toDate(values.dateStart);
    targetOrganizationRelation.dateEnd = toDate(values.dateEnd);
    targetOrganizationRelation.description = values.description;
    targetOrganizationRelation.relationType = values.relationType;
    targetOrganizationRelation.priority = values.priority ? 1 : 0;
    targetOrganizationRelation.dynamic = values.dynamic;

    await organizationRelationStore.save(targetOrganizationRelation);

    history.push(
      `/clients/view/${targetOrganizationRelation.relatedOrganizationId}`,
    );
  };

  return (
    <ManagedOrganizationFormDrawer
      onSubmit={handleSubmit}
      organization={organization}
      organizationRelation={organizationRelation}
      name={name}
      availableTags={tagStore.currentOrganizationTags}
      {...props}
    />
  );
};

ConnectedManagedOrganizationFormDrawer.defaultProps = {
  name: ManagedOrganizationFormDrawer.defaultProps.name,
};

export default observer(ConnectedManagedOrganizationFormDrawer);
