import AbstractResourceStore from './AbstractResourceStore';
import Project, { ProjectState, ProjectType } from '../domain/Project';
import ProjectService from '../services/ProjectService';
import { computed } from 'mobx';
import PersonStore from './PersonStore';
import AccountStore from './AccountStore';
import { singleton } from 'tsyringe';
import OrganizationStore from './OrganizationStore';

@singleton()
export default class ProjectStore extends AbstractResourceStore<Project> {
  public constructor(
    private readonly projectService: ProjectService,
    private readonly organizationStore: OrganizationStore,
    private readonly personStore: PersonStore,
    accountStore: AccountStore,
  ) {
    super(projectService, accountStore);
  }

  public async loadByOrganization(
    organizationId: string,
    state?: ProjectState,
  ) {
    await this.setManyEventually(
      this.projectService.getMany({ organizationId, state }),
    );
  }

  public async loadByActiveOrganization() {
    const currentPerson = this.personStore.currentPerson;

    if (!currentPerson) {
      throw new Error('No person selected');
    }

    const organizationId = currentPerson?.organizationId;

    if (!organizationId) {
      throw new Error('No organization selected');
    }

    return this.loadByOrganization(
      organizationId,
      currentPerson.hasEditPermission() ? undefined : ProjectState.PUBLISHED,
    );
  }

  public async updateState(projectId: string, newState: ProjectState) {
    const project = this.getById(projectId);
    await this.update({
      ...project,
      state: newState,
    });
  }

  @computed
  public get projects() {
    return this.items.filter(
      (project) => project.type === ProjectType.PROJECT || !project.type,
    );
  }

  @computed
  public get trainings() {
    return this.items.filter(
      (project) => project.type === ProjectType.TRAINING,
    );
  }

  @computed
  public get currentOrganizationProjects() {
    const organizationId = this.organizationStore.currentOrganizationId;

    if (!organizationId) {
      return [];
    }

    return this.items.filter(
      (project) => project.organizationId === organizationId,
    );
  }

  @computed
  public get upcomingProjects() {
    return this.currentOrganizationProjects.filter(
      (project) => !project.hasStarted(),
    );
  }

  @computed
  public get ongoingProjects() {
    return this.currentOrganizationProjects.filter((project) =>
      project.isOngoing(),
    );
  }

  @computed
  public get endedProjects() {
    return this.currentOrganizationProjects.filter(
      (project) => project.hasStarted() && project.hasEnded(),
    );
  }
}
