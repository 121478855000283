import React from 'react';
import Project from '../domain/Project';
import Participation from '../domain/Participation';
import Button from '../ui/Button';
import Text from '../ui/Text';
import ConnectedProjectPublishButton from '../containers/ConnectedProjectPublishButton';
import Request, { RequestAction } from '../domain/Request';
import CheckIcon from '../ui/icons/CheckIcon';
import HStack from '../ui/HStack';
import { t } from '../i18n';

export interface ProjectCardActionProps {
  project: Project;
  participations?: Participation[];
  requests?: Request[];
  personId: string;
  editable?: boolean;
  onJoin?: () => void;
}

const ProjectCardAction: React.FC<ProjectCardActionProps> = ({
  project,
  participations,
  requests,
  personId,
  editable,
}) => {
  const participated = project.isPersonRelated(
    personId,
    participations || [],
    true,
  );
  const requested = !!requests?.find(
    (r) => r.toProjectId === project.id && r.personId === personId,
  );

  if (project.isDraft() && editable) {
    return <ConnectedProjectPublishButton project={project} />;
  }

  if (participated) {
    return (
      <HStack>
        <CheckIcon color="green.500" />
        <Text textTransform="uppercase" fontSize="xs" color="gray.500">
          {t('Joined')}
        </Text>
      </HStack>
    );
  }

  return (
    <Button
      to={
        editable
          ? `?participation&personId=${personId}&projectId=${project.id}`
          : `?request&action=${RequestAction.CHANGE_PROJECT}&toProjectId=${project.id}`
      }
      size="sm"
      minWidth="80px"
      colorScheme="brand"
      disabled={requested || !project.isPublished() || project.hasEnded()}
    >
      {requested && (
        <>
          <CheckIcon />
          &nbsp;{t('Requested to join')}
        </>
      )}
      {!requested && t('Join')}
    </Button>
  );
};

export default ProjectCardAction;
