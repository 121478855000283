import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { RequestFormDrawerProps } from '../components/RequestFormDrawer';
import AccessTokenFormDrawer, {
  AccessTokenFormValues,
} from '../components/AccessTokenFormDrawer';
import useResolver from '../hooks/useResolver';
import PersonalAccessTokenService from '../services/PersonalAccessTokenService';
import RouterDrawer from '../components/RouterDrawer';
import { CopyToClipboard, Input, Stack, Text } from '../ui';
import { useTranslation } from 'react-i18next';
import { InputGroup, InputRightAddon } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

export interface ConnectedAccessTokenFormDrawerProps
  extends Omit<RequestFormDrawerProps, 'onSubmit'> {
  onCreate?: () => void;
}

const ConnectedAccessTokenFormDrawer: React.FC<
  ConnectedAccessTokenFormDrawerProps
> = ({ name, onCreate, ...props }) => {
  const { t } = useTranslation();
  const [tokenResult, setTokenResult] = useState<string>('');
  const personalAccessTokenService = useResolver(PersonalAccessTokenService);
  const handleSubmit = async (values: AccessTokenFormValues) => {
    const accessToken = await personalAccessTokenService.create(values);

    setTokenResult(accessToken.token);
    onCreate?.();
  };

  if (tokenResult) {
    return (
      <RouterDrawer
        variant="modal"
        name={name}
        header={t('Personal access token created')}
      >
        <Stack gap={4} pb={4}>
          <Text>
            {t(
              'Make sure to copy your personal access token now. You won’t be able to see it again!',
            )}
          </Text>
          <CopyToClipboard
            text={t('Personal access token copied to clipboard')}
            value={tokenResult}
          >
            <InputGroup style={{ cursor: 'pointer' }}>
              <Input isReadOnly value={tokenResult} />
              <InputRightAddon>
                <CopyIcon />
              </InputRightAddon>
            </InputGroup>
          </CopyToClipboard>
        </Stack>
      </RouterDrawer>
    );
  }

  return (
    <AccessTokenFormDrawer
      name={name}
      variant="modal"
      onSubmit={handleSubmit}
      {...props}
      tokenResult={tokenResult}
    />
  );
};

export default observer(ConnectedAccessTokenFormDrawer);
