import Resource from '../decorators/Resource';
import { toDate } from '../util/date';
import AbstractDomain from './AbstractDomain';

export enum PriceType {
  HOURLY = 'HOURLY',
  MONTHLY = 'MONTHLY',
  FIXED = 'FIXED',
  ONCE_A_MONTH = 'ONCE_A_MONTH',
}

@Resource.Name('prices')
export default class Price extends AbstractDomain {
  public organizationId: string;
  public externalId: string;
  public description: string;
  public value: number;
  public type: PriceType;
  public dateStart?: Date;
  public dateEnd?: Date;

  public toObject(): Partial<Price> {
    return {
      ...super.toObject(),
      organizationId: this.organizationId,
      externalId: this.externalId,
      description: this.description,
      value: this.value,
      type: this.type,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
    };
  }

  public fromObject(payload: Partial<Price>): this {
    super.fromObject(payload);

    this.organizationId = payload.organizationId;
    this.externalId = payload.externalId;
    this.description = payload.description;
    this.value = payload.value;
    this.type = payload.type || PriceType.HOURLY; // Null values are considered as hourly
    this.dateStart = toDate(payload.dateStart);
    this.dateEnd = toDate(payload.dateEnd);

    return this;
  }

  public isActive(date: Date = new Date()): boolean {
    return this.hasStarted(date) && !this.hasEnded(date);
  }

  public isRecurring(): boolean {
    return (
      !this.type || [PriceType.HOURLY, PriceType.MONTHLY].includes(this.type)
    );
  }

  public isBetweenDates(date1: Date, date2: Date): boolean {
    if (this.hasEnded(date1)) {
      return false;
    }

    if (!this.hasStarted(date2)) {
      return false;
    }

    return true;
  }

  private hasStarted(date: Date = new Date()): boolean {
    return !this.dateStart || this.dateStart <= date;
  }

  private hasEnded(date: Date = new Date()): boolean {
    return this.dateEnd && this.dateEnd < date;
  }
}
