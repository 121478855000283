import React from 'react';
import DynamicField from '../domain/DynamicField';
import { isConflicting } from '../utils';
import {
  Button,
  DataGrid,
  DeleteIcon,
  EditIcon,
  HStack,
  MenuItem,
  MoreActionsMenu,
  PlusIcon,
  Popover,
  Stack,
  Text,
} from '../../../ui';
import { t } from '../../../i18n';
import FormDynamicField from './FormDynamicField';
import Form from '../../../components/Form';
import IconedText from '../../../ui/IconedText';
import { TAG_ICONS, TAG_LABELS, TYPE_LABELS } from '../constants';
import { Resources } from '../resources';
import Tooltip from '../../../ui/Tooltip';
import AlertIcon from '../../../ui/icons/AlertIcon';
import { useHistory } from 'react-router-dom';

export interface DynamicFieldsProps {
  fields: DynamicField[];
  editable?: boolean;
}

const DynamicFields: React.FC<DynamicFieldsProps> = ({ fields, editable }) => {
  const history = useHistory();

  return (
    <DataGrid
      title="Dynamic fields"
      filters={[
        {
          key: 'tag',
          label: t('Applied to'),
          options: Object.entries(TAG_LABELS).map(([value, label]) => ({
            value,
            label,
          })),
          apply: (value, field) =>
            value && typeof value === 'string' && field.tags?.includes(value),
        },
      ]}
      actions={[
        {
          label: t('Add dynamic field'),
          icon: <PlusIcon />,
          onClick: () => history.push('?dynamic-field'),
        },
      ]}
      data={fields}
      fields={{
        title: {
          title: t('Title'),
          accessor: (item) => ({
            value: item.label,
            node: (
              <Stack>
                <HStack gap={2}>
                  <Text>{item.label}</Text>
                  {isConflicting(item, fields) && (
                    <Tooltip
                      label={t(
                        'Conflict with another field (same key). Please make sure same field is not applied twice to a resource',
                      )}
                    >
                      <div>
                        <AlertIcon color="red" />
                      </div>
                    </Tooltip>
                  )}
                </HStack>
                <Text color="gray.500" fontSize="sm">
                  {item.description}
                </Text>
              </Stack>
            ),
          }),
        },
        type: {
          title: t('Type'),
          accessor: (item) => ({
            value: item.type,
            node: (
              <Text color="gray.500">
                {TYPE_LABELS[item.type] || item.type}
              </Text>
            ),
          }),
        },
        appliedTo: {
          title: t('Applied to'),
          accessor: (field) => ({
            value: field.tags,
            node: (
              <HStack>
                {field.tags?.filter(Boolean).map((tag) => {
                  const Icon = TAG_ICONS[tag as Resources];

                  return (
                    <IconedText key={tag} icon={Icon && <Icon />}>
                      {tag in TAG_LABELS ? TAG_LABELS[tag as Resources] : tag}
                    </IconedText>
                  );
                })}
              </HStack>
            ),
          }),
        },
        actions: {
          title: '',
          accessor: (field) => (
            <HStack>
              <Popover handle={<Button>{t('Preview')}</Button>}>
                <div style={{ width: 400 }}>
                  <Form onSubmit={() => {}}>
                    <FormDynamicField field={field} />
                  </Form>
                </div>
              </Popover>
              <MoreActionsMenu label="Dynamic field actions">
                {editable && (
                  <MenuItem
                    icon={<EditIcon />}
                    to={`?dynamic-field=${field.id}`}
                  >
                    {t('Edit')}
                  </MenuItem>
                )}
                <MenuItem
                  icon={<DeleteIcon />}
                  to={`?dynamic-field.delete=${field.id}`}
                >
                  {t('Delete')}
                </MenuItem>
              </MoreActionsMenu>
            </HStack>
          ),
        },
      }}
    />
  );
};

export default DynamicFields;
