import React, { ReactElement, ReactNode } from 'react';
import orderBy from 'lodash/orderBy';
import kebabCase from 'lodash/kebabCase';
import HStack from '../ui/HStack';
import RouterDropdownSelect from './RouterDropdownSelect';
import useQuery from '../hooks/useQuery';
import Button from '../ui/Button';
import { useHistory } from 'react-router-dom';
import { t } from '../i18n';

interface BaseFilter<Item, Value = string> {
  name: string;
  label: string;
  placeholder?: string;
  defaultValue?: Value;
  validate?: (value: Value, item: Item) => boolean;
  displayCondition?: (filterNameValueMap: {
    [filterName: string]: string;
  }) => boolean;
}

export interface SelectListFilter<Item, Value = string>
  extends BaseFilter<Item, Value> {
  type: 'select';
  options: Array<{
    value: Value;
    label: string;
  }>;
  multiple?: boolean;
  fastSwitch?: boolean;
}

export interface TextListFilter<Item, Value = string>
  extends BaseFilter<Item, Value> {
  type: 'text';
}

export type ListFilter<Item, Value = string> =
  | TextListFilter<Item, Value>
  | SelectListFilter<Item, Value>;

export interface SortOption<T> {
  sort: Array<[keyof T | string, 'asc' | 'desc']>;
  label: string;
}

function applyFilters<T>(
  items: T[],
  initialFilters: ListFilter<T, any>[],
  values: { [key: string]: string },
): T[] {
  const filters = initialFilters.filter(
    (filter) => !filter.displayCondition || filter.displayCondition(values),
  );

  return items.filter((item) => {
    return !filters.some((filter) => {
      const value = values[filter.name] || filter.defaultValue;

      if (!value) {
        return false;
      }

      return !filter.validate(value, item);
    });
  });
}

function applySort<T>(
  items: T[],
  sortOptions?: SortOption<T>[],
  value?: string,
) {
  if (!sortOptions || !sortOptions.length) {
    return items;
  }

  const sort: SortOption<T> = value
    ? sortOptions.find((option) => value === sortOptionToValue(option))
    : sortOptions[0];

  let fields: Array<keyof T | string> = [];

  let directions: Array<'asc' | 'desc'> = [];

  if (sort?.sort) {
    sort.sort.forEach((elem) => {
      fields.push(elem[0]);
      directions.push(elem[1]);
    });
  }

  return orderBy(items, fields, directions);
}

function sortOptionToValue<T>(sortOption: SortOption<T>): string {
  return kebabCase(sortOption.label);
}

function getFilterValues<T>(
  filters: ListFilter<T>[],
  queryValues: { [key: string]: string },
): { [filterName: string]: string } {
  return Object.assign(
    {},
    ...filters.map((filter) => ({
      [filter.name]: queryValues[filter.name] || filter.defaultValue,
    })),
  );
}

export interface ListFiltersProps<T> {
  filters: ListFilter<T, any>[];
  children: (filteredItems: T[], filtersNode: ReactElement) => ReactNode;
  items: T[];
  clearable?: boolean;
  sortOptions?: SortOption<T>[];
}

function ListFilters<T>({
  filters,
  items,
  children,
  clearable,
  sortOptions,
}: ListFiltersProps<T>) {
  const history = useHistory();
  const { sort: sortQuery, ...query } = useQuery();
  const values = getFilterValues(filters, query);

  const filtersNode = (
    <HStack spacing={4} flexWrap="wrap">
      {filters.filter(Boolean).map((filter) => {
        if (filter.displayCondition && !filter.displayCondition(values)) {
          return null;
        }

        if (filter.type === 'select') {
          return (
            <RouterDropdownSelect
              key={filter.name}
              fastSwitchOnSingleOption={
                filter.fastSwitch &&
                filter.options.length === 2 &&
                !filter.multiple
              }
              name={filter.name}
              isMulti={filter.multiple}
              withChevron
              placeholder={filter.placeholder || 'All'}
              label={filter.label}
              options={filter.options}
              delimiter=" or "
              defaultValue={filter.defaultValue}
              replaceUrl={false}
            />
          );
        }

        return null;
      })}
      {(sortOptions || []).length > 0 && (
        <RouterDropdownSelect
          name="sort"
          withChevron
          fastSwitchOnSingleOption
          label="Sort"
          defaultValue={sortOptionToValue(sortOptions[0])}
          replaceUrl={false}
          options={sortOptions.map((sortOption) => ({
            label: sortOption.label,
            value: sortOptionToValue(sortOption),
          }))}
        />
      )}
      {clearable && (
        <Button
          bg="none"
          color="gray.400"
          size="xs"
          onClick={() => {
            history.replace(window.location.pathname); // FIXME - remove just filters
          }}
        >
          {t('Reset filters')}
        </Button>
      )}
    </HStack>
  );

  if (!children) {
    return filtersNode;
  }

  let filteredItems = applyFilters(items, filters, values);
  filteredItems = applySort(filteredItems, sortOptions, sortQuery);

  return <>{children(filteredItems, filtersNode)}</>;
}

export default ListFilters;
