export default class Resource {
  public static getName(target: object | any) {
    return target._resourceName || target.name;
  }

  public static Name(value: string) {
    return (target: object | any): any => {
      target._resourceName = value;
    };
  }
}
