import React from 'react';
import {
  LinkBox as ChakraLinkBox,
  LinkBoxProps as ChakraLinkBoxProps,
} from '@chakra-ui/react';

export interface LinkBoxProps extends ChakraLinkBoxProps {}

const LinkBox: React.FC<LinkBoxProps> = ({ ...props }) => {
  return <ChakraLinkBox {...props} />;
};

LinkBox.defaultProps = {};

export default LinkBox;
