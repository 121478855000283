import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import ParticipationStore from '../store/ParticipationStore';
import ConnectedProjectLink from './ConnectedProjectLink';
import HStack from '../ui/HStack';
import ConnectedTagWithIcon from './ConnectedTagWithIcon';
import CapacityLabel from '../components/CapacityLabel';
import Stack from '../ui/Stack';
import Text from '../ui/Text';

export interface ConnectedParticipationDetailsProps {
  participationId?: string;
  tagId?: string;
  projectId?: string;
  capacity?: number;
}

const ConnectedParticipationDetails: React.FC<ConnectedParticipationDetailsProps> =
  ({
    participationId,
    tagId: suppliedTagId,
    projectId: suppliedProjectId,
    capacity: suppliedCapacity,
  }) => {
    const participationStore = useResolver(ParticipationStore);

    const participation =
      participationId && participationStore.getByIdOrLoad(participationId);

    if (participationId && !participation) {
      return null;
    }

    const tagId = suppliedTagId || participation?.tagId;
    const projectId = suppliedProjectId || participation?.projectId;
    const capacity = suppliedCapacity || participation?.capacity;

    return (
      <Stack spacing={1}>
        <HStack>
          {tagId && <ConnectedTagWithIcon tagId={tagId} />}
          {capacity && <CapacityLabel capacity={capacity} />}
        </HStack>
        {projectId && (
          <Text fontSize="lg">
            <ConnectedProjectLink projectId={projectId} />
          </Text>
        )}
      </Stack>
    );
  };

export default observer(ConnectedParticipationDetails);
