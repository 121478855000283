import { singleton } from 'tsyringe';
import ProjectStore from './ProjectStore';
import ParticipationStore from './ParticipationStore';
import OrganizationStore from './OrganizationStore';
import PersonStore from './PersonStore';
import AccountStore from './AccountStore';
import HistoryEventStore from './HistoryEventStore';
import InvitationStore from './InvitationStore';
import InvoiceStore from './InvoiceStore';
import NotificationStore from './NotificationStore';
import PaymentMethodStore from './PaymentMethodStore';
import PersonSkillStore from './PersonSkillStore';
import PriceStore from './PriceStore';
import ProjectTagStore from './ProjectTagStore';
import RequestStore from './RequestStore';
import SubscriptionStore from './SubscriptionStore';
import TagStore from './TagStore';
import WorklogStore from './WorklogStore';
import AbstractResourceStore from './AbstractResourceStore';
import ProjectAccessStore from './ProjectAccessStore';
import OrganizationRelationStore from './OrganizationRelationStore';
import PaymentScheduleStore from './PaymentScheduleStore';
import TaskStore from './TaskStore';

@singleton()
export default class RootStore {
  public constructor(
    public readonly accountStore: AccountStore,
    public readonly historyEventStore: HistoryEventStore,
    public readonly invitationStore: InvitationStore,
    public readonly invoiceStore: InvoiceStore,
    public readonly notificationStore: NotificationStore,
    public readonly organizationRelationStore: OrganizationRelationStore,
    public readonly organizationStore: OrganizationStore,
    public readonly participationStore: ParticipationStore,
    public readonly paymentMethodStore: PaymentMethodStore,
    public readonly personSkillStore: PersonSkillStore,
    public readonly personStore: PersonStore,
    public readonly priceStore: PriceStore,
    public readonly projectAccessStore: ProjectAccessStore,
    public readonly projectStore: ProjectStore,
    public readonly projectTagStore: ProjectTagStore,
    public readonly requestStore: RequestStore,
    public readonly subscriptionStore: SubscriptionStore,
    public readonly tagStore: TagStore,
    public readonly worklogStore: WorklogStore,
    public readonly paymentScheduleStore: PaymentScheduleStore,
    public readonly taskStore: TaskStore,
  ) {}

  public clearAll() {
    for (const store of Object.values(this)) {
      if (store instanceof AbstractResourceStore) {
        store.clear();
      }
    }
  }
}
