import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import OrganizationRelation from '../domain/OrganizationRelation';
import OrganizationRelationStore from '../store/OrganizationRelationStore';
import RichText from '../components/RichText';

export interface ConnectedManagedOrganizationAboutProps {
  organizationRelation: OrganizationRelation;
  editable: boolean;
}

const ConnectedManagedOrganizationAbout: React.FC<
  ConnectedManagedOrganizationAboutProps
> = ({ organizationRelation, editable }) => {
  const organizationRelationStore = useResolver(OrganizationRelationStore);

  const handleChange = async (description: string) => {
    await organizationRelationStore.update({
      id: organizationRelation.id,
      description,
    });
  };

  return (
    <RichText
      children={organizationRelation.description}
      onChange={editable && handleChange}
    />
  );
};

export default observer(ConnectedManagedOrganizationAbout);
