import React from 'react';
import Participation from '../domain/Participation';
import Pie from '../ui/Pie';
import {
  getActiveParticipationsByDate,
  getCapacityByDate,
} from '../util/capacity';

export interface AvailabilityPieChartProps {
  participations: Participation[];
  date?: Date;
}

const AvailabilityPieChart: React.FC<AvailabilityPieChartProps> = ({
  participations,
  date: suppliedDate,
}) => {
  const date = suppliedDate || new Date();
  const activeParticipations = getActiveParticipationsByDate(
    participations,
    date,
  );
  const capacity = getCapacityByDate(participations, date);

  return (
    <Pie
      label={`${+(capacity * 100).toFixed(2)}%`}
      totalValue={Math.max(capacity, 1)}
      values={activeParticipations.map((participation) => {
        return {
          title: participation.project?.name,
          value: participation.capacity,
        };
      })}
    />
  );
};

export default AvailabilityPieChart;
