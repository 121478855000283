import React from 'react';
import PersonalAccessToken from '../domain/PersonalAccessToken';
import { formatDate } from '../util/date';
import { t } from '../i18n';
import { DataGrid, PlusIcon, Text } from '../ui';
import { useHistory } from 'react-router-dom';
import orderBy from 'lodash/orderBy';

export interface AccessTokenListProps {
  personalAccessTokens: PersonalAccessToken[];
}

const AccessTokenList: React.FC<AccessTokenListProps> = ({
  personalAccessTokens,
}) => {
  const history = useHistory();

  return (
    <>
      <DataGrid
        title="Personal access tokens"
        data={orderBy(personalAccessTokens, 'createdAt', 'desc')}
        actions={[
          {
            label: t('Create new access token'),
            icon: <PlusIcon />,
            onClick: () => history.push('?create-access-token'),
          },
        ]}
        fields={{
          name: {
            title: t('Name'),
            accessor: (i) => i.name,
          },
          expireDate: {
            title: t('Expire date'),
            accessor: (i) => ({
              node: i.expireDate ? (
                formatDate(i.expireDate)
              ) : (
                <Text color="gray.500">Never</Text>
              ),
              value: i.expireDate,
            }),
          },
          createdAt: {
            title: t('Issue date'),
            accessor: (i) => formatDate(i.createdAt),
          },
        }}
      />
    </>
  );
};

export default AccessTokenList;
