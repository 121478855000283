import React from 'react';
import { observer } from 'mobx-react';
import { RequestFormDrawerProps } from '../components/RequestFormDrawer';
import useResolver from '../hooks/useResolver';
import { useHistory, useLocation } from 'react-router-dom';
import PersonStore from '../store/PersonStore';
import useQueryValue from '../hooks/useQueryValue';
import TaskFormDrawer from '../components/TaskFormDrawer';
import TaskStore from '../store/TaskStore';
import Task from '../domain/Task';
import ProjectStore from '../store/ProjectStore';
import ParticipationStore from '../store/ParticipationStore';

export interface ConnectedRequestFormDrawerProps
  extends Omit<RequestFormDrawerProps, 'onSubmit'> {}

const ConnectedTaskFormDrawer: React.FC<ConnectedRequestFormDrawerProps> = ({
  ...props
}) => {
  const location = useLocation();
  const personStore = useResolver(PersonStore);
  const participationStore = useResolver(ParticipationStore);
  const projectStore = useResolver(ProjectStore);
  const taskStore = useResolver(TaskStore);
  const history = useHistory();
  const taskId = useQueryValue(props.name);
  const prevTask = taskId ? taskStore.getByIdOrLoad(taskId) : null;
  const action = useQueryValue('action');
  const projectId = useQueryValue('projectId');

  const handleSubmit = async (values: Partial<Task>) => {
    const task = new Task().fromObject({
      ...(projectId ? { projectId } : {}),
      ...values,
    });

    task.creatorId = personStore.currentPerson.id;

    if (prevTask) {
      Object.assign(prevTask, values);

      await taskStore.update(prevTask);
    } else {
      if (task.participationId) {
        const participation = participationStore.getById(task.participationId);
        task.personId = participation.personId;
      }

      await taskStore.create(task);

      if (!location.pathname.includes('tasks')) {
        history.push('/personal/tasks/created-by-me');
      }
    }
  };

  return (
    <TaskFormDrawer
      onSubmit={handleSubmit}
      project={
        projectId
          ? projectStore.getByIdOrLoad(projectId)
          : prevTask
          ? projectStore.getByIdOrLoad(prevTask.projectId)
          : undefined
      }
      {...props}
      defaultValues={{
        action,
        ...prevTask,
      }}
      edit={!!prevTask}
    />
  );
};

export default observer(ConnectedTaskFormDrawer);
