import AbstractResourceStore from './AbstractResourceStore';
import Participation, { ParticipationState } from '../domain/Participation';
import ParticipationService from '../services/ParticipationService';
import { computed } from 'mobx';
import PersonStore from './PersonStore';
import AccountStore from './AccountStore';
import { singleton } from 'tsyringe';
import OrganizationStore from './OrganizationStore';
import ProjectStore from './ProjectStore';

@singleton()
export default class ParticipationStore extends AbstractResourceStore<Participation> {
  public constructor(
    private readonly participationService: ParticipationService,
    private readonly personStore: PersonStore,
    private readonly projectStore: ProjectStore,
    private readonly organizationStore: OrganizationStore,
    accountStore: AccountStore,
  ) {
    super(participationService, accountStore);
  }

  public async loadByPerson(personId: string) {
    return await this.setManyEventually(
      this.participationService.getAllByPerson(personId),
    );
  }

  public async loadByCurrentPerson() {
    await this.loadByPerson(this.personStore.currentPerson.id);
  }

  public async loadByProject(projectId: string) {
    await this.setManyEventually(
      this.participationService.getAllByProject(projectId),
    );
  }

  public async loadByOrganization(organizationId: string) {
    await this.setManyEventually(
      this.participationService.getAllByOrganization(organizationId),
    );
  }

  public async loadByCurrentOrganization() {
    return this.loadByOrganization(
      this.organizationStore.currentOrganizationId,
    );
  }

  public async endParticipations(ids: string[]) {
    for (const id of ids) {
      const participation = this.getById(id);

      if (!participation) {
        throw new Error('Something went wrong');
      }

      // Ignore already ended participation
      if (participation.hasEnded()) {
        continue;
      }

      if (participation.hasStarted()) {
        await this.update({ id, dateEnd: new Date() });
      } else {
        // Delete participations that have not started
        await this.delete(id);
      }
    }
  }

  protected prepareForStoring(item: Participation): Participation {
    if (item.person) {
      this.personStore.storeItem(item.person);
      item.person = null;
    }

    return super.prepareForStoring(new Participation().fromObject(item));
  }

  public getItemsByPerson(personId: string): Participation[] {
    return this.getFilteredItems({ personId });
  }

  public getItemsByProject(projectId: string): Participation[] {
    return this.getFilteredItems({ projectId });
  }

  public getProjectParticipantsCount(
    projectId: string,
    date: Date = new Date(),
  ): number {
    const items = this.getItemsByProject(projectId).filter(
      (p) => p.state === ParticipationState.APPROVED && p.isOngoing(date),
    );

    return +items.reduce(
      (total, participation) => total + (+participation.capacity || 1),
      0,
    );
  }

  public getCurrentPersonParticipationInProject(
    projectId: string,
  ): Participation | null {
    const participations = this.getFilteredItems({
      projectId,
      personId: this.personStore.currentPerson?.id,
    });
    participations.sort((a, b) => +b.createdAt - +a.createdAt);

    return (
      participations.find(
        (p) => p.dateEnd === null || p.dateEnd > new Date(),
      ) || null
    );
  }

  @computed
  public get currentOrganizationRequestedItems(): Participation[] {
    return this.getFilteredItems({
      state: ParticipationState.REQUESTED,
    }).filter((item) => {
      const project = this.projectStore.getByIdOrLoad(item.projectId);

      return (
        project?.organizationId === this.organizationStore.currentOrganizationId
      );
    });
  }

  @computed
  public get currentPersonRequestedItems(): Participation[] {
    return this.getFilteredItems({
      state: ParticipationState.REQUESTED,
      personId: this.personStore.currentPerson.id,
    });
  }

  @computed
  public get currentPersonInvitedItems(): Participation[] {
    return this.getFilteredItems({
      state: ParticipationState.INVITED,
      personId: this.personStore.currentPerson.id,
    });
  }
}
