import React from 'react';
import { Container, Grid, GridItem, Stack } from '@chakra-ui/react';
import Box from './Box';
import background1 from './assets/background-1.png';
import background2 from './assets/background-2.png';
import background4 from './assets/background-4.png';
import Text from './Text';
import sample from 'lodash/sample';
import Logo from './Logo';

export interface LayoutProps {
  title: string;
  subtitle: string;
}

const ArtBackgroundLayout: React.FC<LayoutProps> = ({
  title,
  subtitle,
  children,
}) => {
  const background = sample(['', background1, background2, background4]);

  return (
    <Grid templateRows="60px auto" height="100vh">
      <GridItem bg="white" pl="10px" alignSelf="center">
        <Logo size="sm" />
      </GridItem>
      <GridItem>
        <Box
          width="100wh"
          height="100%"
          bg="#F2F2F2"
          bgImage={`url(${background})`}
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          boxShadow="xl"
        >
          <Container display="flex" width="500px">
            <Stack
              spacing={12}
              width="100%"
              mt="100px"
              background={'white'}
              borderRadius="6px"
              boxShadow="lg"
            >
              <Box mx="32px" mb="20px">
                <Text fontSize="24px" fontWeight={500} py="24px">
                  {title}
                </Text>
                <Text
                  fontSize="14px"
                  fontWeight={400}
                  pb="24px"
                  lineHeight="21px"
                  color="#6E6B7B"
                >
                  {subtitle}
                </Text>
                <div>{children}</div>
              </Box>
            </Stack>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default ArtBackgroundLayout;
