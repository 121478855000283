import React from 'react';
import {
  AvatarBadge as ChakraAvatarBadge,
  AvatarBadgeProps as ChakraAvatarBadgeProps,
} from '@chakra-ui/react';

export interface AvatarBadgeProps extends ChakraAvatarBadgeProps {}

const AvatarBadge: React.FC<AvatarBadgeProps> = ({ ...props }) => {
  return <ChakraAvatarBadge {...props} />;
};

AvatarBadge.defaultProps = {};

export default AvatarBadge;
