import AbstractDomain from './AbstractDomain';
import Resource from '../decorators/Resource';

export enum TagType {
  POSITION = 'POSITION',
  TECHNOLOGY = 'TECHNOLOGY',
  PROJECT_TYPE = 'PROJECT_TYPE',
  LOCATION = 'LOCATION',
  LANGUAGE = 'LANGUAGE',
  CUSTOM = 'CUSTOM',
}

@Resource.Name('tags')
export default class Tag extends AbstractDomain {
  public name: string;
  public type: TagType;
  public context?: string;
  public organizationId?: string;

  public fromObject(payload: Partial<Tag>): this {
    super.fromObject(payload);

    this.name = payload.name;
    this.type = payload.type;
    this.organizationId = payload.organizationId;
    this.context = payload.context;

    return this;
  }

  public toObject(): Partial<Tag> {
    return {
      ...super.toObject(),
      name: this.name,
      type: this.type,
      organizationId: this.organizationId,
      context: this.context,
    };
  }
}
