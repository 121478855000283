import User from '../domain/User';

// This is actually an interface
export default class AuthenticationService {
  login(email: string, password: string): Promise<void> {
    throw new Error('Not implemented');
  }

  loginByGoogle(): Promise<void> {
    throw new Error('Not implemented');
  }

  loginByGithub(): Promise<void> {
    throw new Error('Not implemented');
  }

  loginByFacebook(): Promise<void> {
    throw new Error('Not implemented');
  }

  loginByMicrosoft(): Promise<void> {
    throw new Error('Not implemented');
  }

  register(
    email: string,
    password: string,
    redirectUrl?: string,
  ): Promise<void> {
    throw new Error('Not implemented');
  }

  sendPasswordResetEmail(email: string, redirectUrl?: string): Promise<void> {
    throw new Error('Not implemented');
  }

  confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    throw new Error('Not implemented');
  }

  getAccount(): Promise<User | null> {
    throw new Error('Not implemented');
  }

  logout(): Promise<void> {
    throw new Error('Not implemented');
  }

  getToken(): Promise<string | null> {
    throw new Error('Not implemented');
  }
}
