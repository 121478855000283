import React from 'react';
import Subscription, { SubscriptionState } from '../domain/Subscription';
import Text from '../ui/Text';
import { formatDate } from '../util/date';
import Box from '../ui/Box';
import Stack from '../ui/Stack';
import Divider from '../ui/Divider';
import HStack from '../ui/HStack';
import Alert from '../ui/Alert';
import SkeletonLoader from '../ui/SkeletonLoader';
import { getCurrencyFromString, getCurrencyWithValue } from '../util/currency';
import { t } from '../i18n';

export interface SubscriptionInfoProps {
  subscription: Subscription | null;
  loading?: boolean;
}

const SubscriptionInfo: React.FC<SubscriptionInfoProps> = ({
  subscription,
  loading,
}) => {
  const freeTrial = subscription?.state === SubscriptionState.TRIAL;

  if (
    [SubscriptionState.ACTIVE, SubscriptionState.TRIAL].includes(
      subscription?.state,
    )
  ) {
    return (
      <Stack spacing={4}>
        <Box>
          <Text as="span" fontSize="3xl" fontWeight="bold" color="brand.500">
            {getCurrencyWithValue(
              +(subscription.price / subscription.quantity).toFixed(2),
              getCurrencyFromString(subscription.currency),
            )}
          </Text>{' '}
          / {t('account')}
        </Box>
        <Divider />
        <HStack justifyContent="space-between">
          <div>Price for {subscription.quantity} accounts</div>
          <Text fontWeight="bold">
            {getCurrencyWithValue(
              subscription.price?.toFixed(2),
              getCurrencyFromString(subscription.currency),
            )}
          </Text>
        </HStack>
        <HStack justifyContent="space-between">
          <div>VAT</div>
          <Text fontWeight="bold">20%</Text>
        </HStack>
        <Divider />
        <HStack justifyContent="space-between">
          <div>{t('Amount Payable')}</div>
          <div>
            {freeTrial && (
              <span>
                {t('Free until')}{' '}
                <strong>{formatDate(subscription.trialEndAt)}</strong>.{' '}
                {t('Followed by')}
              </span>
            )}
            <Text as="span" fontWeight="bold" color="green.500">
              {getCurrencyWithValue(
                (subscription.price * 1.2).toFixed(2),
                getCurrencyFromString(subscription.currency),
              )}
            </Text>{' '}
            / {t('mo')}
          </div>
        </HStack>
      </Stack>
    );
  }

  if (loading) {
    return <SkeletonLoader />;
  }

  return (
    <div>
      <Alert
        title={t('There is a problem with your subscription')}
        status="error"
      >
        <div>{t('Please contact site administrator')}</div>
      </Alert>
    </div>
  );
};

export default SubscriptionInfo;
