import React from 'react';
import Menu, { MenuItem } from './Menu';
import CheckIcon from './icons/CheckIcon';
import Text from './Text';
import { t } from '../i18n';

export type Value = string | number;

export interface DropdownSelectProps {
  options: Array<{ label: string | any; value: Value }>;
  value: Value | Value[];
  isMulti?: boolean;
  withChevron?: boolean;
  onChange?: (newValue: Value | Value[]) => void;
  fastSwitchOnSingleOption?: boolean;
  placeholder?: string;
  label?: string;
  delimiter?: string;
}

const DropdownSelect: React.FC<DropdownSelectProps> = ({
  options,
  value,
  onChange,
  isMulti,
  withChevron,
  fastSwitchOnSingleOption,
  placeholder,
  label,
  delimiter,
}) => {
  const exclusiveOptions = options.filter((o) => o.value !== value);
  const stringifiedValue = options
    .filter((o) =>
      isMulti ? (value as Value[])?.includes(o.value) : o.value === value,
    )
    .map((o) => o.label)
    .join(delimiter);

  const handle = (
    <Text fontSize="sm" maxW="300px" isTruncated>
      {!!label && <span>{label}: &nbsp;</span>}
      <span style={{ fontWeight: label ? 'bold' : 'normal' }}>
        {stringifiedValue || placeholder}
      </span>
    </Text>
  );

  return (
    <Menu
      handle={handle}
      color="gray.400"
      withChevron={withChevron}
      onHandleClick={
        !isMulti &&
        onChange &&
        fastSwitchOnSingleOption &&
        exclusiveOptions.length === 1
          ? (e) => {
              e.preventDefault();
              onChange(exclusiveOptions[0].value);
            }
          : null
      }
      isMulti={isMulti}
      placement="bottom-start"
    >
      {exclusiveOptions.map((option) => {
        const arrayValue = (value as Value[]) || [];
        const isSelected = isMulti
          ? arrayValue.includes(option.value)
          : value === option.value;

        return (
          <MenuItem
            key={option.value}
            icon={isSelected ? <CheckIcon /> : null}
            onClick={() => {
              if (!onChange) {
                return;
              }

              if (isMulti && !isSelected) {
                onChange([...arrayValue, option.value]);
              } else if (isMulti) {
                onChange(arrayValue.filter((v) => v !== option.value));
              } else {
                onChange(option.value);
              }
            }}
          >
            {option.label}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

DropdownSelect.defaultProps = {
  fastSwitchOnSingleOption: false,
  placeholder: t('Select..'),
  delimiter: ', ',
};

export default DropdownSelect;
