import React, { useEffect } from 'react';
import useResolver from '../hooks/useResolver';
import { observer } from 'mobx-react';
import PersonStore from '../store/PersonStore';
import PersonProfile from '../components/PersonProfile';
import ParticipationStore from '../store/ParticipationStore';
import { calculateHoursFromCapacity } from '../util/capacity';
import useMonthPicker from '../hooks/useMonthPicker';
import useSWR from 'swr';
import { formatDate } from '../util/date';
import { endOfMonth, startOfMonth } from 'date-fns';
import Participation from '../domain/Participation';
import TimesheetService from '../services/TimesheetService';
import { getAllocationHours, getUtilizationHours } from '../util/timesheet';

const MyProfile: React.FC = () => {
  const { currentPerson } = useResolver(PersonStore);
  const participationStore = useResolver(ParticipationStore);
  const month = useMonthPicker();
  const timesheetService = useResolver(TimesheetService);

  useEffect(() => {
    participationStore.loadByPerson(currentPerson.id);
  }, [currentPerson]);

  const { data } = useSWR(
    `ConnectedOrganizationPersonTimeline:${[currentPerson.id]}|${formatDate(
      month,
    )}`,
    () =>
      timesheetService.getDaily({
        organizationId: currentPerson.organizationId,
        personId: [currentPerson.id],
        dateStart: startOfMonth(month),
        dateEnd: endOfMonth(month),
      }),
  );

  if (!currentPerson) {
    return null;
  }

  const timesheet = data?.map(({ participation, entries }) => ({
    entries,
    participations: [new Participation().fromObject(participation)],
  }));

  const utilizationHours = getUtilizationHours(timesheet);
  const allocationHours = getAllocationHours(timesheet);

  const capacityHours = calculateHoursFromCapacity(
    currentPerson.capacity,
    month,
  );

  return (
    <PersonProfile
      editable
      person={currentPerson}
      allocation={allocationHours / capacityHours}
      utilization={utilizationHours / capacityHours}
    />
  );
};

export default observer(MyProfile);
