import React from 'react';
import ConnectedTasksAssignedToMe from '../containers/ConnectedTasksAssignedToMe';
import { Card, Tab } from '../ui';
import RouterTabs from '../components/RouterTabs';
import ConnectedTasksCreatedByMe from '../containers/ConnectedTasksCreatedByMe';
import { t } from '../i18n';

const MyTasks: React.FC = () => {
  return (
    <Card>
      <RouterTabs>
        <Tab title={t('Assigned to me')}>
          <ConnectedTasksAssignedToMe />
        </Tab>

        <Tab title={t('Created by me')}>
          <ConnectedTasksCreatedByMe />
        </Tab>
      </RouterTabs>
    </Card>
  );
};

export default MyTasks;
