import React from 'react';
import HistoryEvent from '../domain/HistoryEvent';
import { formatDateTime } from '../util/date';
import ConnectedUser from '../containers/ConnectedUser';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '../ui';

export interface ProjectHistoryProps {
  events: HistoryEvent[];
}

const ProjectHistory: React.FC<ProjectHistoryProps> = ({ events }) => {
  const { t } = useTranslation();

  return (
    <DataGrid
      title={t('Project history')}
      fields={{
        date: {
          title: t('Date'),
          accessor: (item) => formatDateTime(item.createdAt),
        },
        user: {
          title: t('User'),
          accessor: (item) => ({
            node: <ConnectedUser linkToProfile userId={item.userId} />,
            value: item.userId,
          }),
        },
        event: {
          title: t('Event'),
          accessor: (item) => item.text,
        },
      }}
      data={events}
    />
  );
};

export default ProjectHistory;
