export function slugify(text: string): string {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function isEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
}

export function plural(
  amount: number,
  singular: string,
  plural: string,
): string {
  return amount === 1 ? singular : plural;
}

export function textToInteger(text: string, max: number) {
  let result = 0;

  for (let i = 0; i < text.length; i++) {
    result += text.charCodeAt(i);
  }

  if (max) {
    result = result % max;
  }

  return result;
}

export const capitalize = (s: string) =>
  s && String(s[0]).toUpperCase() + String(s).slice(1);
