import React from 'react';
import logoText from './assets/logo_text.svg';
import Link from './Link';

interface LogoProps {
  size?: 'sm' | 'md' | 'lg' | 'full';
  to?: string;
}

const Logo: React.FC<LogoProps> = ({ size, to }) => {
  const sizes: { [key: string]: number | string } = {
    sm: 88,
    md: 150,
    lg: 300,
    full: '100%',
  };

  return (
    <Link to={to} style={{ width: sizes[size], display: 'inline-block' }}>
      <img width="100%" src={logoText} alt="nopilot" />
    </Link>
  );
};

Logo.defaultProps = {
  to: '/',
  size: 'full',
};

export default Logo;
