import Request from '../domain/Request';
import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';

@injectable()
export default class RequestService extends AbstractRestfulHttpService<Request> {
  public constructor(apiService: ApiService) {
    super(Request, apiService);
  }

  public getAllByPerson(personId: string): Promise<Request[]> {
    return this.getMany({ personId });
  }

  public getAllByProject(toProjectId: string): Promise<Request[]> {
    return this.getMany({ toProjectId });
  }

  public async getAllByOrganization(
    organizationId: string,
  ): Promise<Request[]> {
    return this.getMany({ organizationId });
  }
}
