import React from 'react';
import Organization from '../domain/Organization';
import OrganizationRelation from '../domain/OrganizationRelation';
import MoreActionsMenu from '../ui/MoreActionsMenu';
import { MenuItem } from '../ui/Menu';
import EditIcon from '../ui/icons/EditIcon';
import DeleteIcon from '../ui/icons/DeleteIcon';
import CloseIcon from '../ui/icons/CloseIcon';
import ConnectedManagedOrganizationUpdateTrigger from '../containers/ConnectedManagedOrganizationUpdateTrigger';
import CheckIcon from '../ui/icons/CheckIcon';
import { t } from '../i18n';

export interface ManagedOrganizationCardProps {
  organization: Organization;
  organizationRelation?: OrganizationRelation;
}

const ManagedOrganizationActions: React.FC<ManagedOrganizationCardProps> = ({
  organization,
  organizationRelation,
}) => {
  return (
    <MoreActionsMenu label={`${organization.name} ${t('actions')}`}>
      <MenuItem
        icon={<EditIcon />}
        to={`?managed-organization=${organization.id}`}
      >
        Edit
      </MenuItem>
      {organizationRelation?.isOngoing() && (
        <MenuItem
          icon={<CloseIcon />}
          to={`?managed-organization.deactivate=${organizationRelation.id}`}
        >
          {t('Deactivate')}
        </MenuItem>
      )}
      {organizationRelation?.hasEnded() && (
        <ConnectedManagedOrganizationUpdateTrigger
          organizationRelation={organizationRelation}
          values={() => ({ dateEnd: null })}
        >
          <MenuItem icon={<CheckIcon />}>Reactivate</MenuItem>
        </ConnectedManagedOrganizationUpdateTrigger>
      )}
      <MenuItem
        icon={<DeleteIcon />}
        to={`?managed-organization.delete=${organization.id}`}
      >
        {t('Delete')}
      </MenuItem>
    </MoreActionsMenu>
  );
};

export default ManagedOrganizationActions;
