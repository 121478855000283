import React, { useEffect } from 'react';
import { useForm, UseFormMethods, FormProvider } from 'react-hook-form';
import useToast from '../hooks/useToast';
import { t } from '../i18n';

export interface FormProps {
  onSubmit: (values: any) => void | Promise<void>;
  children: React.ReactNode | ((formContext: UseFormMethods) => any);
  defaultValues?: any;
  successMessage?: string | ((values: any) => string);
}

const Form: React.FC<FormProps> = ({
  onSubmit,
  children,
  defaultValues,
  successMessage,
}) => {
  const formContext = useForm({
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = formContext;
  const toast = useToast();

  const onSubmitWrapper = async (values: any) => {
    try {
      await onSubmit(values);

      if (successMessage) {
        toast({
          description:
            typeof successMessage === 'string'
              ? successMessage
              : successMessage(values),
          status: 'success',
          isClosable: true,
        });
      }
    } catch (err: Error | any) {
      console.error(err);
      toast({
        description: err?.message || t('Something went wrong'),
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [reset, defaultValues, isSubmitSuccessful]);

  return (
    <FormProvider {...formContext}>
      <form onSubmit={handleSubmit(onSubmitWrapper)}>
        {typeof children === 'function'
          ? (children as any)(formContext)
          : children}
      </form>
    </FormProvider>
  );
};

export default Form;
