import React from 'react';
import Text from '../ui/Text';
import Trend from '../ui/Trend';
import SimpleGrid from '../ui/SimpleGrid';
import { t } from '../i18n';

export interface TimesheetStatProps {
  nominal: number | null;
  planned: number;
  worked: number;
  contractual: number;
  total?: number;
  unit?: string;
}

const TimesheetStat: React.FC<TimesheetStatProps> = ({
  nominal,
  planned,
  worked,
  contractual,
  unit,
  total,
}) => {
  return (
    <SimpleGrid templateColumns="1fr 1fr auto" spacing={2} alignItems="center">
      {nominal !== null && (
        <>
          <Text fontSize="sm">{t('Nominal')}</Text>
          <Text color="gray.400">
            {nominal}
            {unit}
          </Text>
          <div />
        </>
      )}

      <Text fontSize="sm">{t('Contractual')}</Text>
      <Text color="gray.400">
        {contractual}
        {unit}
      </Text>
      <div />

      <Text fontSize="sm">{t('Planned')}</Text>
      <Text color="gray.400">
        {planned}
        {unit}
      </Text>
      <Text textAlign="right">
        <Trend expectedValue={nominal} value={contractual} />
      </Text>

      <Text fontSize="sm">{t('Worked')}</Text>
      <Text color="gray.400">
        {worked}
        {unit}
      </Text>
      <Text textAlign="right">
        <Trend expectedValue={nominal} value={worked} />
      </Text>

      {(total || total === 0) && (
        <>
          <Text fontSize="sm">{t('Total worked')}</Text>
          <Text color="gray.400">
            {total}
            {unit}
          </Text>
          <div />
        </>
      )}
    </SimpleGrid>
  );
};

TimesheetStat.defaultProps = {
  unit: 'h',
};

export default TimesheetStat;
