import React from 'react';
import FormField from './FormField';
import { t } from '../i18n';
import Select from '../ui/Select';
import DateInput from '../ui/DateInput';
import { Recurrence } from '../domain/PaymentSchedule';
import Project, { PricingType, ProjectState } from '../domain/Project';

export interface PaymentScheduleDrawerProps {
  project: Project;
  recurrence: Recurrence;
}

const PaymentScheduleFormFields: React.FC<PaymentScheduleDrawerProps> = ({
  project,
  recurrence,
}) => {
  const disabled = project.state && project.state !== ProjectState.DRAFT;

  return (
    <>
      <FormField
        name="paymentSchedule.recurrence"
        label={t('Client payment schedule')}
      >
        <Select nullable isDisabled={disabled}>
          <option value={Recurrence.ONE_TIME}>{t('One-time')}</option>
          <option value={Recurrence.MONTHLY}>{t('Monthly')}</option>
        </Select>
      </FormField>

      {recurrence && recurrence === Recurrence.ONE_TIME && (
        <FormField
          name="paymentSchedule.dateStart"
          label={
            recurrence === Recurrence.ONE_TIME
              ? t('Payment date')
              : t('Start date')
          }
        >
          <DateInput
            isDisabled={disabled}
            min={
              project.pricingType === PricingType.TIME_AND_MATERIAL &&
              recurrence === Recurrence.ONE_TIME &&
              project?.dateEnd
                ? new Date(new Date().setDate(project?.dateEnd.getDate() + 1))
                : null
            }
          />
        </FormField>
      )}
      {recurrence && recurrence === Recurrence.MONTHLY && (
        <FormField name="paymentSchedule.offsetInMonths" label={t('Interval')}>
          <Select nullable isDisabled={disabled}>
            <option value={0}>{t('Starting from the same month')}</option>
            <option value={1}>{t('Starting from the next month')}</option>
            <option value={2}>{t('Every 2nd month')}</option>
            <option value={3}>{t('Every 3rd month')}</option>
          </Select>
        </FormField>
      )}
    </>
  );
};

export default PaymentScheduleFormFields;
