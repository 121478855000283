import React from 'react';
import Person from '../domain/Person';
import Stack from '../ui/Stack';
import HStack from '../ui/HStack';
import ResponsiveGrid from '../ui/ResponsiveGrid';
import RouterMonthPicker from './RouterMonthPicker';
import useMonthPicker from '../hooks/useMonthPicker';
import Box from '../ui/Box';
import BudgetRow from './BudgetRow';
import BudgetRowCalculatedValues from './BudgetRowCalculatedValues';
import Heading from '../ui/Heading';
import ConnectedPricePopover from '../containers/ConnectedPricePopover';
import { FinanceResult } from '../services/FinanceService';
import { calculateTotals } from '../util/budget';
import NoContent from '../ui/NoContent';
import AutoGrid from '../ui/AutoGrid';
import MenuItem from '../ui/MenuItem';
import PlusIcon from '../ui/icons/PlusIcon';
import MoreActionsMenu from '../ui/MoreActionsMenu';
import { formatDate } from '../util/date';
import Text from '../ui/Text';
import { t } from '../i18n';
import IconButton from '../ui/IconButton';
import { DeleteIcon } from '../ui';
import ConnectedPriceDeleteDrawer from '../containers/ConnectedPriceDeleteDrawer';

export interface PersonBudgetProps {
  person: Person;
  finances: FinanceResult[];
  onPriceChange?: () => void;
}

const PersonBudget: React.FC<PersonBudgetProps> = ({
  person,
  finances,
  onPriceChange,
}) => {
  const month = useMonthPicker();

  const participationFinances = finances.filter(
    ({ participation }) => participation,
  );
  const additionalFinances = finances.filter(
    ({ participation, description }) =>
      !participation && description !== 'Cost for period',
  );

  return (
    <>
      <ConnectedPriceDeleteDrawer
        name={'price.delete'}
        onChange={onPriceChange}
      />
      <HStack justifyContent="center">
        <RouterMonthPicker />
      </HStack>
      <Box py={12}>
        <ResponsiveGrid columns={4} textAlign="center" rowGap={8}>
          <ConnectedPricePopover
            onSuccess={onPriceChange}
            date={month}
            externalId={person.id}
          />
          <BudgetRowCalculatedValues centered {...calculateTotals(finances)} />
        </ResponsiveGrid>
      </Box>
      <Stack spacing={4}>
        {participationFinances.map((result, i) => {
          return (
            <BudgetRow
              hidePersonCost
              hidePositionPrice
              variant="project"
              key={i}
              capacity={result.participation?.capacity}
              costPrognosis={result.costPrognosis}
              revenuePrognosis={result.revenuePrognosis}
              marginPrognosis={result.marginPrognosis}
              person={person}
              personCost={null}
              position={result.projectTag}
              positionPrice={null}
            />
          );
        })}
      </Stack>

      <HStack justifyContent="space-between" mt={8}>
        <Heading size="sm">{t('Additional costs/revenues')}</Heading>
        <MoreActionsMenu label="Additional actions">
          <MenuItem
            icon={<PlusIcon />}
            preserveQuery
            to="?add.additional&additionalType=revenue"
          >
            {t('Add additional revenue')}
          </MenuItem>
          <MenuItem
            icon={<PlusIcon />}
            preserveQuery
            to="?add.additional&additionalType=cost"
          >
            {t('Add additional cost')}
          </MenuItem>
        </MoreActionsMenu>
      </HStack>

      <Stack spacing={6} mt={2}>
        {additionalFinances.length <= 0 && (
          <NoContent>
            {t('No additional revenues or costs in selected period')}
          </NoContent>
        )}
        {additionalFinances.map(
          (
            {
              priceId,
              description,
              revenuePrognosis,
              costPrognosis,
              dateStart,
              dateEnd,
            },
            index,
          ) => {
            return (
              <ResponsiveGrid
                key={index}
                templateColumns="220px 50px 1fr 50px"
                alignItems="center"
                spacing={4}
              >
                <div>
                  {description}
                  <Text fontSize="xs">
                    {`${dateStart && formatDate(dateStart)} ${
                      dateEnd ? ` - ${formatDate(dateEnd)}` : ''
                    }`}
                  </Text>
                </div>
                <div />
                <AutoGrid childWidth="150px" rowGap={2}>
                  <BudgetRowCalculatedValues
                    revenuePrognosis={revenuePrognosis}
                    costPrognosis={costPrognosis}
                  />
                </AutoGrid>
                {priceId && (
                  <IconButton
                    icon={<DeleteIcon />}
                    variant="ghost"
                    tooltip
                    aria-label={`Delete`}
                    to={`?price.delete=${priceId}`}
                  />
                )}
              </ResponsiveGrid>
            );
          },
        )}
      </Stack>
    </>
  );
};

export default PersonBudget;
