import React from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem as ChAccordionItem,
  AccordionPanel,
} from '@chakra-ui/react';
import Box from './Box';

export interface AccordionItemProps {
  title?: string | React.ReactNode;
  disabled?: boolean;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  disabled,
  children,
  title,
}) => {
  return (
    <ChAccordionItem isDisabled={disabled}>
      <Box position="relative">
        {title}
        <Box
          position="absolute"
          right={0}
          top="50%"
          transform="translateY(-50%)"
          zIndex={2}
        >
          <AccordionButton _hover={{ bg: null }}>
            <AccordionIcon />
          </AccordionButton>
        </Box>
      </Box>
      <AccordionPanel>{children}</AccordionPanel>
    </ChAccordionItem>
  );
};

AccordionItem.defaultProps = {};

export default AccordionItem;
