import React from 'react';
import {
  Drawer as ChakraDrawer,
  DrawerProps as ChakraDrawerProps,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import Modal from './Modal';

export interface DrawerProps extends ChakraDrawerProps {
  header?: string | React.ReactNode;
  footer?: string | React.ReactNode;
  variant?: 'drawer' | 'modal';
}

const Drawer: React.FC<DrawerProps> = ({
  children,
  header,
  footer,
  variant = 'drawer',
  ...props
}) => {
  if (variant === 'modal') {
    return (
      <Modal header={header} footer={footer} {...props}>
        {children}
      </Modal>
    );
  }

  return (
    <ChakraDrawer placement="right" blockScrollOnMount={false} {...props}>
      <DrawerOverlay>
        <DrawerContent color="black">
          <DrawerCloseButton />
          {header && <DrawerHeader>{header}</DrawerHeader>}
          <DrawerBody>{children}</DrawerBody>
          {footer && <DrawerFooter>{footer}</DrawerFooter>}
        </DrawerContent>
      </DrawerOverlay>
    </ChakraDrawer>
  );
};

export default Drawer;
