import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes, { Layout } from './routes';
import ConnectedAuthenticatedLayout from './containers/ConnectedAuthenticatedLayout';
import PersonGuard from './components/PersonGuard';
import Home from './views/Home';
import Logout from './views/Logout';
import SignIn from './views/SignIn';
import OrganizationWizard from './views/OrganizationWizard';
import SwitchPerson from './views/SwitchPerson';
import CreateAccount from './views/CreateAccount';
import ConnectedAuthenticationGuard from './containers/ConnectedAuthenticationGuard';
import ForgotPassword from './views/ForgotPassword';
import AcceptInvitation from './views/AcceptInvitation';
import SubscriptionGuard from './components/SubscriptionGuard';
import ErrorBoundary from './components/ErrorBoundary';
import ConnectedProjectFormDrawer from './containers/ConnectedProjectFormDrawer';
import { ProjectType } from './domain/Project';
import ConnectedParticipationDrawer from './containers/ConnectedParticipationDrawer';
import ConnectedRequestFormDrawer from './containers/ConnectedRequestFormDrawer';
import ConnectedPersonFormDrawer from './containers/ConnectedPersonFormDrawer';
import ConnectedManagedOrganizationFormDrawer from './containers/ConnectedManagedOrganizationFormDrawer';
import ConnectedTaskFormDrawer from './containers/ConnectedTaskFormDrawer';

function App() {
  const authenticatedLayoutRoutes = routes.filter(
    (r) => r.layout === Layout.AUTHENTICATED,
  );

  const plainLayoutRoutes = routes.filter((r) => r.layout === Layout.PLAIN);

  return (
    <ErrorBoundary>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/logout" component={Logout} />
        <Route path="/login" component={SignIn} />
        <Route path="/create-account" component={CreateAccount} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/create-organization" component={OrganizationWizard} />
        <Route path="/accept-invitation" component={AcceptInvitation} />
        <Route path="/switch-person/:id" component={SwitchPerson} />
        {plainLayoutRoutes.map((r) => (
          <Route key={r.path} path={r.path} component={r.component} />
        ))}
        <Route path="*">
          <ConnectedAuthenticationGuard>
            <PersonGuard>
              <SubscriptionGuard>
                <ConnectedAuthenticatedLayout
                  routes={authenticatedLayoutRoutes}
                />
                <ConnectedManagedOrganizationFormDrawer name="managed-organization" />
                <ConnectedProjectFormDrawer
                  name="project"
                  type={ProjectType.PROJECT}
                />
                <ConnectedProjectFormDrawer
                  name="event"
                  type={ProjectType.EVENT}
                />
                <ConnectedProjectFormDrawer
                  name="training"
                  type={ProjectType.TRAINING}
                />
                <ConnectedParticipationDrawer
                  name="participation"
                  userIsSelectable
                />
                <ConnectedRequestFormDrawer name="request" />
                <ConnectedTaskFormDrawer name="task" />
                <ConnectedPersonFormDrawer name="person" />
              </SubscriptionGuard>
            </PersonGuard>
          </ConnectedAuthenticationGuard>
        </Route>
      </Switch>
    </ErrorBoundary>
  );
}

export default App;
