import React from 'react';
import IconButton, { IconButtonProps } from '../ui/IconButton';
import DisplayForRole from './DisplayForRole';
import { Role } from '../domain/Person';
import Link from '../ui/Link';
import EditIcon from '../ui/icons/EditIcon';
import { t } from '../i18n';

export interface EditButtonProps
  extends Partial<Pick<IconButtonProps, 'size'>> {
  to: string;
  scope?: Role[];
  label?: string;
  icon?: React.ReactElement;
}

const EditButton: React.FC<EditButtonProps> = ({
  to,
  scope,
  label,
  icon,
  ...iconButtonProps
}) => {
  const button = (
    <Link to={to} preserveQuery>
      <IconButton
        opacity={0.3}
        _hover={{ opacity: 0.4 }}
        variant="ghost"
        aria-label={label}
        icon={icon}
        {...iconButtonProps}
      />
    </Link>
  );

  if (!scope) {
    return button;
  }

  return <DisplayForRole roles={scope}>{button}</DisplayForRole>;
};

EditButton.defaultProps = {
  label: t('Edit'),
  icon: <EditIcon />,
};

export default EditButton;
