import AbstractResourceStore from './AbstractResourceStore';
import PersonSkill from '../domain/PersonSkill';
import PersonSkillService from '../services/PersonSkillService';
import { computed } from 'mobx';
import Tag from '../domain/Tag';
import Person from '../domain/Person';
import PersonStore from './PersonStore';
import AccountStore from './AccountStore';
import { container, injectable, singleton } from 'tsyringe';
import TagStore from './TagStore';
import OrganizationStore from './OrganizationStore';

@singleton()
export default class PersonSkillStore extends AbstractResourceStore<PersonSkill> {
  public constructor(
    private readonly personSkillService: PersonSkillService,
    private readonly organizationStore: OrganizationStore,
    accountStore: AccountStore,
  ) {
    super(personSkillService, accountStore);
  }

  public async loadByPerson(personId: string) {
    await this.setManyEventually(
      this.personSkillService.getAllByPerson(personId),
    );
  }

  public async loadByOrganization(organizationId: string) {
    await this.setManyEventually(
      this.personSkillService.getAllByOrganization(organizationId),
    );
  }

  public async loadByCurrentOrganization() {
    return this.loadByOrganization(
      this.organizationStore.currentOrganizationId,
    );
  }

  protected prepareForStoring(item: PersonSkill): PersonSkill {
    return super.prepareForStoring(
      container.resolve(StoredPersonSkill).fromObject(item),
    );
  }

  public getItemsByPerson(personId: string): PersonSkill[] {
    return this.items.filter((i) => i.personId === personId);
  }

  public async syncPersonSkills(personId: string, personSkills: PersonSkill[]) {
    const currentPersonSkills = this.getItemsByPerson(personId);
    const existingTagIds: string[] = [];

    for (const personSkill of currentPersonSkills) {
      const updatedPersonSkill = personSkills.find(
        (pt) => pt.tagId === personSkill.tagId,
      );

      if (updatedPersonSkill) {
        existingTagIds.push(personSkill.tagId);
        updatedPersonSkill.id = personSkill.id;
        await this.update(updatedPersonSkill);
        continue;
      }

      await this.delete(personSkill.id);
    }

    for (const pt of personSkills) {
      if (existingTagIds.includes(pt.tagId)) {
        continue;
      }

      const personSkill = new PersonSkill();
      personSkill.tagId = pt.tagId;
      personSkill.personId = personId;
      personSkill.level = +pt.level;
      await this.create(personSkill);
    }
  }
}

@injectable()
class StoredPersonSkill extends PersonSkill {
  constructor(
    private readonly personStore: PersonStore,
    private readonly tagStore: TagStore,
  ) {
    super();
  }

  @computed
  get person(): Person {
    if (this.personId && !this._person) {
      return this.personStore.getByIdOrLoad(this.personId);
    }

    return this._person;
  }

  @computed
  get tag(): Tag {
    if (this.tagId && !this._tag) {
      return this.tagStore.getByIdOrLoad(this.tagId);
    }

    return this._tag;
  }
}
