import React, { ReactNode } from 'react';
import MenuItem from './MenuItem';
import { flattenChildren } from '../util/react';

export interface MenuListProps {
  children: ReactNode;
}

const MenuList: React.FC<MenuListProps> = ({ children }) => {
  const flattenedChildren = flattenChildren(children);

  return (
    <>
      {flattenedChildren.map((child, i) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        if ((child.type as React.FC).displayName === 'MenuItem') {
          // Can't use MenuItem.displayName as it will be removed in production build
          return child;
        }

        const Component: React.FC = (props) => React.cloneElement(child, props);

        return (
          <Component key={i}>
            <MenuItem>{(child.props as any).children}</MenuItem>
          </Component>
        );
      })}
    </>
  );
};

export default MenuList;
