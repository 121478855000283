import React from 'react';
import { isFragment } from 'react-is';

export function flattenChildren(
  children: React.ReactElement | React.ReactNode | any,
): React.ReactElement[] {
  const flattened: React.ReactElement[] = [];

  React.Children.forEach(children, (child) => {
    if (child && isFragment(child)) {
      flattened.push(...child.props.children);
    } else if (child) {
      // Ignore empty elements to prevent multiple gap boxes
      flattened.push(child);
    }
  });

  return flattened;
}
