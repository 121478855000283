import React from 'react';
import { FiBookOpen } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface BookIconProps extends IconProps {}

const BookIcon: React.FC<BookIconProps> = ({ ...props }) => {
  return <Icon as={FiBookOpen} {...props} />;
};

export default BookIcon;
