export interface Config {
  apiUrl: string;
  authStrategy: 'unsafe' | 'firebase';
  builtAt: string;
  stripeKey: string;
  firebase?: {
    apiKey: string;
    projectId: string;
    messagingSenderId: string;
    appId: string;
  };
}

const config: Config = (window as any).__CONFIG__ as Config;

if (!config) {
  console.error('No config found');
}

export default config;
