import React from 'react';
import { LinkOverlay as ChakraLinkOverlay } from '@chakra-ui/react';
import Link, { LinkProps } from './Link';

export interface LinkOverlayProps extends LinkProps {}

const LinkOverlay: React.FC<LinkOverlayProps> = ({ ...props }) => {
  return <ChakraLinkOverlay as={Link} {...props} />;
};

LinkOverlay.defaultProps = {};

export default LinkOverlay;
