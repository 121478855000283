import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import TagsSelect, { TagsSelectProps } from '../components/TagsSelect';
import useResolver from '../hooks/useResolver';
import TagStore from '../store/TagStore';
import Tag, { TagType } from '../domain/Tag';
import OrganizationStore from '../store/OrganizationStore';
import useToast from '../hooks/useToast';
import { t } from '../i18n';

export interface ConnectedTagsSelectProps
  extends Omit<TagsSelectProps, 'tags'> {
  type?: TagType;
  context?: string;
  tags?: Tag[];
}

const ConnectedTagsSelect: React.FC<ConnectedTagsSelectProps> = ({
  type,
  context,
  tags: suppliedTags,
  ...props
}) => {
  const tagStore = useResolver(TagStore);
  const organizationStore = useResolver(OrganizationStore);
  const toast = useToast();
  const tags =
    suppliedTags ||
    tagStore.getFilteredItems({
      ...(type && { type }),
      ...(context && { context }),
    });

  useEffect(() => {
    if (!suppliedTags) {
      tagStore.loadByOrganization(organizationStore.currentOrganizationId);
    }
  }, [suppliedTags]);

  const onCreate = type
    ? async (tagName: string) => {
        const tag = new Tag();
        tag.organizationId = organizationStore.currentOrganizationId;
        tag.name = tagName;
        tag.type = type;

        if (context) {
          tag.context = context;
        }

        try {
          const createdTag = await tagStore.create(tag);

          props.onChange &&
            props.onChange(
              [
                ...(props.value || []),
                {
                  label: createdTag.name,
                  value: createdTag.id,
                },
              ],
              null,
            );
        } catch (e: Error | any) {
          toast({
            title: t('Failed to create'),
            description: e?.message,
            status: 'error',
          });
        }
      }
    : null;

  return <TagsSelect tags={tags} onCreate={onCreate} {...props} />;
};

export default observer(ConnectedTagsSelect);
