import useQueryFilter from './useQueryFilter';
import Person, { Role } from '../domain/Person';
import useResolver from './useResolver';
import OrganizationStore from '../store/OrganizationStore';

export default function useFilteredPersons(persons: Person[]) {
  const organizationStore = useResolver(OrganizationStore);
  const personFilter = useQueryFilter('personId');
  const personRoleFilter = useQueryFilter('personRole');
  const disabledPersonsFilter = useQueryFilter('disabledPersons');
  const personPositionFilter = useQueryFilter('position');
  const resourceFilter = useQueryFilter('resource');

  const filteredPersons = persons.filter((p) => {
    if (personFilter && !personFilter.includes(p.id)) {
      return false;
    }

    if (personRoleFilter && !personRoleFilter.includes(p.role)) {
      return false;
    }

    if (!disabledPersonsFilter && p.role === Role.DISABLED) {
      return false;
    }

    if (personPositionFilter && !personPositionFilter.includes(p.position)) {
      return false;
    }

    if (resourceFilter) {
      if (
        p.organizationId === organizationStore.currentOrganizationId &&
        resourceFilter.includes('external')
      ) {
        return false;
      }

      if (
        p.organizationId !== organizationStore.currentOrganizationId &&
        resourceFilter.includes('internal')
      ) {
        return false;
      }
      if (resourceFilter.includes('all')) {
        return true;
      }
    } else if (p.organizationId !== organizationStore.currentOrganizationId) {
      return false;
    }

    return true;
  });

  return {
    persons: filteredPersons,
    personFilter,
    personRoleFilter,
  };
}
