import { injectable } from 'tsyringe';
import HttpService from './HttpService';
import config from '../config';
import AuthenticationService from './AuthenticationService';

@injectable()
export default class ApiService extends HttpService {
  public constructor(private readonly accountService: AuthenticationService) {
    super();
    this.instance.defaults.baseURL = config.apiUrl;

    this.instance.interceptors.request.use(async (config) => {
      const token = await this.accountService.getToken();

      if (token) {
        config.headers.authorization = `Bearer ${token}`;
      }

      if (!config.headers.Accept) {
        config.headers.Accept = 'application/json';
      }

      return config;
    });

    this.instance.interceptors.response.use(undefined, (error) => {
      const { title, detail, message } = error?.response?.data || {};
      error.message = title || detail || message || error.message;

      return Promise.reject(error);
    });
  }
}
