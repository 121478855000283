import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Price, { PriceType } from '../domain/Price';
import useResolver from '../hooks/useResolver';
import PriceStore from '../store/PriceStore';
import OrganizationStore from '../store/OrganizationStore';
import PersonStore from '../store/PersonStore';
import PricePopoverForm from '../components/PricePopoverForm';
import Box from '../ui/Box';
import LabeledPriceValue from '../components/LabeledPriceValue';
import { getCurrencySymbol } from '../util/currency';
import { t } from '../i18n';

export interface ConnectedPositionIncomePopoverProps {
  externalId: string;
  handle?:
    | React.ReactNode
    | ((price: Price, unit: string, disabled: boolean) => React.ReactNode);
  price?: Price;
  date?: Date;
  disabled?: boolean;
  onSuccess?: () => void;
}

const ConnectedPricePopover: React.FC<ConnectedPositionIncomePopoverProps> = ({
  externalId,
  handle,
  price: suppliedPrice,
  date = new Date(),
  disabled = false,
  onSuccess,
}) => {
  const personStore = useResolver(PersonStore);
  const priceStore = useResolver(PriceStore);
  const organizationStore = useResolver(OrganizationStore);

  useEffect(() => {
    if (suppliedPrice === undefined && externalId) {
      priceStore.loadForCurrentOrganizationByExternalId(externalId);
    }
  }, [externalId]);

  if (!externalId) {
    return null;
  }

  const price =
    suppliedPrice || priceStore.getActiveByExternalId(externalId, date);

  return (
    <PricePopoverForm
      disabled={disabled}
      handle={
        typeof handle === 'function'
          ? handle(
              price,
              getCurrencySymbol(organizationStore.currentOrganization.currency),
              disabled,
            )
          : handle
      }
      defaultValues={{
        type: PriceType.HOURLY,
        ...price,
        dateStart: new Date(),
      }}
      onChange={
        personStore.currentPerson?.hasEditPermission() &&
        (async (values) => {
          const newPrice = new Price().fromObject({
            ...values,
            value: +values.value,
            externalId,
            organizationId: organizationStore.currentOrganizationId,
          });

          await priceStore.create(newPrice);

          onSuccess?.();
        })
      }
    />
  );
};

ConnectedPricePopover.defaultProps = {
  handle: (price, unit, disabled) => (
    <Box
      p={2}
      borderRadius="md"
      bg={!disabled ? 'gray.50' : undefined}
      m={-2}
      display="inline-block"
    >
      <LabeledPriceValue
        label={t('Cost')}
        price={price}
        unit={unit}
        defaultType={PriceType.HOURLY}
      />
    </Box>
  ),
};

export default observer(ConnectedPricePopover);
