import React from 'react';
import { Text } from '@chakra-ui/react';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATETIME_FORMAT,
  formatDate,
  formatDatePeriod,
} from '../util/date';
import { isSameDay, isSameMinute } from 'date-fns';

export interface DatePeriodProps {
  dateStart?: Date;
  dateEnd?: Date;
  withTime?: boolean;
}

const DatePeriod: React.FC<DatePeriodProps> = ({
  dateStart,
  dateEnd,
  withTime = false,
}) => {
  let content: string;
  const dateFormat = withTime ? DEFAULT_DATETIME_FORMAT : DEFAULT_DATE_FORMAT;

  const isSame = withTime ? isSameMinute : isSameDay;

  if (dateStart && dateEnd && isSame(dateStart, dateEnd)) {
    content = formatDate(dateStart, dateFormat);
  } else {
    content = formatDatePeriod(dateStart, dateEnd, dateFormat);
  }

  return (
    <Text as="span" fontSize="xs">
      {content}
    </Text>
  );
};

export default DatePeriod;
