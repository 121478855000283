import React from 'react';
import Form from './Form';
import FormField from './FormField';
import Input from '../ui/Input';
import Stack from '../ui/Stack';
import Text from '../ui/Text';
import HStack from '../ui/HStack';
import Heading from '../ui/Heading';
import FormSubmitButton from './FormSubmitButton';
import TextLink from '../ui/TextLink';
import SocialLogin from './SocialLogin';
import { t } from '../i18n';
import useQueryValue from '../hooks/useQueryValue';

export interface LoginFormProps {
  onSubmit: (values: {
    email: string;
    password: string;
  }) => void | Promise<void>;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const redirect = useQueryValue('redirect');
  const queryEmail = useQueryValue('email');

  return (
    <Form onSubmit={onSubmit}>
      {({ watch }) => (
        <Stack spacing={4}>
          <Heading size="sm">{t('Sign in to continue')}</Heading>

          <FormField
            name="email"
            label={t('Email')}
            required
            defaultValue={queryEmail ?? ''}
          >
            <Input
              size="lg"
              type="email"
              placeholder="johndoe@gmail.com"
              autoFocus
            />
          </FormField>

          <FormField
            name="password"
            label={
              <HStack justifyContent="space-between">
                <div>{t('Password')}</div>
                <TextLink tabIndex={1} to="/forgot-password">
                  {t('Forgot password?')}
                </TextLink>
              </HStack>
            }
            required
          >
            <Input size="lg" type="password" placeholder="⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉" />
          </FormField>

          <FormSubmitButton>{t('Sign in')}</FormSubmitButton>
          <Text>
            {t('New on our platform?')}{' '}
            <TextLink
              to={`/create-account?_${
                redirect && `&redirect=${encodeURIComponent(redirect)}`
              }&email=${encodeURIComponent(
                watch('email') ?? queryEmail ?? '',
              )}`}
            >
              {t('Create an account')}
            </TextLink>
          </Text>
          <SocialLogin withDivider />
        </Stack>
      )}
    </Form>
  );
};

export default LoginForm;
