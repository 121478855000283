import AbstractDomain from './AbstractDomain';

export default abstract class AbstractDynamicDomain extends AbstractDomain {
  public dynamic: Record<string, string | number | boolean> = {};

  public fromObject(payload: { [key: string]: any }): this {
    super.fromObject(payload);
    this.dynamic = payload.dynamic;

    return this;
  }

  public toObject<T>(): T | any {
    return {
      ...super.toObject(),
      dynamic: this.dynamic,
    };
  }
}
