import React, { useEffect } from 'react';
import { ProjectState } from '../domain/Project';
import useResolver from '../hooks/useResolver';
import ProjectList, { ProjectListProps } from '../components/ProjectList';
import { observer } from 'mobx-react';
import ProjectStore from '../store/ProjectStore';
import ParticipationStore from '../store/ParticipationStore';
import PersonStore from '../store/PersonStore';
import OrganizationStore from '../store/OrganizationStore';
import ProjectAccessStore, {
  ProjectFilters,
} from '../store/ProjectAccessStore';
import TagStore from '../store/TagStore';
import useQuerySort from '../hooks/useQuerySort';
import orderBy from 'lodash/orderBy';

interface ConnectedProjectListProps
  extends Omit<ProjectListProps, 'type' | 'projects'> {
  filters?: ProjectFilters;
}

const ConnectedProjectList: React.FC<ConnectedProjectListProps> = ({
  filters = {},
  ...props
}) => {
  const projectStore = useResolver(ProjectStore);
  const tagStore = useResolver(TagStore);
  const projectAccessStore = useResolver(ProjectAccessStore);
  const projectParticipationStore = useResolver(ParticipationStore);
  const personStore = useResolver(PersonStore);
  const organizationStore = useResolver(OrganizationStore);
  const { sortItems } = useQuerySort('name');

  if (!filters.organizationId) {
    filters.organizationId = organizationStore.currentOrganizationId;
  }

  useEffect(() => {
    projectStore.loadByOrganization(
      filters.organizationId,
      personStore.currentPerson.hasEditPermission()
        ? undefined
        : ProjectState.PUBLISHED,
    );
    projectParticipationStore.loadByOrganization(filters.organizationId);
    tagStore.loadByOrganization(filters.organizationId);
    filters.relatedOrganizationId &&
      personStore.loadByOrganization(filters.relatedOrganizationId);
  }, [filters.organizationId, filters.personId, filters.relatedOrganizationId]);

  const filteredProjects = sortItems(
    projectAccessStore.getFilteredProjects(filters),
  ).map((project) => {
    if (project.relatedToProjectId) {
      project.relatedToProject = projectStore.getById(
        project.relatedToProjectId,
      );
    }

    return project;
  });

  return (
    <ProjectList
      type={filters.type}
      projects={orderBy(filteredProjects, 'priority', 'desc')}
      editable={personStore.currentPerson.hasEditPermission()}
      participations={projectParticipationStore.items}
      relatedToProjectId={filters?.relatedToProjectId}
      personId={filters?.personId}
      {...props}
    />
  );
};

export default observer(ConnectedProjectList);
