import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Redirect, useLocation } from 'react-router-dom';
import useResolver from '../hooks/useResolver';
import AuthenticationGuard, {
  AuthenticationGuardProps,
} from '../components/AuthenticationGuard';
import AccountStore from '../store/AccountStore';

interface ConnectedAuthenticationGuardProps
  extends Partial<AuthenticationGuardProps> {}

const ConnectedAuthenticationGuard: React.FC<ConnectedAuthenticationGuardProps> =
  ({ forbiddenAction, ...props }) => {
    const accountStore = useResolver(AccountStore);
    const { authenticated } = accountStore;
    const location = useLocation();

    const fullPath = `${location.pathname}${location.search}`;

    useEffect(() => {
      if (authenticated === null) {
        accountStore.checkAuthentication();
      }
    }, [authenticated, accountStore]);

    return (
      <AuthenticationGuard
        authenticated={authenticated}
        loading={authenticated === null}
        forbiddenAction={
          forbiddenAction || (
            <Redirect to={`/login?redirect=${encodeURIComponent(fullPath)}`} />
          )
        }
        {...props}
      />
    );
  };

export default observer(ConnectedAuthenticationGuard);
