import AbstractResourceStore from './AbstractResourceStore';
import ProjectTag from '../domain/ProjectTag';
import ProjectTagService from '../services/ProjectTagService';
import { computed } from 'mobx';
import Project from '../domain/Project';
import Tag, { TagType } from '../domain/Tag';
import { container, injectable, singleton } from 'tsyringe';
import ProjectStore from './ProjectStore';
import TagStore from './TagStore';
import AccountStore from './AccountStore';

@singleton()
export default class ProjectTagStore extends AbstractResourceStore<ProjectTag> {
  public constructor(
    private readonly projectTagService: ProjectTagService,
    accountStore: AccountStore,
  ) {
    super(projectTagService, accountStore);
  }

  public async loadByProject(projectId: string) {
    await this.setManyEventually(
      this.projectTagService.getAllByProject(projectId),
    );
  }

  public async loadByProjects(projectIds: string[]) {
    if (projectIds.length <= 0) {
      return;
    }

    await this.setManyEventually(
      this.projectTagService.getAllByProject(projectIds.join(',')),
    );
  }

  protected prepareForStoring(item: ProjectTag): ProjectTag {
    return super.prepareForStoring(
      container.resolve(StoredProjectTag).fromObject(item),
    );
  }

  public getItemsByProject(projectId: string): ProjectTag[] {
    return this.getFilteredItems({ projectId });
  }

  public getProjectPositions(projectId: string): ProjectTag[] {
    return this.getItemsByProject(projectId).filter(
      (projectTag) => projectTag.tag?.type === TagType.POSITION,
    );
  }

  public getProjectTagsExcludingPositions(projectId: string): ProjectTag[] {
    return this.getItemsByProject(projectId).filter(
      (projectTag) => projectTag.tag?.type !== TagType.POSITION,
    );
  }

  public getProjectPositionsCount(projectId: string): number {
    const positions = this.getProjectPositions(projectId);

    return positions.reduce(
      (acc, current) => acc + (+current.capacity || 0),
      0,
    );
  }

  public getProjectTagByProjectAndTag(
    projectId: string,
    tagId: string,
  ): ProjectTag | null {
    return (
      this.items.find(
        (pt) => pt.projectId === projectId && pt.tagId === tagId,
      ) || null
    );
  }
}

@injectable()
class StoredProjectTag extends ProjectTag {
  constructor(
    private readonly projectStore: ProjectStore,
    private readonly tagStore: TagStore,
  ) {
    super();
  }

  @computed
  get project(): Project {
    if (this.projectId && !this._project) {
      return this.projectStore.getByIdOrLoad(this.projectId);
    }

    return this._project;
  }

  @computed
  get tag(): Tag {
    if (this.tagId && !this._tag) {
      return this.tagStore.getByIdOrLoad(this.tagId);
    }

    return this._tag;
  }
}
