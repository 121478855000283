import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import InvitationForm from '../components/InvitationForm';
import InvitationService from '../services/InvitationService';
import useQueryValue from '../hooks/useQueryValue';
import Invitation from '../domain/Invitation';
import { useHistory } from 'react-router-dom';
import { t } from '../i18n';
import AccountStore from '../store/AccountStore';

export interface ConnectedInvitationFormProps {}

const ConnectedInvitationForm: React.FC<ConnectedInvitationFormProps> = () => {
  const invitationService = useResolver(InvitationService);
  const [invitation, setInvitation] = useState<Invitation>(null);
  const [error, setError] = useState<string>(null);
  const hash = useQueryValue('hash');
  const email = useQueryValue('email');
  const history = useHistory();
  const accountStore = useResolver(AccountStore);

  useEffect(() => {
    if (hash) {
      invitationService
        .getOne(hash)
        .then(setInvitation)
        .catch((error) => {
          switch (error.response?.status) {
            case 410:
              return setError(t('Unfortunately this invitation has expired'));

            case 404:
              return setError(t('Invalid invitation'));
          }

          setError(error.message);
        });
    } else {
      setError(t('Invalid invitation'));
    }
  }, [hash]);

  return (
    <InvitationForm
      onSubmit={async () => {
        await invitationService.accept(hash);
        history.replace(`/switch-person/${invitation.personId}?redirect=/app`);
      }}
      invitation={
        invitation &&
        invitation.fromObject({
          ...invitation,
          email,
        })
      }
      error={error}
      viewerEmail={accountStore.authenticatedUser?.email}
    />
  );
};

export default observer(ConnectedInvitationForm);
