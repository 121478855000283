import React from 'react';
import { FiX } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface CloseIconProps extends IconProps {}

const CloseIcon: React.FC<CloseIconProps> = ({ ...props }) => {
  return <Icon as={FiX} {...props} />;
};

export default CloseIcon;
