import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import ProjectParticipants from '../components/ProjectParticipants';
import ParticipationStore from '../store/ParticipationStore';
import PersonStore from '../store/PersonStore';
import ProjectTagStore from '../store/ProjectTagStore';
import TagStore from '../store/TagStore';
import { TagType } from '../domain/Tag';
import { ParticipationState } from '../domain/Participation';
import { getTagIdsRelatedToProject } from '../util/project';
import ProjectStore from '../store/ProjectStore';
import OrganizationStore from '../store/OrganizationStore';

export interface ConnectedProjectParticipantsProps {
  projectId: string;
  editable?: boolean;
}

const ConnectedProjectParticipants: React.FC<
  ConnectedProjectParticipantsProps
> = ({ projectId, editable }) => {
  const projectStore = useResolver(ProjectStore);
  const projectParticipationStore = useResolver(ParticipationStore);
  const personStore = useResolver(PersonStore);
  const organisationStore = useResolver(OrganizationStore);
  const projectTagStore = useResolver(ProjectTagStore);
  const tagStore = useResolver(TagStore);

  useEffect(() => {
    projectParticipationStore.loadByProject(projectId);
    projectTagStore.loadByProject(projectId);
    personStore.loadCurrentOrganizationPersons();
    organisationStore.loadManagedOrganizations();
  }, [projectId]);

  const participants = projectParticipationStore
    .getItemsByProject(projectId)
    .filter((p) => p.state !== ParticipationState.REJECTED);

  // Assign person to each participation
  participants.forEach((p) => {
    p.person = personStore.getById(p.personId);
  });

  const projectTags = projectTagStore.getItemsByProject(projectId);

  const tagIds = getTagIdsRelatedToProject(projectTags, participants);

  return (
    <ProjectParticipants
      project={projectStore.getByIdOrLoad(projectId)}
      editable={editable}
      positions={[
        ...Array.from(tagIds).map((tagId) => {
          const tag = tagStore.getById(tagId);
          const tagProjectTags = projectTags.filter((t) => t.tagId === tagId);

          if (tag?.type !== TagType.POSITION) {
            return null;
          }

          return {
            id: tag?.id,
            name: tag?.name,
            projectTagId:
              tagProjectTags.length === 1 ? tagProjectTags[0].id : null,
            capacity: tagProjectTags.reduce((a, b) => a + b.capacity, 0),
            participations: participants.filter((p) => p.tagId === tagId),
          };
        }),
        participants.some((p) => !p.tagId)
          ? {
              id: null,
              name: '(Uncategorized)',
              capacity: null,
              participations: participants.filter((p) => !p.tagId),
            }
          : null,
      ].filter(Boolean)}
    />
  );
};

export default observer(ConnectedProjectParticipants);
