import React from 'react';
import { FaLanguage } from 'react-icons/fa';
import Icon, { IconProps } from '../Icon';

export interface LanguageIconProps extends IconProps {}

const LanguageIcon: React.FC<LanguageIconProps> = ({ ...props }) => {
  return <Icon as={FaLanguage} {...props} />;
};

export default LanguageIcon;
