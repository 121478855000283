import React from 'react';
import { FiUser } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface UserIconProps extends IconProps {}

const UserIcon: React.FC<UserIconProps> = ({ ...props }) => {
  return <Icon as={FiUser} {...props} />;
};

export default UserIcon;
