import React from 'react';
import { FiCode } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface CodeIconProps extends IconProps {}

const CodeIcon: React.FC<CodeIconProps> = ({ ...props }) => {
  return <Icon as={FiCode} {...props} />;
};

export default CodeIcon;
