import React from 'react';
import Task, { TaskState } from '../domain/Task';
import {
  Accordion,
  AccordionItem,
  Badge,
  Box,
  DateText,
  Heading,
  HStack,
  NoContent,
  SimpleGrid,
  Text,
} from '../ui';
import ConnectedPersonUser from '../containers/ConnectedPersonUser';
import ConnectedTaskStateSelect from '../containers/ConnectedTaskStateSelect';
import { formatDateTime } from '../util/date';
import { formatDistanceToNow } from 'date-fns';
import ConnectedProjectLink from '../containers/ConnectedProjectLink';
import { humanReadableStates } from './TaskStateSelect';
import MoreActionsMenu from '../ui/MoreActionsMenu';
import { t } from '../i18n';
import MenuItem from '../ui/MenuItem';
import DeleteIcon from '../ui/icons/DeleteIcon';
import EditIcon from '../ui/icons/EditIcon';

export interface TasksProps {
  tasks: Task[];
  showProjectInList?: boolean;
  showAssigneeInList?: boolean;
  editable?: boolean;
}

const Tasks: React.FC<TasksProps> = ({
  tasks,
  showAssigneeInList = true,
  showProjectInList,
  editable,
}) => {
  if (!tasks.length) {
    return <NoContent>No tasks found</NoContent>;
  }

  const states: TaskState[] = [
    TaskState.IN_PROGRESS,
    TaskState.OPEN,
    TaskState.ON_HOLD,
    TaskState.DONE,
  ];

  return (
    <Accordion>
      {states.map((state) => {
        const stateTasks = tasks.filter((task) => task.state === state);

        if (!stateTasks.length) {
          return null;
        }

        return (
          <div>
            <Heading size="xs" my={4}>
              {humanReadableStates[state]}{' '}
              <Badge variant="subtle">{stateTasks.length}</Badge>
            </Heading>
            {stateTasks.map((task) => (
              <AccordionItem
                key={task.id}
                title={
                  <SimpleGrid
                    templateColumns={`1fr auto 100px ${
                      editable ? '40px' : '0px'
                    } 0px`}
                    gap={16}
                    alignItems="center"
                    padding={4}
                  >
                    <span>{task.name}</span>
                    <div>
                      {showAssigneeInList && (
                        <div>
                          {task.personId ? (
                            <ConnectedPersonUser personId={task.personId} />
                          ) : (
                            <Text color="gray">Unassigned</Text>
                          )}
                        </div>
                      )}
                      {showProjectInList && (
                        <div>
                          <ConnectedProjectLink projectId={task.projectId} />
                        </div>
                      )}
                    </div>
                    <ConnectedTaskStateSelect task={task} />
                    {editable && (
                      <MoreActionsMenu label={t('Client list actions')}>
                        <MenuItem
                          icon={<EditIcon />}
                          to={`?task.edit=${task.id}`}
                        >
                          {t('Edit task')}
                        </MenuItem>
                        <MenuItem
                          icon={<DeleteIcon />}
                          to={`?task.delete=${task.id}`}
                        >
                          {t('Delete task')}
                        </MenuItem>
                      </MoreActionsMenu>
                    )}
                  </SimpleGrid>
                }
              >
                <HStack gap={16}>
                  <div>
                    <Text fontSize="small">Reporter</Text>
                    <ConnectedPersonUser personId={task.creatorId} />
                  </div>

                  <div>
                    <Text fontSize="small">Assignee</Text>
                    <ConnectedPersonUser personId={task.personId} />
                  </div>

                  <div>
                    <Text fontSize="small">Project</Text>
                    {task.projectId && (
                      <ConnectedProjectLink projectId={task.projectId} />
                    )}
                  </div>

                  <div>
                    <Text fontSize="small">Created at</Text>
                    <DateText
                      fontSize="xs"
                      color="gray.500"
                      dateTime={formatDateTime(task.createdAt)}
                    >
                      {formatDistanceToNow(task.createdAt, { addSuffix: true })}
                    </DateText>
                  </div>

                  <div>
                    <Text fontSize="small">Updated at</Text>
                    <DateText
                      fontSize="xs"
                      color="gray.500"
                      dateTime={formatDateTime(task.updatedAt)}
                    >
                      {formatDistanceToNow(task.updatedAt, { addSuffix: true })}
                    </DateText>
                  </div>
                </HStack>

                <Box mt={4} maxW={650} style={{ whiteSpace: 'pre-wrap' }}>
                  <Text fontSize="small">Notes</Text>
                  {task.notes || '-'}
                </Box>
              </AccordionItem>
            ))}
          </div>
        );
      })}
    </Accordion>
  );
};

export default Tasks;
