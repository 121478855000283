import React from 'react';
import Project from '../domain/Project';
import Link from '../ui/Link';
import ProjectCard from './ProjectCard';

export interface ProjectLinkProps {
  project: Project;
  variant?: 'plain' | 'card' | 'link';
  children?: React.ReactNode;
}

const ProjectLink: React.FC<ProjectLinkProps> = ({
  project,
  variant,
  children = project.name,
}) => {
  if (variant === 'card') {
    return <ProjectCard project={project} />;
  }

  return <Link to={`/projects/view/${project.id}`}>{children}</Link>;
};

ProjectLink.defaultProps = {
  variant: 'plain',
};

export default ProjectLink;
