import React from 'react';
import { observer } from 'mobx-react';
import RequestFormDrawer, {
  RequestFormDrawerProps,
} from '../components/RequestFormDrawer';
import useResolver from '../hooks/useResolver';
import RequestStore from '../store/RequestStore';
import Request from '../domain/Request';
import { useHistory } from 'react-router-dom';
import PersonStore from '../store/PersonStore';
import useQueryValue from '../hooks/useQueryValue';

export interface ConnectedRequestFormDrawerProps
  extends Omit<RequestFormDrawerProps, 'onSubmit'> {}

const ConnectedRequestFormDrawer: React.FC<ConnectedRequestFormDrawerProps> = ({
  ...props
}) => {
  const personStore = useResolver(PersonStore);
  const requestStore = useResolver(RequestStore);
  const history = useHistory();
  const action = useQueryValue('action');

  const handleSubmit = async (values: Partial<Request>) => {
    const request = new Request().fromObject(values);
    request.personId = personStore.currentPerson.id;

    await requestStore.create(request);
    history.push('/personal/requests');
  };

  return (
    <RequestFormDrawer
      onSubmit={handleSubmit}
      {...props}
      defaultValues={{ action }}
    />
  );
};

export default observer(ConnectedRequestFormDrawer);
