import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import useQueryValue from '../hooks/useQueryValue';
import ParticipationDeleteDrawer from '../components/ParticipationDeleteDrawer';
import PersonStore from '../store/PersonStore';
import ParticipationStore from '../store/ParticipationStore';

export interface ConnectedParticipationDeleteDrawerProps {
  name: string;
}

const ConnectedParticipationDeleteDrawer: React.FC<ConnectedParticipationDeleteDrawerProps> =
  ({ name }) => {
    const participationStore = useResolver(ParticipationStore);
    const personStore = useResolver(PersonStore);
    const participationId = useQueryValue(name);
    const participation = participationId
      ? participationStore.getById(participationId)
      : null;

    if (!participationId || !participation) {
      return null;
    }

    const handleSubmit = async () => {
      await participationStore.delete(participationId);
    };

    const person = personStore.getById(participation.personId);

    return (
      <ParticipationDeleteDrawer
        name={name}
        person={person}
        onSubmit={handleSubmit}
      />
    );
  };

export default observer(ConnectedParticipationDeleteDrawer);
