import React, { FC } from 'react';
import Text from '../../../ui/Text';
import DynamicField from '../domain/DynamicField';

export interface DynamicFieldCardValuesProps {
  dynamic: Record<string, unknown>;
  dynamicFields: DynamicField[];
}

const DynamicFieldCardValues: FC<DynamicFieldCardValuesProps> = ({
  dynamicFields,
  dynamic,
}) => {
  if (!dynamic) {
    return null;
  }

  return (
    <>
      {Object.entries(dynamic).map(([name, value]) => {
        const dynamicField = dynamicFields.find((f) => f.name === name);

        if (!dynamicField) {
          return null;
        }

        return (
          <>
            <Text
              fontSize="sm"
              fontWeight="bold"
              color="brand.500"
              mb={2}
              pt={2}
            >
              {dynamicField.label}
            </Text>
            {Array.isArray(value) ? value.join(', ') : value}
          </>
        );
      })}
    </>
  );
};

export default DynamicFieldCardValues;
