import React from 'react';
import ConnectedAuthenticationGuard from '../containers/ConnectedAuthenticationGuard';
import ConnectedInvitationForm from '../containers/ConnectedInvitationForm';
import ConnectedCleanLayout from '../containers/ConnectedCleanLayout';
import Stack from '../ui/Stack';
import Heading from '../ui/Heading';
import Text from '../ui/Text';
import HStack from '../ui/HStack';
import Button from '../ui/Button';
import { useLocation } from 'react-router-dom';
import useQueryValue from '../hooks/useQueryValue';
import TextLink from '../ui/TextLink';
import { useTranslation } from 'react-i18next';

const AcceptInvitation = () => {
  const location = useLocation();
  const email = useQueryValue('email');
  const fullPath = `${location.pathname}${location.search}`;
  const { t } = useTranslation();

  return (
    <ConnectedAuthenticationGuard
      forbiddenAction={
        <ConnectedCleanLayout>
          <Stack spacing={4}>
            <Heading size="md">
              {t("You've been invited to join organization in nopilot")}
            </Heading>
            <Text mb={6}>
              {t('Sign in or create an account to accept invitation')}
            </Text>
            <HStack justifyContent="center" spacing={6}>
              <Button
                to={`/create-account?redirect=${encodeURIComponent(fullPath)}${
                  email ? `&email=${email}` : ''
                }`}
                colorScheme="brand"
              >
                {t('Create account')}
              </Button>
              <TextLink
                to={`/login?redirect=${encodeURIComponent(fullPath)}${
                  email ? `&email=${email}` : ''
                }`}
              >
                {t('Sign in')}
              </TextLink>
            </HStack>
          </Stack>
        </ConnectedCleanLayout>
      }
    >
      <ConnectedCleanLayout>
        <ConnectedInvitationForm />
      </ConnectedCleanLayout>
    </ConnectedAuthenticationGuard>
  );
};

export default AcceptInvitation;
