import Person from '../domain/Person';
import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';

@injectable()
export default class PersonService
  extends AbstractRestfulHttpService<Person>
  implements PersonService
{
  public constructor(private readonly apiService: ApiService) {
    super(Person, apiService);
  }

  public async getAllRelatedToUser(): Promise<Person[]> {
    return this.getMany({ relatedToUser: true });
  }

  public async getAllByOrganization(organizationId: string): Promise<Person[]> {
    return this.getMany({ organizationId });
  }

  public async getAllByOrganizationWithRelatedOrganizations(
    organizationId: string,
  ): Promise<Person[]> {
    return this.getMany({
      organizationId,
      includeRelatedOrganizationsPersons: true,
    });
  }
}
