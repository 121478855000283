import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';
import Task from '../domain/Task';

@injectable()
export default class TaskService extends AbstractRestfulHttpService<Task> {
  public constructor(apiService: ApiService) {
    super(Task, apiService);
  }

  public getAllByPerson(personId: string): Promise<Task[]> {
    return this.getMany({ personId });
  }

  public getAllByCreator(creatorId: string): Promise<Task[]> {
    return this.getMany({ creatorId });
  }

  public getAllByProject(projectId: string): Promise<Task[]> {
    return this.getMany({ projectId });
  }
}
