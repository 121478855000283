import React from 'react';
import useResolver from '../hooks/useResolver';
import RejectButton from '../ui/RejectButton';
import RequestStore from '../store/RequestStore';
import { t } from '../i18n';

interface ParticipationRejectButtonProps {
  requestId: string;
}

const ConnectedRequestRejectButton: React.FC<
  ParticipationRejectButtonProps
> = ({ requestId }) => {
  const requestStore = useResolver(RequestStore);

  return (
    <RejectButton
      onClick={() => requestStore.cancelRequest(requestId)}
      aria-label={t('Cancel')}
    />
  );
};

export default ConnectedRequestRejectButton;
