import Resource from '../decorators/Resource';
import AbstractDomain from './AbstractDomain';
import { toDate } from '../util/date';

export enum SubscriptionState {
  TRIAL = 'TRIAL',
  INCOMPLETE = 'INCOMPLETE',
  INCOMPLETE_EXPIRED = 'INCOMPLETE_EXPIRED',
  PAST_DUE = 'PAST_DUE',
  CANCELED = 'CANCELED',
  UNPAID = 'UNPAID',
  ACTIVE = 'ACTIVE',
  UNKNOWN = 'UNKNOWN',
}

@Resource.Name('subscriptions')
export default class Subscription extends AbstractDomain {
  public state: SubscriptionState;
  public trialEndAt: Date;
  public organizationId: string;
  public price: number;
  public currency: string;
  public quantity: number;

  fromObject(payload: Partial<Subscription>): this {
    super.fromObject(payload);

    this.state = payload.state;
    this.trialEndAt = toDate(payload.trialEndAt);
    this.organizationId = payload.organizationId;
    this.quantity = payload.quantity;
    this.price = payload.price;
    this.currency = payload.currency;

    return this;
  }

  toObject(): Partial<Subscription> {
    return {
      ...super.toObject(),
      state: this.state,
      validTo: this.trialEndAt,
      organizationId: this.organizationId,
      price: this.price,
      currency: this.currency,
      quantity: this.quantity,
    };
  }

  public isActive(): boolean {
    return [SubscriptionState.ACTIVE, SubscriptionState.TRIAL].includes(
      this.state,
    );
  }
}
