import { injectable } from 'tsyringe';
import ApiService from './ApiService';
import { toISO } from '../util/date';
import Participation from '../domain/Participation';

export interface Timesheet {
  participation: {
    id: string;
    personId: string;
    projectId: string;
    tagId?: string;
  };
  entries: Array<{
    date: string;
    loggedTimeInSeconds: number;
    predictedTimeInSeconds: number;
    timeInSeconds: number;
    workday?: boolean;
  }>;
  taskEntries?: {
    [id: string]: Array<{
      date: string;
      loggedTimeInSeconds: number;
      predictedTimeInSeconds: number;
      timeInSeconds: number;
      workday?: boolean;
    }>;
  };
}

@injectable()
export default class TimesheetService {
  public constructor(private readonly apiService: ApiService) {}

  public async getDaily({
    organizationId,
    projectId,
    personId,
    dateEnd,
    dateStart,
    includeRelatedOrganizationsPersons,
  }: {
    organizationId: string;
    projectId?: string | string[];
    personId?: string | string[];
    dateStart: Date;
    dateEnd: Date;
    includeRelatedOrganizationsPersons?: boolean;
  }) {
    const { data } = await this.apiService.get<Timesheet[]>(
      '/timesheets/daily',
      {
        params: {
          organizationId,
          projectId: projectId?.toString(),
          personId: personId?.toString(),
          dateStart: toISO(dateStart),
          dateEnd: toISO(dateEnd),
          includeRelatedOrganizationsPersons,
        },
      },
    );

    return data.map(({ participation, ...values }) => ({
      ...values,
      participation: new Participation().fromObject(participation),
    }));
  }
}
