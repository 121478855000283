import React, { ForwardedRef } from 'react';
import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react';

export interface InputProps extends ChakraInputProps {
  blurOnEnter?: boolean;
}

const Input = React.forwardRef(
  ({ blurOnEnter, ...props }: InputProps, ref: ForwardedRef<any>) => {
    if (blurOnEnter) {
      props.onKeyUp = (e) => {
        e.preventDefault();
        if (e.key === 'Enter') {
          (e.target as any)?.blur();
        }
      };
    }

    return <ChakraInput ref={ref} {...props} />;
  },
);

Input.defaultProps = {};

export default Input;
