import DynamicField from './domain/DynamicField';
import orderBy from 'lodash/orderBy';

export function isConflicting(
  field: DynamicField,
  existingFields: DynamicField[],
) {
  return existingFields.some((existingField) => {
    if (existingField.id === field.id) {
      return false;
    }

    if (existingField.name !== field.name) {
      return false;
    }

    return field.tags.some((tag) => existingField.tags.includes(tag));
  });
}

export function alphabeticallyOrderedFields(
  fields: DynamicField[],
): DynamicField[] {
  return orderBy(fields, 'name');
}
