import { ResourceId } from '../types';
import RestfulService from './RestfulService';
import AbstractDomain from '../domain/AbstractDomain';
import Resource from '../decorators/Resource';
import HttpService from './HttpService';

export default abstract class AbstractRestfulHttpService<
  T extends AbstractDomain,
> implements RestfulService<T>
{
  protected constructor(
    protected readonly domain: new () => T,
    protected readonly client: HttpService,
  ) {}

  protected get resource(): string {
    return Resource.getName(this.domain);
  }

  public async getMany(params: object = {}): Promise<T[]> {
    const { data } = await this.client.get<Partial<T>[]>(`/${this.resource}`, {
      params,
    });

    return data.map((d) => this.format(d));
  }

  public async getOne(id: ResourceId, params: object = {}): Promise<T> {
    const { data } = await this.client.get<Partial<T>>(
      `/${this.resource}/${id}`,
      {
        params,
      },
    );

    return this.format(data);
  }

  public async update(id: ResourceId, item: Partial<T>): Promise<T> {
    const { data } = await this.client.patch<Partial<T>>(
      `/${this.resource}/${item.id}`,
      item,
    );

    return this.format(data);
  }

  public async create(item: Omit<Partial<T>, 'id'>): Promise<T> {
    const { data } = await this.client.post<Partial<T>>(
      `/${this.resource}`,
      item,
    );

    return this.format(data);
  }

  public async delete(id: ResourceId): Promise<void> {
    await this.client.delete(`/${this.resource}/${id}`);
  }

  protected format(data: Partial<T>) {
    return new this.domain().fromObject(data);
  }
}
