import React from 'react';
import { FaUserTag } from 'react-icons/fa';
import Icon, { IconProps } from '../Icon';

export interface UserTagIconProps extends IconProps {}

const UserTagIcon: React.FC<UserTagIconProps> = ({ ...props }) => {
  return <Icon as={FaUserTag} {...props} />;
};

export default UserTagIcon;
