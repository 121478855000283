import React, { Suspense } from 'react';
import Stack from '../ui/Stack';
import DrawerForm from './DrawerForm';
import { t } from '../i18n';

const CreditCardField = React.lazy(() => import('./CreditCardField'));

export interface OrganizationEditFormProps {
  onSubmit: (data: any) => void;
}

const OrganizationPaymentFormDrawer: React.FC<OrganizationEditFormProps> = ({
  onSubmit,
}) => {
  return (
    <DrawerForm
      header={<>{t('Add card')}</>}
      name="add.card"
      onSubmit={onSubmit}
      successMessage={t('Card added successfully')}
      submitButton={{ text: 'Add' }}
    >
      <Stack spacing={4}>
        <Suspense fallback={null}>
          <CreditCardField />
        </Suspense>
      </Stack>
    </DrawerForm>
  );
};

export default OrganizationPaymentFormDrawer;
