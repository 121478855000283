import React from 'react';
import Heading from '../ui/Heading';
import Input from '../ui/Input';
import Stack from '../ui/Stack';
import Text from '../ui/Text';
import FormField from './FormField';
import TextLink from '../ui/TextLink';
import FormSubmitButton from './FormSubmitButton';
import Form from './Form';
import SocialLogin from './SocialLogin';
import { isEmail } from '../util/string';
import Button from '../ui/Button';
import HStack from '../ui/HStack';
import CheckIcon from '../ui/icons/CheckIcon';
import Link from '../ui/Link';
import { t } from '../i18n';
import useQueryValue from '../hooks/useQueryValue';

export interface CreateAccountFormResult {
  email: string;
  password: string;
}

export interface CreateAccountFormProps {
  onSubmit: (values: CreateAccountFormResult) => void | Promise<void>;
  submitted?: Omit<CreateAccountFormResult, 'password'> | false;
  redirectUrl?: string;
}

const CreateAccountForm: React.FC<CreateAccountFormProps> = ({
  onSubmit,
  submitted,
  redirectUrl,
}) => {
  const queryEmail = useQueryValue('email');

  if (submitted) {
    return (
      <div>
        {t('An email has been sent to')} <strong>{submitted.email}</strong>{' '}
        {t('with a link to activate your account')}
        <HStack justifyContent="center" mt={6}>
          <Link to={redirectUrl || '/'} external newWindow={false}>
            <Button leftIcon={<CheckIcon />} colorScheme="brand">
              {t('I have clicked the link')}
            </Button>
          </Link>
        </HStack>
      </div>
    );
  }

  return (
    <Form onSubmit={onSubmit}>
      {({ watch }) => (
        <Stack spacing={4}>
          <Heading size="sm">Create new account</Heading>
          <FormField
            name="email"
            label={t('Email')}
            required
            validate={(value) => isEmail(value) || 'Invalid email'}
            defaultValue={queryEmail ?? ''}
          >
            <Input
              size="lg"
              type="email"
              placeholder="johndoe@gmail.com"
              autoFocus
            />
          </FormField>
          <FormField
            name="password"
            label={t('Password')}
            required
            minLength={6}
          >
            <Input size="lg" type="password" placeholder="⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉" />
          </FormField>
          <FormField
            name="password2"
            label="Confirm password"
            required
            validate={(value, getValues) => {
              return value === getValues().password || 'Passwords do not match';
            }}
          >
            <Input size="lg" type="password" placeholder="⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉" />
          </FormField>
          <FormSubmitButton>{t('Sign Up')}</FormSubmitButton>
          <Text>
            {t('Already have an account?')}{' '}
            <TextLink
              to={`/login?_${
                redirectUrl
                  ? `&redirect=${encodeURIComponent(redirectUrl)}`
                  : ''
              }&email=${encodeURIComponent(
                watch('email') ?? queryEmail ?? '',
              )}`}
            >
              {t('Sign in instead')}
            </TextLink>
          </Text>
          <SocialLogin withDivider />
        </Stack>
      )}
    </Form>
  );
};

export default CreateAccountForm;
