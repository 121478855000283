import React from 'react';
import { FiRepeat } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface SwitchIconProps extends IconProps {}

const SwitchIcon: React.FC<SwitchIconProps> = ({ ...props }) => {
  return <Icon as={FiRepeat} {...props} />;
};

export default SwitchIcon;
