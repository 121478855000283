import React from 'react';
import SimpleGrid from '../ui/SimpleGrid';
import { TagType } from '../domain/Tag';
import TagWithIcon from './TagWithIcon';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import Stack from '../ui/Stack';
import MoreItems from '../ui/MoreItems';

export interface TagsGridTag {
  name: string;
  icon?: any;
  type?: TagType;
}

export interface TagsGridProps {
  tags: TagsGridTag[];
  compact?: boolean;
}

const TagsGrid: React.FC<TagsGridProps> = ({ tags, compact }) => {
  const sortedTags = orderBy(tags, 'name');
  const grouped = groupBy(sortedTags, 'type');
  const groupedTypes = [TagType.POSITION, TagType.LOCATION, TagType.LANGUAGE];

  return (
    <SimpleGrid columns={2} spacingX={4}>
      <Stack spacing={0}>
        <MoreItems limit={compact ? 6 : 50}>
          {sortedTags.map(
            (tag) =>
              !groupedTypes.includes(tag.type) && (
                <TagWithIcon key={tag.name} {...tag} />
              ),
          )}
        </MoreItems>
      </Stack>
      <Stack spacing={0}>
        {groupedTypes.map(
          (type) =>
            grouped[type]?.length > 0 && (
              <TagWithIcon
                key={type}
                type={type}
                icon={
                  grouped[type]?.length === 1 ? grouped[type][0].icon : null
                }
                name={grouped[type].map((tag) => tag.name).join(', ')}
              />
            ),
        )}
      </Stack>
    </SimpleGrid>
  );
};

export default TagsGrid;
