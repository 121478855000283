import AbstractResourceStore from './AbstractResourceStore';
import Invoice from '../domain/Invoice';
import InvoiceService from '../services/InvoiceService';
import AccountStore from './AccountStore';
import { singleton } from 'tsyringe';
import OrganizationStore from './OrganizationStore';
import { computed } from 'mobx';

@singleton()
export default class InvoiceStore extends AbstractResourceStore<Invoice> {
  public constructor(
    private readonly invoiceService: InvoiceService,
    private readonly organizationStore: OrganizationStore,
    accountStore: AccountStore,
  ) {
    super(invoiceService, accountStore);
  }

  public async loadByOrganization(organizationId: string) {
    await this.setManyEventually(
      this.invoiceService.getAllByOrganization(organizationId),
    );
  }

  public async loadByCurrentOrganization() {
    return this.loadByOrganization(
      this.organizationStore.currentOrganizationId,
    );
  }

  public getItemsByOrganization(organizationId: string): Invoice[] {
    return this.getFilteredItems({ organizationId });
  }

  @computed
  public get currentOrganizationItems(): Invoice[] {
    return this.getItemsByOrganization(
      this.organizationStore.currentOrganizationId,
    );
  }
}
