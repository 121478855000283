import 'reflect-metadata'; // Necessary for dependency injection
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Providers from './Providers';
import './container.ts';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
);
