import React, { useState } from 'react';
import orderBy from 'lodash/orderBy';
import Person, { PersonState, Role } from '../domain/Person';
import ConnectedPersonDeleteDrawer from '../containers/ConnectedPersonDeleteDrawer';
import ConnectedInvitationFormDrawer from '../containers/ConnectedInvitationFormDrawer';
import AddUserIcon from '../ui/icons/AddUserIcon';
import PersonUser from './PersonUser';
import CapacityLabel from './CapacityLabel';
import PersonRole from './PersonRole';
import PersonStateBadge, { getHumanReadableState } from './PersonStateBadge';
import MoreActionsMenu from '../ui/MoreActionsMenu';
import { MenuItem } from '../ui/Menu';
import EditIcon from '../ui/icons/EditIcon';
import EnvelopeIcon from '../ui/icons/EnvelopeIcon';
import PlaneIcon from '../ui/icons/PlaneIcon';
import DeleteIcon from '../ui/icons/DeleteIcon';
import CopyToClipboard from '../ui/CopyToClipboard';
import Invitation from '../domain/Invitation';
import { t } from '../i18n';
import { CloseIcon, DataGrid } from '../ui';
import ConnectedPersonDeactivateDrawer from '../containers/ConnectedPersonDeactivateDrawer';
import ConnectedPersonFormDrawer from '../containers/ConnectedPersonFormDrawer';

export interface OrganizationPersonListProps {
  persons: Person[];
  editable?: boolean;
  currentPerson: Person;
  title?: string;
  compact?: boolean;
  getPersonInvitation: (id: string) => Invitation | null;
  createInvitationForPerson: (id: string) => Promise<Invitation>;
  organizationId?: string;
  status?: any;
}

const OrganizationPersonList: React.FC<OrganizationPersonListProps> = ({
  editable,
  persons: initialPersons,
  currentPerson,
  title = t('List of users'),
  compact,
  getPersonInvitation,
  createInvitationForPerson,
  organizationId,
  status,
}) => {
  const persons = orderBy(initialPersons, [
    (person) => person.isDisabled(),
    'role',
    'name',
  ]);
  const [copyToClipboard, setCopyToClipboard] = useState<string>(null);

  return (
    <div>
      {editable && (
        <>
          <ConnectedPersonFormDrawer
            name="person.add"
            organizationId={organizationId}
            simple={!currentPerson.hasPermission(Role.ADMIN)}
          />
          <ConnectedPersonDeactivateDrawer name="person.deactivate" />
          <ConnectedPersonDeleteDrawer name="person.delete" />
          <ConnectedInvitationFormDrawer name="person.invite" />
        </>
      )}
      <DataGrid
        title={title}
        data={persons}
        filters={[
          {
            key: 'role',
            label: t('Access'),
            apply: (value, person) => person.role === value,
            options: [
              { label: t('Admin'), value: Role.ADMIN },
              { label: t('User'), value: Role.USER },
            ],
          },
          {
            key: 'capacity',
            label: t('Capacity'),
            apply: (value, person) => person.capacity >= value,
            options: [
              { label: '100%', value: 1 },
              { label: '>80%', value: 0.8 },
              { label: '>50%', value: 0.5 },
            ],
          },
          {
            key: 'status',
            label: t('Status'),
            apply: (value, person) => {
              const invitation = getPersonInvitation(person.id);
              return person.getState(invitation) === value;
            },
            options: [
              { label: 'Active', value: PersonState.ACTIVE },
              {
                label: 'Invitation expired',
                value: PersonState.INVITATION_EXPIRED,
              },
              { label: 'Not invited', value: PersonState.UNINVITED },
              { label: 'Inactive', value: PersonState.INACTIVE },
            ],
          },
        ]}
        actions={[
          {
            label: 'Add user',
            icon: <AddUserIcon />,
            to: '?person.add',
          },
        ]}
        fields={{
          name: {
            title: t('Name'),
            accessor: (person) => ({
              value: person.name,
              node: (
                <PersonUser hideOrganization linkToProfile person={person} />
              ),
            }),
          },
          capacity: {
            title: t('Capacity'),
            accessor: (p) => ({
              value: p.capacity,
              node: <CapacityLabel capacity={p.capacity} />,
            }),
            hidden: compact,
          },
          email: {
            title: t('Email'),
            accessor: (p) => p.contactEmail || p.userId,
          },
          phone: {
            title: t('Phone'),
            accessor: (p) => p.contactPhone,
            hidden: !compact,
          },
          role: {
            title: t('Access'),
            accessor: (p) => ({
              value: p.role,
              node: <PersonRole person={p} />,
            }),
          },
          status: {
            title: t('Status'),
            accessor: (p) => ({
              value: getHumanReadableState(
                p.getState(getPersonInvitation(p.id)),
              ),
              node: (
                <PersonStateBadge
                  state={p.getState(getPersonInvitation(p.id))}
                />
              ),
            }),
          },
          actions: {
            title: '',
            hiddenInExport: true,
            accessor: (item) => {
              const invitation = getPersonInvitation(item.id);

              return (
                <>
                  <MoreActionsMenu label={`${item.name} ${t('actions')}`}>
                    {editable && (
                      <MenuItem icon={<EditIcon />} to={`?person=${item.id}`}>
                        {t('Edit')}
                      </MenuItem>
                    )}
                    {editable && !item.userId && (
                      <MenuItem
                        icon={<EnvelopeIcon />}
                        to={`?person.invite=${item.id}`}
                      >
                        {invitation ? t('Reinvite') : t('Invite')}
                      </MenuItem>
                    )}
                    {editable && !item.userId && !invitation && (
                      <MenuItem
                        icon={<PlaneIcon />}
                        onClick={() =>
                          createInvitationForPerson(item.id).then((inv) => {
                            setCopyToClipboard(inv.getAcceptURL());
                          })
                        }
                      >
                        {t('Create invitation link')}
                      </MenuItem>
                    )}
                    {editable && !item.userId && invitation && (
                      <MenuItem
                        icon={<PlaneIcon />}
                        onClick={() =>
                          setCopyToClipboard(invitation.getAcceptURL(true))
                        }
                      >
                        {t('Copy invitation link')}
                      </MenuItem>
                    )}
                    {editable &&
                      item.id !== currentPerson.id &&
                      !item.isDisabled() && (
                        <MenuItem
                          icon={<CloseIcon />}
                          to={`?person.deactivate=${item.id}`}
                        >
                          {t('Deactivate')}
                        </MenuItem>
                      )}
                    {editable && item.id !== currentPerson.id && (
                      <MenuItem
                        icon={<DeleteIcon />}
                        to={`?person.delete=${item.id}`}
                      >
                        {t('Delete')}
                      </MenuItem>
                    )}
                  </MoreActionsMenu>
                </>
              );
            },
          },
        }}
      />
      {copyToClipboard && (
        <CopyToClipboard
          value={copyToClipboard}
          key={copyToClipboard}
          instant
          text="Invitation link has been copied to your clipboard"
        />
      )}
    </div>
  );
};

export default OrganizationPersonList;
