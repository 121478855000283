import React from 'react';
import FullPageLoader from '../ui/FullPageLoader';

export interface AuthenticationGuardProps {
  authenticated: boolean;
  forbiddenAction?: React.ReactNode;
  loading: boolean;
}

const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({
  children,
  forbiddenAction,
  authenticated,
  loading,
}) => {
  if (loading) {
    return <FullPageLoader />;
  }

  if (!authenticated) {
    return <>{forbiddenAction}</>;
  }

  return <>{children}</>;
};

export default AuthenticationGuard;
