import React, { VFC } from 'react';
import ConnectedOrganizationDynamicFields from './ConnectedOrganizationDynamicFields';
import ConnectedDynamicFieldForm from './ConnectedDynamicFieldForm';
import ConnectedDynamicFieldDeleteForm from './ConnectedDynamicFieldDeleteForm';

interface DynamicFieldsManagerProps {
  organizationId: string;
  editable?: boolean;
}

const DynamicFieldsManager: VFC<DynamicFieldsManagerProps> = ({
  organizationId,
  editable,
}) => {
  return (
    <>
      <ConnectedOrganizationDynamicFields
        organizationId={organizationId}
        editable={editable}
      />
      {editable && (
        <ConnectedDynamicFieldForm organizationId={organizationId} />
      )}
      {editable && <ConnectedDynamicFieldDeleteForm />}
    </>
  );
};

export default DynamicFieldsManager;
