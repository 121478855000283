import Price, { PriceType } from '../domain/Price';
import { getAllDaysWithinDates } from './date';
import mean from 'lodash/mean';
import { t } from '../i18n';

export function addPriceSuffix(
  text: string,
  type: PriceType,
  compact?: boolean,
) {
  const suffix = getPriceSuffix(type, compact);

  return suffix ? `${text} ${suffix}` : text;
}

export function getPriceSuffix(type: PriceType, compact?: boolean) {
  switch (type) {
    case PriceType.FIXED:
      return '';

    case PriceType.HOURLY:
      return compact ? t('/h') : t('per hour');

    case PriceType.MONTHLY:
      return compact ? t('/mo') : t('per month');
  }

  return '';
}

export function getActivePrice(
  prices: Price[],
  date: Date = new Date(),
): Price | null {
  const activePrices = prices.filter(
    (price) => price.isRecurring() && price.isActive(date),
  );

  if (activePrices.length === 0) {
    return null;
  }

  if (activePrices.length === 1) {
    return activePrices[0];
  }

  // multiple prices? return latest created
  return activePrices.sort((a, b) => +b.createdAt - +a.createdAt)[0];
}

export function getMeanPriceForPeriod(
  prices: Price[],
  dateStart: Date,
  dateEnd: Date,
): Price | null {
  const activePrices = prices.filter((price) =>
    price.isBetweenDates(dateStart, dateEnd),
  );

  if (!activePrices.length) {
    return null;
  }

  const valuesByDay = getAllDaysWithinDates(dateStart, dateEnd).map(
    (date) => getActivePrice(activePrices, date)?.value || 0,
  );

  const result = new Price().fromObject({
    ...(getActivePrice(activePrices, dateStart) || {}),
  });
  result.value = +mean(valuesByDay).toFixed(2);

  return result;
}

export function getHumanReadablePriceType(type: PriceType) {
  switch (type) {
    case PriceType.FIXED:
      return t('one time cost');
    case PriceType.ONCE_A_MONTH:
      return t('monthly cost');
    default:
      return t('Cost');
  }
}
