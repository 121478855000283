import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useQueryValue from '../hooks/useQueryValue';
import Drawer, { DrawerProps } from '../ui/Drawer';

export interface DrawerContext {
  value: string;
  handleClose: () => void;
}

export interface RouterDrawerProps
  extends Partial<Pick<DrawerProps, 'size' | 'header' | 'footer' | 'variant'>> {
  name: string;
  children?: ((drawerContext: DrawerContext) => any) | React.ReactNode;
}

const RouterDrawer: React.FC<RouterDrawerProps> = ({
  name,
  children,
  ...props
}) => {
  const location = useLocation();
  const history = useHistory();
  const value = useQueryValue(name);
  const isOpen = value !== null;

  const handleClose = () => {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.delete(name);

    history.replace(`?${urlSearchParams.toString()}`);
  };

  return (
    <Drawer isOpen={isOpen} onClose={handleClose} {...props}>
      {typeof children === 'function'
        ? (children as any)({ value, handleClose })
        : children}
    </Drawer>
  );
};

export default RouterDrawer;
