import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import useQueryValue from '../hooks/useQueryValue';
import PersonDeleteDrawer, {
  PersonDeleteDrawerProps,
} from '../components/PersonDeleteDrawer';
import PersonStore from '../store/PersonStore';
import ProjectStore from '../store/ProjectStore';
import ParticipationStore from '../store/ParticipationStore';

export interface ConnectedPersonDeleteDrawerProps {
  name: string;
}

const ConnectedPersonDeleteDrawer: React.FC<
  ConnectedPersonDeleteDrawerProps
> = ({ name }) => {
  const personStore = useResolver(PersonStore);
  const projectStore = useResolver(ProjectStore);
  const participationStore = useResolver(ParticipationStore);
  const personId = useQueryValue(name);

  useEffect(() => {
    if (personId) {
      participationStore.loadByPerson(personId);
    }
  }, [personId]);

  if (!personId) {
    return null;
  }

  const handleSubmit: PersonDeleteDrawerProps['onSubmit'] = async ({
    endParticipations = {},
  }) => {
    const endParticipationIds = Object.keys(endParticipations).filter(
      (participationId) => !!endParticipations[participationId],
    );

    await participationStore.endParticipations(endParticipationIds);
    await personStore.delete(personId);
  };

  const person = personStore.getById(personId);

  return (
    <PersonDeleteDrawer
      participations={participationStore
        .getItemsByPerson(personId)
        .map((participation) => {
          participation.project = projectStore.getByIdOrLoad(
            participation.projectId,
          );

          return participation;
        })}
      name={name}
      person={person}
      onSubmit={handleSubmit}
    />
  );
};

export default observer(ConnectedPersonDeleteDrawer);
