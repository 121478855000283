import React from 'react';
import useResolver from '../hooks/useResolver';
import TagWithIcon from '../components/TagWithIcon';
import { observer } from 'mobx-react';
import TagStore from '../store/TagStore';

interface ConnectedTagWithIconProps {
  tagId: string;
}

const ConnectedTagWithIcon: React.FC<ConnectedTagWithIconProps> = ({
  tagId,
}) => {
  const tagStore = useResolver(TagStore);
  const tag = tagStore.getByIdOrLoad(tagId);

  if (!tag) {
    return null;
  }

  return <TagWithIcon name={tag.name} type={tag.type} />;
};

export default observer(ConnectedTagWithIcon);
