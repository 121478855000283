import React from 'react';
import Input from '../ui/Input';
import Stack from '../ui/Stack';
import FormField from './FormField';
import Tag, { TagType } from '../domain/Tag';
import { observer } from 'mobx-react';
import DrawerForm from './DrawerForm';
import TagTypeSelect from './TagTypeSelect';
import { t } from '../i18n';

export interface TagFormDrawerProps {
  name?: string;
  onSubmit: (values: Partial<Tag>) => void | Promise<void>;
  tag?: Tag | Partial<Tag>;
}

const TagFormDrawer: React.FC<TagFormDrawerProps> = ({
  name,
  onSubmit,
  tag,
}) => {
  return (
    <DrawerForm
      name={name}
      onSubmit={onSubmit}
      header={
        <>
          {tag?.id ? t('Edit') : t('Add')} {t('skill')}
        </>
      }
      defaultValues={tag || {}}
    >
      {({ formContext: { watch } }) => (
        <Stack spacing={4}>
          <FormField name="name" label={t('Name')} required>
            <Input autoFocus={!tag?.id} />
          </FormField>

          <FormField
            name="type"
            label={t('Type')}
            defaultValue={TagType.POSITION}
          >
            <TagTypeSelect placement="bottom-start" />
          </FormField>

          {watch('type') === TagType.CUSTOM && (
            <FormField name="context" label={t('Context')}>
              <Input />
            </FormField>
          )}
        </Stack>
      )}
    </DrawerForm>
  );
};

TagFormDrawer.defaultProps = {
  name: 'tag',
};

export default observer(TagFormDrawer);
