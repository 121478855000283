import Participation from '../domain/Participation';
import useResolver from './useResolver';
import ProjectStore from '../store/ProjectStore';
import useQueryFilter from './useQueryFilter';

export default function useParticipationFilter() {
  const projectStore = useResolver(ProjectStore);
  const projectFilter = useQueryFilter('projectId');
  const projectStateFilter = useQueryFilter('projectState');
  const participationTagFilter = useQueryFilter('participation.tagId');

  return {
    apply: (p: Participation) => {
      const project = projectStore.getById(p.projectId);

      if (projectFilter && !projectFilter.includes(p.projectId)) {
        return false;
      }

      if (projectStateFilter && !projectStateFilter.includes(project?.state)) {
        return false;
      }

      if (participationTagFilter && !participationTagFilter.includes(p.tagId)) {
        return false;
      }

      return true;
    },
    parameters: {
      projectFilter,
      projectStateFilter,
      participationTagFilter,
    },
  };
}
