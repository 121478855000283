import Participation from '../domain/Participation';
import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';

@injectable()
export default class ParticipationService extends AbstractRestfulHttpService<Participation> {
  public constructor(apiService: ApiService) {
    super(Participation, apiService);
  }

  public getAllByPerson(personId: string): Promise<Participation[]> {
    return this.getMany({ personId });
  }

  public getAllByProject(projectId: string): Promise<Participation[]> {
    return this.getMany({ projectId, withPerson: true });
  }

  public async getAllByOrganization(
    organizationId: string,
  ): Promise<Participation[]> {
    return this.getMany({ organizationId });
  }
}
