import { OrganizationCurrency } from '../domain/Organization';
import { t } from '../i18n';

export function getHumanReadableCurrency(currency: OrganizationCurrency) {
  switch (currency) {
    case OrganizationCurrency.EUR:
      return t('Euro');
    case OrganizationCurrency.AUD:
      return t('Australian Dollar');
    case OrganizationCurrency.USD:
      return t('United States Dollar');
  }

  return t('Euro');
}

export function getCurrencySymbol(currency: OrganizationCurrency): string {
  switch (currency) {
    case OrganizationCurrency.EUR:
      return '€';
    case OrganizationCurrency.AUD:
      return '$';
    case OrganizationCurrency.USD:
      return '$';
  }

  return '€';
}

export function getCurrencyWithValue(
  value: number | string,
  currency: OrganizationCurrency,
) {
  switch (currency) {
    case OrganizationCurrency.AUD:
      return `$${value}`;
    case OrganizationCurrency.USD:
      return `$${value}`;
    case OrganizationCurrency.EUR:
      return `${value}€`;
  }
}

export function getCurrencyFromString(currency: string) {
  switch (currency) {
    case 'aud':
      return OrganizationCurrency.AUD;
    case 'eur':
      return OrganizationCurrency.EUR;
    case 'usd':
      return OrganizationCurrency.USD;
  }
}

export function isCurrencyInFront(currency: OrganizationCurrency) {
  return [OrganizationCurrency.AUD, OrganizationCurrency.USD].includes(
    currency,
  );
}
