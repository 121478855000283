import React from 'react';
import HStack from '../ui/HStack';
import CircularProgress from '../ui/CircularProgress';

interface CapacityLabelProps {
  capacity: number;
  maxCapacity?: number;
  color?: string;
}

const CapacityLabel: React.FC<CapacityLabelProps> = ({
  capacity,
  maxCapacity,
  color,
}) => {
  return (
    <HStack>
      <CircularProgress
        label={`${(capacity * 100).toFixed()}%`}
        value={capacity}
        max={maxCapacity}
        color={color}
        size="32px"
        thickness="10px"
      ></CircularProgress>
    </HStack>
  );
};

CapacityLabel.defaultProps = {
  maxCapacity: 1,
  color: 'primary.400',
};

export default CapacityLabel;
