import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import OrganizationStore from '../store/OrganizationStore';
import OrganizationFormDrawer from '../components/OrganizationFormDrawer';

export interface ConnectedOrganizationFormDrawerProps {}

const ConnectedOrganizationFormDrawer: React.FC<ConnectedOrganizationFormDrawerProps> =
  () => {
    const organizationStore = useResolver(OrganizationStore);
    const { currentOrganization } = organizationStore;

    if (!currentOrganization) {
      return null;
    }

    const onSubmit = async (organization: any) => {
      await organizationStore.update({
        ...currentOrganization,
        ...organization,
      });
    };

    return (
      <OrganizationFormDrawer
        onSubmit={onSubmit}
        organization={currentOrganization}
      />
    );
  };

export default observer(ConnectedOrganizationFormDrawer);
