import React from 'react';
import ConnectedForgotPasswordForm from '../containers/ConnectedForgotPasswordForm';
import CleanLayout from '../ui/CleanLayout';

const ForgotPassword: React.FC = () => {
  return (
    <CleanLayout size="md">
      <ConnectedForgotPasswordForm />
    </CleanLayout>
  );
};

export default ForgotPassword;
