import Notification from '../domain/Notification';
import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';

@injectable()
export default class NotificationService extends AbstractRestfulHttpService<Notification> {
  public constructor(apiService: ApiService) {
    super(Notification, apiService);
  }

  public getAllByPerson(personId: string): Promise<Notification[]> {
    return this.getMany({ personId });
  }
}
