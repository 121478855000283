import React, { useState } from 'react';
import { HStack, Stack } from '@chakra-ui/react';
import Logo from './Logo';
import BurgerIcon from './icons/BurgerIcon';
import ChevronIcon from './icons/ChevronIcon';

export interface SidebarProps {}

export const SidebarContext = React.createContext<{ collapsed: boolean }>({
  collapsed: false,
});

const Sidebar: React.FC<SidebarProps> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Stack
      spacing={8}
      minWidth={collapsed ? '40px' : '260px'}
      maxWidth={collapsed ? '40px' : null}
      minH="100%"
      maxH="100vh"
      overflow="hidden"
      bg="white"
      boxShadow="xl"
      p={6}
      px={collapsed ? 3 : 6}
      transition="all .2s"
    >
      <HStack justifyContent={collapsed ? 'center' : 'space-between'}>
        {!collapsed && <Logo size="sm" />}
        <HStack
          spacing={0}
          cursor="pointer"
          onClick={() => setCollapsed(!collapsed)}
          aria-label={collapsed ? 'Expand' : 'Collapse'}
        >
          <ChevronIcon direction={collapsed ? 'right' : 'left'} fontSize="xs" />
          <BurgerIcon />
        </HStack>
      </HStack>
      <SidebarContext.Provider value={{ collapsed }}>
        {children}
      </SidebarContext.Provider>
    </Stack>
  );
};

Sidebar.defaultProps = {};

export default Sidebar;
