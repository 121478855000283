import React from 'react';
import {
  SimpleGrid as ChakraSimpleGrid,
  SimpleGridProps as ChakraSimpleGridProps,
} from '@chakra-ui/react';

export interface SimpleGridProps extends ChakraSimpleGridProps {}

const SimpleGrid: React.FC<SimpleGridProps> = ({ ...props }) => {
  return <ChakraSimpleGrid {...props} />;
};

SimpleGrid.defaultProps = {};

export default SimpleGrid;
