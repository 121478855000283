import React from 'react';
import Stack from '../ui/Stack';
import ConnectedPersonUser from '../containers/ConnectedPersonUser';
import useResolver from '../hooks/useResolver';
import RequestActions from './RequestActions';
import Person from '../domain/Person';
import ParticipationStore from '../store/ParticipationStore';
import HStack from '../ui/HStack';
import Request from '../domain/Request';
import IconedText from '../ui/IconedText';
import { requestActionIcons, requestActionTexts } from '../util/request';
import Participation from '../domain/Participation';
import ArrowIcon from '../ui/icons/ArrowIcon';
import ConnectedParticipationDetails from '../containers/ConnectedParticipationDetails';
import ClockIcon from '../ui/icons/ClockIcon';
import CommunicationIcon from '../ui/icons/CommunicationIcon';
import Card from '../ui/Card';

export interface ParticipationRequestPreviewProps {
  request: Request;
  viewer?: Person;
}

const RequestDetails: React.FC<{
  request: Request;
  fromParticipation?: Participation;
}> = ({ request }) => {
  const RequestIcon = requestActionIcons[request.action];
  return (
    <Stack spacing={2}>
      <div>
        <IconedText fontSize="md" icon={<RequestIcon />}>
          {requestActionTexts[request.action]}
        </IconedText>
        {request.timing && (
          <IconedText icon={<ClockIcon />} fontSize="xs">
            {request.getStringifedTiming()}
          </IconedText>
        )}
        {request.description && (
          <IconedText icon={<CommunicationIcon />}>
            {request.description}
          </IconedText>
        )}
      </div>
      <HStack spacing={12} py={4}>
        {request.fromParticipationId && (
          <ConnectedParticipationDetails
            participationId={request.fromParticipationId}
          />
        )}
        {request.fromParticipationId && request.toProjectId && (
          <ArrowIcon direction="right" />
        )}
        {request.toProjectId && (
          <div>
            <ConnectedParticipationDetails
              projectId={request.toProjectId}
              tagId={request.toTagId}
              capacity={request.capacity}
            />
          </div>
        )}
      </HStack>
    </Stack>
  );
};

RequestDetails.defaultProps = {};

const RequestPreview: React.FC<ParticipationRequestPreviewProps> = ({
  request,
  viewer,
}) => {
  const projectParticipationStore = useResolver(ParticipationStore);
  const fromParticipation =
    request.fromParticipationId &&
    projectParticipationStore.getById(request.fromParticipationId);

  return (
    <Card>
      <HStack justifyContent="space-between" mb={4}>
        <ConnectedPersonUser personId={request.personId} hideOrganization />
        <RequestActions viewer={viewer} request={request} />
      </HStack>
      <Stack p={4} spacing={4}>
        <RequestDetails
          request={request}
          fromParticipation={fromParticipation}
        />
      </Stack>
    </Card>
  );
};

export default RequestPreview;
