import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { FaGithub } from 'react-icons/fa';
import Tooltip from './Tooltip';

export interface GithubButtonProps {
  onClick: () => void;
}

const GithubButton: React.FC<GithubButtonProps> = ({ onClick }) => {
  return (
    <Tooltip label="Sign in with Github">
      <IconButton
        colorScheme="gray"
        icon={<FaGithub />}
        onClick={onClick}
        aria-label="Github"
      />
    </Tooltip>
  );
};

GithubButton.defaultProps = {};

export default GithubButton;
