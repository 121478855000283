import React from 'react';
import HStack from '../ui/HStack';
import ConnectedRequestAcceptButton from '../containers/ConnectedRequestAcceptButton';
import ConnectedRequestRejectButton from '../containers/ConnectedRequestRejectButton';
import Person from '../domain/Person';
import RequestStatus from './RequestStatus';
import Request from '../domain/Request';

export interface RequestActionsProps {
  request: Request;
  viewer: Person;
}

const RequestActions: React.FC<RequestActionsProps> = ({ request, viewer }) => {
  return (
    <RequestStatus request={request} viewer={viewer}>
      <HStack>
        <ConnectedRequestAcceptButton requestId={request.id} />
        <ConnectedRequestRejectButton requestId={request.id} />
      </HStack>
    </RequestStatus>
  );
};

export default RequestActions;
