import React from 'react';
import { Box, Center, Progress } from '@chakra-ui/react';

export interface FullPageLoaderProps {}

const FullPageLoader: React.FC<FullPageLoaderProps> = () => {
  return (
    <Center height="100vh">
      <Box width="50vw">
        <Progress colorScheme="brand" size="xs" isIndeterminate />
      </Box>
    </Center>
  );
};

FullPageLoader.defaultProps = {};

export default FullPageLoader;
