import { useRef } from 'react';

export interface DocumentDownloadOptions {
  margin: number;
  filename: string;
  orientation: 'portrait' | 'landscape';
}

const defaultOptions: DocumentDownloadOptions & any = {
  margin: 6,
  filename: 'document.pdf',
  html2canvas: {
    useCORS: true,
    scale: 4,
  },
  orientation: 'portrait',
};

const useDocumentDownload = ({
  orientation,
  ...options
}: Partial<DocumentDownloadOptions> = {}) => {
  const documentRef = useRef(null);
  const downloadDocument = async () => {
    if (!documentRef.current) {
      throw new Error('documentRef is empty - did you forget to include it?');
    }

    // @ts-ignore
    const { default: html2pdf } = await import('html2pdf.js');
    await html2pdf()
      .from(documentRef.current)
      .set({
        ...defaultOptions,
        ...options,
        jsPDF: {
          orientation,
        },
      })
      .save();
  };

  return { documentRef, downloadDocument };
};

export default useDocumentDownload;
