import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import ConnectedManagedOrganizationOverview from '../containers/ConnectedManagedOrganizationOverview';

const ManagedOrganizationView: React.FC = () => {
  const match = useRouteMatch<{ id: string }>();
  const organizationId = match.params.id;

  return (
    <ConnectedManagedOrganizationOverview organizationId={organizationId} />
  );
};

export default ManagedOrganizationView;
