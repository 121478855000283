import React from 'react';
import Heading from '../ui/Heading';
import Input from '../ui/Input';
import Stack from '../ui/Stack';
import Text from '../ui/Text';
import FormField from './FormField';
import FormSubmitButton from './FormSubmitButton';
import Form from './Form';
import { isEmail } from '../util/string';
import { t } from '../i18n';

export interface ForgotPasswordFormProps {
  onSubmit: (values: { email: string }) => Promise<void> | void;
  submitted?: false | { email: string };
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  onSubmit,
  submitted,
}) => {
  if (submitted) {
    return (
      <div>
        {t('An email with instructions has been sent to')}{' '}
        <strong>{submitted.email}</strong>
      </div>
    );
  }

  return (
    <Form onSubmit={onSubmit}>
      <Stack spacing={4}>
        <Heading>{t('Forgot password')}</Heading>
        <Text>{t('Provide email to reset password<')}</Text>

        <FormField
          name="email"
          label="Email"
          validate={(value) => isEmail(value) || 'Invalid email'}
          required
        >
          <Input size="lg" type="email" autoFocus />
        </FormField>

        <FormSubmitButton>{t('Send link')}</FormSubmitButton>
      </Stack>
    </Form>
  );
};

export default ForgotPasswordForm;
