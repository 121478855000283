import React from 'react';
import { FiCalendar } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface CalendarIconProps extends IconProps {}

const CalendarIcon: React.FC<CalendarIconProps> = ({ ...props }) => {
  return <Icon as={FiCalendar} {...props} />;
};

export default CalendarIcon;
