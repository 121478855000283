import { ReactNode } from 'react';
import TimelineCell from './TimelineCell';

export interface TimelineLeftCellProps {
  children?: ReactNode;
}

export default function TimelineLeftCell({ children }: TimelineLeftCellProps) {
  return <TimelineCell width="200px">{children}</TimelineCell>;
}
