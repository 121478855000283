import useQueryValue from './useQueryValue';
import orderBy from 'lodash/orderBy';

type Direction = 'asc' | 'desc';

export interface QuerySort {
  name: string;
  direction: Direction;
  sortItems: <T>(items: T[]) => T[];
}

export default function useQuerySort(
  defaultName?: string,
  defaultDirection: Direction = 'asc',
): QuerySort {
  const [name, initialDirection] = useQueryValue(
    'sort',
    defaultName ? `${defaultName}.${defaultDirection}` : null,
  ).split('.');

  const direction = ['asc', 'desc'].includes(initialDirection)
    ? (initialDirection as Direction)
    : defaultDirection;

  return {
    name,
    direction,
    sortItems: (items) => orderBy(items, name, direction),
  };
}
