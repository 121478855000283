import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import DeleteDrawer from '../components/DeleteDrawer';
import useQueryValue from '../hooks/useQueryValue';
import { t } from '../i18n';
import TaskStore from '../store/TaskStore';
import { Text } from '../ui';

export interface ConnectedTaskDeleteDrawerProps {
  name: string;
}

const ConnectedTaskDeleteDrawer: React.FC<ConnectedTaskDeleteDrawerProps> = ({
  name,
}) => {
  const taskStore = useResolver(TaskStore);
  const taskId = useQueryValue(name);
  const task = taskStore.getByIdOrLoad(taskId);

  if (!task) {
    return null;
  }

  const handleSubmit = async () => {
    await taskStore.delete(task?.id);
  };

  return (
    <DeleteDrawer
      name={name}
      onSubmit={handleSubmit}
      confirmationText={null}
      header={`${t('Delete')} ${task.name}?`}
    >
      <Text>{t('This will move all logged task time under project')}</Text>
    </DeleteDrawer>
  );
};

export default observer(ConnectedTaskDeleteDrawer);
