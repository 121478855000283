import React from 'react';
import { VStack, Icon, Text } from '@chakra-ui/react';
import { FaMugHot } from 'react-icons/fa';

export interface NoContentProps {}

const NoContent: React.FC<NoContentProps> = ({ children }) => {
  return (
    <VStack spacing={12} p={12}>
      <Icon as={FaMugHot} boxSize={120} opacity={0.15} />
      <Text color="gray.500">{children}</Text>
    </VStack>
  );
};

NoContent.defaultProps = {};

export default NoContent;
