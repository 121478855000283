import TimelineCell, { TimelineCellProps } from './TimelineCell';
import { isThisMonth, isThisYear, isToday, isWeekend } from 'date-fns';
import Box from '../Box';
import React from 'react';
import Text from '../Text';

export interface TimelineDateCellProps extends TimelineCellProps {
  date: Date;
  format: 'day' | 'month' | 'year';
  highlightCurrentDate?: boolean;
  onClick?: () => void;
}

export default function TimelineDateCell({
  date,
  format,
  onClick,
  highlightCurrentDate,
  ...rest
}: TimelineDateCellProps) {
  const currentDateBorder = {
    color: 'red.500',
    borderWidth: '2px',
  } as const;
  const currentDateLine = (
    <Box
      position="absolute"
      bg={currentDateBorder.color}
      width={currentDateBorder.borderWidth}
      left="calc(50% - 1px)"
      height="100vh"
      zIndex={2}
    />
  );
  const commonProps = {
    onClick,
    cursor: onClick ? 'pointer' : 'default',
  } as const;

  if (format === 'day') {
    const today = highlightCurrentDate && isToday(date);

    return (
      <TimelineCell align="center" {...rest}>
        <Box
          fontSize="xs"
          borderWidth={currentDateBorder.borderWidth}
          borderColor={today ? currentDateBorder.color : 'transparent'}
          borderRadius="50%"
          width="22px"
          height="22px"
          {...commonProps}
        >
          {date.getDate()}
        </Box>
        {today && currentDateLine}
        {isWeekend(date) && (
          <Box
            bg="gray.100"
            position="absolute"
            width="calc(100% - 1px)"
            left="1px"
            height="100vh"
            zIndex={1}
          />
        )}
      </TimelineCell>
    );
  }

  if (format === 'month') {
    const thisMonth = highlightCurrentDate && isThisMonth(date);

    return (
      <TimelineCell align="center" {...rest}>
        <Text
          fontSize="xs"
          borderColor={thisMonth ? currentDateBorder.color : 'transparent'}
          borderBottomWidth={currentDateBorder.borderWidth}
          {...commonProps}
        >
          {date.toLocaleDateString('en-US', { month: 'short' })}
        </Text>
        {thisMonth && currentDateLine}
      </TimelineCell>
    );
  }

  if (format === 'year') {
    const thisYear = highlightCurrentDate && isThisYear(date);

    return (
      <TimelineCell align="center" {...rest}>
        <Text
          fontSize="xs"
          borderColor={thisYear ? currentDateBorder.color : 'transparent'}
          borderBottomWidth={currentDateBorder.borderWidth}
          {...commonProps}
        >
          {date.getFullYear()}
        </Text>
        {thisYear && currentDateLine}
      </TimelineCell>
    );
  }
}
