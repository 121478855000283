import React from 'react';
import useResolver from '../hooks/useResolver';
import ProjectLink, { ProjectLinkProps } from '../components/ProjectLink';
import { observer } from 'mobx-react';
import ProjectStore from '../store/ProjectStore';

interface ConnectedProjectLinkProps extends Omit<ProjectLinkProps, 'project'> {
  projectId: string;
}

const ConnectedProjectLink: React.FC<ConnectedProjectLinkProps> = ({
  projectId,
  ...props
}) => {
  const projectStore = useResolver(ProjectStore);

  const project = projectStore.getByIdOrLoad(projectId);

  if (!project) {
    return null;
  }

  return (
    <ProjectLink project={projectStore.getByIdOrLoad(projectId)} {...props} />
  );
};

export default observer(ConnectedProjectLink);
