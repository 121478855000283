import React from 'react';
import { FiSearch } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface SearchIconProps extends IconProps {}

const SearchIcon: React.FC<SearchIconProps> = ({ ...props }) => {
  return <Icon as={FiSearch} {...props} />;
};

export default SearchIcon;
