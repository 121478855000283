import React from 'react';
import RouterDrawer from './RouterDrawer';
import { observer } from 'mobx-react';
import Notification from '../domain/Notification';
import Stack from '../ui/Stack';
import NotificationInList from './NotificationInList';
import NoContent from '../ui/NoContent';

export interface NotificationsDrawerProps {
  notifications: Notification[];
  onMark: (notification: Notification) => void | Promise<void>;
}

const NotificationsDrawer: React.FC<NotificationsDrawerProps> = ({
  notifications,
  onMark,
}) => {
  return (
    <RouterDrawer name="notifications" header="Notifications">
      <Stack spacing={2}>
        {notifications?.map((notification) => {
          return (
            <div key={notification.id}>
              <NotificationInList
                notification={notification}
                onMark={() => onMark(notification)}
              />
            </div>
          );
        })}
        {notifications.length <= 0 && <NoContent>No notifications</NoContent>}
      </Stack>
    </RouterDrawer>
  );
};

export default observer(NotificationsDrawer);
