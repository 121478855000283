import React from 'react';
import { Grid, GridItem, HStack, Stack } from '@chakra-ui/react';
import Menu from './Menu';
import Box from './Box';
import Sidebar from './Sidebar';
import MenuList from './MenuList';

export interface LayoutProps {
  navigation: React.ReactNode;
  notificationsHandle?: React.ReactNode;
  currentUser?: React.ReactNode;
  userNavigation?: React.ReactNode;
  secondaryUsers?: React.ReactNode;
  quickActions?: React.ReactNode;
  footer?: React.ReactNode;
  search?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  navigation,
  notificationsHandle,
  currentUser,
  userNavigation,
  secondaryUsers,
  quickActions,
  footer,
  search,
}) => {
  return (
    <Box bg="#F8F8F8">
      <Grid
        minHeight="100vh"
        templateRows="auto auto 1fr auto"
        templateColumns="auto 1fr auto"
        rowGap={4}
        color="black"
        minWidth="1024px"
        maxWidth="1980px"
      >
        <GridItem rowSpan={4} colSpan={1}>
          <Sidebar>
            <Stack
              flexGrow={1}
              minH="100%"
              justifyContent="space-between"
              alignItems="start"
            >
              <Box width="100%">
                <div>{quickActions}</div>
                <Box>{navigation}</Box>
              </Box>
              <Box width="100%">
                <div>{footer}</div>
              </Box>
            </Stack>
          </Sidebar>
        </GridItem>
        <GridItem bg="white" border="1px" borderColor="#EBE9F1" borderTop="0px">
          <HStack justify="space-between" pl={4}>
            <div>{search}</div>
            <HStack justify="flex-end" spacing={2}>
              {notificationsHandle}
              {currentUser && (
                <Menu handle={currentUser} p={4}>
                  <MenuList>
                    {secondaryUsers}
                    {userNavigation}
                  </MenuList>
                </Menu>
              )}
            </HStack>
          </HStack>
        </GridItem>
        <GridItem />
        <GridItem pl="1.5rem" pr="1.5rem">
          {children}
        </GridItem>
        <GridItem />
      </Grid>
    </Box>
  );
};

export default Layout;
