import React from 'react';
import Project from '../domain/Project';
import { RichSelect } from '../ui';
import { RichSelectOption } from '../ui/RichSelect';

export interface ProjectSelectProps {
  projects: Project[];
  value?: string;
  onChange?: (organizationId: string) => void;
  clearable?: boolean;
  placeholder?: string;
  onCreate?: (name: string) => void;
  disabled?: boolean;
}

const projectToOption = (project: Project): RichSelectOption => {
  return {
    value: project.id,
    label: project.name,
  };
};

const ProjectSelect: React.FC<ProjectSelectProps> = ({
  projects,
  onChange,
  value,
  ...props
}) => {
  return (
    <RichSelect
      {...props}
      options={projects.map((project) => projectToOption(project))}
      onChange={(option) => {
        onChange?.((option as RichSelectOption).value);
      }}
    />
  );
};

export default ProjectSelect;
