import { extendTheme } from '@chakra-ui/react';

const purple = {
  50: '#eceafd',
  100: '#cecafa',
  200: '#ada9f7',
  300: '#8b85f4',
  400: '#7267f0',
  500: '#5d47ea',
  600: '#583ede',
  700: '#5031d1',
  800: '#4923c6',
  900: '#4000ad',
};

const primary = purple;

const gray = {
  50: '#f9fdff',
  100: '#f4f8fe',
  200: '#eff3f9',
  300: '#eaeef4',
  400: '#c9ced3',
  500: '#acb0b6',
  600: '#82868b',
  700: '#6d7176',
  800: '#4d5156',
  900: '#2b2f33',
};

const secondary = gray;

// Flattens objects so you could define a default color as well for that keyword
function flattenColors(colorPalette: {
  [name: string]: { [tone: number]: string };
}) {
  const result: { [key: string]: string } = {};

  Object.keys(colorPalette).forEach((name) => {
    Object.keys(colorPalette[name]).forEach((key: any) => {
      result[`${name}.${key}`] = colorPalette[name][key];
    });
  });

  return result;
}

export const colors = {
  black: '#5E5873', // FIXME: what is this?
  purple,
  gray,
  brand: purple, // FIXME: REMOVE
  ...flattenColors({
    primary,
    secondary,
    surface: {
      100: secondary['100'],
      200: secondary['200'],
      300: secondary['400'],
    },
  }),
  primary: purple['400'],
  secondary: gray['600'],
  tertiary: '#7e95ac',
  error: '#dc3210',
  info: '#2196f3',
  success: '#28c76f',
  warning: '#f0a30d',
  background: '#fff',
  outline: secondary['400'],
  text: gray['900'],
  'text.reverse': '#fff',
  'text.sub': '#c5d0db',
};

const theme = extendTheme(
  {
    fonts: {
      heading: 'Montserrat',
      body: 'Montserrat',
    },
    colors,
  },
  // withDefaultColorScheme({ colorScheme: 'primary' }),
);

export default theme;
