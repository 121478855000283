import React from 'react';
import HStack from '../ui/HStack';
import RouterMonthPicker from './RouterMonthPicker';
import Box from '../ui/Box';
import ResponsiveGrid from '../ui/ResponsiveGrid';
import BudgetRowCalculatedValues from './BudgetRowCalculatedValues';
import Stack from '../ui/Stack';
import BudgetRow from './BudgetRow';
import AutoGrid from '../ui/AutoGrid';
import Accordion from '../ui/Accordion';
import AccordionItem from '../ui/AccordionItem';
import { calculateTotals } from '../util/budget';
import { FinanceResult } from '../services/FinanceService';
import Text from '../ui/Text';
import ProjectLink from './ProjectLink';
import { formatDate } from '../util/date';
import { t } from '../i18n';
import Project from '../domain/Project';
import ConnectedProjectCashflowValue from '../containers/ConnectedProjectCashflowValue';
import { endOfMonth, startOfMonth } from 'date-fns';
import ConnectedPersonUser from '../containers/ConnectedPersonUser';
import IconButton from '../ui/IconButton';
import { DeleteIcon } from '../ui';
import ConnectedPriceDeleteDrawer from '../containers/ConnectedPriceDeleteDrawer';

export interface ProjectsBudgetProps {
  organizationId: string;
  finances: FinanceResult[];
  month: Date;
  onChange: () => void;
}

function getProjectsFromFinances(finances: FinanceResult[]): Project[] {
  const projects: Project[] = [];

  finances.forEach((finance) => {
    if (
      !finance.project ||
      projects.find((p) => p.id === finance.project?.id)
    ) {
      return;
    }

    projects.push(finance.project);
  });

  return projects;
}

const ManagedOrganizationBudget: React.FC<ProjectsBudgetProps> = ({
  organizationId,
  finances,
  month,
  onChange,
}) => {
  const projects = getProjectsFromFinances(finances);
  const additionalFinances = finances.filter((f) => !f.project && !f.person);
  const additionalUserFinances = finances.filter((f) => !f.project && f.person);

  return (
    <div>
      <ConnectedPriceDeleteDrawer name={'price.delete'} onChange={onChange} />
      <HStack justifyContent="center">
        <RouterMonthPicker />
      </HStack>
      <Box py={12}>
        <ResponsiveGrid columns={4} textAlign="center" rowGap={8}>
          <ConnectedProjectCashflowValue
            organizationId={organizationId}
            dateStart={startOfMonth(month)}
            dateEnd={endOfMonth(month)}
            centered
          />
          <BudgetRowCalculatedValues centered {...calculateTotals(finances)} />
        </ResponsiveGrid>
      </Box>
      <Stack spacing={8}>
        <Accordion>
          {projects.map((project) => {
            const projectFinances = finances.filter(
              (f) => f.project?.id === project.id,
            );

            return (
              <AccordionItem
                title={
                  <ResponsiveGrid
                    templateColumns="220px 50px 1fr"
                    alignItems="center"
                    spacing={4}
                    py={4}
                    px={4}
                  >
                    <ProjectLink project={project} />
                    <div />

                    <AutoGrid childWidth="150px" rowGap={2}>
                      <BudgetRowCalculatedValues
                        {...calculateTotals(projectFinances)}
                      />
                    </AutoGrid>
                  </ResponsiveGrid>
                }
              >
                <Stack spacing={2}>
                  {projectFinances.map((result, i) => {
                    if (!result.participation) {
                      return (
                        <ResponsiveGrid
                          key={i}
                          templateColumns="220px 50px 1fr"
                          alignItems="center"
                          spacing={4}
                        >
                          <div>
                            {result.description || '-'}

                            <Text as="div" fontSize="xs">
                              {result.dateStart && formatDate(result.dateStart)}
                            </Text>
                          </div>
                          <div />
                          <AutoGrid childWidth="150px" rowGap={2}>
                            <BudgetRowCalculatedValues
                              revenuePrognosis={result.revenuePrognosis}
                              costPrognosis={result.costPrognosis}
                            />
                          </AutoGrid>
                        </ResponsiveGrid>
                      );
                    }

                    return (
                      <BudgetRow
                        key={i}
                        hidePersonCost
                        hidePositionPrice
                        variant="person"
                        capacity={result.participation?.capacity}
                        costPrognosis={result.costPrognosis}
                        revenuePrognosis={result.revenuePrognosis}
                        marginPrognosis={result.marginPrognosis}
                        person={result.person}
                        personCost={null}
                        position={result.projectTag}
                        positionPrice={null}
                      />
                    );
                  })}
                  {projectFinances.length <= 0 && (
                    <Box
                      display="inline-block"
                      borderRadius="md"
                      p={2}
                      bg="gray.50"
                      fontSize="xs"
                    >
                      {t('No participations at specified time')}
                    </Box>
                  )}
                </Stack>
              </AccordionItem>
            );
          })}
          {additionalUserFinances.length > 0 && (
            <AccordionItem
              title={
                <ResponsiveGrid
                  templateColumns="220px 50px 1fr"
                  alignItems="center"
                  spacing={4}
                  py={4}
                  px={4}
                >
                  <div>{t('Unassociated')}</div>
                  <div />

                  <AutoGrid childWidth="150px" rowGap={2}>
                    <BudgetRowCalculatedValues
                      {...calculateTotals(additionalUserFinances)}
                    />
                  </AutoGrid>
                </ResponsiveGrid>
              }
            >
              <Stack spacing={2}>
                {additionalUserFinances.map((result, i) => (
                  <ResponsiveGrid
                    key={i}
                    templateColumns="220px 50px 1fr"
                    alignItems="center"
                    spacing={4}
                  >
                    <>
                      <ConnectedPersonUser
                        personId={result.person.id}
                        linkToProfile
                      />
                      <div />

                      <AutoGrid childWidth="150px" rowGap={2}>
                        <BudgetRowCalculatedValues
                          revenuePrognosis={result.revenuePrognosis}
                          costPrognosis={result.costPrognosis}
                          marginPrognosis={
                            result.revenuePrognosis - result.costPrognosis
                          }
                        />
                      </AutoGrid>
                    </>
                  </ResponsiveGrid>
                ))}
              </Stack>
            </AccordionItem>
          )}
          <AccordionItem
            title={
              <ResponsiveGrid
                templateColumns="220px 50px 1fr"
                alignItems="center"
                spacing={4}
                py={4}
                px={4}
              >
                <div>{t('Additional costs/revenues')}</div>
                <div />

                <AutoGrid childWidth="150px" rowGap={2}>
                  <BudgetRowCalculatedValues
                    {...calculateTotals(additionalFinances)}
                  />
                </AutoGrid>
              </ResponsiveGrid>
            }
          >
            <Stack spacing={2}>
              {additionalFinances.map((result, i) => (
                <ResponsiveGrid
                  key={i}
                  templateColumns="220px 50px 1fr 50px"
                  alignItems="center"
                  spacing={4}
                >
                  <div>
                    <div>{result.description || '-'}</div>
                    <Text fontSize="xs">
                      {`${result.dateStart && formatDate(result.dateStart)} ${
                        result.dateEnd ? ` - ${formatDate(result.dateEnd)}` : ''
                      }`}
                    </Text>
                  </div>
                  <div />
                  <AutoGrid childWidth="150px" rowGap={2}>
                    <BudgetRowCalculatedValues
                      revenuePrognosis={result.revenuePrognosis}
                      costPrognosis={result.costPrognosis}
                    />
                  </AutoGrid>
                  {result.priceId && (
                    <IconButton
                      icon={<DeleteIcon />}
                      variant="ghost"
                      tooltip
                      aria-label={`Delete`}
                      to={`?price.delete=${result.priceId}`}
                    />
                  )}
                </ResponsiveGrid>
              ))}
            </Stack>
          </AccordionItem>
        </Accordion>
      </Stack>
    </div>
  );
};

export default ManagedOrganizationBudget;
