import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import NoOrganization from '../views/NoOrganization';
import { ResourceStoreStatus } from '../store/AbstractResourceStore';
import FullPageLoader from '../ui/FullPageLoader';
import PersonStore from '../store/PersonStore';
import ErrorPage from '../views/ErrorPage';

const PersonGuard: React.FC = ({ children }) => {
  const personStore = useResolver(PersonStore);

  useEffect(() => {
    personStore.loadUserRelated();
  }, [personStore]);

  if (
    !personStore.currentPerson &&
    personStore.status === ResourceStoreStatus.PENDING
  ) {
    return <FullPageLoader />;
  }

  if (
    !personStore.currentPerson &&
    personStore.status === ResourceStoreStatus.REJECTED
  ) {
    return <ErrorPage />;
  }

  if (!personStore.currentPerson) {
    return <NoOrganization />;
  }

  return <>{children}</>;
};

export default observer(PersonGuard);
