import React from 'react';
import Stack from './Stack';
import Box from './Box';
import Image from './Image';
import HStack from './HStack';
import VStack from './VStack';
import Divider from './Divider';
import Card from './Card';
import { AspectRatio } from '@chakra-ui/react';
import { Heading } from './index';

export interface MediaCardProps {
  image?: string;
  title: React.ReactNode;
  maxHeight?: number | string;
  topLeft?: React.ReactNode;
  topRight?: React.ReactNode;
  action?: React.ReactNode;
  linkTo?: string;
  imageStyle?: 'cover' | 'contain';
  color?: string;
  height?: number | string;
}

const MediaCard: React.FC<MediaCardProps> = ({
  image,
  maxHeight,
  topLeft,
  topRight,
  title,
  action,
  children,
  linkTo,
  imageStyle,
  color = 'gray.50',
  height,
}) => {
  return (
    <Card role="listitem" p={0} {...(height ? { height } : {})}>
      <Stack height="100%">
        <AspectRatio
          width="100%"
          ratio={16 / 9}
          backgroundColor="gray.300"
          borderTopRadius="md"
          position="relative"
          overflow="hidden"
          bgGradient={`linear(to-b, gray.200, ${color} )`}
        >
          <div>
            <Box width="100%" height="100%">
              <Box
                to={linkTo}
                position="absolute"
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {image && (
                  <Image
                    src={image}
                    objectFit={imageStyle}
                    border={0}
                    width="100%"
                    height="100%"
                    {...(imageStyle === 'contain' && {
                      width: '60%',
                      height: '60%',
                      top: '20%',
                      left: '20%',
                    })}
                  />
                )}
              </Box>
              {(topLeft || topRight) && (
                <HStack
                  position="relative"
                  justifyContent="space-between"
                  alignItems="start"
                  p={3}
                  pointerEvents="none"
                >
                  <Box pointerEvents="auto">{topLeft}</Box>
                  <Box pointerEvents="auto">{topRight}</Box>
                </HStack>
              )}
            </Box>
          </div>
        </AspectRatio>

        <Stack
          spacing={2}
          alignItems="center"
          maxHeight={maxHeight}
          overflow="hidden"
          to={linkTo}
          flexGrow={1}
        >
          {title && (
            <Heading size="md" fontWeight="normal">
              {title}
            </Heading>
          )}
          {children}
        </Stack>
        <VStack px={2} pb={2}>
          {action && (
            <>
              <Divider />
              {action}
            </>
          )}
        </VStack>
      </Stack>
    </Card>
  );
};

MediaCard.defaultProps = {
  imageStyle: 'contain',
};

export default MediaCard;
