import React from 'react';
import { Viewer } from '@toast-ui/react-editor';

import '@toast-ui/editor/dist/toastui-editor-viewer.css';

export interface MarkdownProps {
  children: string;
}

const Markdown: React.FC<MarkdownProps> = ({ children }) => {
  if (!children) {
    return null;
  }

  return <Viewer initialValue={children} usageStatistics={false} />;
};

export default Markdown;
