import Resource from '../decorators/Resource';
import { toDate } from '../util/date';
import AbstractDynamicDomain from './AbstractDynamicDomain';

export enum OrganizationRelationType {
  CLIENT = 'CLIENT',
  PARTNER = 'PARTNER',
  SALESLEAD = 'SALESLEAD',
  SPONSOR = 'SPONSOR',
  INTERNAL = 'INTERNAL',
}

@Resource.Name('organization-relations')
export default class OrganizationRelation extends AbstractDynamicDomain {
  public organizationId: string;
  public relatedOrganizationId: string;
  public description: string;
  public relationType?: OrganizationRelationType;
  public dateStart?: Date;
  public dateEnd?: Date;
  public tags: string[];
  public priority: number;

  public fromObject(payload: Partial<OrganizationRelation>): this {
    super.fromObject(payload);

    this.organizationId = payload.organizationId;
    this.relatedOrganizationId = payload.relatedOrganizationId;
    this.relationType = payload.relationType;
    this.description = payload.description;
    this.dateStart = toDate(payload.dateStart);
    this.dateEnd = toDate(payload.dateEnd);
    this.tags = payload.tags;
    this.priority = payload.priority;

    return this;
  }

  public toObject(): Partial<OrganizationRelation> {
    return {
      ...super.toObject(),
      organizationId: this.organizationId,
      relatedOrganizationId: this.relatedOrganizationId,
      relationType: this.relationType,
      description: this.description,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      tags: this.tags,
      priority: this.priority,
    };
  }

  public hasStarted(date: Date = new Date()): boolean {
    return !this.dateStart || this.dateStart < date;
  }

  public hasEnded(date: Date = new Date()): boolean {
    return this.dateEnd && this.dateEnd < date;
  }

  public isOngoing(): boolean {
    return this.hasStarted() && !this.hasEnded();
  }
}
