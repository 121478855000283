import React from 'react';
import Tabs, { TabsProps } from '../ui/Tabs';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { slugify } from '../util/string';
import { flattenChildren } from '../util/react';

interface RouterTabsProps extends TabsProps {
  name?: string;
  preserveQuery?: boolean;
}

const RouterTabs: React.FC<RouterTabsProps> = ({
  name,
  children,
  preserveQuery,
  ...props
}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const currentSlug = location.pathname.replace(
    new RegExp(`^${match.url}/?`),
    '',
  );

  const childElements = flattenChildren(children);

  const slugs: string[] = childElements.map((child, index) => {
    return child.props.title ? slugify(child.props.title) : index.toString();
  });

  return (
    <Tabs
      index={currentSlug ? slugs.indexOf(currentSlug) : 0}
      onChange={(index) => {
        history.push(
          `${match.url}/${slugs[index]}${
            preserveQuery && location.search ? location.search : ''
          }`,
        );
      }}
      {...props}
    >
      {children}
    </Tabs>
  );
};

RouterTabs.defaultProps = {
  name: 'tab',
};

export default RouterTabs;
