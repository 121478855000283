import Project from '../domain/Project';
import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';

@injectable()
export default class ProjectService extends AbstractRestfulHttpService<Project> {
  public constructor(apiService: ApiService) {
    super(Project, apiService);
  }

  public getAllByOrganization(organizationId: string): Promise<Project[]> {
    return this.getMany({ organizationId });
  }
}
