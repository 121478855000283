import AbstractResourceStore from './AbstractResourceStore';
import Tag, { TagType } from '../domain/Tag';
import TagService from '../services/TagService';
import { computed } from 'mobx';
import { singleton } from 'tsyringe';
import AccountStore from './AccountStore';
import OrganizationStore from './OrganizationStore';

@singleton()
export default class TagStore extends AbstractResourceStore<Tag> {
  public constructor(
    private readonly tagService: TagService,
    private readonly organizationStore: OrganizationStore,
    accountStore: AccountStore,
  ) {
    super(tagService, accountStore);
  }

  public async loadByOrganization(organizationId: string) {
    await this.setManyEventually(
      this.tagService.getAllByOrganization(organizationId),
    );
  }

  public async createMany(tags: Tag[]) {
    await Promise.all(tags.map((tag) => this.create(tag)));
  }

  public async loadByCurrentOrganization() {
    return this.loadByOrganization(
      this.organizationStore.currentOrganizationId,
    );
  }

  @computed
  public get currentOrganizationTags(): Tag[] {
    return this.getFilteredItems({
      organizationId: this.organizationStore.currentOrganizationId,
    });
  }

  @computed
  public get tagsForProject() {
    return this.currentOrganizationTags.filter(
      (item) => item.type !== TagType.POSITION,
    );
  }

  @computed
  public get positions() {
    return this.currentOrganizationTags.filter(
      (item) => item.type === TagType.POSITION,
    );
  }

  @computed
  public get skills() {
    return this.currentOrganizationTags.filter((item) =>
      [TagType.LANGUAGE, TagType.TECHNOLOGY].includes(item.type),
    );
  }
}
