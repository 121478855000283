import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  format,
  formatDistance as formatDistanceDatefns,
  formatISO,
  getDaysInMonth,
  isWeekend,
  parse,
  parseISO,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';

export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';
export const DEFAULT_DATETIME_FORMAT = 'dd.MM.yyyy HH:mm';

export function toDate(
  date: string | Date | null,
  format?: string,
): Date | null {
  if (!date) {
    return null;
  }

  if (typeof date === 'string' && !format) {
    return parseISO(date);
  }

  if (typeof date === 'string' && format) {
    return parse(date, format, new Date());
  }

  return new Date(date);
}

export function toDateOrDefault(
  date: string | Date | null,
  format?: string,
  fallback = new Date(),
): Date {
  if (!date) {
    return fallback;
  }

  try {
    const result = toDate(date, format);

    if (isNaN(+result)) {
      return fallback;
    }
    return result;
  } catch (e: any) {
    console.warn(e);
    return fallback;
  }
}

export function toISO(date: Date): string | null {
  if (!date) {
    return null;
  }

  return formatISO(date);
}

export function formatDate(
  date: Date | string | null,
  dateFormat = DEFAULT_DATE_FORMAT,
): string | null {
  if (!date) {
    return null;
  }

  if (typeof date === 'string') {
    date = toDate(date);
  }

  return format(date, dateFormat);
}

export function formatDateTime(
  date: Date | null,
  dateFormat = DEFAULT_DATETIME_FORMAT,
): string | null {
  return formatDate(date, dateFormat);
}

export function formatDatePeriod(
  date1: Date | null,
  date2: Date | null,
  dateFormat = DEFAULT_DATE_FORMAT,
): string {
  return `${formatDate(date1, dateFormat) || '...'} — ${
    formatDate(date2, dateFormat) || '...'
  }`;
}

export function getAllDatesInMonth(month: Date): Date[] {
  return Array.from({ length: getDaysInMonth(month) }, (x, i) => i + 1).map(
    (dayInMonth) => {
      const date = new Date(month);
      date.setDate(dayInMonth);

      return date;
    },
  );
}

export function getAllDatesWithinDates(
  date1: Date,
  date2: Date,
  incrementer: (date: Date) => Date,
): Date[] {
  const dates = [];

  let current = date1;

  while (current <= date2) {
    dates.push(current);

    current = incrementer(current);
  }

  return dates;
}

export function getAllDaysWithinDates(date1: Date, date2: Date): Date[] {
  return getAllDatesWithinDates(date1, date2, (date) => addDays(date, 1));
}

export function getAllWorkdaysInMonth(month: Date): Date[] {
  return getAllDatesInMonth(month).filter((date) => !isWeekend(date));
}

export function getAllWorkDaysWithinDates(date1: Date, date2: Date): Date[] {
  return getAllDaysWithinDates(date1, date2).filter((d) => !isWeekend(d));
}

export function formatDistance(
  date: Date,
  baseDate: Date = new Date(),
): string {
  return formatDistanceDatefns(date, baseDate);
}

export function getNextWeek(date: Date = new Date()): Date {
  return startOfWeek(addWeeks(date, 1));
}

export function getNextMonth(date: Date = new Date()): Date {
  return startOfMonth(addMonths(date, 1));
}

export function getNextYear(date: Date = new Date()): Date {
  return startOfYear(addYears(date, 1));
}
