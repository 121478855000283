import React from 'react';
import { observer } from 'mobx-react';
import useQueryValue from '../hooks/useQueryValue';
import useResolver from '../hooks/useResolver';
import TagStore from '../store/TagStore';
import DeleteDrawer from '../components/DeleteDrawer';
import { t } from '../i18n';

export interface ConnectedTagDeleteDrawerProps {
  name: string;
}

const ConnectedTagDeleteDrawer: React.FC<ConnectedTagDeleteDrawerProps> = ({
  name,
}) => {
  const tagStore = useResolver(TagStore);
  const tagId = useQueryValue(name);

  if (!tagId) {
    return null;
  }

  const handleSubmit = async () => {
    await tagStore.delete(tagId);
  };

  const tag = tagStore.getById(tagId);

  if (!tag) {
    return null;
  }

  return (
    <DeleteDrawer
      name={name}
      confirmationText={null}
      onSubmit={handleSubmit}
      header={`${t('Delete')} ${tag.name}?`}
    />
  );
};

export default observer(ConnectedTagDeleteDrawer);
