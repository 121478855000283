import React from 'react';
import Logo from './Logo';
import { Stack, VStack, Container } from '@chakra-ui/react';
import HStack from './HStack';
import Box from './Box';

export interface CleanLayoutProps {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  logoSize?: 'sm' | 'md' | 'lg' | 'none';
  align?: 'start' | 'center';
  header?: any;
}

const CleanLayout: React.FC<CleanLayoutProps> = ({
  children,
  size,
  logoSize,
  align,
  header,
}) => {
  return (
    <Container
      maxW={size}
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems={align}
    >
      <Stack spacing={12} width="100%">
        <HStack>
          {logoSize === 'sm' && <Logo size="sm" />}
          <Box flexGrow={1}>{header}</Box>
        </HStack>
        {logoSize !== 'none' && logoSize !== 'sm' && (
          <VStack>
            <Logo size={logoSize} />
          </VStack>
        )}
        <div>{children}</div>
      </Stack>
    </Container>
  );
};

CleanLayout.defaultProps = {
  size: 'xl',
  logoSize: 'md',
  align: 'center',
};

export default CleanLayout;
