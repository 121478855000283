import React from 'react';
import FormField from '../../../components/FormField';
import DynamicField, { DynamicFieldType } from '../domain/DynamicField';
import RichSelect from '../../../ui/RichSelect';
import Textarea from '../../../ui/Textarea';
import Stack from '../../../ui/Stack';
import Input from '../../../ui/Input';
import DrawerForm from '../../../components/DrawerForm';
import {
  EDIT_FORM_QUERY_PARAM_NAME,
  TAG_LABELS,
  TYPE_LABELS,
} from '../constants';
import camelCase from 'lodash/camelCase';
import { t } from '../../../i18n';
import { FormLabel } from '@chakra-ui/react';

export interface DynamicFieldFormPayload {
  name: string;
  description?: string;
  options?: string[];
  tags?: string[];
  type?: DynamicFieldType;
}

export interface DynamicFieldFormProps {
  dynamicField?: DynamicField;
  onSubmit: (field: DynamicFieldFormPayload) => void;
}

function showOptions(type: DynamicFieldType) {
  return [DynamicFieldType.MULTISELECT, DynamicFieldType.SELECT].includes(type);
}

const DynamicFieldForm: React.VFC<DynamicFieldFormProps> = ({
  dynamicField,
  onSubmit,
}) => {
  return (
    <DrawerForm
      header={
        dynamicField
          ? `${t('Edit')} "${dynamicField.name}"`
          : t('Add dynamic field')
      }
      variant="modal"
      name={EDIT_FORM_QUERY_PARAM_NAME}
      onSubmit={onSubmit}
      defaultValues={{
        type: DynamicFieldType.TEXT,
        ...dynamicField,
      }}
    >
      {({ formContext: { watch, setValue } }) => (
        <Stack spacing={4}>
          <FormField name="label" label={t('Title')} required>
            <Input
              autoFocus
              onChange={(e) => {
                if (dynamicField) {
                  return;
                }

                setValue('name', camelCase(e.target.value));
              }}
            />
          </FormField>

          <FormField
            name="name"
            label={t('Key')}
            required
            helperText={t('Unique key used to identify this field')}
          >
            <Input isReadOnly={!!dynamicField} />
          </FormField>

          <FormField name="description" label={t('Description')}>
            <Textarea />
          </FormField>

          {dynamicField ? (
            <FormLabel>
              {t('Type')}
              <Input isReadOnly value={TYPE_LABELS[dynamicField.type]} />
            </FormLabel>
          ) : (
            <FormField name="type" label={t('Type')} required>
              <RichSelect
                simpleValue
                options={Object.entries(TYPE_LABELS).map(([value, label]) => ({
                  value,
                  label,
                }))}
              />
            </FormField>
          )}

          {showOptions(watch('type')) && (
            <FormField name="options" label={t('Options')}>
              <RichSelect
                multiple
                simpleValue
                options={[]}
                noOptionsMessage={() => t('Type to add new option')}
                formatCreateLabel={(value) => `${t('Add')} "${value}"`}
                onCreate={(option) =>
                  setValue('options', [...(watch('options') || []), option])
                }
              />
            </FormField>
          )}

          <FormField name="tags" label={t('Apply to')}>
            <RichSelect
              multiple
              simpleValue
              options={Object.entries(TAG_LABELS).map(([value, label]) => ({
                value,
                label,
              }))}
            />
          </FormField>
        </Stack>
      )}
    </DrawerForm>
  );
};

export default DynamicFieldForm;
