import React from 'react';
import { observer } from 'mobx-react';
import CleanLayout, { CleanLayoutProps } from '../ui/CleanLayout';
import HStack from '../ui/HStack';
import ConnectedUserMenu from './ConnectedUserMenu';

export interface ConnectedCleanLayoutProps extends CleanLayoutProps {}

const ConnectedCleanLayout: React.FC<ConnectedCleanLayoutProps> = ({
  children,
  header,
  ...props
}) => {
  return (
    <CleanLayout
      header={
        <HStack justifyContent="space-between">
          <div>{header}</div>
          <div>
            <ConnectedUserMenu hidePersons />
          </div>
        </HStack>
      }
      {...props}
    >
      {children}
    </CleanLayout>
  );
};

export default observer(ConnectedCleanLayout);
