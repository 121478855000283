import { TagType } from '../../domain/Tag';
import defaults from './defaults';

const itTags: { [key in TagType]: string[] } = {
  [TagType.POSITION]: [
    ...defaults[TagType.POSITION],
    'Architect',
    'Developer',
    'Manager',
    'Designer',
    'Tester',
    'Analyst',
    'Devops',
    'Support',
  ],
  [TagType.TECHNOLOGY]: [
    ...defaults[TagType.TECHNOLOGY],
    'Javascript',
    'Typescript',
    'Go',
    'Swift',
    'Ruby',
    'Python',
    'Scala',
    'Rust',
    'Unity',
    'C',
    'C#',
    'C++',
    'PHP',
    'Node.js',
    'Angular',
    'React',
    'Vue.js',
    '.NET',
    'Java',
  ],
  [TagType.LOCATION]: [...defaults[TagType.LOCATION]],
  [TagType.PROJECT_TYPE]: [...defaults[TagType.PROJECT_TYPE]],
  [TagType.LANGUAGE]: [...defaults[TagType.LANGUAGE]],
  [TagType.CUSTOM]: [...defaults[TagType.CUSTOM]],
};

export default itTags;
