export function downloadFile(
  content: string,
  fileType: string,
  fileName: string,
) {
  const blob = new Blob([content], {
    type: fileType,
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url;
  link.click();
}

export function downloadCsv(rows: string[][], fileName = 'file.csv') {
  const content = rows
    .map((row) => row.map((cell) => `"${cell.replace('"', '\\"')}"`).join(','))
    .join('\n');

  return downloadFile(content, 'text/csv', fileName);
}
