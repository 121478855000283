import Tag, { TagType } from '../domain/Tag';
import { IndustryType } from '../components/IndustrySelect';
import defaultTags from '../seed/tags/defaults';
import itTags from '../seed/tags/it';
import Participation from '../domain/Participation';
import { t } from '../i18n';

export function stringifyTagType(type: TagType): string {
  const stringValues: { [key in TagType]: string } = {
    [TagType.PROJECT_TYPE]: t('Project types'),
    [TagType.LANGUAGE]: t('Communication language'),
    [TagType.TECHNOLOGY]: t('Expertise'),
    [TagType.POSITION]: t('Can fill following roles'),
    [TagType.LOCATION]: t('Locations'),
    [TagType.CUSTOM]: t('Custom'),
  };

  return stringValues[type] || type;
}

export function getPredefinedTagsByIndustry(
  industry: IndustryType | string,
): Tag[] {
  if (industry === IndustryType.IT) {
    return tagsByTypeToArray(itTags);
  }

  return tagsByTypeToArray(defaultTags);
}

export function pickTagIdsFromParticipations(
  participations: Participation[],
): string[] {
  const tagIds: string[] = [];

  participations.forEach((participation) => {
    if (!participation.tagId || tagIds.includes(participation.tagId)) {
      return;
    }

    tagIds.push(participation.tagId);
  });

  return tagIds;
}

function tagsByTypeToArray(tagsByType: { [key in TagType]: string[] }): Tag[] {
  const results: Tag[] = [];

  for (const type in tagsByType) {
    // @ts-ignore
    for (const name of tagsByType[type]) {
      const tag = new Tag();
      tag.type = type as TagType;
      tag.name = name;
      results.push(tag);
    }
  }

  return results;
}

export function getCustomTagContexts(tags: Tag[]): string[] {
  return Array.from(
    new Set(
      tags
        .map((tag) => tag.context)
        .filter(Boolean)
        .sort(),
    ),
  );
}
