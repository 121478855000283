import { singleton } from 'tsyringe';
import ParticipationStore from './ParticipationStore';
import ProjectStore from './ProjectStore';
import { ProjectPeriod, ProjectState, ProjectType } from '../domain/Project';
import { ParticipationState } from '../domain/Participation';
import { equalsOrIsInArray } from '../util/array';
import orderBy from 'lodash/orderBy';
import OrganizationStore from './OrganizationStore';
import PersonStore from './PersonStore';

export interface ProjectFilters {
  type?: ProjectType;
  personId?: string;
  organizationId?: string;
  relatedOrganizationId?: string;
  currentOrganization?: boolean;
  currentPerson?: boolean;
  period?: ProjectPeriod | ProjectPeriod[];
  state?: ProjectState | ProjectState[];
  relatedToProjectId?: string;
}

@singleton()
export default class ProjectAccessStore {
  public constructor(
    private readonly projectStore: ProjectStore,
    private readonly projectParticipationStore: ParticipationStore,
    private readonly organizationStore: OrganizationStore,
    private readonly personStore: PersonStore,
  ) {}

  public getFilteredProjects(
    filters: ProjectFilters,
    sort?: [string, 'asc' | 'desc'],
  ) {
    const {
      personId,
      type,
      organizationId,
      period,
      state,
      relatedOrganizationId,
    } = {
      ...filters,
      ...(filters.currentOrganization
        ? { organizationId: this.organizationStore.currentOrganizationId }
        : {}),
      ...(filters.currentPerson
        ? { personId: this.personStore.currentPerson?.id }
        : {}),
    } as ProjectFilters;

    let items = this.projectStore.items.filter((project) => {
      if (organizationId && project.organizationId !== organizationId) {
        return false;
      }

      if (type && project.type !== type) {
        return false;
      }

      //if its related company list then check if any accounts in that company have participation in any project
      if (relatedOrganizationId) {
        const participation = this.projectParticipationStore.getItemsByProject(
          project.id,
        );

        const anyParticipationIncludesCurrentCompany = participation.some(
          (item) => {
            const person = this.personStore.getById(item.personId);
            return person?.organizationId === relatedOrganizationId;
          },
        );

        if (
          !anyParticipationIncludesCurrentCompany &&
          project.relatedOrganizationId !== relatedOrganizationId
        )
          return false;
      }

      // State check
      if (state && !equalsOrIsInArray(state, project.state)) {
        return false;
      }

      // Period check
      if (period && !equalsOrIsInArray(period, project.period)) {
        return false;
      }

      // if project view show only things related to the project
      if (
        filters.relatedToProjectId &&
        project.relatedToProjectId !== filters.relatedToProjectId
      ) {
        return false;
      }

      // Participation check
      if (
        personId &&
        project.ownerPersonId !== personId &&
        this.projectParticipationStore.getFilteredItems({
          personId,
          projectId: project.id,
          state: ParticipationState.APPROVED,
        }).length <= 0
      ) {
        return false;
      }

      return true;
    });

    if (sort) {
      items = orderBy(items, sort[0], sort[1]);
    }

    return items;
  }
}
