import Resource from '../decorators/Resource';
import AbstractDomain from './AbstractDomain';
import { toDate } from '../util/date';
import { t } from '../i18n';

export enum Recurrence {
  ONE_TIME = 'ONE_TIME',
  MONTHLY = 'MONTHLY',
}

export const stringifiedRecurrence: { [key in Recurrence]: string } = {
  ONE_TIME: t('One-time'),
  MONTHLY: t('Monthly'),
};

@Resource.Name('payment-schedules')
export default class PaymentSchedule extends AbstractDomain {
  public projectId: string;
  public dateStart: Date;
  public recurrence: Recurrence;
  public offsetInMonths?: number;

  fromObject(payload: Partial<PaymentSchedule>): this {
    super.fromObject(payload);

    this.projectId = payload.projectId;
    this.dateStart = toDate(payload.dateStart);
    this.recurrence = payload.recurrence;
    this.offsetInMonths = payload.offsetInMonths;
    return this;
  }

  toObject(): Partial<Request> {
    return {
      ...super.toObject(),
      projectId: this.projectId,
      dateStart: this.dateStart,
      recurrence: this.recurrence,
      offsetInMonths: this.offsetInMonths,
    };
  }
}
