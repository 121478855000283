import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import TaskStore from '../store/TaskStore';
import Tasks from '../components/Tasks';

export interface ConnectedTasksAssignedToMeProps {}

const ConnectedTasksAssignedToMe: React.FC<
  ConnectedTasksAssignedToMeProps
> = () => {
  const taskStore = useResolver(TaskStore);
  const personStore = useResolver(PersonStore);

  useEffect(() => {
    taskStore.loadByCurrentPerson();
  }, []);

  return (
    <Tasks
      showProjectInList
      showAssigneeInList={false}
      tasks={taskStore.getFilteredItems({
        personId: personStore.currentPerson.id,
      })}
    />
  );
};

export default observer(ConnectedTasksAssignedToMe);
