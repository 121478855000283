import React from 'react';
import DrawerForm, { DrawerFormProps } from './DrawerForm';
import Stack from '../ui/Stack';
import FormField from './FormField';
import DateInput from '../ui/DateInput';
import Textarea from '../ui/Textarea';
import ConnectedProjectSelect from '../containers/ConnectedProjectSelect';
import Project, { ProjectPeriod, ProjectState } from '../domain/Project';
import { useTranslation } from 'react-i18next';
import { HStack, Input, Select } from '../ui';
import { TaskPriority } from '../domain/Task';
import ConnectedProjectParticipationSelect from '../containers/ConnectedProjectParticipationSelect';

export interface TaskFormDrawerProps extends Omit<DrawerFormProps, 'children'> {
  project?: Project;
  edit?: boolean;
}

const TaskFormDrawer: React.FC<TaskFormDrawerProps> = ({
  project,
  edit,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <DrawerForm
      header={
        project ? `${t('Create task to')} ${project.name}` : t('Create task')
      }
      {...props}
      submitButton={{ text: t('Create') }}
    >
      {({ formContext: { watch } }) => (
        <Stack spacing={4}>
          <FormField name="name" label={t('Title')} required>
            <Input autoFocus />
          </FormField>

          {!project && !edit && (
            <FormField name="projectId" label={t('Project')} required>
              <ConnectedProjectSelect
                state={ProjectState.PUBLISHED}
                period={[ProjectPeriod.UPCOMING, ProjectPeriod.ONGOING]}
              />
            </FormField>
          )}

          {!edit && (
            <FormField name="participationId" label={t('Assignee')} required>
              <ConnectedProjectParticipationSelect
                projectId={project ? project.id : watch('projectId')}
              />
            </FormField>
          )}

          <FormField
            name="priority"
            label={t('Priority')}
            defaultValue={TaskPriority.MEDIUM}
          >
            <Select>
              <option value={TaskPriority.LOW}>Low</option>
              <option value={TaskPriority.MEDIUM}>Medium</option>
              <option value={TaskPriority.HIGH}>High</option>
            </Select>
          </FormField>

          <HStack justifyContent="space-between">
            <FormField
              name="dateStart"
              label={t('Start date')}
              defaultValue={new Date()}
            >
              <DateInput />
            </FormField>

            <FormField name="dateEnd" label={t('End date')}>
              <DateInput />
            </FormField>
          </HStack>

          <FormField name="notes" label={t('Notes')}>
            <Textarea />
          </FormField>
        </Stack>
      )}
    </DrawerForm>
  );
};

export default TaskFormDrawer;
