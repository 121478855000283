import React from 'react';
import Box from './Box';
import HStack from './HStack';
import Tooltip from './Tooltip';

export type Value = string | number;

export interface PillSelectProps {
  options: Array<{ label: string; value: Value; description?: string }>;
  value?: Value | Value[];
  onChange?: (newValue: Value | Value[]) => void;
  isMulti?: boolean;
}

const PillSelect: React.FC<PillSelectProps> = ({
  options,
  value,
  onChange,
  isMulti,
}) => {
  return (
    <HStack spacing={1}>
      {options.map((option) => {
        const arrayValue = (value as Value[]) || [];
        const isSelected = isMulti
          ? arrayValue.includes(option.value)
          : value === option.value;
        const selectedProps = isSelected
          ? {
              color: 'white',
              background:
                'linear-gradient(46.62deg, #7367F0 0%, #9E95F5 93.64%)',
            }
          : {};

        const pill = (
          <Box
            key={option.value}
            tabIndex={0}
            cursor="pointer"
            rounded="md"
            fontSize="sm"
            fontWeight="500"
            userSelect="none"
            px={3}
            height={8}
            display="flex"
            alignItems="center"
            whiteSpace="nowrap"
            transition="background-color .2s"
            onClick={() => {
              if (!onChange) {
                return;
              }

              if (isMulti && !isSelected) {
                onChange([...arrayValue, option.value]);
              } else if (isMulti) {
                onChange(arrayValue.filter((v) => v !== option.value));
              } else {
                onChange(option.value);
              }
            }}
            {...selectedProps}
          >
            {option.label}
          </Box>
        );

        if (option.description) {
          return (
            <Tooltip key={option.value} label={option.description}>
              <div>{pill}</div>
            </Tooltip>
          );
        }

        return pill;
      })}
    </HStack>
  );
};

PillSelect.defaultProps = {};

export default PillSelect;
