import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import InvoiceList from '../components/InvoiceList';
import useResolver from '../hooks/useResolver';
import InvoiceStore from '../store/InvoiceStore';

export interface ConnectedInvoiceListProps {}

const ConnectedInvoiceList: React.FC<ConnectedInvoiceListProps> = ({
  ...props
}) => {
  const invoiceStore = useResolver(InvoiceStore);

  useEffect(() => {
    invoiceStore.loadByCurrentOrganization().catch(() => null);
  }, []);

  return (
    <InvoiceList invoices={invoiceStore.currentOrganizationItems} {...props} />
  );
};

export default observer(ConnectedInvoiceList);
