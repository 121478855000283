import Resource from '../decorators/Resource';
import AbstractDomain from './AbstractDomain';

export enum InvoiceState {
  OPEN = 'OPEN',
  PAID = 'PAID',
  UNKNOWN = 'UNKNOWN',
}

@Resource.Name('invoices')
export default class Invoice extends AbstractDomain {
  public currency: string;
  public amount: number;
  public state: InvoiceState;
  public organizationId: string;
  public invoiceNumber: string;
  public pdfUrl: string;

  fromObject(payload: Partial<Invoice>): this {
    super.fromObject(payload);

    this.currency = payload.currency;
    this.amount = payload.amount;
    this.state = payload.state;
    this.organizationId = payload.organizationId;
    this.invoiceNumber = payload.invoiceNumber;
    this.pdfUrl = payload.pdfUrl;

    return this;
  }

  toObject(): Partial<Invoice> {
    return {
      ...super.toObject(),
      currency: this.currency,
      amount: this.amount,
      state: this.state,
      organizationId: this.organizationId,
      invoiceNumber: this.invoiceNumber,
      pdfUrl: this.pdfUrl,
    };
  }
}
