import React from 'react';
import Participation from '../domain/Participation';
import Select, { SelectProps } from '../ui/Select';
import { formatDatePeriod } from '../util/date';

export interface ParticipationSelectProps extends SelectProps {
  participations: Participation[];
}

const ParticipationSelect: React.FC<ParticipationSelectProps> = ({
  participations,
  ...props
}) => {
  return (
    <Select nullable {...props}>
      {participations.map((participation) => {
        return (
          <option key={participation.id} value={participation.id}>
            {participation.tag?.name}
            {` ${'at'} `}
            {participation.project?.name} (
            {formatDatePeriod(participation.dateStart, participation.dateEnd)})
          </option>
        );
      })}
    </Select>
  );
};

export default ParticipationSelect;
