import Organization from './Organization';
import User from './User';
import Resource from '../decorators/Resource';
import Invitation from './Invitation';
import { humanReadableRole } from '../util/person';
import AbstractDynamicDomain from './AbstractDynamicDomain';

export enum Role {
  ADMIN = 'ADMIN',
  FINANCIAL_MANAGER = 'MANAGER',
  OPERATIONAL_MANAGER = 'OPERATIONAL_MANAGER',
  USER = 'USER',
  DISABLED = 'DISABLED',
}

export const ACTIVE_ROLES = [
  Role.ADMIN,
  Role.OPERATIONAL_MANAGER,
  Role.FINANCIAL_MANAGER,
  Role.USER,
];

export enum PersonState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  UNINVITED = 'UNINVITED',
  INVITATION_EXPIRED = 'INVITATION_EXPIRED',
}

@Resource.Name('persons')
export default class Person extends AbstractDynamicDomain {
  public name: string;
  public position: string;
  public userId?: string | null;
  public user?: User;
  public capacity?: number | null;
  public organizationId: string;
  public role: Role;
  public image?: string;
  protected _organization?: Organization;
  public contactEmail?: string;
  public contactPhone?: string;
  public description?: string;

  public get subTitle(): string {
    const pieces = [this.position, this.organization?.name].filter(Boolean);

    return pieces.join(' at ');
  }

  public get organization(): Organization {
    return this._organization;
  }

  public set organization(value: Organization) {
    this._organization = value;
  }

  public hasPermission(...scope: Role[]): boolean {
    return (
      !scope || scope.length <= 0 || scope.some((role) => role === this.role)
    );
  }

  public hasEditPermission(): boolean {
    return this.hasPermission(
      Role.ADMIN,
      Role.FINANCIAL_MANAGER,
      Role.OPERATIONAL_MANAGER,
    );
  }

  public get humanReadableRole(): string {
    return humanReadableRole(this.role);
  }

  public isDisabled(): boolean {
    return this.role === Role.DISABLED;
  }

  public getState(invitation?: Invitation): PersonState {
    if (this.isDisabled()) {
      return PersonState.INACTIVE;
    }

    if (this.userId) {
      return PersonState.ACTIVE;
    }

    if (!this.userId && !!invitation) {
      return invitation.isExpired()
        ? PersonState.INVITATION_EXPIRED
        : PersonState.PENDING;
    }

    return PersonState.UNINVITED;
  }

  public fromObject(payload: Partial<Person>): this {
    super.fromObject(payload);

    this.name = payload.name;
    this.position = payload.position;
    this.userId = payload.userId;
    this.user = payload.user;
    this.capacity = payload.capacity || 1;
    this.organizationId = payload.organizationId;
    this.role = payload.role;
    this.image = payload.image;
    this.contactEmail = payload.contactEmail;
    this.contactPhone = payload.contactPhone;
    this.description = payload.description;

    return this;
  }

  public toObject(): Partial<Person> {
    return {
      ...super.toObject(),
      name: this.name,
      position: this.position,
      userId: this.userId,
      user: this.user,
      capacity: this.capacity,
      organizationId: this.organizationId,
      role: this.role,
      image: this.image,
      contactEmail: this.contactEmail,
      contactPhone: this.contactPhone,
      description: this.description,
    };
  }
}
