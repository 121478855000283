import React from 'react';
import { t } from '../i18n';
import Badge from '../ui/Badge';
import { ProjectState } from '../domain/Project';

export interface ProjectStatusProps {
  state: ProjectState;
}

const PROJECT_STATE_TEXTS: Record<ProjectState, string> = {
  [ProjectState.DRAFT]: t('Draft'),
  [ProjectState.PUBLISHED]: t('Published'),
  [ProjectState.ARCHIVED]: t('Archived'),
};

const PROJECT_STATE_COLORS: Record<ProjectState, string> = {
  [ProjectState.DRAFT]: 'gray',
  [ProjectState.PUBLISHED]: 'green',
  [ProjectState.ARCHIVED]: 'purple',
};

const ProjectStateBadge: React.FC<ProjectStatusProps> = ({ state }) => {
  return (
    <Badge colorScheme={PROJECT_STATE_COLORS[state]}>
      {PROJECT_STATE_TEXTS[state]}
    </Badge>
  );
};

export default ProjectStateBadge;
