import React from 'react';
import HStack from './HStack';
import Text from './Text';
import PlusIcon from './icons/PlusIcon';
import MinusIcon from './icons/MinusIcon';

export interface TrendProps {
  expectedValue: number;
  value: number;
}

const Trend: React.FC<TrendProps> = ({ expectedValue, value }) => {
  const percentage = expectedValue ? 1 - value / expectedValue : 0;
  const positive = percentage < 0;

  if (percentage === 0 || isNaN(percentage)) {
    return null;
  }

  return (
    <HStack spacing={2}>
      {positive && <PlusIcon color="green.500" />}
      {!positive && <MinusIcon color="red.500" />}
      <Text color="gray.400" fontSize="sm">
        {Math.abs(+(percentage * 100).toFixed())}%
      </Text>
    </HStack>
  );
};

Trend.defaultProps = {};

export default Trend;
