import React from 'react';
import TagToIcon from './TagToIcon';
import { TagType } from '../domain/Tag';
import IconedText, { IconedTextProps } from '../ui/IconedText';

export interface TagProps extends Omit<IconedTextProps, 'icon'> {
  name: string;
  type?: TagType;
  icon?: any;
  fontSize?: string;
}

const TagWithIcon: React.FC<TagProps> = ({
  children,
  name,
  type,
  icon,
  ...props
}) => {
  return (
    <IconedText icon={icon || <TagToIcon name={name} type={type} />} {...props}>
      {children || name}
    </IconedText>
  );
};

export default TagWithIcon;
