import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import OrganizationStore from '../store/OrganizationStore';
import LabeledPriceValue, {
  LabeledPriceValueProps,
} from '../components/LabeledPriceValue';
import { getCurrencySymbol, isCurrencyInFront } from '../util/currency';

const ConnectedLabeledPriceValue: React.FC<LabeledPriceValueProps> = (
  props,
) => {
  const organizationStore = useResolver(OrganizationStore);

  return (
    <LabeledPriceValue
      unit={getCurrencySymbol(organizationStore.currentOrganization.currency)}
      preUnit={isCurrencyInFront(
        organizationStore.currentOrganization.currency,
      )}
      {...props}
    />
  );
};

export default observer(ConnectedLabeledPriceValue);
