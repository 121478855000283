import React, { useEffect, useState } from 'react';
import Select, { SelectProps } from '../ui/Select';
import Input from '../ui/Input';
import { InputGroup, InputRightElement } from '@chakra-ui/react';
import CloseIcon from '../ui/icons/CloseIcon';
import { t } from '../i18n';
import Organization from '../domain/Organization';

export interface IndustrySelectProps extends SelectProps {
  managedOrganizations?: Organization[];
}

export enum IndustryType {
  IT = 'IT & programming',
}

const IndustrySelect: React.FC<IndustrySelectProps> = ({ ...props }) => {
  const [forceSelect, setForceSelect] = useState(false);
  const { managedOrganizations } = props;

  const managedIndustries = managedOrganizations
    ? Array.from(
        new Set(
          managedOrganizations
            .map((org) => org.industry !== IndustryType.IT && org.industry)
            .filter(Boolean),
        ),
      )
    : [];

  useEffect(() => {
    if (props.value === '' && forceSelect) {
      setForceSelect(false);
    }
  }, [props.value]);

  if (
    !forceSelect &&
    props.value !== null &&
    !Object.values(IndustryType).includes(props.value as any)
  ) {
    return (
      <InputGroup>
        <Input
          placeholder={t('Type industry..')}
          autoFocus
          {...(props as any)}
        />
        <InputRightElement
          children={
            <CloseIcon
              onClick={() => {
                props.onChange && props.onChange(null);
              }}
            />
          }
        />
      </InputGroup>
    );
  }

  return (
    <Select autoFocus={forceSelect} data-testid="industry-select" {...props}>
      <option value={null}>{t('Select industry...')}</option>
      <option value={IndustryType.IT}>{t('IT & programming')}</option>
      {managedIndustries.map((d) => {
        return <option value={d}>{d}</option>;
      })}
      <option value="">{t('Other')}</option>
    </Select>
  );
};

export default IndustrySelect;
