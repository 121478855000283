import React from 'react';
import { FiEdit3 } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface EditIconProps extends IconProps {}

const EditIcon: React.FC<EditIconProps> = ({ ...props }) => {
  return <Icon as={FiEdit3} {...props} />;
};

export default EditIcon;
