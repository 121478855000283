import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import Heading from './Heading';

export interface CardProps extends BoxProps {
  inner?: boolean;
  title?: string;
  wrap?: boolean;
}

const Card: React.FC<CardProps> = ({ children, inner, title, ...props }) => {
  return (
    <Box
      bg="white"
      borderRadius="md"
      boxShadow={inner ? '0px 4px 9px 0px rgba(0,0,0,0.06)' : 'lg'}
      p={4}
      overflow="auto"
      {...props}
    >
      {title && (
        <Heading size="md" mb={2}>
          {title}
        </Heading>
      )}
      {children}
    </Box>
  );
};

export default Card;
