import React, { cloneElement, useEffect } from 'react';
import { useClipboard } from '@chakra-ui/react';
import useToast from '../hooks/useToast';

export interface CopyButtonProps {
  value: string;
  instant?: boolean;
  text?: string;
}

const CopyToClipboard: React.FC<CopyButtonProps> = ({
  children,
  instant,
  value,
  text,
}) => {
  const { hasCopied, onCopy } = useClipboard(value);
  const toast = useToast();

  useEffect(() => {
    if (hasCopied) {
      toast({
        description: text,
      });
    }
  }, [hasCopied]);

  useEffect(() => {
    if (value && instant) {
      onCopy();
    }
  }, [instant, value]);

  return (
    <>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        return cloneElement(child, {
          onClick: onCopy,
        });
      })}
    </>
  );
};

CopyToClipboard.defaultProps = {
  text: '✓ Copied',
};

export default CopyToClipboard;
