import React from 'react';
import HStack from './HStack';
import IconButton from './IconButton';
import ArrowIcon from './icons/ArrowIcon';
import { formatDate } from '../util/date';
import Text from './Text';
import Link from './Link';
import { addMonths, subMonths } from 'date-fns';

export interface MonthPickerProps {
  value: Date;
  linkTo: (date: Date) => string;
}

const MonthPicker: React.FC<MonthPickerProps> = ({ value, linkTo }) => {
  return (
    <HStack spacing={2}>
      <Link preserveQuery to={linkTo(subMonths(value, 1))}>
        <IconButton
          variant="ghost"
          icon={<ArrowIcon direction="left" />}
          aria-label="Previous"
        />
      </Link>
      <Text fontWeight="bold">{formatDate(value, 'LLL yyyy')}</Text>
      <Link preserveQuery to={linkTo(addMonths(value, 1))}>
        <IconButton
          variant="ghost"
          icon={<ArrowIcon direction="right" />}
          aria-label="Next"
        />
      </Link>
    </HStack>
  );
};

MonthPicker.defaultProps = {};

export default MonthPicker;
