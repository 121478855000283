import React, { RefObject } from 'react';
import {
  Popover as ChakraPopover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import Box from './Box';

export interface PopoverProps {
  handle: React.ReactNode;
  title?: React.ReactNode | string;
  initialFocusRef?: RefObject<any>;
  children: React.ReactNode | ((context: { onClose: () => void }) => any);
  width?: string | number;
  disabled?: boolean;
}

const Popover: React.FC<PopoverProps> = ({
  initialFocusRef,
  title,
  handle,
  children,
  width,
  disabled = false,
}) => {
  function content() {
    const content = (
      <div style={{ display: 'inline-block' }}>
        <Box
          display="inline-block"
          cursor={disabled ? 'normal' : 'pointer'}
          _hover={{ bg: disabled ? undefined : 'gray.50' }}
        >
          {handle}
        </Box>
      </div>
    );

    if (disabled) return content;

    return <PopoverTrigger>{content}</PopoverTrigger>;
  }

  return (
    <ChakraPopover initialFocusRef={initialFocusRef} matchWidth>
      {({ onClose }) => (
        <>
          {content()}
          <PopoverContent width={width}>
            {title && <PopoverHeader>{title}</PopoverHeader>}
            <PopoverBody>
              {typeof children === 'function'
                ? (children as any)({ onClose })
                : children}
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </ChakraPopover>
  );
};

Popover.defaultProps = {
  width: 'auto',
};

export default Popover;
