import DynamicField from '../domain/DynamicField';
import DynamicFieldService from '../services/DynamicFieldService';
import { singleton } from 'tsyringe';
import AbstractResourceStore from '../../../store/AbstractResourceStore';
import AccountStore from '../../../store/AccountStore';
import OrganizationStore from '../../../store/OrganizationStore';

@singleton()
export default class DynamicFieldStore extends AbstractResourceStore<DynamicField> {
  public constructor(
    private readonly dynamicFieldService: DynamicFieldService,
    private readonly organizationStore: OrganizationStore,
    accountStore: AccountStore,
  ) {
    super(dynamicFieldService, accountStore);
  }

  public async loadByOrganization(organizationId: string) {
    await this.setManyEventually(
      this.dynamicFieldService.getAllByOrganization(organizationId),
    );
  }

  public async loadByCurrentOrganization() {
    return this.loadByOrganization(
      this.organizationStore.currentOrganizationId,
    );
  }

  public organizationItemsByTags(organizationId: string, tags: string[]) {
    return this.getFilteredItems({ organizationId }).filter((item) => {
      return tags.every((tag) => item.tags.includes(tag));
    });
  }
}
