import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import AvailabilityChart, {
  AvailabilityChartProps,
} from '../components/AvailabilityChart';
import useResolver from '../hooks/useResolver';
import ParticipationStore from '../store/ParticipationStore';
import ProjectTagStore from '../store/ProjectTagStore';

export interface ConnectedProjectCapacityChartProps
  extends Omit<AvailabilityChartProps, 'participations' | 'referenceLine'> {
  projectId: string;
}

const ConnectedProjectCapacityChart: React.FC<ConnectedProjectCapacityChartProps> =
  ({ projectId, ...props }) => {
    const participationStore = useResolver(ParticipationStore);
    const projectTagStore = useResolver(ProjectTagStore);

    useEffect(() => {
      participationStore.loadByProject(projectId);
      projectTagStore.loadByProject(projectId);
    }, [projectId]);

    return (
      <AvailabilityChart
        participations={participationStore.getItemsByProject(projectId)}
        referenceLine={projectTagStore.getProjectPositionsCount(projectId)}
        {...props}
      />
    );
  };

export default observer(ConnectedProjectCapacityChart);
