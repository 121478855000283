import React from 'react';
import Select, { SelectProps } from '../ui/Select';
import { RequestTiming, stringifiedRequestTiming } from '../domain/Request';

export interface RequestTimingSelectProps extends SelectProps {}

const RequestTimingSelect: React.FC<RequestTimingSelectProps> = ({
  ...props
}) => {
  return (
    <Select {...props}>
      {[
        RequestTiming.ASAP,
        RequestTiming.NEXT_WEEK,
        RequestTiming.NEXT_MONTH,
        RequestTiming.NEXT_YEAR,
        RequestTiming.CUSTOM_DATE,
      ].map((timing) => {
        return (
          <option key={timing} value={timing}>
            {stringifiedRequestTiming[timing]}
          </option>
        );
      })}
    </Select>
  );
};

export default RequestTimingSelect;
