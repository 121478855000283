import React from 'react';
import { FiTag } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface TagIconProps extends IconProps {}

const TagIcon: React.FC<TagIconProps> = ({ ...props }) => {
  return <Icon as={FiTag} {...props} />;
};

export default TagIcon;
