import React, {
  ComponentType,
  Fragment,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  ButtonGroup,
  InputGroup,
  InputRightElement,
  MenuGroup,
  Tag,
  TagCloseButton,
} from '@chakra-ui/react';
import { Heading, HStack, IconButton, MenuItem } from '../index';
import {
  CheckIcon,
  ChevronIcon,
  DownloadIcon,
  GridIcon,
  ListIcon,
  SearchIcon,
  TimelineIcon,
} from '../icons';
import {
  DataGridAction,
  DataGridField,
  DataGridFilter,
} from './DataGrid.types';
import { DataGridTable } from './DataGridTable';
import { DataGridItems } from './DataGridItems';
import { exportToCsv, isFilterValueSelected } from './DataGrid.utils';
import Input from '../Input';
import { FiFilter } from 'react-icons/all';
import { useFilters, useSearch } from './DataGrid.hooks';
import Menu from '../Menu';
import Button from '../Button';
import Box from '../Box';
import NoContent from '../NoContent';
import { t } from '../../i18n';
import Text from '../Text';
import {
  DataGridTimeline,
  DataGridTimelineSpecificProps,
} from './DataGridTimeline';

export interface DataGridProps<Item, Key extends string> {
  title: string;
  data: Item[];
  fields: Record<Key, DataGridField<Item>>;
  actions?: Array<DataGridAction | undefined | false | null>;
  grid?: {
    render: (input: {
      item: Item;
      index: number;
      getField: (key: Key) => ReactNode;
    }) => ReactNode;
  };
  timeline?: DataGridTimelineSpecificProps<Item, Key>;
  filters?: DataGridFilter<Item>[];
  wrapper?: ComponentType;
  perPage?: number;
  exportable?: boolean;
}

export const DataGrid = <Item, Key extends string>({
  title,
  data,
  fields,
  grid,
  timeline,
  actions,
  filters: availableFilters = [],
  wrapper: Wrapper = Fragment,
  perPage = 25,
  exportable = true,
}: DataGridProps<Item, Key>) => {
  const [page, defaultSetPage] = useState<number>(1);
  const {
    appliedFilters,
    setFilterValues,
    applyFilters,
    addFilterValue,
    removeFilterValue,
  } = useFilters(availableFilters);
  const { applySearch, searchTerm, setSearchTerm } = useSearch(
    Object.values(fields) as DataGridField<Item>[],
  );

  const setPage = (page: number) => {
    window.scrollTo(0, 0);
    defaultSetPage(page);
  };

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, [appliedFilters.length, searchTerm]);

  useEffect(() => {
    if (availableFilters) {
      setFilterValues(
        availableFilters
          .map((filter) =>
            filter.options
              .filter((option) => option.appliedByDefault)
              .map((option) => ({
                key: filter.key,
                value: option.value,
              })),
          )
          .flat(),
      );
    }
  }, []);

  const filteredData = applySearch(applyFilters(data));
  const slicedData = filteredData.slice((page - 1) * perPage, page * perPage);

  const viewOptions = [
    ...(grid
      ? [
          {
            value: 'grid',
            label: t('List view'),
            icon: <GridIcon />,
            element: (
              <DataGridItems data={slicedData} fields={fields} {...grid} />
            ),
          },
        ]
      : []),
    ...(timeline
      ? [
          {
            value: 'timeline',
            label: t('Timeline view'),
            icon: <TimelineIcon />,
            element: (
              <Wrapper>
                <DataGridTimeline
                  data={slicedData}
                  fields={fields}
                  {...timeline}
                />
              </Wrapper>
            ),
          },
        ]
      : []),
    {
      value: 'list',
      label: t('List view'),
      icon: <ListIcon />,
      element: (
        <Wrapper>
          <DataGridTable data={slicedData} fields={fields} />
        </Wrapper>
      ),
    },
  ];
  const [view, setView] = useState<string>(viewOptions[0].value);

  return (
    <div>
      <Wrapper>
        <HStack
          pb={4}
          gap={4}
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Heading size="md">{title}</Heading>
          {actions && (
            <HStack flexDirection="row-reverse" gap={2}>
              {actions.filter(Boolean).map((action, index) => {
                if (!action) {
                  return null;
                }

                const { label, icon, to, onClick } = action;

                return (
                  <Button
                    key={label}
                    leftIcon={icon}
                    onClick={onClick}
                    to={to}
                    {...(index === 0
                      ? { variant: 'solid', colorScheme: 'primary' }
                      : { variant: 'outline' })}
                  >
                    {label}
                  </Button>
                );
              })}
            </HStack>
          )}
        </HStack>
        <HStack justifyContent="space-between" alignItems="center">
          <HStack>
            <InputGroup maxW={280} minW={40}>
              <Input
                placeholder={t('Search')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <InputRightElement
                pointerEvents="none"
                children={<SearchIcon color="gray.400" />}
              />
            </InputGroup>
            {availableFilters.length > 0 && (
              <Menu
                handle={
                  <IconButton
                    variant="outline"
                    aria-label="Filters"
                    icon={<FiFilter />}
                  />
                }
              >
                {availableFilters.map((filter) => (
                  <MenuGroup key={filter.key} title={filter.label}>
                    {filter.options.map((option) => (
                      <MenuItem
                        key={String(option.value)}
                        closeOnSelect={true}
                        {...(isFilterValueSelected(
                          appliedFilters,
                          filter.key,
                          option.value,
                        )
                          ? {
                              onClick: () =>
                                removeFilterValue(filter.key, option.value),
                              icon: <CheckIcon />,
                            }
                          : {
                              onClick: () =>
                                addFilterValue(filter.key, option.value),
                            })}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuGroup>
                ))}
              </Menu>
            )}
          </HStack>
          <HStack gap={1}>
            {exportable && (
              <IconButton
                variant="outline"
                aria-label="Export to CSV"
                onClick={() => exportToCsv(filteredData, fields)}
                title={t('Export to CSV')}
              >
                <DownloadIcon />
              </IconButton>
            )}
            {viewOptions.length > 1 && (
              <ButtonGroup isAttached variant="outline">
                {viewOptions.map(({ value, label, icon }) => (
                  <IconButton
                    key={value}
                    aria-label={label}
                    onClick={() => setView(value)}
                    colorScheme={value === view ? 'primary' : undefined}
                    title={label}
                  >
                    {icon}
                  </IconButton>
                ))}
              </ButtonGroup>
            )}
          </HStack>
        </HStack>
        {appliedFilters.length > 0 && (
          <HStack mt={3} flexWrap="wrap" gap={0}>
            <Text fontSize="sm">
              {t('Applied filters')} ({appliedFilters.length}):
            </Text>

            {appliedFilters.map((filter, index) => (
              <Tag colorScheme="primary" variant="solid" key={filter.label}>
                {filter.options.find((o) => o.value === filter.value)?.label ??
                  filter.value}
                <TagCloseButton
                  onClick={() =>
                    setFilterValues(
                      appliedFilters.filter((_, i) => i !== index),
                    )
                  }
                />
              </Tag>
            ))}

            <Button
              variant="ghost"
              colorScheme="primary"
              onClick={() => setFilterValues([])}
            >
              {t('Clear all')}
            </Button>
          </HStack>
        )}
      </Wrapper>
      <Box mt={4}>
        {filteredData.length <= 0 && (
          <NoContent>
            {t('No results found. Please check your filters')}
          </NoContent>
        )}
        {filteredData.length > 0 &&
          viewOptions.map(
            ({ value, element }) =>
              view === value && (
                <Fragment key={String(value)}>{element}</Fragment>
              ),
          )}
      </Box>
      {filteredData.length > slicedData.length && (
        <Box mt={4}>
          <Wrapper>
            <HStack justifyContent="center">
              {page > 1 && (
                <IconButton
                  aria-label="Previous page"
                  variant="outline"
                  onClick={() => setPage(page - 1)}
                  icon={<ChevronIcon direction="left" />}
                />
              )}
              {filteredData.length > page * perPage && (
                <IconButton
                  aria-label="Next page"
                  variant="outline"
                  onClick={() => setPage(page + 1)}
                  icon={<ChevronIcon direction="right" />}
                />
              )}
            </HStack>
          </Wrapper>
        </Box>
      )}
    </div>
  );
};
