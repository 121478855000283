import React from 'react';
import { FiChevronRight } from 'react-icons/fi';
import ArrowIcon, { ArrowIconProps } from './ArrowIcon';

export interface ChevronIconProps extends ArrowIconProps {
  direction?: 'up' | 'right' | 'down' | 'left';
}

const ChevronIcon: React.FC<ChevronIconProps> = ({ direction, ...props }) => {
  return <ArrowIcon as={FiChevronRight} direction={direction} {...props} />;
};

ChevronIcon.defaultProps = {
  direction: 'right',
};

export default ChevronIcon;
