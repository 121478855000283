import AbstractResourceStore from './AbstractResourceStore';
import Person, { Role } from '../domain/Person';
import PersonService from '../services/PersonService';
import { action, computed, makeObservable, observable } from 'mobx';
import LocalStorageService from '../services/LocalStorageService';
import { singleton } from 'tsyringe';
import AccountStore from './AccountStore';
import orderBy from 'lodash/orderBy';

@singleton()
export default class PersonStore extends AbstractResourceStore<Person> {
  @observable private selectedPersonId: string;

  public constructor(
    private readonly personService: PersonService,
    private readonly localStorageService: LocalStorageService<string>,
    accountStore: AccountStore,
  ) {
    super(personService, accountStore);
    this.selectedPersonId =
      this.localStorageService.getItem('selectedPersonId');
    makeObservable(this);
  }

  public async loadUserRelated() {
    await this.setManyEventually(this.personService.getAllRelatedToUser());
  }

  public async loadByOrganization(organizationId: string): Promise<Person[]> {
    const promise = this.personService.getAllByOrganization(organizationId);
    await this.setManyEventually(promise);

    return promise;
  }

  public async loadByOrganizationWithRelatedOrganizations(
    organizationId: string,
  ): Promise<Person[]> {
    const promise =
      this.personService.getAllByOrganizationWithRelatedOrganizations(
        organizationId,
      );
    await this.setManyEventually(promise);

    return promise;
  }

  public async deactivatePerson(id: string) {
    const person = this.getById(id);

    if (!person) {
      throw new Error('Something went wrong');
    }

    // Ignore already disabled
    if (person.role !== Role.DISABLED) {
      await this.update({ id, role: Role.DISABLED });
    }
  }

  @computed
  public get currentUserPersons(): Person[] {
    const authenticatedUserId = this.accountStore.authenticatedUser?.id;

    if (!authenticatedUserId) {
      return [];
    }

    return this.alphabeticallyOrderedItems.filter(
      (p) => p.userId === authenticatedUserId && p.role !== Role.DISABLED,
    );
  }

  @computed
  public get currentUserNonActivePersons(): Person[] {
    const persons = this.currentUserPersons;

    return persons.filter((p) => p.id !== this.currentPerson.id);
  }

  @computed
  public get currentPerson(): Person | null {
    const selectedPerson =
      this.selectedPersonId &&
      this.currentUserPersons.find((p) => p.id === this.selectedPersonId);

    return selectedPerson || this.currentUserPersons[0] || null;
  }

  @computed
  public get currentOrganizationPersons(): Person[] {
    return this.alphabeticallyOrderedItems.filter(
      (item) => item.organizationId === this.currentPerson?.organizationId,
    );
  }

  public getOrganizationsPersons(organizationIds: string[]): Person[] {
    return this.alphabeticallyOrderedItems.filter((item) =>
      organizationIds.includes(item.organizationId),
    );
  }

  @computed
  public get currentOrganizationActivePersons(): Person[] {
    return this.currentOrganizationPersons.filter(
      (item) => item.role !== Role.DISABLED,
    );
  }

  @action
  public async loadCurrentOrganizationPersons(): Promise<Person[]> {
    return this.loadByOrganization(this.currentPerson?.organizationId);
  }

  @action
  public async loadCurrentAndRelatedOrganizationPersons(): Promise<Person[]> {
    return this.loadByOrganizationWithRelatedOrganizations(
      this.currentPerson?.organizationId,
    );
  }

  @action
  public selectPerson(id: string): void {
    this.selectedPersonId = id;
    this.localStorageService.setItem('selectedPersonId', id);
  }

  public isCurrentPerson(id: string): boolean {
    return this.currentPerson?.id === id;
  }

  @computed
  public get alphabeticallyOrderedItems(): Person[] {
    return orderBy(this.items, 'name', 'asc');
  }
}
