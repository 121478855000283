import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useMonthPicker from '../hooks/useMonthPicker';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import PriceStore from '../store/PriceStore';
import { endOfMonth, startOfMonth } from 'date-fns';
import PersonsBudget from '../components/PersonsBudget';
import OrganizationPeopleFilters from '../components/OrganizationPeopleFilters';
import ProjectStore from '../store/ProjectStore';
import TagStore from '../store/TagStore';
import useFilteredPersons from '../hooks/useFilteredPersons';
import Stack from '../ui/Stack';
import HStack from '../ui/HStack';
import MoreActionsMenu from '../ui/MoreActionsMenu';
import MenuItem from '../ui/MenuItem';
import PlusIcon from '../ui/icons/PlusIcon';
import { Role } from '../domain/Person';
import OrganizationStore from '../store/OrganizationStore';
import useSWR from 'swr';
import { formatDate } from '../util/date';
import FinanceService from '../services/FinanceService';
import SkeletonLoader from '../ui/SkeletonLoader';
import Alert from '../ui/Alert';
import ConnectedAdditionalCostDrawer from './ConnectedAdditionalCostDrawer';
import { t } from '../i18n';
import TimesheetService from '../services/TimesheetService';
import Participation from '../domain/Participation';
import orderBy from 'lodash/orderBy';

export interface ConnectedOrganizationPersonsBudgetProps {
  organizationId?: string;
  removeFilter?: boolean;
}

const ConnectedOrganizationPersonsBudget: React.FC<
  ConnectedOrganizationPersonsBudgetProps
> = ({ organizationId: suppliedOrganizationId, removeFilter }) => {
  const month = useMonthPicker();
  const financeService = useResolver(FinanceService);
  const personStore = useResolver(PersonStore);
  const priceStore = useResolver(PriceStore);
  const projectStore = useResolver(ProjectStore);
  const tagStore = useResolver(TagStore);
  const timesheetService = useResolver(TimesheetService);
  const organizationStore = useResolver(OrganizationStore);

  const monthStart = startOfMonth(month);
  const monthEnd = endOfMonth(month);
  const organizationId =
    suppliedOrganizationId || organizationStore.currentOrganizationId;

  const persons = personStore.currentOrganizationPersons;

  const {
    data: finances,
    error,
    mutate,
  } = useSWR(
    `ConnectedOrganizationPersonsBudget:${formatDate(month)}|${organizationId}`,
    () =>
      financeService.getFinances({
        organizationId,
        dateStart: monthStart,
        dateEnd: monthEnd,
      }),
  );

  const { data: hours } = useSWR(
    `ConnectedOrganizationPersonTimeline:${organizationId}|${formatDate(
      month,
    )}`,
    () =>
      timesheetService.getDaily({
        organizationId,
        dateStart: monthStart,
        dateEnd: monthEnd,
      }),
  );

  for (const financeResult of finances || []) {
    if (
      !financeResult.participation ||
      persons.find((p) => p.id === financeResult.participation.personId)
    ) {
      continue;
    }

    const person = personStore.getByIdOrLoad(
      financeResult.participation.personId,
    );

    if (!person || person.isDeleted()) {
      continue;
    }

    persons.push(person);
  }

  const { persons: filteredPersons } = useFilteredPersons(persons);

  useEffect(() => {
    personStore.loadCurrentOrganizationPersons();
    priceStore.loadForCurrentOrganization();
    projectStore.loadByActiveOrganization();
  }, []);

  const editable = personStore.currentPerson.hasPermission(Role.ADMIN);

  if (error) {
    return <Alert status="error">{String(error)}</Alert>;
  }

  if (!finances || !hours) {
    return <SkeletonLoader />;
  }

  return (
    <div>
      <Stack spacing={4}>
        <HStack justifyContent="space-between">
          {removeFilter ? (
            <div />
          ) : (
            <OrganizationPeopleFilters
              persons={persons}
              projects={projectStore.currentOrganizationProjects}
              positions={tagStore.positions}
              date={month}
              simple
            />
          )}
          {editable && (
            <MoreActionsMenu label="Persons budget actions">
              <MenuItem
                icon={<PlusIcon />}
                preserveQuery
                to="?add.additional&additionalType=revenue"
              >
                {t('Add additional revenue')}
              </MenuItem>

              <MenuItem
                icon={<PlusIcon />}
                preserveQuery
                to="?add.additional&additionalType=cost"
              >
                {t('Add additional cost')}
              </MenuItem>
            </MoreActionsMenu>
          )}
        </HStack>
        <PersonsBudget
          persons={orderBy(filteredPersons, 'name')}
          finances={finances.filter(
            (f) =>
              !f.person || !!filteredPersons.find((p) => p.id === f.person.id),
          )}
          timesheet={hours.map(({ participation, entries }) => ({
            entries,
            participations: [new Participation().fromObject(participation)],
            person: personStore.getById(participation.personId),
          }))}
          onChange={() => mutate()}
        />
      </Stack>
      <ConnectedAdditionalCostDrawer
        externalId={organizationId}
        organizationId={organizationId}
        onSuccess={() => mutate()}
      />
    </div>
  );
};

export default observer(ConnectedOrganizationPersonsBudget);
