import React from 'react';
import Project from '../domain/Project';
import DatePeriod from '../ui/DatePeriod';

interface ProjectPeriodProps {
  project: Project;
}

const ProjectPeriod: React.FC<ProjectPeriodProps> = ({ project }) => {
  return (
    <DatePeriod
      withTime={project.datesIncludeTime}
      dateStart={project.dateStart}
      dateEnd={project.dateEnd}
    />
  );
};

export default ProjectPeriod;
