import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';
import PaymentSchedule from '../domain/PaymentSchedule';

@injectable()
export default class PaymentScheduleService extends AbstractRestfulHttpService<PaymentSchedule> {
  public constructor(apiService: ApiService) {
    super(PaymentSchedule, apiService);
  }

  public getAllByProject(projectId: string): Promise<PaymentSchedule[]> {
    return this.getMany({ projectId });
  }
}
