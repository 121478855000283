import { SearchResource, SearchResourceEnum } from '../domain/Search';

export function humanReadableSearchResource(
  search: SearchResourceEnum,
): string {
  const texts: {
    [key in SearchResourceEnum]: any;
  } = {
    [SearchResourceEnum.PERSON]: 'Person',
    [SearchResourceEnum.ORGANIZATION]: 'Client',
    [SearchResourceEnum.PROJECT]: 'Project',
  };

  return texts[search] || '';
}

export function getLinkFromSearchResource(resource: SearchResource): string {
  if (resource.name === SearchResourceEnum.ORGANIZATION) {
    return `/clients/view/${resource.value}`;
  }

  if (resource.name === SearchResourceEnum.PROJECT) {
    return `/projects/view/${resource.value}`;
  }

  if (resource.name === SearchResourceEnum.PERSON) {
    return `/profile/view/${resource.value}`;
  }
}
