import React from 'react';
import { FiFlag } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface FlagIconProps extends IconProps {}

const FlagIcon: React.FC<FlagIconProps> = ({ ...props }) => {
  return <Icon as={FiFlag} {...props} />;
};

export default FlagIcon;
