import { Tooltip } from '@chakra-ui/react';
import React from 'react';
import { VscStarEmpty } from 'react-icons/vsc';
import Icon, { IconProps } from '../Icon';

export interface FlagIconProps extends IconProps {}

const PriorityIcon: React.FC<FlagIconProps> = ({ ...props }) => {
  return (
    <Tooltip hasArrow label="High priority">
      <span>
        <Icon as={VscStarEmpty} color="crimson" {...props} />
      </span>
    </Tooltip>
  );
};

export default PriorityIcon;
