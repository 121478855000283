import AbstractDomain from './AbstractDomain';
import Resource from '../decorators/Resource';
import { toDate } from '../util/date';

@Resource.Name('invitations')
export default class Invitation extends AbstractDomain {
  public personId: string;
  public hash: string;
  public email?: string;
  public title: string;
  public description: string;
  public personName: string;
  public organizationName: string;
  public expireDate: Date;

  public toObject(): Partial<Invitation> {
    return {
      ...super.toObject(),
      personId: this.personId,
      hash: this.hash,
      email: this.email,
      title: this.title,
      description: this.description,
      expireDate: this.expireDate,
      personName: this.personName,
      organizationName: this.organizationName,
    };
  }

  public fromObject(payload: Partial<Invitation>): this {
    super.fromObject(payload);

    this.personId = payload.personId;
    this.hash = payload.hash;
    this.email = payload.email;
    this.title = payload.title;
    this.description = payload.description;
    this.expireDate = toDate(payload.expireDate);
    this.personName = payload.personName;
    this.organizationName = payload.organizationName;

    return this;
  }

  public isExpired(): boolean {
    return this.expireDate < new Date();
  }

  public getAcceptURL(unique?: boolean): string {
    return `${window.location.protocol}//${
      window.location.host
    }/accept-invitation?hash=${this.hash}${
      this.email ? `&email=${this.email}` : ''
    }${unique ? `&d=${Date.now()}` : ''}`;
  }
}
