import React from 'react';
import { HStack, Text, TextProps } from '@chakra-ui/react';

export interface IconedTextProps extends Partial<Pick<TextProps, 'fontSize'>> {
  icon: React.ReactNode;
}

const IconedText: React.FC<IconedTextProps> = ({
  children,
  icon,
  fontSize,
}) => {
  return (
    <HStack spacing={2} py={1}>
      <span style={{ flexShrink: 0 }}>{icon}</span>
      <Text fontSize={fontSize}>{children}</Text>
    </HStack>
  );
};

IconedText.defaultProps = {
  fontSize: 'xs',
};

export default IconedText;
