import React from 'react';
import { FiMail } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface EnvelopeIconProps extends IconProps {}

const EnvelopeIcon: React.FC<EnvelopeIconProps> = ({ ...props }) => {
  return <Icon as={FiMail} {...props} />;
};

export default EnvelopeIcon;
