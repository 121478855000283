import AbstractResourceStore from './AbstractResourceStore';
import OrganizationRelation from '../domain/OrganizationRelation';
import OrganizationRelationService from '../services/OrganizationRelationService';
import { singleton } from 'tsyringe';
import AccountStore from './AccountStore';
import OrganizationStore from './OrganizationStore';
import { computed } from 'mobx';

@singleton()
export default class OrganizationRelationStore extends AbstractResourceStore<OrganizationRelation> {
  public constructor(
    private readonly organizationRelationService: OrganizationRelationService,
    private readonly organizationStore: OrganizationStore,
    accountStore: AccountStore,
  ) {
    super(organizationRelationService, accountStore);
  }

  public async loadByOrganization(organizationId: string) {
    await this.setManyEventually(
      this.organizationRelationService.getAllByOrganization(organizationId),
    );
  }

  public async loadByCurrentOrganization() {
    return this.loadByOrganization(
      this.organizationStore.currentOrganizationId,
    );
  }

  public async disableOrganizationRelation(organizationId: string) {
    await this.update({
      id: organizationId,
      dateEnd: new Date(),
    });
  }

  @computed
  public get currentOrganizationRelations(): OrganizationRelation[] {
    return this.getFilteredItems({
      organizationId: this.organizationStore.currentOrganizationId,
    });
  }

  public getCurrentOrganizationRelationsToOrganization(
    relatedOrganizationId: string,
  ): OrganizationRelation[] {
    return this.getFilteredItems({
      organizationId: this.organizationStore.currentOrganizationId,
      relatedOrganizationId,
    });
  }
}
