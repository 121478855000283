import React from 'react';
import { observer } from 'mobx-react';
import ConnectedLabeledPriceValue from './ConnectedLabeledPriceValue';
import useSWR from 'swr';
import { formatDate } from '../util/date';
import useResolver from '../hooks/useResolver';
import FinanceService from '../services/FinanceService';
import { LabeledValue } from '../ui';
import Text from '../ui/Text';
import { Skeleton } from '@chakra-ui/react';
import { t } from '../i18n';
import OrganizationStore from '../store/OrganizationStore';

export interface ConnectedProjectCashflowValueProps {
  organizationId?: string;
  projectId?: string;
  dateStart: Date;
  dateEnd: Date;
  centered?: boolean;
}

const ConnectedProjectCashflowValue: React.FC<
  ConnectedProjectCashflowValueProps
> = ({
  projectId,
  dateStart,
  dateEnd,
  organizationId: suppliedOrganizationId,
  ...rest
}) => {
  const financeService = useResolver(FinanceService);
  const organizationStore = useResolver(OrganizationStore);
  const organizationId =
    suppliedOrganizationId || organizationStore.currentOrganizationId;

  const commonProps = {
    label: t('Cash flow prognosis'),
    ...rest,
  };

  const { data, error } = useSWR(
    `ConnectedProjectCashflowValue:${projectId}|${organizationId}|${formatDate(
      dateStart,
    )}|${formatDate(dateEnd)}`,
    () =>
      financeService.getCashflows({
        organizationId,
        projectId,
        dateStart,
        dateEnd,
      }),
  );

  if (error) {
    return (
      <LabeledValue
        {...commonProps}
        value={
          <Text color="red" fontSize="sm" fontWeight="normal">
            {t('Unable to load')}
          </Text>
        }
      />
    );
  }

  if (!data) {
    return (
      <LabeledValue
        {...commonProps}
        value={<Skeleton height="20px" width="64px" />}
      />
    );
  }

  return (
    <ConnectedLabeledPriceValue
      {...commonProps}
      digits={2}
      value={data.reduce((total, cashflow) => total + cashflow.value, 0)}
    />
  );
};

export default observer(ConnectedProjectCashflowValue);
