import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ProjectCardAction, {
  ProjectCardActionProps,
} from '../components/ProjectCardAction';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import RequestStore from '../store/RequestStore';

export interface ConnectedProjectCardActionProps
  extends Omit<ProjectCardActionProps, 'personId'> {}

const ConnectedProjectCardAction: React.FC<ConnectedProjectCardActionProps> = ({
  ...props
}) => {
  const personStore = useResolver(PersonStore);
  const requestStore = useResolver(RequestStore);

  useEffect(() => {
    requestStore.loadByProject(props.project.id);
  }, [props.project.id]);

  return (
    <ProjectCardAction
      personId={personStore.currentPerson?.id}
      editable={personStore.currentPerson?.hasEditPermission()}
      requests={requestStore.getProjectRequests(props.project.id)}
      {...props}
    />
  );
};

export default observer(ConnectedProjectCardAction);
