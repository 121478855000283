import React, { useState } from 'react';
import { observer } from 'mobx-react';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import useResolver from '../hooks/useResolver';
import AuthenticationService from '../services/AuthenticationService';

export interface ConnectedForgotPasswordFormProps {}

const ConnectedForgotPasswordForm: React.FC<ConnectedForgotPasswordFormProps> =
  ({ children }) => {
    const accountService = useResolver(AuthenticationService);
    const [submitted, setSubmitted] =
      useState<{ email: string } | false>(false);

    return (
      <ForgotPasswordForm
        submitted={submitted}
        onSubmit={async ({ email }) => {
          await accountService.sendPasswordResetEmail(
            email,
            window.location.origin,
          );
          setSubmitted({ email });
        }}
      />
    );
  };

export default observer(ConnectedForgotPasswordForm);
