import React, { useRef } from 'react';
import PopoverForm from './PopoverForm';
import FormField from './FormField';
import Input from '../ui/Input';
import Price, { PriceType } from '../domain/Price';
import HStack from '../ui/HStack';
import Select from '../ui/Select';
import { getPriceSuffix } from '../util/price';
import useResolver from '../hooks/useResolver';
import OrganizationStore from '../store/OrganizationStore';
import { getCurrencySymbol } from '../util/currency';
import DateInput from '../ui/DateInput';

export interface PricePopoverFormProps {
  onChange?: (values: Partial<Price>) => void;
  handle: React.ReactNode;
  defaultValues?: Partial<Price>;
  disabled?: boolean;
}

const PricePopoverForm: React.FC<PricePopoverFormProps> = ({
  defaultValues,
  handle,
  onChange,
  disabled = false,
}) => {
  const organizationStore = useResolver(OrganizationStore);
  const { currentOrganization } = organizationStore;
  const inputRef = useRef();

  if (!onChange) {
    return <>{handle}</>;
  }

  return (
    <PopoverForm
      initialFocusRef={inputRef}
      onSubmit={onChange}
      handle={handle}
      width={440}
      disabled={disabled}
    >
      <HStack>
        <FormField name="value" defaultValue={defaultValues?.value}>
          <Input
            placeholder={getCurrencySymbol(currentOrganization.currency)}
            ref={inputRef}
            autoFocus
            type="number"
            step="any"
          />
        </FormField>
        <FormField name="type" defaultValue={defaultValues?.type}>
          <Select>
            <option value={PriceType.HOURLY}>
              {getPriceSuffix(PriceType.HOURLY, true)}
            </option>
            <option value={PriceType.MONTHLY}>
              {getPriceSuffix(PriceType.MONTHLY, true)}
            </option>
            {/* <option value={PriceType.FIXED}>fixed</option> */}
          </Select>
        </FormField>
        <FormField name="dateStart" defaultValue={defaultValues?.dateStart}>
          <DateInput placeholder="Start date" width={48} />
        </FormField>
      </HStack>
    </PopoverForm>
  );
};

export default PricePopoverForm;
