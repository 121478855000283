import React from 'react';
import ProjectTag from '../domain/ProjectTag';
import Select, { SelectProps } from '../ui/Select';
import Participation, { ParticipationState } from '../domain/Participation';

export interface ProjectPositionSelectProps extends SelectProps {
  projectTags: ProjectTag[];
  participations?: Participation[];
}

const ProjectPositionSelect: React.FC<ProjectPositionSelectProps> = ({
  projectTags,
  participations,
  ...props
}) => {
  return (
    <Select nullable {...props}>
      {projectTags.map((projectTag) => {
        const tagApprovedParticipationsCapacity = participations
          .filter(
            (p) =>
              p.state === ParticipationState.APPROVED &&
              p.tagId === projectTag.tagId,
          )
          .reduce((a, b) => a + +b.capacity || 1, 0);
        const availableCapacity =
          projectTag.capacity &&
          +(+projectTag.capacity - tagApprovedParticipationsCapacity).toFixed(
            3,
          );

        return (
          <option key={projectTag.id} value={projectTag.tagId}>
            {projectTag.tag.name}{' '}
            {projectTag.capacity &&
              `(Available: ${Math.max(availableCapacity, 0)})`}
          </option>
        );
      })}
    </Select>
  );
};

export default ProjectPositionSelect;
