import AbstractResourceStore from './AbstractResourceStore';
import Request, { RequestAction, RequestState } from '../domain/Request';
import RequestService from '../services/RequestService';
import AccountStore from './AccountStore';
import { singleton } from 'tsyringe';
import OrganizationStore from './OrganizationStore';
import PersonStore from './PersonStore';
import { computed } from 'mobx';
import ParticipationStore from './ParticipationStore';

@singleton()
export default class RequestStore extends AbstractResourceStore<Request> {
  public constructor(
    private readonly requestService: RequestService,
    private readonly organizationStore: OrganizationStore,
    private readonly personStore: PersonStore,
    private readonly participationStore: ParticipationStore,
    accountStore: AccountStore,
  ) {
    super(requestService, accountStore);
  }

  public async approveRequest(requestId: string): Promise<void> {
    await this.changeRequestState(requestId, RequestState.APPROVED);
  }

  public async rejectRequest(requestId: string): Promise<void> {
    await this.changeRequestState(requestId, RequestState.REJECTED);
  }

  public async cancelRequest(requestId: string): Promise<void> {
    await this.changeRequestState(requestId, RequestState.CANCELED);
  }

  public async changeRequestState(
    requestId: string,
    state: RequestState,
  ): Promise<void> {
    const request = this.getByIdOrLoad(requestId);

    if (!request) {
      throw new Error('Failed to load');
    }

    request.state = state;

    await this.update(request);
  }

  public async loadByPerson(personId: string) {
    await this.setManyEventually(this.requestService.getAllByPerson(personId));
  }

  public async loadByCurrentPerson() {
    await this.loadByPerson(this.personStore.currentPerson.id);
  }

  public async loadByProject(projectId: string) {
    await this.setManyEventually(
      this.requestService.getAllByProject(projectId),
    );
  }

  public async loadByOrganization(organizationId: string) {
    await this.setManyEventually(
      this.requestService.getAllByOrganization(organizationId),
    );
  }

  public async loadByCurrentOrganization() {
    return this.loadByOrganization(
      this.organizationStore.currentOrganizationId,
    );
  }

  @computed
  public get currentOrganizationRequests(): Request[] {
    const currentPerson = this.personStore.currentPerson;

    return this.items.filter((item) => {
      const person = this.personStore.getByIdOrLoad(item.personId);
      const correctOrganization =
        person?.organizationId === currentPerson.organizationId;

      return (
        correctOrganization &&
        (!item.targetPersonId || item.targetPersonId === currentPerson?.id)
      );
    });
  }

  @computed
  public get currentPersonRequests(): Request[] {
    return this.getFilteredItems({
      personId: this.personStore.currentPerson.id,
    }).filter((r) => r.action !== RequestAction.TASK);
  }

  @computed
  public get currentPersonTasks(): Request[] {
    console.log('this', this.items);
    return this.getFilteredItems({
      personId: this.personStore.currentPerson.id,
      action: RequestAction.TASK,
    });
  }

  public getRequestFromProject(projectId: string): Request[] {
    return this.getFilteredItems({
      state: RequestState.REQUESTED,
    }).filter((item) => {
      if (item.state !== RequestState.REQUESTED) {
        return false;
      }

      if (item.toProjectId === projectId) {
        return true;
      }

      if (
        item.fromParticipationId &&
        this.participationStore.getById(item.fromParticipationId)?.projectId ===
          projectId
      ) {
        return true;
      }

      return false;
    });
  }

  public getProjectRequests(projectId: string): Request[] {
    return this.getRequestFromProject(projectId).filter(
      (r) => r.action !== RequestAction.TASK,
    );
  }

  public getProjectTasks(projectId: string): Request[] {
    return this.getRequestFromProject(projectId).filter(
      (r) => r.action === RequestAction.TASK,
    );
  }
}
