import React from 'react';
import {
  HStack,
  Menu as ChakraMenu,
  MenuButton,
  MenuList as ChakraMenuList,
  Portal,
} from '@chakra-ui/react';
import MenuItem from './MenuItem';
import { ChevronDownIcon } from '@chakra-ui/icons';

export interface MenuProps {
  handle: React.ReactNode;
  withChevron?: boolean;
  placement?: 'bottom-end' | 'bottom-start';
  disabled?: boolean;
  color?: string;
  onHandleClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  fullWidth?: boolean;
  p?: number;
  isMulti?: boolean;
}

export { MenuItem };

const MenuList: React.FC = ({ children }) => {
  return (
    <ChakraMenuList zIndex={2000} maxH={80} overflowY="auto">
      {children}
    </ChakraMenuList>
  );
};

const Menu: React.FC<MenuProps> = ({
  color,
  disabled,
  placement,
  withChevron,
  p,
  handle,
  children,
  onHandleClick,
  fullWidth,
  isMulti,
}) => {
  return (
    <ChakraMenu
      placement={placement}
      isLazy
      offset={[-7, 0]}
      matchWidth={fullWidth}
      closeOnSelect={!isMulti}
    >
      {({ isOpen }) => (
        <>
          <MenuButton
            type="button"
            disabled={disabled}
            p={p}
            onClick={onHandleClick}
            width={fullWidth ? 'full' : null}
          >
            {!withChevron && handle}
            {withChevron && (
              <HStack spacing={4} color={color} justifyContent="space-between">
                {handle}
                <ChevronDownIcon />
              </HStack>
            )}
          </MenuButton>
          {isOpen && (
            <Portal>
              <MenuList>{children}</MenuList>
            </Portal>
          )}
        </>
      )}
    </ChakraMenu>
  );
};

Menu.defaultProps = {
  placement: 'bottom-end',
};

export default Menu;
