import Resource from '../decorators/Resource';
import AbstractDomain from './AbstractDomain';
import { toDate } from '../util/date';

@Resource.Name('worklogs')
export default class Worklog extends AbstractDomain {
  public timeInSeconds: number;
  public date: Date;
  public personId: string;
  public projectId: string;
  public participationId: string;
  public taskId: string;

  fromObject(payload: Partial<Worklog>): this {
    super.fromObject(payload);

    this.date = toDate(payload.date);
    this.personId = payload.personId;
    this.timeInSeconds = payload.timeInSeconds;
    this.projectId = payload.projectId;
    this.participationId = payload.participationId;
    this.taskId = payload.taskId;

    return this;
  }

  toObject(): Partial<Worklog> {
    return {
      ...super.toObject(),
      timeInSeconds: this.timeInSeconds,
      date: this.date,
      personId: this.personId,
      projectId: this.projectId,
      participationId: this.participationId,
      taskId: this.taskId,
    };
  }

  public getTimeInHours() {
    return this.timeInSeconds / 3600;
  }
}
