import React from 'react';
import { FiBarChart2 } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface TimelineIconProps extends IconProps {}

const TimelineIcon: React.FC<TimelineIconProps> = ({ ...props }) => {
  return <Icon as={FiBarChart2} transform="rotate(90deg)" {...props} />;
};

export default TimelineIcon;
