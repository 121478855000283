import React from 'react';
import Project from '../domain/Project';
import DeleteDrawer from './DeleteDrawer';
import { useTranslation } from 'react-i18next';
import { Text } from '../ui';

interface ProjectDeleteDrawerProps {
  name: string;
  onSubmit: () => void | Promise<void>;
  project: Project;
}

const ProjectEndDrawer: React.FC<ProjectDeleteDrawerProps> = ({
  name,
  onSubmit,
  project,
}) => {
  const { t } = useTranslation();

  if (!project?.id) {
    return null;
  }

  return (
    <DeleteDrawer
      name={name}
      onSubmit={onSubmit}
      confirmationText={null}
      header={`${t('End')} ${project.name}?`}
      buttonText={t('End')}
    >
      <Text>
        {t(
          `This will set project and all active participations end date to now`,
        )}
      </Text>
    </DeleteDrawer>
  );
};

export default ProjectEndDrawer;
