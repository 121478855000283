import React from 'react';
import FormField from './FormField';
import i18n, { t } from '../i18n';
import Select from '../ui/Select';
import Form from './Form';
import FormSubmitButton from './FormSubmitButton';
import { VStack } from '../ui';

export interface AppearanceFormProps {
  onSubmit: (data: { language: string }) => void;
}

const AppearanceForm: React.FC<AppearanceFormProps> = ({ onSubmit }) => {
  return (
    <Form onSubmit={onSubmit}>
      <VStack spacing={4} align="start">
        <FormField
          name="language"
          defaultValue={i18n.language}
          label={t('Language')}
        >
          <Select>
            <option value={'en'}>English</option>
            <option value={'et'}>Eesti</option>
          </Select>
        </FormField>

        <FormSubmitButton>{t('Save')}</FormSubmitButton>
      </VStack>
    </Form>
  );
};

export default AppearanceForm;
