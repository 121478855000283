import React, { useEffect } from 'react';
import Box from './Box';
import useDocumentDownload, {
  DocumentDownloadOptions,
} from '../hooks/useDocumentDownload';
import useQueryValue from '../hooks/useQueryValue';

export interface DocumentExportProps extends Partial<DocumentDownloadOptions> {
  handle: React.ReactNode;
}

const DocumentExport: React.FC<DocumentExportProps> = ({
  handle,
  children,
  ...options
}) => {
  const { documentRef, downloadDocument } = useDocumentDownload(options);
  const queryValue = useQueryValue('export');

  useEffect(() => {
    if (queryValue === 'pdf') {
      downloadDocument();
    }
  }, [queryValue]);

  return (
    <div>
      <span onClick={downloadDocument}>{handle}</span>
      <Box width="210mm" position="fixed" right="9999px" bottom="9999px">
        <div ref={documentRef}>{children}</div>
      </Box>
    </div>
  );
};
export default DocumentExport;
