import React from 'react';
import { FiLink } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface LinkIconProps extends IconProps {}

const LinkIcon: React.FC<LinkIconProps> = ({ ...props }) => {
  return <Icon as={FiLink} {...props} />;
};

export default LinkIcon;
