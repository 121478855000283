import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import ProjectStore from '../store/ProjectStore';
import TagStore from '../store/TagStore';
import ProjectOverview from '../components/ProjectOverview';
import PersonStore from '../store/PersonStore';
import { Role } from '../domain/Person';

export interface ConnectedProjectOverviewProps {
  projectId: string;
}

const ConnectedProjectOverview: React.FC<ConnectedProjectOverviewProps> = ({
  projectId,
}) => {
  const projectStore = useResolver(ProjectStore);
  const tagStore = useResolver(TagStore);
  const personStore = useResolver(PersonStore);
  const project = projectStore.getById(projectId);
  const currentPerson = personStore.currentPerson;

  useEffect(() => {
    projectStore.loadOne(projectId);
    tagStore.loadByCurrentOrganization();
  }, [projectId]);

  const editable =
    project?.ownerPersonId === currentPerson?.id ||
    currentPerson?.hasEditPermission();

  return (
    <ProjectOverview
      project={project}
      editable={editable}
      showBudget={
        currentPerson?.hasPermission(Role.ADMIN) ||
        (project?.ownerPersonId === currentPerson?.id &&
          currentPerson?.hasPermission(Role.FINANCIAL_MANAGER))
      }
    />
  );
};

export default observer(ConnectedProjectOverview);
