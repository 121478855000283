import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ParticipationSelect, {
  ParticipationSelectProps,
} from '../components/ParticipationSelect';
import useResolver from '../hooks/useResolver';
import ParticipationStore from '../store/ParticipationStore';
import { ParticipationState } from '../domain/Participation';
import ProjectStore from '../store/ProjectStore';
import TagStore from '../store/TagStore';

export interface ConnectedParticipationSelectProps
  extends Omit<ParticipationSelectProps, 'participations'> {
  personId?: string;
  ignoredParticipationId?: string;
}

const ConnectedParticipationSelect: React.FC<ConnectedParticipationSelectProps> =
  ({ personId, ignoredParticipationId, ...props }) => {
    const participationStore = useResolver(ParticipationStore);
    const projectStore = useResolver(ProjectStore);
    const tagStore = useResolver(TagStore);

    useEffect(() => {
      if (personId) {
        participationStore.loadByPerson(personId);
      }
    }, [personId]);

    if (!personId) {
      return null;
    }

    const participations = participationStore
      .getFilteredItems({
        personId,
        state: ParticipationState.APPROVED,
      })
      .filter((p) => p.id !== ignoredParticipationId && !p.hasEnded());

    participations.forEach((participation) => {
      participation.project = projectStore.getByIdOrLoad(
        participation.projectId,
      );

      participation.tag = tagStore.getByIdOrLoad(participation.tagId);
    });

    return <ParticipationSelect participations={participations} {...props} />;
  };

export default observer(ConnectedParticipationSelect);
