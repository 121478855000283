import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import useQueryValue from '../hooks/useQueryValue';
import ProjectStore from '../store/ProjectStore';
import ProjectEndDrawer from '../components/ProjectEndDrawer';

export interface ConnectedProjectDeleteDrawerProps {
  name: string;
}

const ConnectedProjectEndDrawer: React.FC<
  ConnectedProjectDeleteDrawerProps
> = ({ name }) => {
  const projectStore = useResolver(ProjectStore);
  const projectId = useQueryValue(name);

  if (!projectId) {
    return null;
  }

  const handleSubmit = async () => {
    await projectStore.update({
      id: project.id,
      dateEnd: new Date(),
    });
  };

  const project = projectStore.getById(projectId);

  return (
    <ProjectEndDrawer name={name} project={project} onSubmit={handleSubmit} />
  );
};

export default observer(ConnectedProjectEndDrawer);
