import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';
import HistoryEvent from '../domain/HistoryEvent';

@injectable()
export default class HistoryEventService extends AbstractRestfulHttpService<HistoryEvent> {
  public constructor(apiService: ApiService) {
    super(HistoryEvent, apiService);
  }

  public getAllByProject(projectId: string): Promise<HistoryEvent[]> {
    return this.getMany({ projectId });
  }
}
