import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import HistoryEventStore from '../store/HistoryEventStore';
import ProjectHistory from '../components/ProjectHistory';

export interface ConnectedProjectHistoryProps {
  projectId: string;
}

const ConnectedProjectHistory: React.FC<ConnectedProjectHistoryProps> = ({
  projectId,
}) => {
  const historyEventStore = useResolver(HistoryEventStore);

  useEffect(() => {
    historyEventStore.loadByProject(projectId);
  }, [projectId]);

  const events = historyEventStore.getFilteredItems({ projectId });

  return <ProjectHistory events={events} />;
};

export default observer(ConnectedProjectHistory);
