import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import TagStore from '../store/TagStore';
import ProjectTagStore from '../store/ProjectTagStore';
import TagsField, { TagsFieldProps } from '../components/TagsField';
import { TagType } from '../domain/Tag';

export interface ConnectedProjectTagsFieldProps
  extends Omit<TagsFieldProps, 'availableTags' | 'assignedTags'> {
  projectId?: string;
}

const ConnectedProjectTagsField: React.FC<ConnectedProjectTagsFieldProps> = ({
  projectId,
  ...props
}) => {
  const tagStore = useResolver(TagStore);
  const projectTagStore = useResolver(ProjectTagStore);

  const projectTagTypes = [
    TagType.TECHNOLOGY,
    TagType.LANGUAGE,
    TagType.LOCATION,
    TagType.PROJECT_TYPE,
  ];

  useEffect(() => {
    tagStore.loadByCurrentOrganization();
  }, []);

  return (
    <TagsField
      groupByType={projectTagTypes}
      availableTags={tagStore.tagsForProject.filter((t) =>
        projectTagTypes.includes(t.type),
      )}
      assignedTags={projectTagStore
        .getItemsByProject(projectId)
        .map((pt) => pt.tag)
        .filter(Boolean)}
      {...props}
    />
  );
};

export default observer(ConnectedProjectTagsField);
