import React from 'react';
import Box from './Box';

interface TabProps {
  title: string;
  badge?: string | number;
}

const Tab: React.FC<TabProps> = ({ title, badge }) => {
  return (
    <>
      {title}
      {badge && (
        <>
          &nbsp;&nbsp;
          <Box
            color="brand.500"
            bg="white"
            borderRadius="50%"
            width="16px"
            fontSize="xs"
            fontWeight="500"
            height="16px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {typeof badge === 'number' ? (badge > 99 ? '∞' : badge) : badge}
          </Box>
        </>
      )}
    </>
  );
};

export default Tab;
