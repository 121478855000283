import React from 'react';
import {
  Sparklines,
  SparklinesCurve,
  SparklinesReferenceLine,
} from 'react-sparklines-typescript';
import { useTheme } from '@chakra-ui/react';
import get from 'lodash/get';

export interface SparklineProps {
  points: number[];
  referenceLine?: number;
  width?: number;
  height?: number;
}

const Sparkline: React.FC<SparklineProps> = ({
  points,
  referenceLine,
  width,
  height,
}) => {
  const theme = useTheme();
  const max = Math.max(referenceLine || 0, ...points);
  let referenceLinePosition = 0;

  if (referenceLine) {
    referenceLinePosition = height - (referenceLine / max) * height - 1;
  }

  return (
    <Sparklines data={points} width={width} height={height} min={0} max={max}>
      <SparklinesCurve
        style={{ fill: 'none', strokeWidth: 2 }}
        color={get(theme.colors, 'brand.500')}
      />
      {referenceLine ? (
        <SparklinesReferenceLine
          style={{
            stroke: 'lightgray',
            strokeOpacity: 0.75,
            strokeDasharray: '2, 2',
          }}
          value={referenceLinePosition}
          type={'custom' as any}
        />
      ) : (
        <React.Fragment />
      )}
    </Sparklines>
  );
};

Sparkline.defaultProps = {
  width: 120,
  height: 20,
};

export default Sparkline;
