import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import OrganizationStore from '../store/OrganizationStore';
import { OrganizationState } from '../domain/Organization';
import { Redirect, useLocation } from 'react-router-dom';
import ErrorPage from '../views/ErrorPage';
import FullPageLoader from '../ui/FullPageLoader';

const SubscriptionGuard: React.FC = ({ children }) => {
  const organizationStore = useResolver(OrganizationStore);
  const location = useLocation();

  const organization = organizationStore.currentOrganization;

  if (!organization && organizationStore.pending) {
    return <FullPageLoader />;
  }

  if (!organization) {
    return <ErrorPage />;
  }

  const expiredRoute = '/subscription-expired';
  const disabledRoute = '/organization-suspended';

  if (
    !location.pathname.startsWith(expiredRoute) &&
    organization.state === OrganizationState.SUBSCRIPTION_EXPIRED
  ) {
    return <Redirect to={expiredRoute} />;
  }

  if (
    !location.pathname.startsWith(disabledRoute) &&
    organization.state === OrganizationState.DISABLED
  ) {
    return <Redirect to={disabledRoute} />;
  }

  return <>{children}</>;
};

export default observer(SubscriptionGuard);
