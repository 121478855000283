import { useBreakpointValue } from '@chakra-ui/react';

export enum Device {
  DESKTOP,
  MOBILE,
}

class DeviceResult {
  public readonly device: Device;

  constructor(device: Device) {
    this.device = device;
  }

  get isMobile() {
    return this.device === Device.MOBILE;
  }

  get isDesktop() {
    return this.device === Device.DESKTOP;
  }
}

export default function useDevice(): DeviceResult {
  const device = useBreakpointValue({
    base: Device.MOBILE,
    md: Device.DESKTOP,
  });

  return new DeviceResult(device);
}
