import React from 'react';
import Organization from '../domain/Organization';
import Card from '../ui/Card';
import HStack from '../ui/HStack';
import EditButton from './EditButton';
import ConnectedOrganizationFormDrawer from '../containers/ConnectedOrganizationFormDrawer';
import ResponsiveGrid from '../ui/ResponsiveGrid';
import Text from '../ui/Text';
import Image from '../ui/Image';
import SimpleGrid from '../ui/SimpleGrid';
import { t } from '../i18n';

export interface OrganizationCardProps {
  organization: Organization;
  editable?: boolean;
}

const OrganizationCard: React.FC<OrganizationCardProps> = ({
  organization,
  editable,
}) => {
  const data = [
    [t('Industry'), organization.industry],
    [t('Registry code'), organization.registryCode],
    [t('VAT number'), organization.vatNumber],
    [t('Physical address'), organization.address],
    [t('Contact e-mail'), organization.email],
    [t('Contact phone'), organization.phone],
  ].filter(([, value]) => !!value);

  return (
    <Card>
      {editable && <ConnectedOrganizationFormDrawer />}
      <SimpleGrid templateColumns="1fr auto" alignItems="center">
        <HStack>
          {organization.logo && (
            <Image src={organization.logo} maxH="40px" maxW="60px" />
          )}
          <Text fontSize="md" fontWeight="bold">
            {organization.name}
          </Text>
        </HStack>
        <div>{editable && <EditButton to="?organization" />}</div>
      </SimpleGrid>

      <ResponsiveGrid templateColumns="1fr" gap={8} columnGap={16} mt={8}>
        {data.map(([label, value]) => (
          <div key={label}>
            <Text fontWeight="bold" color="brand.500" fontSize="xs">
              {label}
            </Text>
            <div>{value || ''}</div>
          </div>
        ))}
      </ResponsiveGrid>
    </Card>
  );
};

export default OrganizationCard;
