import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '../ui/FormControl';
import { t } from '../i18n';

interface FormFieldProps {
  name: string;
  required?: boolean;
  label?: string | React.ReactNode;
  helperText?: string | React.ReactNode;
  defaultValue?: any;
  pattern?: RegExp;
  validate?: (value: any, getValues: () => any) => string | true;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
}

const FormField: React.FC<FormFieldProps> = ({
  children,
  name,
  required,
  label,
  helperText,
  defaultValue,
  pattern,
  validate,
  min,
  max,
  minLength,
  maxLength,
}) => {
  const { errors, control, getValues, formState, setValue } = useFormContext();
  const error = errors[name]?.message || null;
  const [storedDefaultValue, setStoredDefaultValue] = useState(defaultValue);

  if (React.Children.count(children) > 1) {
    throw new Error('Invalid children count');
  }

  // Updates default value when form field has not been touched yet
  useEffect(() => {
    if (defaultValue !== storedDefaultValue && !formState.dirtyFields[name]) {
      setValue(name, defaultValue);
      setStoredDefaultValue(defaultValue);
    }
  }, [defaultValue]);

  const child = children && React.Children.only(children);

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required && t('This is required'),
        pattern,
        validate: validate && ((value: any) => validate(value, getValues)),
        min: {
          value: min,
          message: `${t('Minimum value is')} ${min}`,
        },
        max: {
          value: max,
          message: `${t('Maximum value is')} ${max}`,
        },
        minLength: {
          value: minLength,
          message: `${t('Minimum length is')} ${minLength}`,
        },
        maxLength: {
          value: maxLength,
          message: `${t('Maximum length is')} ${maxLength}`,
        },
      }}
      defaultValue={defaultValue || null}
      render={({ onChange, onBlur, value }) => (
        <FormControl
          id={`formField__${name}`}
          isRequired={required}
          isInvalid={!!error}
          labelText={label}
          errorText={error}
          helperText={helperText}
        >
          {child &&
            React.isValidElement(child) &&
            React.cloneElement(child, {
              id: `formField__${name}`,
              name,
              onChange: (...args: unknown[]) => {
                onChange(...args);
                child.props.onChange?.(...args);
              },
              onBlur: (...args: unknown[]) => {
                onBlur();
                child.props.onBlur?.(...args);
              },
              value,
            })}
        </FormControl>
      )}
    />
  );
};

FormField.defaultProps = {
  defaultValue: '',
};

export default FormField;
