import Worklog from '../domain/Worklog';
import {
  addDays,
  endOfDay,
  endOfMonth,
  isSameDay,
  isWeekend,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { calculateMonthCapacity, capacityToDayHours } from './capacity';
import Participation, { ParticipationState } from '../domain/Participation';
import { Sheet } from '../services/FileExportService';
import Project from '../domain/Project';
import { formatDatePeriod, getAllDatesInMonth } from './date';
import Person from '../domain/Person';
import { ReactNode } from 'react';

export function getTotalWorkHoursBetweenDates(
  date1: Date,
  date2: Date,
  worklogs: Worklog[],
  capacity = 1,
) {
  let date = new Date(date1);
  let hourCount = 0;
  const usedWorklogs: Worklog[] = [];

  while (date < date2) {
    // eslint-disable-next-line
    const worklog = worklogs.find((w) => isSameDay(w.date, date));

    if (worklog) {
      hourCount += worklog.getTimeInHours();
      usedWorklogs.push(worklog);
    } else if (!isWeekend(date)) {
      hourCount += capacityToDayHours(capacity);
    }
    date = addDays(date, 1);
  }

  // User could have logged time outside of participation dates
  worklogs.forEach((worklog) => {
    if (!usedWorklogs.includes(worklog)) {
      hourCount += worklog.getTimeInHours();
    }
  });

  return hourCount;
}

export function getTotalWorkHoursForParticipations(
  participations: Participation[],
  worklogs: Worklog[],
) {
  return participations.reduce((acc, current) => {
    return (
      acc +
      getTotalWorkHoursBetweenDates(
        current.dateStart || new Date(0),
        current.dateEnd || new Date(),
        worklogs.filter(
          (w) =>
            w.personId === current.personId &&
            w.projectId === current.projectId,
        ),
        current.capacity,
      )
    );
  }, 0);
}

export function timesheetToSheet(
  name: string,
  month: Date,
  timesheet: Timesheet,
): Sheet {
  const sheet = new Sheet();
  const dateStart = startOfMonth(month);
  const dateEnd = endOfMonth(month);

  sheet
    .setCurrentCell(name)
    .setNextRowCell('Monthly timesheet')
    .setNextRowCell(formatDatePeriod(dateStart, dateEnd))
    .setNextRowCell('')
    .setNextRowCells(['', ...timesheet.labels.map((l) => l.value)]);

  timesheet.rows.forEach((row) => {
    sheet
      .resetX()
      .setNextRowCells([
        row.head,
        ...row.columns.map((col) =>
          col.future ? '' : col.value?.toString() || '',
        ),
        row.total?.toString() || '',
      ]);
  });

  sheet.setNextRowCells([
    '',
    ...timesheet.labels.map(() => ''),
    (+timesheet.rows
      .reduce((total, row) => total + row.worked, 0)
      .toFixed(1)).toString(),
  ]);

  sheet.setColumnWidths([16, ...timesheet.labels.map(() => 4), 4]);

  return sheet;
}

export interface Timesheet {
  labels: Array<{
    value: string;
    date: Date;
  }>;
  rows: Array<{
    head: string;
    type: string;
    person?: Person;
    project?: Project;
    participations: Participation[];
    worked: number;
    planned: number;
    total: number;
    end?: ReactNode;
    columns: Array<{
      value: number;
      date: Date;
      future: boolean;
      weekend: boolean;
      participations: Participation[];
    }>;
  }>;
}

export interface TimesheetOptions {
  showOnlyActiveParticipations?: boolean;
  showLessThan80?: boolean;
  showOver100?: boolean;
}

export function generateProjectTimesheet(
  month: Date,
  personsOrProjects: Array<Person | Project>,
  worklogs: Worklog[],
  participations: Participation[],
  options: TimesheetOptions = {},
): Timesheet {
  const datesInMonth = getAllDatesInMonth(month);
  const currentDate = new Date();
  const startOfMonth = startOfDay(datesInMonth[0]);
  const endOfMonth = endOfDay(datesInMonth[datesInMonth.length - 1]);

  return {
    labels: datesInMonth.map((d) => ({
      value: d.getDate().toString().padStart(2, '0'),
      date: d,
    })),
    rows: personsOrProjects
      .map((personOrProject: Person | Project) => {
        const filteredParticipations = participations.filter(
          (participation) => {
            if (participation.state !== ParticipationState.APPROVED) {
              return false;
            }

            if (
              personOrProject instanceof Person &&
              participation.personId !== personOrProject.id
            ) {
              return false;
            }

            if (
              personOrProject instanceof Project &&
              participation.projectId !== personOrProject.id
            ) {
              return false;
            }

            if (
              options.showOnlyActiveParticipations &&
              !participation.isBetweenDates(startOfMonth, endOfMonth)
            ) {
              return false;
            }

            return true;
          },
        );

        if (
          options.showOnlyActiveParticipations &&
          filteredParticipations.length <= 0
        ) {
          return null;
        }

        const monthCapacity = calculateMonthCapacity(
          filteredParticipations,
          month,
        );

        if (options.showLessThan80 && !(monthCapacity < 0.8)) {
          return null;
        }

        if (options.showOver100 && !(monthCapacity > 1)) {
          return null;
        }

        let predictedTotal: number = 0;

        const columns = datesInMonth.map((date) => {
          const weekend = isWeekend(date);

          const personDayWorklog = worklogs.find((worklog) => {
            return (
              (personOrProject instanceof Person
                ? worklog.personId === personOrProject.id
                : worklog.projectId === personOrProject.id) &&
              isSameDay(worklog.date, date)
            );
          });

          let value = personDayWorklog
            ? +(personDayWorklog.timeInSeconds / 3600).toFixed(4)
            : null;

          const activeParticipationsThisDay = filteredParticipations.filter(
            (p) => p.isOngoing(date),
          );

          // Predicted value calculation
          let predicted = null;

          if (!weekend) {
            predicted =
              +activeParticipationsThisDay
                .reduce((a, b) => a + capacityToDayHours(b.capacity), 0)
                .toFixed(4) || null;
          }

          if (!personDayWorklog) {
            value = predicted;
          }

          predictedTotal += predicted || 0;

          return {
            value,
            date,
            worklog: personDayWorklog,
            weekend,
            future: date > currentDate,
            participations: activeParticipationsThisDay,
          };
        });

        return {
          head: personOrProject.name,
          type: 'default',
          person: personOrProject instanceof Person ? personOrProject : null,
          project: personOrProject instanceof Project ? personOrProject : null,
          columns,
          total: +columns.reduce((a, b) => a + +b.value, 0).toFixed(8),
          worked: columns
            .filter((column) => !column.future)
            .reduce((a, b) => a + +b.value, 0),
          planned: predictedTotal,
          participations: filteredParticipations,
        };
      })
      .filter(Boolean),
  };
}
