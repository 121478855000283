import React from 'react';
import {
  Alert as ChakraAlert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
} from '@chakra-ui/react';
import Box from './Box';

export interface AlertProps {
  title?: string;
  status: 'success' | 'error' | 'warning' | 'info';
}

const Alert: React.FC<AlertProps> = ({ title, children, status }) => {
  return (
    <ChakraAlert status={status}>
      <AlertIcon />
      <Box flex={1}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {children && <AlertDescription>{children}</AlertDescription>}
      </Box>
    </ChakraAlert>
  );
};

Alert.defaultProps = {};

export default Alert;
