import React from 'react';
import Box from '../ui/Box';
import ConnectedOrganizationPaymentDrawer from '../containers/ConnectedOrganizationPaymentDrawer';
import PaymentMethod from '../domain/PaymentMethod';
import TextToIcon from './TextToIcon';
import HStack from '../ui/HStack';
import Button from '../ui/Button';
import Alert from '../ui/Alert';
import Stack from '../ui/Stack';
import SkeletonLoader from '../ui/SkeletonLoader';
import PlusIcon from '../ui/icons/PlusIcon';
import { t } from '../i18n';

export interface CardsManagementProps {
  cards: PaymentMethod[];
  loading?: boolean;
}

const CardsManagement: React.FC<CardsManagementProps> = ({
  cards,
  loading,
}) => {
  if (cards.length <= 0 && loading) {
    return <SkeletonLoader />;
  }

  return (
    <Box>
      <ConnectedOrganizationPaymentDrawer />

      <Stack spacing={4}>
        {cards.length <= 0 && (
          <Alert status="warning">
            {t(
              'No payment method defined. Please add a card to keep using platform',
            )}
          </Alert>
        )}
        {cards.map((card) => {
          return (
            <HStack key={card.id} spacing={4}>
              <TextToIcon name={card.type} />
              <div>{`**** **** **** ${card.endingNumbers}`}</div>
            </HStack>
          );
        })}
        <div>
          <Button
            to="?add.card"
            variant="outline"
            colorScheme="brand"
            leftIcon={<PlusIcon />}
          >
            Add a card
          </Button>
        </div>
      </Stack>
    </Box>
  );
};

export default CardsManagement;
