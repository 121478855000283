import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import useInterval from '../hooks/useInterval';
import NotificationsHandle from '../components/NotificationsHandle';
import NotificationsDrawer from '../components/NotificationsDrawer';
import NotificationStore from '../store/NotificationStore';

export interface ConnectedNotificationsHandleProps {}

const ConnectedNotificationsHandleAndDrawer: React.FC<ConnectedNotificationsHandleProps> =
  () => {
    const notificationStore = useResolver(NotificationStore);
    const {
      currentPersonNotifications: notifications,
      currentPersonUnreadNotificationsCount: count,
    } = notificationStore;

    useEffect(() => {
      notificationStore.loadByActivePerson();
    }, []);

    useInterval(() => {
      notificationStore.loadByActivePerson();
    }, 30000);

    return (
      <>
        <NotificationsHandle count={count} />
        <NotificationsDrawer
          notifications={notifications}
          onMark={(notification) => notificationStore.markAsRead(notification)}
        />
      </>
    );
  };

export default observer(ConnectedNotificationsHandleAndDrawer);
