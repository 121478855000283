import { injectable } from 'tsyringe';
import ApiService from './ApiService';
import { toISO } from '../util/date';
import Participation from '../domain/Participation';
import Project from '../domain/Project';
import Person from '../domain/Person';
import ProjectTag from '../domain/ProjectTag';

export interface FinanceResult {
  project?: Project;
  projectTag?: ProjectTag;
  participation?: Participation;
  person?: Person;
  priceId?: string;
  description: string;
  revenuePrognosis: number;
  costPrognosis: number;
  marginPrognosis: number;
  dateStart?: string;
  dateEnd?: string;
}

export interface CashflowResult {
  date: string;
  value: number;
}

@injectable()
export default class FinanceService {
  public constructor(private readonly apiService: ApiService) {}

  public async getFinances({
    organizationId,
    projectId,
    personId,
    dateEnd,
    dateStart,
    includeRelatedOrganizationsPersons,
  }: {
    organizationId: string;
    projectId?: string | string[];
    personId?: string | string[];
    dateStart: Date;
    dateEnd: Date;
    includeRelatedOrganizationsPersons?: boolean;
  }): Promise<FinanceResult[]> {
    const { data } = await this.apiService.get<FinanceResult[]>('/finances', {
      params: {
        organizationId,
        projectId: projectId?.toString(),
        personId: personId?.toString(),
        includeRelatedOrganizationsPersons: includeRelatedOrganizationsPersons,
        dateStart: toISO(dateStart),
        dateEnd: toISO(dateEnd),
      },
    });

    return data.map((d) => {
      if (d.participation) {
        d.participation = new Participation().fromObject(d.participation);
      }

      if (d.project) {
        d.project = new Project().fromObject(d.project);
      }

      if (d.person) {
        d.person = new Person().fromObject(d.person);
      }

      if (d.projectTag) {
        d.projectTag = new ProjectTag().fromObject(d.projectTag);
      }

      return d;
    });
  }

  public async getCashflows({
    organizationId,
    projectId,
    dateEnd,
    dateStart,
  }: {
    organizationId: string;
    projectId?: string;
    dateStart: Date;
    dateEnd: Date;
  }) {
    const { data } = await this.apiService.get<CashflowResult[]>(
      '/finances/cashflows',
      {
        params: {
          organizationId,
          projectId: projectId?.toString(),
          dateStart: toISO(dateStart),
          dateEnd: toISO(dateEnd),
        },
      },
    );

    return data;
  }
}
