import React from 'react';
import { FiCheck } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface CheckIconProps extends IconProps {}

const CheckIcon: React.FC<CheckIconProps> = ({ ...props }) => {
  return <Icon as={FiCheck} {...props} />;
};

export default CheckIcon;
