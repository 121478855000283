import React from 'react';
import {
  Tooltip as ChakraTooltip,
  TooltipProps as ChakraTooltipProps,
} from '@chakra-ui/react';

export interface TooltipProps extends ChakraTooltipProps {}

const Tooltip: React.FC<TooltipProps> = ({ ...props }) => {
  return <ChakraTooltip {...props} />;
};

Tooltip.defaultProps = {};

export default Tooltip;
