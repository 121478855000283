import React from 'react';
import NoContent from '../ui/NoContent';
import Page from '../components/Page';
import { t } from '../i18n';

const OrganizationSuspended: React.FC = () => {
  return (
    <Page>
      <NoContent>{t('This organization has been suspended')}</NoContent>
    </Page>
  );
};

export default OrganizationSuspended;
