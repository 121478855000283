import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import OrganizationStore from '../store/OrganizationStore';
import ManagedOrganizationList from '../components/ManagedOrganizationList';
import PersonStore from '../store/PersonStore';
import OrganizationRelationStore from '../store/OrganizationRelationStore';
import TagStore from '../store/TagStore';

export interface ConnectedManagedOrganizationListProps {}

const ConnectedManagedOrganizationList: React.FC<
  ConnectedManagedOrganizationListProps
> = () => {
  const organizationStore = useResolver(OrganizationStore);
  const tagStore = useResolver(TagStore);
  const organizationRelationStore = useResolver(OrganizationRelationStore);
  const personStore = useResolver(PersonStore);

  useEffect(() => {
    organizationStore.loadManagedOrganizations();
    organizationRelationStore.loadByCurrentOrganization();
    tagStore.loadByCurrentOrganization();
  }, []);

  return (
    <ManagedOrganizationList
      organizations={organizationStore.managedOrganizations}
      relations={organizationRelationStore.currentOrganizationRelations}
      editable={personStore.currentPerson.hasEditPermission()}
      tags={tagStore.currentOrganizationTags}
    />
  );
};

export default observer(ConnectedManagedOrganizationList);
