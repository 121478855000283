import React from 'react';
import { observer } from 'mobx-react';
import OrganizationPaymentFormDrawer from '../components/OrganizationPaymentFormDrawer';
import useResolver from '../hooks/useResolver';
import OrganizationStore from '../store/OrganizationStore';
import PaymentMethodStore from '../store/PaymentMethodStore';

export interface ConnectedOrganizationPaymentDrawerProps {}

const ConnectedOrganizationPaymentDrawer: React.FC<ConnectedOrganizationPaymentDrawerProps> =
  () => {
    const organizationStore = useResolver(OrganizationStore);
    const paymentMethodStore = useResolver(PaymentMethodStore);

    return (
      <OrganizationPaymentFormDrawer
        onSubmit={(values) =>
          paymentMethodStore.create({
            organizationId: organizationStore.currentOrganizationId,
            ...values,
          })
        }
      />
    );
  };
export default observer(ConnectedOrganizationPaymentDrawer);
