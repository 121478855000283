import Organization from './Organization';
import Resource from '../decorators/Resource';
import { toDate } from '../util/date';
import ProjectTag from './ProjectTag';
import Participation, { ParticipationState } from './Participation';
import AbstractDynamicDomain from './AbstractDynamicDomain';
import { toNumberOrNull } from '../util/budget';

export enum ProjectType {
  PROJECT = 'PROJECT',
  TRAINING = 'TRAINING',
  EVENT = 'EVENT',
}

export enum ProjectPeriod {
  UPCOMING = 'UPCOMING',
  ONGOING = 'ONGOING',
  ENDED = 'ENDED',
}

export enum ProjectState {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export enum PricingType {
  TIME_AND_MATERIAL = 'TIME_AND_MATERIAL',
  BUDGET = 'BUDGET',
  POTENTIAL_COST = 'POTENTIAL_COST',
}

@Resource.Name('projects')
export default class Project extends AbstractDynamicDomain {
  public name: string;
  public description: string;
  public type: ProjectType = ProjectType.PROJECT;
  public pricingType: PricingType = PricingType.TIME_AND_MATERIAL;
  public budget?: number | null;
  public state: ProjectState = ProjectState.DRAFT;
  public dateStart?: Date;
  public dateEnd?: Date;
  public datesIncludeTime: boolean = false;
  public organizationId: string;
  public clientName?: string;
  public projectTags?: ProjectTag[];
  public ownerPersonId?: string;
  public image?: string;
  public relatedOrganizationId?: string;
  public relatedToProjectId?: string;
  public relatedToProject?: Project;
  public priority: number;
  protected _organization?: Organization;

  get organization(): Organization {
    return this._organization;
  }

  set organization(value: Organization) {
    this._organization = value;
  }

  public getImage(): string {
    return this.image;
  }

  public hasStarted(date: Date = new Date()): boolean {
    return !this.dateStart || this.dateStart < date;
  }

  public hasEnded(date: Date = new Date()): boolean {
    return this.dateEnd && this.dateEnd < date;
  }

  public isOngoing(): boolean {
    return this.hasStarted() && !this.hasEnded();
  }

  public get period(): ProjectPeriod {
    if (this.isOngoing()) {
      return ProjectPeriod.ONGOING;
    }

    if (!this.hasStarted()) {
      return ProjectPeriod.UPCOMING;
    }

    if (this.hasEnded()) {
      return ProjectPeriod.ENDED;
    }

    return ProjectPeriod.ENDED;
  }

  public fromObject(payload: Partial<Project>): this {
    super.fromObject(payload);

    this.name = payload.name;
    this.type = payload.type;
    this.pricingType = payload.pricingType;
    this.budget = toNumberOrNull(payload.budget);
    this.dateStart = toDate(payload.dateStart);
    this.dateEnd = toDate(payload.dateEnd);
    this.datesIncludeTime = payload.datesIncludeTime;
    this.organizationId = payload.organizationId;
    this.clientName = payload.clientName;
    this.projectTags = payload.projectTags;
    this.description = payload.description;
    this.ownerPersonId = payload.ownerPersonId;
    this.state = payload.state;
    this.image = payload.image;
    this.relatedOrganizationId = payload.relatedOrganizationId;
    this.relatedToProjectId = payload.relatedToProjectId;
    this.priority = payload.priority;

    return this;
  }

  public toObject(): Partial<Project> {
    return {
      ...super.toObject(),
      name: this.name,
      type: this.type,
      pricingType: this.pricingType,
      budget: toNumberOrNull(this.budget),
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      datesIncludeTime: this.datesIncludeTime,
      organizationId: this.organizationId,
      clientName: this.clientName,
      projectTags: this.projectTags,
      image: this.image,
      description: this.description,
      ownerPersonId: this.ownerPersonId,
      state: this.state,
      relatedOrganizationId: this.relatedOrganizationId,
      relatedToProjectId: this.relatedToProjectId,
      priority: this.priority,
    };
  }

  public isPersonRelated(
    personId: string,
    participations: Participation[],
    ignoreEnded?: boolean,
  ): boolean {
    return (
      this.ownerPersonId === personId ||
      !!participations?.find(
        (p) =>
          p?.personId === personId &&
          p?.projectId === this.id &&
          p?.state === ParticipationState.APPROVED &&
          !(ignoreEnded && p.hasEnded()),
      )
    );
  }

  public isDraft() {
    return this.state === ProjectState.DRAFT;
  }

  public isArchived() {
    return this.state === ProjectState.ARCHIVED;
  }

  public isPublished() {
    return this.state === ProjectState.PUBLISHED;
  }
}
