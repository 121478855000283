import React from 'react';
import { TagType } from '../domain/Tag';
import TextToIcon from './TextToIcon';
import CodeIcon from '../ui/icons/CodeIcon';
import LocationIcon from '../ui/icons/LocationIcon';
import UserTagIcon from '../ui/icons/UserTagIcon';
import TagIcon from '../ui/icons/TagIcon';
import LanguageIcon from '../ui/icons/LanguageIcon';

interface TagToIconProps {
  name: string;
  type: TagType;
}

const iconByTagType: { [key in TagType]: React.ReactNode } = {
  [TagType.TECHNOLOGY]: <CodeIcon />,
  [TagType.LOCATION]: <LocationIcon />,
  [TagType.POSITION]: <UserTagIcon />,
  [TagType.PROJECT_TYPE]: <TagIcon />,
  [TagType.LANGUAGE]: <LanguageIcon />,
  [TagType.CUSTOM]: <TagIcon />,
};

const TagToIcon: React.FC<TagToIconProps> = ({ name, type }) => {
  return (
    <TextToIcon
      name={name}
      fallback={<>{iconByTagType[type] || TextToIcon.defaultProps.fallback}</>}
    />
  );
};

export default TagToIcon;
