import React from 'react';
import DeleteDrawer from './DeleteDrawer';
import Person from '../domain/Person';
import { t } from '../i18n';

export interface ParticipationDeleteDrawerProps {
  name: string;
  onSubmit: () => void | Promise<void>;
  person?: Person;
}

const ParticipationDeleteDrawer: React.FC<ParticipationDeleteDrawerProps> = ({
  name,
  onSubmit,
  person,
}) => {
  return (
    <DeleteDrawer
      name={name}
      onSubmit={onSubmit}
      confirmationText={null}
      header={`${t('Remove')} ${person.name || t('person')} ${t(
        'from project?',
      )}`}
      buttonText={t('Remove')}
    />
  );
};

export default ParticipationDeleteDrawer;
