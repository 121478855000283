import React, { ErrorInfo } from 'react';
import ErrorPage from '../views/ErrorPage';

export default class ErrorBoundary extends React.Component<
  { fallback?: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error); // TODO: report this error
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || <ErrorPage />;
    }

    return this.props.children;
  }
}
