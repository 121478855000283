import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { HStack, Text } from '@chakra-ui/react';
import { SidebarContext } from './Sidebar';
import Tooltip from './Tooltip';

export interface NavItemProps {
  to: string;
  title: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  as?: string;
  onClick?: (event: MouseEvent) => void;
}

const NavItem: React.FC<NavItemProps> = ({
  as,
  to,
  title,
  icon,
  disabled,
  onClick,
}) => {
  const sidebarContext = useContext(SidebarContext);

  const activeLinkProps = {
    as: as || NavLink,
    activeStyle: { backgroundColor: 'rgba(108, 117, 125, 0.12)' },
    _hover: {
      backgroundColor: 'rgba(108, 117, 125, 0.06)',
    },
    to,
    href: as === 'a' ? to : undefined,
  };

  const disabledLinkProps = {
    color: 'gray.300',
  };

  const navItem = (
    <HStack
      borderRadius="xs"
      transition="background .2s"
      spacing={3}
      py={1}
      px={sidebarContext.collapsed ? 3 : 6}
      mx={sidebarContext.collapsed ? -3 : -6}
      height={8}
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      onClick={onClick}
      {...(disabled ? disabledLinkProps : (activeLinkProps as any))}
    >
      {icon}
      {!sidebarContext.collapsed && <Text fontSize="sm">{title}</Text>}
    </HStack>
  );

  if (!sidebarContext.collapsed) {
    return navItem;
  }

  return (
    <Tooltip placement="right" label={title}>
      {navItem}
    </Tooltip>
  );
};

export default NavItem;
