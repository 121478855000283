import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import ParticipationStore from '../store/ParticipationStore';
import TaskStore from '../store/TaskStore';
import Tasks from '../components/Tasks';
import Stack from '../ui/Stack';
import HStack from '../ui/HStack';
import MoreActionsMenu from '../ui/MoreActionsMenu';
import { t } from '../i18n';
import MenuItem from '../ui/MenuItem';
import PlusIcon from '../ui/icons/PlusIcon';
import ConnectedTaskDeleteDrawer from './ConnectedTaskDeleteDrawer';
import ConnectedTaskFormDrawer from './ConnectedTaskFormDrawer';

export interface ConnectedProjectRequestsProps {
  projectId: string;
}

const ConnectedProjectTasks: React.FC<ConnectedProjectRequestsProps> = ({
  projectId,
}) => {
  const taskStore = useResolver(TaskStore);
  const participationStore = useResolver(ParticipationStore);

  useEffect(() => {
    taskStore.loadByProject(projectId);
    participationStore.loadByProject(projectId);
  }, [projectId]);

  return (
    <>
      <ConnectedTaskDeleteDrawer name="task.delete" />
      <ConnectedTaskFormDrawer name="task.edit" />
      <Stack mb={4}>
        <HStack justifyContent="space-between">
          <div />
          <MoreActionsMenu label={t('Project tasks actions')}>
            <MenuItem
              icon={<PlusIcon />}
              preserveQuery
              to={`?task&projectId=${projectId}`}
            >
              {t('Create task')}
            </MenuItem>
          </MoreActionsMenu>
        </HStack>
      </Stack>
      <Tasks tasks={taskStore.getProjectTasks(projectId)} editable />
    </>
  );
};

export default observer(ConnectedProjectTasks);
