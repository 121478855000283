import React from 'react';
import {
  Container as ChakraContainer,
  ContainerProps as ChakraContainerProps,
} from '@chakra-ui/react';

export interface ContainerProps extends ChakraContainerProps {}

const Container: React.FC<ContainerProps> = ({ ...props }) => {
  return <ChakraContainer {...props} />;
};

Container.defaultProps = {};

export default Container;
