import React from 'react';
import Input from '../ui/Input';
import Stack from '../ui/Stack';
import FormField from './FormField';
import DrawerForm from './DrawerForm';
import { t } from '../i18n';

interface DeleteDrawerProps {
  name: string;
  onSubmit: (values: any) => void | Promise<void>;
  confirmationText: string;
  header: string;
  successMessage?: string;
  buttonText?: string;
}

const DeleteDrawer: React.FC<DeleteDrawerProps> = ({
  name,
  onSubmit,
  header,
  confirmationText,
  successMessage,
  buttonText,
  children,
}) => {
  return (
    <DrawerForm
      variant="modal"
      name={name}
      onSubmit={onSubmit}
      header={header}
      successMessage={successMessage}
      cancelButton={{
        text: t('Cancel'),
      }}
      submitButton={{
        text: buttonText,
      }}
    >
      <Stack spacing={4}>
        {confirmationText && (
          <FormField
            name="confirmation"
            label={`Type "${confirmationText}" to delete`}
            required
            validate={(value: string) =>
              value === confirmationText || 'Invalid'
            }
          >
            <Input autoFocus />
          </FormField>
        )}
        {children}
      </Stack>
    </DrawerForm>
  );
};

DeleteDrawer.defaultProps = {
  name: 'delete',
  successMessage: t('Successfully deleted!'),
  buttonText: t('Delete'),
};

export default DeleteDrawer;
