import React from 'react';
import IconedText from '../ui/IconedText';
import CheckIcon from '../ui/icons/CheckIcon';
import CloseIcon from '../ui/icons/CloseIcon';
import FlagIcon from '../ui/icons/FlagIcon';
import Person from '../domain/Person';
import Request, { RequestState } from '../domain/Request';
import HStack from '../ui/HStack';
import ConnectedRequestCancelButton from '../containers/ConnectedRequestCancelButton';
import { t } from '../i18n';

export interface RequestStatusProps {
  request: Request;
  viewer?: Person;
}

const RequestStatus: React.FC<RequestStatusProps> = ({
  request,
  viewer,
  children,
}) => {
  if (request.state === RequestState.APPROVED) {
    return (
      <IconedText icon={<CheckIcon color="green.500" />}>
        {t('Approved')}
      </IconedText>
    );
  }

  if (request.state === RequestState.REJECTED) {
    return (
      <IconedText icon={<CloseIcon color="red.500" />}>
        {t('Rejected')}
      </IconedText>
    );
  }

  if (request.state === RequestState.CANCELED) {
    return (
      <IconedText icon={<CloseIcon color="red.500" />}>
        {t('Canceled')}
      </IconedText>
    );
  }

  if (
    request.state === RequestState.REQUESTED &&
    (!viewer?.hasEditPermission() || viewer?.id === request.personId)
  ) {
    return (
      <HStack spacing={2}>
        <IconedText icon={<FlagIcon color="brand.500" />}>
          {t('Waiting for approval')}
        </IconedText>
        {viewer?.id === request.personId && (
          <ConnectedRequestCancelButton requestId={request.id} />
        )}
      </HStack>
    );
  }

  return <>{children}</>;
};

export default RequestStatus;
