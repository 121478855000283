import React from 'react';
import { BiTask } from 'react-icons/bi';
import Icon, { IconProps } from '../Icon';

export interface FlagIconProps extends IconProps {}

const TaskIcon: React.FC<FlagIconProps> = ({ ...props }) => {
  return <Icon as={BiTask} {...props} />;
};

export default TaskIcon;
