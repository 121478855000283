import React from 'react';
import HStack from '../ui/HStack';
import VStack from '../ui/VStack';
import Divider from '../ui/Divider';
import ConnectedGoogleLogin from '../containers/ConnectedGoogleLogin';
import ConnectedGithubLogin from '../containers/ConnectedGithubLogin';
import ConnectedMicrosoftLogin from '../containers/ConnectedMicrosoftLogin';
import ConnectedFacebookLogin from '../containers/ConnectedFacebookLogin';

export interface SocialLoginProps {
  withDivider?: boolean;
}

const SocialLogin: React.FC<SocialLoginProps> = ({ withDivider }) => {
  return (
    <VStack spacing={4}>
      {withDivider && <Divider>or</Divider>}
      <HStack justifyContent="center">
        <ConnectedGoogleLogin />
        <ConnectedGithubLogin />
        <ConnectedFacebookLogin />
        <ConnectedMicrosoftLogin />
      </HStack>
    </VStack>
  );
};

export default SocialLogin;
