import React from 'react';
import { Text } from '@chakra-ui/react';
import ConnectedCleanLayout from '../containers/ConnectedCleanLayout';
import Heading from '../ui/Heading';
import Image from '../ui/Image';
import catImage from '../ui/assets/cat_glasseson.jpg';
import { t } from '../i18n';

const ErrorPage: React.FC = () => {
  return (
    <ConnectedCleanLayout logoSize="sm">
      <Image src={catImage} alt="Oops!" />
      <Heading mb={4}>Oops!</Heading>
      <Text>
        {t(
          'Looks like there was a problem. Try to reload or feel free to contact us if the problem persists.',
        )}
      </Text>
    </ConnectedCleanLayout>
  );
};

export default ErrorPage;
