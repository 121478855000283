import React from 'react';
import Form, { FormProps } from './Form';
import FormSubmitButton, { FormSubmitButtonProps } from './FormSubmitButton';
import RouterDrawer, { RouterDrawerProps } from './RouterDrawer';
import { UseFormMethods } from 'react-hook-form';
import i18n from '../i18n';
import { Stack, Button, Box } from '../ui';

export interface DrawerFormProps
  extends Omit<FormProps, 'children' | 'onSubmit'>,
    Partial<Pick<RouterDrawerProps, 'size' | 'name' | 'variant'>> {
  header?: React.ReactNode;
  submitButton?: FormSubmitButtonProps & { text: string };
  cancelButton?: { text: string };
  children:
    | React.ReactNode
    | ((context: {
        formContext: UseFormMethods;
        drawerContext: string;
      }) => any);
  onSubmit: (values: any, drawerValue: string) => void | Promise<void>;
  autoClose?: boolean;
}

const DrawerForm: React.FC<DrawerFormProps> = ({
  submitButton,
  cancelButton,
  name,
  size,
  header,
  children,
  onSubmit,
  autoClose,
  variant,
  ...formProps
}) => {
  return (
    <RouterDrawer name={name} size={size} header={header} variant={variant}>
      {(drawerContext) => {
        const handleSubmit = async (values: any) => {
          const result = await onSubmit(values, drawerContext.value);

          if (autoClose) {
            drawerContext.handleClose();
          }

          return result;
        };

        return (
          <Box>
            <Form onSubmit={handleSubmit} {...formProps}>
              {(formContext) => (
                <>
                  <>
                    {typeof children === 'function'
                      ? (children as any)({
                          formContext,
                          drawerContext,
                        })
                      : children}
                  </>

                  {(submitButton || cancelButton) && (
                    <Stack
                      justifyContent="end"
                      spacing={1}
                      direction="row"
                      mt={6}
                    >
                      {cancelButton && (
                        <Button
                          variant="ghost"
                          onClick={drawerContext.handleClose}
                        >
                          {cancelButton.text}
                        </Button>
                      )}
                      {submitButton && (
                        <FormSubmitButton {...submitButton}>
                          {submitButton.text}
                        </FormSubmitButton>
                      )}
                    </Stack>
                  )}
                </>
              )}
            </Form>
          </Box>
        );
      }}
    </RouterDrawer>
  );
};

DrawerForm.defaultProps = {
  submitButton: {
    text: i18n.t('Save'),
  },
  autoClose: true,
  size: 'md',
};

export default DrawerForm;
