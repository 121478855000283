import React from 'react';
import Person from '../domain/Person';
import DeleteDrawer from './DeleteDrawer';
import { t } from '../i18n';
import Participation from '../domain/Participation';
import { Checkbox, DatePeriod, Text } from '../ui';
import FormField from './FormField';

export interface PersonDeleteInput {
  endParticipations: {
    [participationId: string]: boolean;
  };
}

export interface PersonDeleteDrawerProps {
  name: string;
  onSubmit: (input: PersonDeleteInput) => void | Promise<void>;
  participations: Participation[];
  person: Person;
  readOnly?: boolean;
  buttonText?: string;
  header?: string;
  successMessage?: string;
}

const PersonDeleteDrawer: React.FC<PersonDeleteDrawerProps> = ({
  name,
  onSubmit,
  participations,
  person,
  readOnly = true,
  buttonText,
  header,
  successMessage,
}) => {
  if (!person?.id) {
    return null;
  }

  const activeParticipations = participations.filter(
    (participation) => !participation.hasEnded(),
  );

  return (
    <DeleteDrawer
      name={name}
      onSubmit={onSubmit}
      confirmationText={null}
      buttonText={buttonText || t('Delete')}
      successMessage={successMessage}
      header={header || t('Confirm deletion')}
    >
      {activeParticipations.length <= 0 && (
        <Text>
          <strong>{person.name}</strong> {t('does not have any participations')}
        </Text>
      )}
      {activeParticipations.length > 0 && (
        <Text>
          {t('Following participations will end for')}{' '}
          <strong>{person.name}</strong>{' '}
        </Text>
      )}
      {!readOnly && activeParticipations.length > 0 && (
        <Text fontSize="xs" color="gray.500">
          {t('Deselect to keep participation active')}
        </Text>
      )}
      {activeParticipations.map((participation) => {
        return (
          <FormField
            defaultValue={true}
            name={`endParticipations.${participation.id}`}
          >
            <Checkbox isReadOnly={readOnly} key={participation.id}>
              {participation.project?.name} <DatePeriod {...participation} />
            </Checkbox>
          </FormField>
        );
      })}
    </DeleteDrawer>
  );
};

export default PersonDeleteDrawer;
