import React, { useEffect } from 'react';
import OrganizationSelect, {
  OrganizationSelectProps,
} from '../components/OrganizationSelect';
import useResolver from '../hooks/useResolver';
import OrganizationStore from '../store/OrganizationStore';
import { observer } from 'mobx-react';
import useToast from '../hooks/useToast';
import { t } from '../i18n';

export interface ConnectedManagedOrganizationSelectProps
  extends Omit<OrganizationSelectProps, 'organizations'> {}

const ConnectedManagedOrganizationSelect: React.FC<
  ConnectedManagedOrganizationSelectProps
> = ({ ...props }) => {
  const organizationStore = useResolver(OrganizationStore);
  const toast = useToast();

  useEffect(() => {
    organizationStore.loadManagedOrganizations();
  }, []);

  return (
    <OrganizationSelect
      onCreate={async (name) => {
        try {
          const organization =
            await organizationStore.createManagedOrganization({
              name,
            });

          props.onChange?.(organization.id);
        } catch (err: Error | any) {
          toast({ status: 'error', description: t('Something went wrong') });
        }
      }}
      organizations={organizationStore.managedOrganizations}
      {...props}
    />
  );
};

export default observer(ConnectedManagedOrganizationSelect);
