import React from 'react';
import { FiClipboard } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface ClipboardIconProps extends IconProps {}

const ClipboardIcon: React.FC<ClipboardIconProps> = ({ ...props }) => {
  return <Icon as={FiClipboard} {...props} />;
};

export default ClipboardIcon;
