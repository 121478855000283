import React from 'react';
import Box from './Box';
import Text from './Text';
import HStack from './HStack';
import Value, { ValueFormat } from './Value';

export interface LabeledValueProps {
  label?: string | React.ReactNode;
  value: any;
  unit?: string | React.ReactNode;
  preUnit?: boolean;
  format?: ValueFormat;
  digits?: number;
  centered?: boolean;
}

const LabeledValue: React.FC<LabeledValueProps> = ({
  label,
  value,
  unit,
  format,
  digits,
  centered,
  preUnit,
}) => {
  return (
    <Box display="inline-box" textAlign={centered ? 'center' : 'left'}>
      {label && (
        <Text color="gray.400" fontSize="xs" textTransform="uppercase">
          {label}
        </Text>
      )}
      <HStack
        alignItems="center"
        spacing={1}
        justifyContent={centered ? 'center' : null}
      >
        {preUnit && <Text fontSize="sm">{unit}</Text>}
        <Text fontSize="lg" fontWeight="bold">
          <Value value={value} format={format} digits={digits} />
        </Text>
        {!preUnit && unit && <Text fontSize="sm">{unit}</Text>}
      </HStack>
    </Box>
  );
};

LabeledValue.defaultProps = {};

export default LabeledValue;
