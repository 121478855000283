import React from 'react';
import { BiWrench } from 'react-icons/bi';
import Icon, { IconProps } from '../Icon';

export interface PreferenceIconProps extends IconProps {}

const PreferenceIcon: React.FC<PreferenceIconProps> = ({ ...props }) => {
  return <Icon as={BiWrench} {...props} />;
};

export default PreferenceIcon;
