import React from 'react';
import { FiUsers } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface UsersIconProps extends IconProps {}

const UsersIcon: React.FC<UsersIconProps> = ({ ...props }) => {
  return <Icon as={FiUsers} {...props} />;
};

export default UsersIcon;
