import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import ConnectedPersonProfile from '../containers/ConnectedPersonProfile';

const PersonProfile: React.FC = () => {
  const match = useRouteMatch<{ id: string }>();

  return <ConnectedPersonProfile personId={match.params.id} />;
};

export default PersonProfile;
