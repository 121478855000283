import { injectable } from 'tsyringe';
import ApiService from './ApiService';
import Search from '../domain/Search';

@injectable()
export default class SearchService {
  public constructor(private client: ApiService) {}

  public async searchByOrganizationAndTerm(
    organizationId: string,
    term: string,
  ): Promise<Search[]> {
    const { data } = await this.client.get<Search[]>('/search', {
      params: {
        organizationId: organizationId,
        term: term,
      },
    });

    return data;
  }
}
