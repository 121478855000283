import React from 'react';
import { Placement, Route as NavRoute } from '../routes';
import NavItemGroup from '../ui/NavItemGroup';
import NavItem from '../ui/NavItem';
import ConnectedNotificationsHandleAndDrawer from '../containers/ConnectedNotificationsHandleAndDrawer';
import PersonUser from './PersonUser';
import { Redirect, Route, Switch } from 'react-router-dom';
import Link from '../ui/Link';
import HStack from '../ui/HStack';
import Text from '../ui/Text';
import Layout from '../ui/Layout';
import Person from '../domain/Person';
import AuthenticatedMobileLayout from './AuthenticatedMobileLayout';
import RouteLink from './RouteLink';
import QuickActions from './QuickActions';
import Divider from '../ui/Divider';
import ConnectedSearch from '../containers/ConnectedSearch';

export interface AuthenticatedLayoutProps {
  currentPerson: Person;
  currentUserNonActivePersons: Person[];
  routes: NavRoute[];
  isMobile: boolean;
}

const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({
  routes,
  currentPerson,
  currentUserNonActivePersons,
  isMobile,
  children: providedChildren,
}) => {
  const navigation = (
    <div>
      {[Placement.PERSONAL, Placement.ORGANIZATION, Placement.MARKETPLACE].map(
        (placement, index) => {
          const filteredRoutes = routes.filter(
            (route) =>
              route.placements?.includes(placement) &&
              currentPerson.hasPermission(...(route.scope || [])),
          );

          if (!filteredRoutes.length) {
            return null;
          }

          return (
            <React.Fragment key={placement}>
              {index !== 0 && <Divider />}
              <NavItemGroup
                key={placement}
                aria-label={placement}
                title={
                  placement === Placement.ORGANIZATION
                    ? currentPerson.organization?.name || 'Organization'
                    : placement
                }
              >
                {filteredRoutes.map((route) => {
                  return (
                    <NavItem
                      key={route.path}
                      to={route.path}
                      title={route.title}
                      icon={route.icon}
                      disabled={!!route.disabled}
                    />
                  );
                })}
              </NavItemGroup>
            </React.Fragment>
          );
        },
      )}
    </div>
  );

  const notificationsHandle = <ConnectedNotificationsHandleAndDrawer />;
  const currentUser = <PersonUser align="right" person={currentPerson} />;
  const search = <ConnectedSearch />;
  const secondaryUsers = currentUserNonActivePersons.length > 0 && (
    <>
      {currentUserNonActivePersons.map((person) => (
        <Link key={person.id} to={`/switch-person/${person.id}`}>
          <PersonUser person={person} />
        </Link>
      ))}
    </>
  );
  const userNavigation = (
    <>
      {routes
        .filter((route) => route.placements?.includes(Placement.USER_MENU))
        .map((route) => (
          <RouteLink key={route.path} route={route}>
            <HStack spacing={2}>
              {route.icon}
              <Text fontSize="sm" pr={2}>
                {route.title}
              </Text>
            </HStack>
          </RouteLink>
        ))}
    </>
  );

  const children = providedChildren || (
    <>
      <Switch>
        {routes
          .filter(
            (route) =>
              !!route.component &&
              currentPerson.hasPermission(...(route.scope || [])),
          )
          .map((route) => (
            <Route
              key={route.path}
              path={route.path}
              component={route.component}
            />
          ))}
        <Route path="/app">
          <Redirect to={routes[0].path} />
        </Route>
      </Switch>
    </>
  );

  const quickActions = (
    <QuickActions manager={currentPerson.hasEditPermission()} />
  );

  const commonProps = {
    navigation,
    notificationsHandle,
    currentUser,
    userNavigation,
    secondaryUsers,
    quickActions,
    search,
  };

  if (isMobile) {
    return (
      <AuthenticatedMobileLayout {...commonProps}>
        {children}
      </AuthenticatedMobileLayout>
    );
  }

  return <Layout {...commonProps}>{children}</Layout>;
};

export default AuthenticatedLayout;
