import React from 'react';
import Person from '../domain/Person';
import Invitation from '../domain/Invitation';
import DrawerForm from './DrawerForm';
import Stack from '../ui/Stack';
import FormField from './FormField';
import Input from '../ui/Input';
import HStack from '../ui/HStack';
import FormSubmitButton from './FormSubmitButton';
import { t } from '../i18n';

export interface InvitationFormProps {
  person: Person;
  invitation?: Invitation;
  name?: string;
  onSubmit: (values: Partial<Invitation>) => void | Promise<void>;
}

const InvitationFormDrawer: React.FC<InvitationFormProps> = ({
  person,
  name,
  onSubmit,
  invitation,
}) => {
  return (
    <DrawerForm
      submitButton={null}
      name={name}
      onSubmit={onSubmit}
      successMessage={({ email }) =>
        `${t('Invitation has been sent to')} ${email}`
      }
      defaultValues={{
        email: invitation?.email || null,
      }}
      header={
        <>
          {invitation ? t('Reinvite') : t('Invite')} {person.name}
        </>
      }
    >
      <Stack spacing={4}>
        <FormField name="email" label={t('Email')}>
          <Input type="email" autoFocus />
        </FormField>

        <HStack spacing={2} justifyContent="flex-end">
          <FormSubmitButton>{t('Send')}</FormSubmitButton>
        </HStack>
      </Stack>
    </DrawerForm>
  );
};

InvitationFormDrawer.defaultProps = {
  name: 'person.invite',
};

export default InvitationFormDrawer;
