import {
  DataGridAppliedFilter,
  DataGridField,
  DataGridFilter,
} from './DataGrid.types';
import { ReactNode } from 'react';
import { downloadCsv } from '../../util/file';

export function processAccessor<Value = unknown>(
  value: Value | { value: Value; node: ReactNode },
): { value: Value; node: ReactNode } {
  if (value && typeof value === 'object' && 'value' in value) {
    return value;
  }

  return {
    value: value as Value,
    node: value,
  };
}

function toMatrix<Item, Key extends string>(
  data: Item[],
  fields: Record<Key, DataGridField<Item>>,
): string[][] {
  const fieldsArray = (Object.values(fields) as DataGridField<Item>[]).filter(
    (field) => !field.hiddenInExport,
  );

  const header = fieldsArray.map((field) => field.title ?? '');
  const rows = data.map((item) =>
    fieldsArray.map((field) =>
      String(processAccessor(field.accessor(item, 0)).value ?? ''),
    ),
  );

  return [header, ...rows];
}

export function exportToCsv<Item, Key extends string>(
  data: Item[],
  fields: Record<Key, DataGridField<Item>>,
) {
  return downloadCsv(toMatrix(data, fields), 'export.csv');
}

export function applyFilters<Item, Value>(
  filters: DataGridAppliedFilter<Item, Value>[],
  items: Item[],
) {
  const filterGroups = filters.reduce((acc, filter) => {
    if (!acc[filter.key]) {
      acc[filter.key] = [];
    }

    acc[filter.key].push(filter);
    return acc;
  }, {} as Record<string, DataGridAppliedFilter<Item, Value>[]>);

  return items.filter((item) => {
    return Object.values(filterGroups).every((filters) => {
      return filters.some((filter) => filter.apply(filter.value, item));
    });
  });
}

export function mergeFilterValues<Item, Value>(
  filters: DataGridFilter<Item, Value>[],
  values: Pick<DataGridAppliedFilter<Item, Value>, 'key' | 'value'>[],
): DataGridAppliedFilter<Item, Value>[] {
  const results: DataGridAppliedFilter<Item, Value>[] = [];

  for (const { key, value } of values) {
    const filter = filters.find((f) => f.key === key);

    if (filter) {
      results.push({ ...filter, value });
    }
  }

  return results;
}

export function isFilterValueSelected<Item, Value>(
  appliedFilters: DataGridAppliedFilter<Item, Value>[],
  key: string,
  value: Value,
) {
  return appliedFilters.some(
    (filter) => filter.key === key && filter.value === value,
  );
}
