import React from 'react';
import { observer } from 'mobx-react';
import useMonthPicker from '../hooks/useMonthPicker';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import { endOfMonth, startOfMonth } from 'date-fns';
import Stack from '../ui/Stack';
import HStack from '../ui/HStack';
import MoreActionsMenu from '../ui/MoreActionsMenu';
import MenuItem from '../ui/MenuItem';
import PlusIcon from '../ui/icons/PlusIcon';
import { Role } from '../domain/Person';
import OrganizationStore from '../store/OrganizationStore';
import useSWR from 'swr';
import { formatDate } from '../util/date';
import FinanceService from '../services/FinanceService';
import SkeletonLoader from '../ui/SkeletonLoader';
import Alert from '../ui/Alert';
import ConnectedAdditionalCostDrawer from './ConnectedAdditionalCostDrawer';
import { t } from '../i18n';
import ManagedOrganizationBudget from '../components/ManagedOrganizationBudget';

export interface ConnectedOrganizationPersonsBudgetProps {
  organizationId?: string;
}

const ConnectedManagedOrganizationBudget: React.FC<
  ConnectedOrganizationPersonsBudgetProps
> = ({ organizationId: suppliedOrganizationId }) => {
  const month = useMonthPicker();
  const organizationStore = useResolver(OrganizationStore);
  const personStore = useResolver(PersonStore);
  const financeService = useResolver(FinanceService);
  const organizationId =
    suppliedOrganizationId || organizationStore.currentOrganizationId;

  const {
    data: finances,
    error,
    mutate,
  } = useSWR(
    `ConnectedOrganizationProjectsBudget:${formatDate(
      month,
    )}|${organizationId}`,
    () =>
      financeService.getFinances({
        organizationId,
        dateStart: startOfMonth(month),
        dateEnd: endOfMonth(month),
        includeRelatedOrganizationsPersons: true,
      }),
  );

  const editable = personStore.currentPerson.hasPermission(Role.ADMIN);

  if (error) {
    return <Alert status="error">{String(error)}</Alert>;
  }

  if (!finances) {
    return <SkeletonLoader />;
  }

  return (
    <div>
      <Stack spacing={4}>
        <HStack justifyContent="space-between">
          <div />
          {editable && (
            <MoreActionsMenu label="Projects budget actions">
              <MenuItem
                icon={<PlusIcon />}
                preserveQuery
                to="?add.additional&additionalType=revenue"
              >
                {t('Add additional revenue')}
              </MenuItem>

              <MenuItem
                icon={<PlusIcon />}
                preserveQuery
                to="?add.additional&additionalType=cost"
              >
                {t('Add additional cost')}
              </MenuItem>
            </MoreActionsMenu>
          )}
        </HStack>
        <ManagedOrganizationBudget
          organizationId={organizationId}
          month={month}
          finances={finances}
          onChange={() => mutate()}
        />
      </Stack>
      <ConnectedAdditionalCostDrawer
        externalId={organizationId}
        organizationId={organizationId}
        onSuccess={() => mutate()}
      />
    </div>
  );
};

export default observer(ConnectedManagedOrganizationBudget);
