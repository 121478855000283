import AbstractResourceStore from './AbstractResourceStore';
import AccountStore from './AccountStore';
import { singleton } from 'tsyringe';
import HistoryEventService from '../services/HistoryEventService';
import HistoryEvent from '../domain/HistoryEvent';

@singleton()
export default class HistoryEventStore extends AbstractResourceStore<HistoryEvent> {
  public constructor(
    private readonly historyEventService: HistoryEventService,
    accountStore: AccountStore,
  ) {
    super(historyEventService, accountStore);
  }

  public async loadByProject(projectId: string) {
    await this.setManyEventually(
      this.historyEventService.getAllByProject(projectId),
    );
  }
}
