import React from 'react';
import MicrosoftButton from '../ui/MicrosoftButton';
import useResolver from '../hooks/useResolver';
import AccountStore, { SocialLoginProvider } from '../store/AccountStore';

export interface ConnectedMicrosoftLoginProps {}

const ConnectedMicrosoftLogin: React.FC<ConnectedMicrosoftLoginProps> = () => {
  const accountStore = useResolver(AccountStore);

  return (
    <MicrosoftButton
      onClick={() => accountStore.socialLogin(SocialLoginProvider.MICROSOFT)}
    />
  );
};

export default ConnectedMicrosoftLogin;
