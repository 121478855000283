import React from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { DataGridField } from './DataGrid.types';
import { processAccessor } from './DataGrid.utils';

export interface DataGridTableProps<Item, Key extends string> {
  data: Item[];
  fields: Record<Key, DataGridField<Item>>;
}

export const DataGridTable = <Item, Key extends string>({
  data,
  fields,
}: DataGridTableProps<Item, Key>) => {
  const fieldsArray = Object.entries(fields) as [string, DataGridField<Item>][];

  return (
    <Table variant="simple">
      <Thead>
        <Tr bg="gray.100">
          {fieldsArray.map(([key, field]) => {
            if (field.hidden) {
              return null;
            }

            return <Th key={key}>{field.title}</Th>;
          })}
        </Tr>
      </Thead>
      <Tbody>
        {data.map((item, rowIndex) => {
          return (
            <Tr key={rowIndex}>
              {fieldsArray.map(([key, field]) => {
                if (field.hidden) {
                  return null;
                }

                const { node } = processAccessor(
                  field.accessor(item, rowIndex),
                );

                return <Td key={key}>{node}</Td>;
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
