import React from 'react';
import Badge, { BadgeProps } from '../ui/Badge';
import { PersonState } from '../domain/Person';
import { t } from '../i18n';

export interface StatusBadgeProps {
  state: PersonState;
}

export const getHumanReadableState = (state: PersonState): string => {
  const texts: {
    [key in PersonState]: string;
  } = {
    [PersonState.ACTIVE]: t('Active'),
    [PersonState.INACTIVE]: t('Inactive'),
    [PersonState.PENDING]: t('Pending'),
    [PersonState.UNINVITED]: t('Not invited'),
    [PersonState.INVITATION_EXPIRED]: t('Invitation expired'),
  };

  return texts[state] || '';
};

const getBadgeProps = (state: PersonState): BadgeProps => {
  const colorSchemes: {
    [key in PersonState]: string;
  } = {
    [PersonState.ACTIVE]: 'green',
    [PersonState.INACTIVE]: 'gray',
    [PersonState.PENDING]: 'yellow',
    [PersonState.UNINVITED]: 'purple',
    [PersonState.INVITATION_EXPIRED]: 'red',
  };

  return {
    colorScheme: colorSchemes[state],
  };
};

const PersonStateBadge: React.FC<StatusBadgeProps> = ({ state }) => {
  return (
    <Badge {...getBadgeProps(state)}>{getHumanReadableState(state)}</Badge>
  );
};

export default PersonStateBadge;
