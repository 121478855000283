import React from 'react';
import {
  CircularProgress as ChakraCircularProgress,
  CircularProgressProps as ChakraCircularProgressProps,
  CircularProgressLabel,
} from '@chakra-ui/react';

export interface CircularProgressProps extends ChakraCircularProgressProps {
  label?: string | number;
}

const CircularProgress: React.FC<CircularProgressProps> = ({
  label,
  ...props
}) => {
  return (
    <ChakraCircularProgress {...props}>
      {label && <CircularProgressLabel>{label}</CircularProgressLabel>}
    </ChakraCircularProgress>
  );
};

CircularProgress.defaultProps = {};

export default CircularProgress;
