import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import useQueryValue from '../hooks/useQueryValue';
import ProjectStore from '../store/ProjectStore';
import ProjectChangeTypeDrawer, {
  ProjectChangeTypeDrawerProps,
} from '../components/ProjectChangeTypeDrawer';

export interface ConnectedProjectDeleteDrawerProps {
  name: string;
}

const ConnectedProjectEndDrawer: React.FC<
  ConnectedProjectDeleteDrawerProps
> = ({ name }) => {
  const projectStore = useResolver(ProjectStore);
  const projectId = useQueryValue(name);

  if (!projectId) {
    return null;
  }

  const handleSubmit: ProjectChangeTypeDrawerProps['onSubmit'] = async ({
    type,
  }) => {
    await projectStore.update({
      id: project.id,
      type: type,
    });
  };

  const project = projectStore.getById(projectId);

  return (
    <ProjectChangeTypeDrawer
      name={name}
      type={project?.type}
      onSubmit={handleSubmit}
    />
  );
};

export default observer(ConnectedProjectEndDrawer);
