import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import ConnectedParticipationSelect, {
  ConnectedParticipationSelectProps,
} from './ConnectedParticipationSelect';

export interface ConnectedCurrentPersonParticipationSelectProps
  extends Omit<ConnectedParticipationSelectProps, 'personId'> {}

const ConnectedCurrentPersonParticipationSelect: React.FC<ConnectedCurrentPersonParticipationSelectProps> =
  ({ ...props }) => {
    const personStore = useResolver(PersonStore);

    return (
      <ConnectedParticipationSelect
        personId={personStore.currentPerson.id}
        {...props}
      />
    );
  };

export default observer(ConnectedCurrentPersonParticipationSelect);
