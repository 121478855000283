import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import useQueryValue from '../hooks/useQueryValue';
import Project, { PricingType, ProjectState } from '../domain/Project';
import ProjectTag from '../domain/ProjectTag';
import { parse } from '../util/json';
import ProjectFormDrawer, {
  ProjectFormDrawerProps,
} from '../components/ProjectFormDrawer';
import ProjectStore from '../store/ProjectStore';
import OrganizationStore from '../store/OrganizationStore';
import ProjectTagStore from '../store/ProjectTagStore';
import { useHistory } from 'react-router-dom';
import PersonStore from '../store/PersonStore';
import useToast from '../hooks/useToast';
import { getCurrencySymbol } from '../util/currency';
import PriceStore from '../store/PriceStore';
import Price, { PriceType } from '../domain/Price';
import { useTranslation } from 'react-i18next';
import PaymentScheduleStore from '../store/PaymentScheduleStore';
import PaymentSchedule, { Recurrence } from '../domain/PaymentSchedule';

export interface ConnectedProjectFormDrawerProps
  extends Omit<
    ProjectFormDrawerProps,
    'onSubmit' | 'project' | 'currencySymbol'
  > {
  relatedToProjectId?: string;
}

const ConnectedProjectFormDrawer: React.FC<ConnectedProjectFormDrawerProps> = ({
  name: suppliedName,
  type,
  relatedToProjectId,
}) => {
  const name = suppliedName || type;
  const personStore = useResolver(PersonStore);
  const projectStore = useResolver(ProjectStore);
  const projectTagStore = useResolver(ProjectTagStore);
  const organizationStore = useResolver(OrganizationStore);
  const paymentScheduleStore = useResolver(PaymentScheduleStore);
  const priceStore = useResolver(PriceStore);
  const projectId = useQueryValue(name);
  const history = useHistory();
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    if (projectId) {
      paymentScheduleStore.loadByProjectId(projectId);
    }
  }, [projectId]);

  const currencySymbol = getCurrencySymbol(
    organizationStore.currentOrganization.currency,
  );

  let project: Project;
  let prevPaymentSchedule: PaymentSchedule | undefined;

  if (projectId) {
    project = projectStore.getByIdOrLoad(projectId);
    prevPaymentSchedule = paymentScheduleStore.getItemByProjectId(projectId);
  } else {
    project = new Project();
    project.ownerPersonId = personStore.currentPerson?.id;
  }

  if (projectId && !project) {
    return null;
  }

  function handleBudgetPositionRevenue(proj: Project) {
    if (proj.pricingType === PricingType.BUDGET) {
      const projectTags: ProjectTag[] = projectTagStore.getItemsByProject(
        proj.id,
      );

      projectTags.forEach((tag) => {
        priceStore.save(
          new Price().fromObject({
            type: PriceType.FIXED,
            value: proj.budget / projectTags.length,
            externalId: tag.id,
            dateStart: proj.dateStart,
            dateEnd: proj.dateEnd,
            organizationId: organizationStore.currentOrganizationId,
          }),
        );
      });
    }
  }

  const handleSubmit: ProjectFormDrawerProps['onSubmit'] = async (
    { tags, positions, priority, paymentSchedule, ...values },
    projectId,
  ) => {
    const projectTags: ProjectTag[] = [];

    const project = parse(
      {
        type,
        ...values,
      },
      Project,
    );

    project.organizationId = organizationStore.currentOrganizationId;

    if (values.relatedToProjectId) {
      project.relatedToProjectId = values.relatedToProjectId;
    }

    if (positions) {
      projectTags.push(
        ...positions.map((position: Partial<ProjectTag>) => {
          const existingProjectTag = projectId
            ? projectTagStore.getProjectTagByProjectAndTag(
                projectId,
                position.tagId,
              )
            : null;

          if (existingProjectTag) {
            existingProjectTag.capacity = position.capacity;
            return existingProjectTag;
          }

          return new ProjectTag().fromObject(position);
        }),
      );
    }

    if (tags) {
      const existingTagIds: string[] = [];

      projectTags.push(
        ...Object.values(tags)
          .flat()
          .filter(Boolean)
          .map((tag: any) => {
            if (existingTagIds.includes(tag.value)) {
              return null;
            }

            const existingProjectTag = projectId
              ? projectTagStore.getProjectTagByProjectAndTag(
                  projectId,
                  tag.value,
                )
              : null;

            const projectTag = existingProjectTag || new ProjectTag();
            projectTag.tagId = tag.value;

            existingTagIds.push(projectTag.tagId);

            return projectTag;
          })
          .filter(Boolean),
      );
    }
    if (priority) {
      project.priority = 1;
    } else {
      project.priority = 0;
    }
    project.projectTags = projectTags;

    if (projectId) {
      project.id = projectId;
      const result = await projectStore.update(project);

      projectTagStore.clear({ projectId: result.id });
      await projectTagStore.loadByProject(result.id);

      handleBudgetPositionRevenue(result);
    } else {
      project.state = ProjectState.DRAFT;
      const result = await projectStore.create(project);
      handleBudgetPositionRevenue(result);
      project.id = result.id;

      if (relatedToProjectId) {
        history.push(`/projects/view/${relatedToProjectId}/events`);
      } else {
        history.push(`/projects/view/${result.id}`);
      }

      toast({
        description: t(
          'Project has been created, but has not yet been published',
        ),
        status: 'success',
      });
    }

    const {
      recurrence,
      dateStart = project.dateStart || new Date(),
      offsetInMonths,
    } = paymentSchedule;

    if (recurrence) {
      if (
        recurrence === Recurrence.ONE_TIME &&
        prevPaymentSchedule?.dateStart !== dateStart
      ) {
        await paymentScheduleStore.save({
          projectId: project.id,
          recurrence,
          offsetInMonths,
          dateStart,
        });
      }

      if (
        recurrence === Recurrence.MONTHLY &&
        (prevPaymentSchedule?.offsetInMonths !== offsetInMonths ||
          prevPaymentSchedule?.dateStart !== dateStart)
      ) {
        await paymentScheduleStore.save({
          projectId: project.id,
          recurrence,
          offsetInMonths,
          dateStart,
        });
      }
    }

    if (!project) {
      throw new Error(t('Something went wrong'));
    }
  };

  if (!project && projectId) {
    return null;
  }

  return (
    <ProjectFormDrawer
      key={prevPaymentSchedule?.id}
      name={name}
      type={projectId && project ? project.type : type}
      onSubmit={handleSubmit}
      project={project}
      currencySymbol={currencySymbol}
      recurrenceValues={prevPaymentSchedule}
    />
  );
};

ConnectedProjectFormDrawer.defaultProps = {
  type: ProjectFormDrawer.defaultProps.type,
};

export default observer(ConnectedProjectFormDrawer);
