import React, { useEffect, useState } from 'react';
import FormDynamicFields, { FormDynamicFieldsProps } from './FormDynamicFields';
import useResolver from '../../../hooks/useResolver';
import DynamicFieldStore from '../store/DynamicFieldStore';
import OrganizationStore from '../../../store/OrganizationStore';
import DynamicField from '../domain/DynamicField';
import { UseFormMethods } from 'react-hook-form';

export interface ConnectedFormDynamicFieldsProps
  extends Omit<FormDynamicFieldsProps, 'fields'> {
  tags: string[];
  organizationId?: string;
  formContext?: UseFormMethods;
}

const ConnectedFormDynamicFields: React.FC<ConnectedFormDynamicFieldsProps> = ({
  tags,
  organizationId: suppliedOrganizationId,
  formContext,
  ...props
}) => {
  const [fields] = useState<DynamicField[]>([]);
  const dynamicFieldStore = useResolver(DynamicFieldStore);
  const organizationStore = useResolver(OrganizationStore);

  const organizationId =
    suppliedOrganizationId || organizationStore.currentOrganizationId;

  useEffect(() => {
    dynamicFieldStore.loadByOrganization(organizationId);
  }, []);

  const onCreate = async (
    option: string,
    field: DynamicField,
    name: string,
  ) => {
    await dynamicFieldStore.save({
      ...field,
      options: [...field.options, option],
    });
    formContext.setValue(option, name);
  };

  return <FormDynamicFields fields={fields} onSubmit={onCreate} {...props} />;
};

export default ConnectedFormDynamicFields;
