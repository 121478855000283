import React from 'react';
import Person from '../domain/Person';
import Avatar from '../ui/Avatar';
import Tooltip from '../ui/Tooltip';
import Link from '../ui/Link';
import Text from '../ui/Text';

export interface PersonAvatarProps {
  person: Person;
}

const PersonAvatar: React.FC<PersonAvatarProps> = ({ person, ...props }) => {
  return (
    <Tooltip
      label={
        <>
          <Text fontSize="sm">{person.name}</Text>
          <Text fontSize="xs">{person.position}</Text>
        </>
      }
    >
      <div>
        <Link to={`/profile/view/${person.id}`}>
          <Avatar name={person.name} src={person.image} {...props} />
        </Link>
      </div>
    </Tooltip>
  );
};

export default PersonAvatar;
