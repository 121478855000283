import React from 'react';
import Participation from '../domain/Participation';
import ConnectedProjectCardAction from '../containers/ConnectedProjectCardAction';
import Project from '../domain/Project';

export interface ProjectParticipateButtonProps {
  participation?: Participation;
  project: Project;
}

const ProjectParticipateButton: React.FC<ProjectParticipateButtonProps> = ({
  participation,
  project,
}) => {
  return (
    <ConnectedProjectCardAction
      project={project}
      participations={[participation]}
    />
  );
};

export default ProjectParticipateButton;
