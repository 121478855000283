import ProjectTag from '../domain/ProjectTag';
import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';

@injectable()
export default class ProjectTagService extends AbstractRestfulHttpService<ProjectTag> {
  public constructor(apiService: ApiService) {
    super(ProjectTag, apiService);
  }

  public getAllByProject(projectId: string): Promise<ProjectTag[]> {
    return this.getMany({ projectId });
  }
}
