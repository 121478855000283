import React from 'react';
import ConnectedAuthenticationGuard from '../containers/ConnectedAuthenticationGuard';
import ConnectedOrganizationCreateForm from '../containers/ConnectedOrganizationCreateForm';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ArtBackgroundLayout from '../ui/ArtBackgroundLayout';
import { t } from '../i18n';

const OrganizationWizard: React.FC = () => {
  const match = useRouteMatch();

  return (
    <ConnectedAuthenticationGuard>
      <ArtBackgroundLayout
        title={t('Welcome to NoPilot!')}
        subtitle={t(
          "Let's begin by just filling in these three form fields and get started with your dashboard.",
        )}
      >
        <Switch>
          <Route exact path={`${match.path}`}>
            <ConnectedOrganizationCreateForm nextStep={`/welcome`} />
          </Route>
        </Switch>
      </ArtBackgroundLayout>
    </ConnectedAuthenticationGuard>
  );
};

export default OrganizationWizard;
