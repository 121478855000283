import React from 'react';
import { FiHash } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface HashtagIconProps extends IconProps {}

const HashtagIcon: React.FC<HashtagIconProps> = ({ ...props }) => {
  return <Icon as={FiHash} {...props} />;
};

export default HashtagIcon;
