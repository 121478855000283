import React from 'react';
import { observer } from 'mobx-react';
import DeleteDrawer from '../components/DeleteDrawer';
import useResolver from '../hooks/useResolver';
import OrganizationStore from '../store/OrganizationStore';
import { t } from '../i18n';

export interface ConnectedOrganizationDeleteDrawerProps {
  name: string;
}

const ConnectedOrganizationDeleteDrawer: React.FC<
  ConnectedOrganizationDeleteDrawerProps
> = ({ name }) => {
  const organizationStore = useResolver(OrganizationStore);
  const organization = organizationStore.currentOrganization;

  if (!organization) {
    return null;
  }

  const handleSubmit = async () => {
    await organizationStore.delete(organizationStore.currentOrganizationId);
  };

  return (
    <DeleteDrawer
      name={name}
      onSubmit={handleSubmit}
      confirmationText={organization.name || 'delete'}
      header={`${t('Delete')} ${organization.name}`}
    />
  );
};

export default observer(ConnectedOrganizationDeleteDrawer);
