import React from 'react';
import { Skeleton, Stack } from '@chakra-ui/react';

export interface SkeletonLoaderProps {}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = () => {
  return (
    <Stack>
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
    </Stack>
  );
};

SkeletonLoader.defaultProps = {};

export default SkeletonLoader;
