import React, { useEffect } from 'react';
import useResolver from '../hooks/useResolver';
import { observer } from 'mobx-react';
import AuthenticatedLayout from '../components/AuthenticatedLayout';
import useDevice from '../hooks/useDevice';
import PersonStore from '../store/PersonStore';
import { Route as NavRoute } from '../routes';
import OrganizationStore from '../store/OrganizationStore';

interface ConnectedAuthenticatedLayoutProps {
  routes?: NavRoute[];
}

const ConnectedAuthenticatedLayout: React.FC<
  ConnectedAuthenticatedLayoutProps
> = ({ routes, children }) => {
  const personStore = useResolver(PersonStore);
  const organizationStore = useResolver(OrganizationStore);
  const { currentUserNonActivePersons, currentPerson } = personStore;
  const device = useDevice();

  useEffect(() => {
    personStore.loadUserRelated();
  }, []);

  if (!currentPerson) {
    return null;
  }

  currentPerson.organization = organizationStore.getByIdOrLoad(
    currentPerson.organizationId,
  );

  return (
    <AuthenticatedLayout
      routes={routes || []}
      currentUserNonActivePersons={currentUserNonActivePersons.map((p) => {
        p.organization = organizationStore.getByIdOrLoad(p.organizationId);

        return p;
      })}
      currentPerson={currentPerson}
      isMobile={device.isMobile}
    >
      {children}
    </AuthenticatedLayout>
  );
};

export default observer(ConnectedAuthenticatedLayout);
