import Resource from '../decorators/Resource';
import AbstractDomain from './AbstractDomain';

@Resource.Name('payment-methods')
export default class PaymentMethod extends AbstractDomain {
  public externalId: string;
  public endingNumbers: string;
  public type: string;
  public organizationId: string;

  fromObject(payload: Partial<PaymentMethod>): this {
    super.fromObject(payload);

    this.externalId = payload.externalId;
    this.endingNumbers = payload.endingNumbers;
    this.type = payload.type;
    this.organizationId = payload.organizationId;

    return this;
  }

  toObject(): Partial<PaymentMethod> {
    return {
      ...super.toObject(),
      externalId: this.externalId,
      endingNumbers: this.endingNumbers,
      type: this.type,
      organizationId: this.organizationId,
    };
  }
}
