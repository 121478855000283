import AbstractResourceStore from './AbstractResourceStore';
import PaymentMethod from '../domain/PaymentMethod';
import PaymentMethodService from '../services/PaymentMethodService';
import AccountStore from './AccountStore';
import { singleton } from 'tsyringe';
import OrganizationStore from './OrganizationStore';
import { computed } from 'mobx';

@singleton()
export default class PaymentMethodStore extends AbstractResourceStore<PaymentMethod> {
  public constructor(
    private readonly paymentMethodService: PaymentMethodService,
    private readonly organizationStore: OrganizationStore,
    accountStore: AccountStore,
  ) {
    super(paymentMethodService, accountStore);
  }

  public async loadByOrganization(organizationId: string) {
    await this.setManyEventually(
      this.paymentMethodService.getAllByOrganization(organizationId),
    );
  }

  public async loadByCurrentOrganization() {
    return this.loadByOrganization(
      this.organizationStore.currentOrganizationId,
    );
  }

  public getItemsByOrganization(organizationId: string): PaymentMethod[] {
    return this.getFilteredItems({ organizationId });
  }

  @computed
  public get currentOrganizationItems(): PaymentMethod[] {
    return this.getItemsByOrganization(
      this.organizationStore.currentOrganizationId,
    );
  }
}
