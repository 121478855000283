import React, { useEffect } from 'react';
import Heading from '../ui/Heading';
import LinkBox from '../ui/LinkBox';
import LinkOverlay from '../ui/LinkOverlay';
import Text from '../ui/Text';
import { formatDistanceToNow } from 'date-fns';
import Notification from '../domain/Notification';
import { formatDate } from '../util/date';
import DateText from '../ui/DateText';
import Markdown from '../ui/Markdown';

export interface NotificationProps {
  notification: Notification;
  onMark: () => void;
}

function getPath(path: string) {
  if (path.startsWith('tasks/')) {
    return `/personal/tasks`;
  }

  return path;
}

const NotificationInList: React.FC<NotificationProps> = ({
  onMark,
  notification,
}) => {
  const formattedDate = formatDate(notification.createdAt);

  useEffect(() => {
    if (!notification.readAt) {
      onMark();
    }
  }, []);

  return (
    <LinkBox
      opacity={notification.readAt ? 0.6 : 1}
      p="5"
      borderWidth="1px"
      rounded="md"
    >
      <DateText fontSize="xs" color="gray.500" dateTime={formattedDate}>
        {formatDistanceToNow(notification.createdAt, { addSuffix: true })}
      </DateText>
      <Heading size="sm" my="2">
        {notification.path && (
          <LinkOverlay to={getPath(notification.path)}>
            {notification.title}
          </LinkOverlay>
        )}
        {!notification.path && notification.title}
      </Heading>
      <Text fontSize="xs">
        <Markdown>{notification.content}</Markdown>
      </Text>
    </LinkBox>
  );
};

export default NotificationInList;
