import AbstractDomain from './AbstractDomain';
import Resource from '../decorators/Resource';
import { toDate } from '../util/date';

@Resource.Name('notifications')
export default class Notification extends AbstractDomain {
  public title: string;
  public content: string;
  public path: string;
  public readAt?: Date;
  public personId: string;

  public toObject(): Partial<Notification> {
    return {
      ...super.toObject(),
      title: this.title,
      content: this.content,
      path: this.path,
      readAt: this.readAt,
      personId: this.personId,
    };
  }

  public fromObject(payload: Partial<Notification>): this {
    super.fromObject(payload);

    this.title = payload.title;
    this.content = payload.content;
    this.path = payload.path;
    this.readAt = toDate(payload.readAt);
    this.personId = payload.personId;

    return this;
  }
}
