import React from 'react';
import { observer } from 'mobx-react';
import ProjectMoreActionsMenu from '../components/ProjectMoreActionsMenu';
import Project, { ProjectState } from '../domain/Project';
import useResolver from '../hooks/useResolver';
import ProjectStore from '../store/ProjectStore';
import useToast from '../hooks/useToast';
import { getTextsByType } from '../util/project';
import { t } from '../i18n';

export interface ConnectedProjectMoreActionsMenuProps {
  project: Project;
}

const ConnectedProjectMoreActionsMenu: React.FC<
  ConnectedProjectMoreActionsMenuProps
> = ({ project }) => {
  const projectStore = useResolver(ProjectStore);
  const toast = useToast();
  const projectTypeName = getTextsByType(project.type).name;

  return (
    <ProjectMoreActionsMenu
      project={project}
      onEnd={async (dateEnd) => {
        await projectStore.update({
          id: project.id,
          dateEnd,
        });

        toast({
          description: `${projectTypeName} ${t('has been ended')}`,
          status: 'success',
        });
      }}
      onStateChange={async (newState) => {
        await projectStore.updateState(project.id, newState);

        const descriptionByState: { [key in ProjectState]: string } = {
          [ProjectState.PUBLISHED]: `${projectTypeName} ${project.name} ${t(
            'has been successfully published',
          )}`,
          [ProjectState.DRAFT]: `${projectTypeName} ${project.name} ${t(
            'is now a draft',
          )}`,
          [ProjectState.ARCHIVED]: `${projectTypeName} ${project.name} ${t(
            'has been archived',
          )}`,
        };

        toast({
          description:
            descriptionByState[newState] ||
            `${projectTypeName} ${t('state updated')}`,
          status: 'success',
        });
      }}
    />
  );
};

export default observer(ConnectedProjectMoreActionsMenu);
