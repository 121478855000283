import React from 'react';
import { observer } from 'mobx-react';
import ParticipationDrawer, {
  ParticipationDrawerProps,
} from '../components/ParticipationDrawer';
import useQueryValue from '../hooks/useQueryValue';
import useResolver from '../hooks/useResolver';
import ProjectStore from '../store/ProjectStore';
import ParticipationStore from '../store/ParticipationStore';
import Participation, { ParticipationState } from '../domain/Participation';
import Project, { ProjectType } from '../domain/Project';

export interface ConnectedParticipationDrawerProps
  extends Omit<ParticipationDrawerProps, 'project' | 'onSubmit'> {}

const ConnectedParticipationDrawer: React.FC<
  ConnectedParticipationDrawerProps
> = ({ ...props }) => {
  const projectStore = useResolver(ProjectStore);
  const participationStore = useResolver(ParticipationStore);
  const participationId = useQueryValue(props.name);
  const participation = participationId
    ? participationStore.getByIdOrLoad(participationId)
    : null;
  const projectId = useQueryValue(
    'projectId',
    participation?.projectId || null,
  );
  const personId = useQueryValue('personId', participation?.projectId || null);
  const projectType = useQueryValue<ProjectType>(
    'projectType',
    ProjectType.PROJECT,
  );
  const project = projectId ? projectStore.getByIdOrLoad(projectId) : null;

  const handleSubmit = async (
    values: Partial<Project> & { personIds?: string[] },
  ) => {
    const personIds = participation
      ? [participation.personId]
      : values.personIds;

    for (const id of personIds) {
      const p = participation || new Participation();
      Object.assign(p, {
        personId: id,
        state: ParticipationState.APPROVED,
        projectId,
        ...values,
      });

      await participationStore.save(p);
    }
  };

  return (
    <ParticipationDrawer
      project={project}
      onSubmit={handleSubmit}
      defaultValues={participation || {}}
      participation={participation}
      personId={personId}
      projectType={projectType}
      {...props}
      userIsSelectable={participation ? false : props.userIsSelectable}
    />
  );
};

ConnectedParticipationDrawer.defaultProps = {
  name: ParticipationDrawer.defaultProps.name,
};

export default observer(ConnectedParticipationDrawer);
