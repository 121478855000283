import Participation, { ParticipationState } from '../domain/Participation';
import { addDays, isWeekend, startOfMonth } from 'date-fns';
import mean from 'lodash/mean';
import { BudgetSheetRow } from '../lib/BudgetSheetBuilder';

export const DAY_HOURS = 8;

export function capacityToDayHours(capacity: number): number {
  return +((capacity || 0) * DAY_HOURS).toFixed(3);
}

export function getActiveParticipationsByDate(
  participations: Participation[],
  date: Date,
): Participation[] {
  return participations.filter((participation) => {
    return (
      participation.state === ParticipationState.APPROVED &&
      participation.isOngoing(date)
    );
  });
}

export function getCapacityByDate(
  participations: Participation[],
  date: Date,
): number {
  let value = 0;

  getActiveParticipationsByDate(participations, date).forEach(
    (participation) => {
      value += participation.capacity;
    },
  );

  return value;
}

export function calculateMonthCapacity(
  participations: Participation[],
  date: Date,
  excludeWeekends: boolean = false,
): number {
  const monthIndex = date.getMonth();
  let currentDate = startOfMonth(date);
  const capacities: number[] = [];

  while (currentDate.getMonth() === monthIndex) {
    const shouldInclude = excludeWeekends ? !isWeekend(currentDate) : true;

    if (shouldInclude) {
      capacities.push(getCapacityByDate(participations, currentDate));
    }

    currentDate = addDays(currentDate, 1);
  }

  return mean(capacities);
}

export function calculateHoursFromCapacity(
  capacity: number,
  date: Date,
  excludeWeekends: boolean = true,
): number {
  const monthIndex = date.getMonth();
  let currentDate = startOfMonth(date);
  const capacities: number[] = [];

  while (currentDate.getMonth() === monthIndex) {
    const shouldInclude = excludeWeekends ? !isWeekend(currentDate) : true;

    if (shouldInclude) {
      capacities.push(capacityToDayHours(capacity));
    }

    currentDate = addDays(currentDate, 1);
  }

  return capacities.reduce((partialSum, a) => partialSum + a, 0);
}

export function getCapacityByBudgetRow(budgetRow: BudgetSheetRow[]): number {
  return budgetRow.reduce((total, row) => total + row.capacity, 0);
}

export function strokePercentage(percentage: number, circ: number): number {
  if (percentage >= 100) return 0;

  return ((100 - percentage) * circ) / 100;
}
