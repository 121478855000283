import React from 'react';
import GoogleButton from '../ui/GoogleButton';
import useResolver from '../hooks/useResolver';
import AccountStore, { SocialLoginProvider } from '../store/AccountStore';

export interface ConnectedGoogleLoginProps {}

const ConnectedGoogleLogin: React.FC<ConnectedGoogleLoginProps> = () => {
  const accountStore = useResolver(AccountStore);

  return (
    <GoogleButton
      onClick={() => accountStore.socialLogin(SocialLoginProvider.GOOGLE)}
    />
  );
};

export default ConnectedGoogleLogin;
