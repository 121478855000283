import React from 'react';
import { InputGroup, InputRightElement } from '@chakra-ui/react';
import {
  toDateOrDefault,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_TIME_FORMAT,
  toDate,
} from '../util/date';
import { CalendarIcon } from './icons';
import ReactDatePicker from 'react-datepicker';
import Input, { InputProps } from './Input';

import 'react-datepicker/dist/react-datepicker.css';

export interface DateInputProps
  extends Omit<InputProps, 'type' | 'value' | 'min' | 'max' | 'onChange'> {
  value?: string | Date | null;
  onChange?: (date: Date | null) => void;
  dateFormat?: string;
  withTime?: boolean;
  min?: Date;
  max?: Date;
}

const DateInput: React.FC<DateInputProps> = ({
  value,
  min,
  max,
  withTime,
  onChange,
  id,
  ...props
}) => {
  const dateValue = value && toDateOrDefault(value);
  const dateFormat = withTime ? DEFAULT_DATETIME_FORMAT : DEFAULT_DATE_FORMAT;

  return (
    <InputGroup>
      <ReactDatePicker
        onChange={onChange}
        selected={dateValue}
        dateFormat={dateFormat}
        showTimeSelect={withTime}
        timeFormat={DEFAULT_TIME_FORMAT}
        maxDate={max && toDate(max)}
        minDate={min && toDate(min)}
        customInput={<Input {...props} />}
      />
      <InputRightElement {...(id ? { as: 'label', for: id } : {})}>
        <CalendarIcon />
      </InputRightElement>
    </InputGroup>
  );
};

export default DateInput;
