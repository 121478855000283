import React from 'react';
import { strokePercentage } from '../util/capacity';

enum circleX {
  'l' = 150,
  'm' = 165,
  's' = 180,
}

enum circleY {
  'l' = 75,
  'm' = 25,
  's' = 15,
}

enum strokeSize {
  'l' = '6px',
  'm' = '4px',
  's' = '2px',
}

const CapacityCircle = ({
  colour,
  percentage,
  r,
  size,
}: {
  colour: string;
  percentage?: number;
  r: number;
  size: 's' | 'l' | 'm';
}) => {
  const circ = 2 * Math.PI * r;

  return (
    <circle
      r={r}
      cx={circleX[size]}
      cy={circleY[size]}
      fill="transparent"
      stroke={percentage === 0 ? '' : colour}
      strokeWidth={strokeSize[size]}
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePercentage(percentage, circ) : 0}
    />
  );
};

export default CapacityCircle;
