import AbstractDomain from './AbstractDomain';
import Resource from '../decorators/Resource';

@Resource.Name('users')
export default class User extends AbstractDomain {
  public name?: string;
  public email: string;

  public getDisplayName() {
    return this.name || this.email;
  }

  public fromObject(payload: Partial<User>): this {
    super.fromObject(payload);

    this.name = payload.name;
    this.email = payload.email;

    return this;
  }

  public toObject(): Partial<User> {
    return {
      ...super.toObject(),
      name: this.name,
      email: this.email,
    };
  }
}
