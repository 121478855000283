import React from 'react';
import Form from './Form';
import Heading from '../ui/Heading';
import Stack from '../ui/Stack';
import Text from '../ui/Text';
import FormSubmitButton from './FormSubmitButton';
import Invitation from '../domain/Invitation';
import SkeletonLoader from '../ui/SkeletonLoader';
import Alert from '../ui/Alert';
import { t } from '../i18n';
import { HStack, TextLink } from '../ui';

export interface InvitationFormProps {
  onSubmit: () => void | Promise<void>;
  invitation: Invitation;
  error?: string;
  viewerEmail?: string;
}

const InvitationForm: React.FC<InvitationFormProps> = ({
  onSubmit,
  invitation,
  error,
  viewerEmail,
}) => {
  if (error) {
    return <Alert status="error">{error}</Alert>;
  }

  if (!invitation) {
    return <SkeletonLoader />;
  }

  return (
    <Form onSubmit={onSubmit}>
      <Stack spacing={4}>
        <Heading>
          {t('Hey')}, {invitation.personName}!
        </Heading>
        <Text>
          {t(`You've been invited to join`)}{' '}
          <strong>{invitation.organizationName}</strong>
        </Text>

        {invitation.email !== viewerEmail && (
          <Alert status="warning">
            <div>
              {t('You are signed in as')} <strong>{viewerEmail}</strong>{' '}
              {t('but invitation is for')} <strong>{invitation.email}</strong>.{' '}
              {t(
                'When accepting the invitation now, your signed in account will be used.',
              )}
            </div>
            <HStack paddingTop={2}>
              <TextLink
                to={`/logout?redirect=${encodeURIComponent(
                  `/login?redirect=${encodeURIComponent(
                    window.location.pathname + window.location.search,
                  )}&email=${encodeURIComponent(invitation.email)}`,
                )}`}
              >
                {t('Switch account')}
              </TextLink>
            </HStack>
          </Alert>
        )}

        <FormSubmitButton>{t('Accept invitation')}</FormSubmitButton>
      </Stack>
    </Form>
  );
};

export default InvitationForm;
