import React, { useEffect } from 'react';
import useResolver from '../hooks/useResolver';
import { observer } from 'mobx-react';
import TagsGrid, { TagsGridProps, TagsGridTag } from '../components/TagsGrid';
import { TagType } from '../domain/Tag';
import ProjectTagStore from '../store/ProjectTagStore';
import UsersIcon from '../ui/icons/UsersIcon';
import ParticipationStore from '../store/ParticipationStore';
import ProjectStore from '../store/ProjectStore';

export interface ProjectTagsProps extends Omit<TagsGridProps, 'tags'> {
  projectId: string;
}

const ConnectedProjectTagsGrid: React.FC<ProjectTagsProps> = ({
  projectId,
  ...props
}) => {
  const projectTagStore = useResolver(ProjectTagStore);
  const participationStore = useResolver(ParticipationStore);
  const projectStore = useResolver(ProjectStore);
  const project = projectStore.getByIdOrLoad(projectId);

  useEffect(() => {
    projectTagStore.loadByProject(projectId);
    participationStore.loadByProject(projectId);
  }, [projectId]);

  const other = projectTagStore.getProjectTagsExcludingPositions(projectId);
  const tags: TagsGridTag[] = other
    .map((projectTag) => projectTag.tag || null)
    .filter(Boolean);

  const positionsCount = projectTagStore.getProjectPositionsCount(projectId);
  const participantsCount = participationStore.getProjectParticipantsCount(
    projectId,
    project?.isOngoing() || !project?.dateStart
      ? new Date()
      : project.dateStart,
  );

  if (positionsCount > 0 || participantsCount > 0) {
    tags.unshift({
      icon: <UsersIcon />,
      name: `${+participantsCount.toFixed(1)}${
        positionsCount > 0 ? ` / ${+positionsCount.toFixed(1)}` : ''
      }`,
      type: TagType.POSITION,
    });
  }

  return <TagsGrid tags={tags} {...props} />;
};

export default observer(ConnectedProjectTagsGrid);
