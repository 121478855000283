import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

export interface DateTextProps extends TextProps {
  dateTime: string;
}

const DateText: React.FC<DateTextProps> = ({ dateTime, ...props }) => {
  return <Text as="time" title={dateTime} dateTime={dateTime} {...props} />;
};

DateText.defaultProps = {};

export default DateText;
