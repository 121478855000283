import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

type Direction = 'up' | 'right' | 'down' | 'left';

export interface ArrowIconProps extends IconProps {
  direction?: Direction;
}

const getRotation = (direction: Direction): number => {
  switch (direction) {
    case 'right':
      return 0;
    case 'down':
      return 90;
    case 'left':
      return 180;
    case 'up':
      return -90;
  }
};

const ArrowIcon: React.FC<ArrowIconProps> = ({ direction, ...props }) => {
  return (
    <Icon
      transition="transform .3s"
      transform={`rotate(${getRotation(direction)}deg)`}
      as={FiArrowRight}
      {...props}
    />
  );
};

ArrowIcon.defaultProps = {
  direction: 'right',
};

export default ArrowIcon;
