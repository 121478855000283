import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import useQueryValue from '../hooks/useQueryValue';
import PersonStore from '../store/PersonStore';
import ParticipationStore from '../store/ParticipationStore';
import { Role } from '../domain/Person';
import { t } from '../i18n';
import ManagedOrganizationDeactivateDrawer, {
  ManagedOrganizationDeactivateDrawerProps,
} from '../components/ManagedOrganizationDeactivateDrawer';
import OrganizationStore from '../store/OrganizationStore';
import OrganizationRelationStore from '../store/OrganizationRelationStore';

export interface ConnectedPersonDeactivateDrawerProps {
  name: string;
}

const ConnectedPersonDeactivateDrawer: React.FC<
  ConnectedPersonDeactivateDrawerProps
> = ({ name }) => {
  const organizationStore = useResolver(OrganizationStore);
  const personStore = useResolver(PersonStore);
  const organizationRelationStore = useResolver(OrganizationRelationStore);
  const participationStore = useResolver(ParticipationStore);
  const organizationRelationId = useQueryValue(name);
  const organizationRelation = organizationRelationId
    ? organizationRelationStore.getByIdOrLoad(organizationRelationId)
    : null;

  useEffect(() => {
    if (organizationRelation) {
      personStore.loadByOrganization(
        organizationRelation?.relatedOrganizationId,
      );
      organizationStore.loadOne(organizationRelation?.relatedOrganizationId);
      participationStore.loadByCurrentOrganization();
      participationStore.loadByOrganization(
        organizationRelation?.relatedOrganizationId,
      );
    }
  }, [organizationRelation]);

  if (!organizationRelationId) {
    return null;
  }

  const handleSubmit: ManagedOrganizationDeactivateDrawerProps['onSubmit'] =
    async ({ deactivate = {} }) => {
      const disablePersonIds = Object.keys(deactivate).filter(
        (id) => !!deactivate[id],
      );

      for (const id of disablePersonIds) {
        await personStore.deactivatePerson(id);
        const participation = participationStore.getItemsByPerson(id);
        await participationStore.endParticipations(
          participation.map((p) => p.id),
        );
        await organizationRelationStore.disableOrganizationRelation(
          organizationRelationId,
        );
      }

      await organizationRelationStore.disableOrganizationRelation(
        organizationRelationId,
      );
    };

  const persons = personStore
    .getOrganizationsPersons([organizationRelation?.relatedOrganizationId])
    .filter((p) => p.role !== Role.DISABLED);

  const managedOrganization = organizationStore.getById(
    organizationRelation?.relatedOrganizationId,
  );

  if (!managedOrganization) {
    return null;
  }

  return (
    <ManagedOrganizationDeactivateDrawer
      readOnly={false}
      managedOrganization={managedOrganization}
      header={t('Confirm deactivation')}
      buttonText={t('Deactivate')}
      successMessage={`${t('deactivated')}`}
      name={name}
      persons={persons}
      onSubmit={handleSubmit}
    />
  );
};

export default observer(ConnectedPersonDeactivateDrawer);
