import React from 'react';
import Participation from '../domain/Participation';
import Box from '../ui/Box';
import HStack from '../ui/HStack';
import { getProjectColor } from '../util/project';
import ConnectedProjectLink from '../containers/ConnectedProjectLink';
import Stack from '../ui/Stack';
import DatePeriod from '../ui/DatePeriod';
import CapacityLabel from './CapacityLabel';

export interface ParticipationQuickInfoProps {
  participation: Participation;
}

const ParticipationQuickInfo: React.FC<ParticipationQuickInfoProps> = ({
  participation,
}) => {
  return (
    <Stack>
      <HStack spacing={2}>
        <Box w={4} h={4} bg={getProjectColor(participation.projectId)} />
        <ConnectedProjectLink projectId={participation.projectId} />
        <CapacityLabel capacity={participation.capacity} />
      </HStack>
      <HStack>
        <DatePeriod
          dateStart={participation.dateStart}
          dateEnd={participation.dateEnd}
        />
      </HStack>
    </Stack>
  );
};

export default ParticipationQuickInfo;
