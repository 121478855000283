import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import InvitationStore from '../store/InvitationStore';
import OrganizationPersonList, {
  OrganizationPersonListProps,
} from '../components/OrganizationPersonList';
import OrganizationStore from '../store/OrganizationStore';

export interface ConnectedOrganizationPersonListProps
  extends Partial<Pick<OrganizationPersonListProps, 'compact' | 'title'>> {
  organizationId?: string;
}

const ConnectedOrganizationPersonList: React.FC<
  ConnectedOrganizationPersonListProps
> = ({ organizationId: suppliedOrganizationId, ...props }) => {
  const organizationStore = useResolver(OrganizationStore);
  const personStore = useResolver(PersonStore);
  const invitationStore = useResolver(InvitationStore);
  const currentPerson = personStore.currentPerson;
  const organizationId =
    suppliedOrganizationId || organizationStore.currentOrganizationId;

  useEffect(() => {
    personStore.loadByOrganization(organizationId);
    invitationStore.loadByOrganization(organizationId);
  }, [personStore, invitationStore, organizationId]);

  return (
    <OrganizationPersonList
      status={invitationStore.status}
      {...props}
      persons={personStore.getFilteredItems({ organizationId })}
      currentPerson={currentPerson}
      editable={currentPerson.hasEditPermission()}
      getPersonInvitation={(personId) =>
        invitationStore.getLatestPersonInvitation(personId)
      }
      createInvitationForPerson={(personId) =>
        invitationStore.createForPerson(personId)
      }
      organizationId={organizationId}
    />
  );
};

export default observer(ConnectedOrganizationPersonList);
