import React from 'react';
import { AvatarGroup as ChakraAvatarGroup } from '@chakra-ui/react';

export interface AvatarGroupProps {}

const AvatarGroup: React.FC<AvatarGroupProps> = ({ children }) => {
  return <ChakraAvatarGroup size="sm">{children}</ChakraAvatarGroup>;
};

AvatarGroup.defaultProps = {};

export default AvatarGroup;
