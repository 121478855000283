import React from 'react';
import AvailabilityLineChart, {
  AvailabilityLineChartProps,
} from './AvailabilityLineChart';
import AvailabilityPieChart, {
  AvailabilityPieChartProps,
} from './AvailabilityPieChart';

export interface AvailabilityChartProps
  extends AvailabilityLineChartProps,
    AvailabilityPieChartProps {
  variant?: 'line' | 'pie';
}

const AvailabilityChart: React.FC<AvailabilityChartProps> = ({
  variant,
  ...props
}) => {
  if (variant === 'line') {
    return <AvailabilityLineChart {...props} />;
  }

  if (variant === 'pie') {
    return <AvailabilityPieChart {...props} />;
  }

  return null;
};

AvailabilityChart.defaultProps = {
  variant: 'line',
};

export default AvailabilityChart;
