import React from 'react';
import Icon, { IconProps } from '../Icon';
import { FiClock } from 'react-icons/fi';

export interface ClockIconProps extends IconProps {}

const ClockIcon: React.FC<ClockIconProps> = ({ ...props }) => {
  return <Icon as={FiClock} {...props} />;
};

export default ClockIcon;
