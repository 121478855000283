import React from 'react';
import Person from '../domain/Person';
import ConnectedPersonUser from '../containers/ConnectedPersonUser';
import RichSelect, {
  RichSelectOption,
  RichSelectProps,
} from '../ui/RichSelect';

export interface PersonsSelectProps extends Omit<RichSelectProps, 'options'> {
  persons: Person[];
  relatedOrganisationPersons?: Person[];
  onChange?: (personId: string) => void;
  onSelect?: (option: RichSelectOption[]) => void;
  nullable?: boolean;
  placeholder?: string;
  onCreate?: (name: string) => void;
}

const personToOption = (person: Person, hideOrg: boolean): RichSelectOption => {
  return {
    value: person.id,
    label: person.name,
    component: (
      <ConnectedPersonUser personId={person.id} hideOrganization={hideOrg} />
    ),
  };
};

const PersonsSelect: React.FC<PersonsSelectProps> = ({
  persons,
  relatedOrganisationPersons,
  value,
  nullable,
  placeholder,
  onCreate,
  onSelect,
}) => {
  const options = [
    ...persons.map((elem) => personToOption(elem, true)),
    ...relatedOrganisationPersons.map((elem) => personToOption(elem, false)),
  ];

  return (
    <RichSelect
      onCreate={onCreate}
      minHeight={50}
      options={options}
      onChange={(option) => {
        onSelect?.(option as RichSelectOption[]);
      }}
      value={
        value &&
        (value as RichSelectOption[]).map(
          (v) => options.find((o) => o.value === v.value) || v,
        )
      }
      clearable={nullable}
      placeholder={placeholder}
      multiple
    />
  );
};

export default PersonsSelect;
