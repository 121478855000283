import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ProjectParticipateButton, {
  ProjectParticipateButtonProps,
} from '../components/ProjectParticipateButton';
import useResolver from '../hooks/useResolver';
import ParticipationStore from '../store/ParticipationStore';
import ProjectStore from '../store/ProjectStore';

export interface ConnectedProjectParticipateButtonProps
  extends Omit<ProjectParticipateButtonProps, 'participation' | 'project'> {
  projectId: string;
}

const ConnectedProjectParticipateButton: React.FC<ConnectedProjectParticipateButtonProps> =
  ({ projectId, ...props }) => {
    const participationStore = useResolver(ParticipationStore);
    const projectStore = useResolver(ProjectStore);

    useEffect(() => {
      participationStore.loadByProject(projectId);
    }, [projectId]);

    return (
      <ProjectParticipateButton
        participation={participationStore.getCurrentPersonParticipationInProject(
          projectId,
        )}
        project={projectStore.getByIdOrLoad(projectId)}
        {...props}
      />
    );
  };

export default observer(ConnectedProjectParticipateButton);
