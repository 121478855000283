import React from 'react';
import ConnectedCardsManagement from '../containers/ConnectedCardsManagement';
import ResponsiveGrid from '../ui/ResponsiveGrid';
import Stack from '../ui/Stack';
import ConnectedInvoiceList from '../containers/ConnectedInvoiceList';
import ConnectedSubscriptionInfo from '../containers/ConnectedSubscriptionInfo';
import Button from '../ui/Button';
import HStack from '../ui/HStack';
import ConnectedOrganizationDeleteDrawer from '../containers/ConnectedOrganizationDeleteDrawer';
import DeleteIcon from '../ui/icons/DeleteIcon';
import { t } from '../i18n';

export interface OrganizationBillingProps {}

const OrganizationBilling: React.FC<OrganizationBillingProps> = () => {
  return (
    <Stack spacing={12}>
      <ResponsiveGrid columns={2} spacing={4}>
        <div>
          <ConnectedCardsManagement />
        </div>

        <div>
          <ConnectedSubscriptionInfo />
        </div>
      </ResponsiveGrid>

      <div>
        <ConnectedInvoiceList />
      </div>

      <div>
        <ConnectedOrganizationDeleteDrawer name="organization.delete" />

        <HStack justifyContent="flex-end">
          <Button
            leftIcon={<DeleteIcon />}
            variant="outline"
            to="?organization.delete"
            colorScheme="red"
          >
            {t('Delete organization')}
          </Button>
        </HStack>
      </div>
    </Stack>
  );
};

export default OrganizationBilling;
