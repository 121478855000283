import React from 'react';
import Button from '../ui/Button';
import Menu, { MenuItem, MenuProps } from '../ui/Menu';
import { TagType } from '../domain/Tag';
import TagWithIcon from './TagWithIcon';
import { t } from '../i18n';

export interface TagTypeSelectProps {
  value?: TagType;
  onChange?: (value: TagType) => void;
  fontSize?: string;
  placement?: MenuProps['placement'];
}

export const humanReadableTypes: { [key in TagType]: string } = {
  [TagType.POSITION]: t('Roles'),
  [TagType.LANGUAGE]: t('Communication languages'),
  [TagType.TECHNOLOGY]: t('Expertise'),
  [TagType.LOCATION]: t('Locations'),
  [TagType.PROJECT_TYPE]: t('Projects'),
  [TagType.CUSTOM]: t('Custom'),
};

const TagTypeSelect: React.FC<TagTypeSelectProps> = ({
  value,
  onChange,
  fontSize,
  placement,
}) => {
  const handle = (
    <Button variant="ghost">
      <TagWithIcon
        name={humanReadableTypes[value]}
        type={value}
        fontSize={fontSize}
      />
    </Button>
  );

  return (
    <Menu handle={handle} withChevron placement={placement}>
      {[
        TagType.POSITION,
        TagType.LANGUAGE,
        TagType.TECHNOLOGY,
        TagType.LOCATION,
        TagType.CUSTOM,
      ]
        .filter((v) => v !== value)
        .map((tagType) => (
          <MenuItem
            onClick={onChange && (() => onChange(tagType))}
            key={tagType}
          >
            <TagWithIcon
              name={humanReadableTypes[tagType]}
              type={tagType}
              fontSize={fontSize}
            />
          </MenuItem>
        ))}
    </Menu>
  );
};

export default TagTypeSelect;
