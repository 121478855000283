import React from 'react';
import {
  Avatar as ChakraAvatar,
  AvatarProps as ChakraAvatarProps,
} from '@chakra-ui/react';

export interface AvatarProps extends ChakraAvatarProps {}

const Avatar: React.FC<AvatarProps> = ({ ...props }) => {
  return <ChakraAvatar {...props} />;
};

Avatar.defaultProps = {};

export default Avatar;
