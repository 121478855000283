import React, { useEffect, useState } from 'react';
import Tag, { TagType } from '../domain/Tag';
import Organization from '../domain/Organization';
import Checkbox from '../ui/Checkbox';
import TagWithIcon from './TagWithIcon';
import AutoGrid from '../ui/AutoGrid';
import Text from '../ui/Text';
import Button from '../ui/Button';
import HStack from '../ui/HStack';
import Heading from '../ui/Heading';
import { stringifyTagType } from '../util/tag';
import Box from '../ui/Box';
import TextLink from '../ui/TextLink';
import Input from '../ui/Input';
import { t } from '../i18n';

export interface OrganizationCreateSkillsProps {
  onSubmit: (tags: Tag[]) => void;
  organization: Organization;
  availableTags: Tag[];
  organizationTags: Tag[];
  nextStep: string;
}

const OrganizationCreateSkills: React.FC<OrganizationCreateSkillsProps> = ({
  organization,
  onSubmit,
  availableTags: initialTags,
  nextStep,
  organizationTags,
}) => {
  const [availableTags, setAvailableTags] = useState<Tag[]>(initialTags);
  const [checkedTags, setCheckedTags] = useState<Tag[]>([]);
  const groups = [
    TagType.POSITION,
    TagType.TECHNOLOGY,
    TagType.LANGUAGE,
    TagType.LOCATION,
  ];

  useEffect(() => {
    if (availableTags.length !== initialTags.length) {
      setAvailableTags(initialTags);
    }
  }, [initialTags.length]);

  const addTags = (...tags: Tag[]) => {
    const copyOfTags = [...checkedTags];

    for (const tag of tags) {
      if (!copyOfTags.includes(tag)) {
        copyOfTags.push(tag);
      }
    }

    setCheckedTags(copyOfTags);
  };

  const removeTags = (...tags: Tag[]) => {
    setCheckedTags(checkedTags.filter((t) => !tags.includes(t)));
  };

  const areSelected = (...tags: Tag[]) => {
    for (const tag of tags) {
      if (!checkedTags.includes(tag)) {
        return false;
      }
    }

    return true;
  };

  return (
    <div>
      <Text mb={4}>
        What kind of roles and skills describe{' '}
        <strong>{organization.name}</strong>?
      </Text>
      {groups.map((group) => {
        const groupTags = availableTags.filter((t) => t.type === group);
        const organizationGroupTags = organizationTags.filter(
          (t) => t.type === group,
        );

        const allSelected = areSelected(...groupTags);

        return (
          <Box key={group} mt={4}>
            <HStack justifyContent="space-between">
              <Heading size="sm" mb={4}>
                <TagWithIcon name={stringifyTagType(group)} type={group} />
              </Heading>
              {groupTags.length > 0 && (
                <Text fontSize="xs">
                  <TextLink
                    onClick={(e) => {
                      allSelected
                        ? removeTags(...groupTags)
                        : addTags(...groupTags);
                    }}
                  >
                    {allSelected ? t('Deselect all') : t('Select all')}
                  </TextLink>
                </Text>
              )}
            </HStack>
            <AutoGrid childWidth="150px" spacing={2}>
              {[...organizationGroupTags, ...groupTags].map((tag) => {
                const checked = tag.id ? true : areSelected(tag);

                return (
                  <div key={tag.name}>
                    <Checkbox
                      isDisabled={!!tag.id}
                      isChecked={checked}
                      onChange={() =>
                        checked ? removeTags(tag) : addTags(tag)
                      }
                    >
                      <TagWithIcon type={tag.type} name={tag.name} />
                    </Checkbox>
                  </div>
                );
              })}
              <div>
                <Input
                  size="xs"
                  placeholder={t('Add..')}
                  variant="flushed"
                  blurOnEnter
                  onBlur={(e) => {
                    let newTag = new Tag();
                    newTag.name = e.target.value;

                    if (!newTag.name) {
                      return;
                    }

                    newTag.type = group;

                    // Use existing if same name & type already exists
                    const existingTag = [
                      ...availableTags,
                      ...organizationTags,
                    ].find(
                      (t) =>
                        t.name.toLocaleLowerCase() ===
                          newTag.name.toLocaleLowerCase() &&
                        t.type === newTag.type,
                    );
                    if (!existingTag) {
                      setAvailableTags([...availableTags, newTag]);
                    } else {
                      newTag = existingTag;
                    }

                    addTags(newTag);

                    e.target.value = '';
                    e.target.focus();
                  }}
                />
              </div>
            </AutoGrid>
          </Box>
        );
      })}
      <HStack justifyContent="flex-end" my={4}>
        <Button colorScheme="brand" onClick={() => onSubmit(checkedTags)}>
          {t('Save')}
        </Button>
      </HStack>
    </div>
  );
};

export default OrganizationCreateSkills;
