import React, { useEffect, useState } from 'react';
import Stack from '../ui/Stack';
import FormField from './FormField';
import Input from '../ui/Input';
import FormImageField from './FormImageField';
import Organization from '../domain/Organization';
import DrawerForm from './DrawerForm';
import TagsField from './TagsField';
import Tag, { TagType } from '../domain/Tag';
import Button from '../ui/Button';
import PlusIcon from '../ui/icons/PlusIcon';
import { getCustomTagContexts } from '../util/tag';
import OrganizationRelation, {
  OrganizationRelationType,
} from '../domain/OrganizationRelation';
import DateInput from '../ui/DateInput';
import Select from '../ui/Select';
import IndustrySelect from './IndustrySelect';
import { getHumanReadableOrganizationRelationType } from '../util/organization';
import Checkbox from '../ui/Checkbox';
import { t } from '../i18n';
import { isEmail } from '../util/string';
import {
  ConnectedFormDynamicFields,
  Resources,
} from '../features/dynamic-field';
import useResolver from '../hooks/useResolver';
import OrganizationStore from '../store/OrganizationStore';

export interface ManagedOrganizationFormData {
  organization: {
    name: string;
    logo: string;
    address?: string;
    vatNumber?: string;
    email?: string;
    phone?: string;
    registryCode?: string;
  };
  dynamic: Record<string, any>;
  tags: { [context: string]: Array<{ value: string; label: string }> };
  relationType: OrganizationRelationType;
  dateStart: string;
  dateEnd: string;
  description: string;
  priority: number;
}

export interface ManagedOrganizationFormDrawerProps {
  onSubmit: (data: ManagedOrganizationFormData) => void;
  organization?: Organization;
  organizationRelation?: OrganizationRelation;
  name?: string;
  availableTags: Tag[];
}

const ManagedOrganizationFormDrawer: React.FC<
  ManagedOrganizationFormDrawerProps
> = ({ onSubmit, organization, name, availableTags, organizationRelation }) => {
  const customTagContexts = getCustomTagContexts(availableTags);
  const organizationStore = useResolver(OrganizationStore);
  const [newCustomTagContexts, setNewCustomTagContexts] = useState<string[]>(
    [],
  );
  const allCustomTagContexts = Array.from(
    new Set([...customTagContexts, ...newCustomTagContexts]),
  ).sort();

  useEffect(() => {}, [availableTags]);
  useEffect(() => {
    organizationStore.loadManagedOrganizations();
  }, []);

  return (
    <DrawerForm
      header={
        organization ? `${t('Edit')} ${organization.name}` : t('Add client')
      }
      name={name}
      onSubmit={onSubmit}
      defaultValues={{
        dateStart: new Date(),
        ...(organizationRelation || {}),
        organization,
      }}
      successMessage={t('Client saved successfully!')}
    >
      {({ formContext }) => (
        <Stack spacing={4}>
          <FormField name="organization.name" required label={t('Name')}>
            <Input autoFocus />
          </FormField>

          <FormImageField label="Logo" name="organization.logo" />

          <FormField name="organization.industry" label={t('Industry')}>
            <IndustrySelect
              managedOrganizations={organizationStore.managedOrganizations}
            />
          </FormField>

          <FormField
            name="relationType"
            label={t('Type')}
            defaultValue={OrganizationRelationType.PARTNER}
          >
            <Select>
              <option value={OrganizationRelationType.PARTNER}>
                {getHumanReadableOrganizationRelationType(
                  OrganizationRelationType.PARTNER,
                )}
              </option>
              <option value={OrganizationRelationType.CLIENT}>
                {getHumanReadableOrganizationRelationType(
                  OrganizationRelationType.CLIENT,
                )}
              </option>
              <option value={OrganizationRelationType.SALESLEAD}>
                {getHumanReadableOrganizationRelationType(
                  OrganizationRelationType.SALESLEAD,
                )}
              </option>
              <option value={OrganizationRelationType.INTERNAL}>
                {getHumanReadableOrganizationRelationType(
                  OrganizationRelationType.INTERNAL,
                )}
              </option>
              <option value={OrganizationRelationType.SPONSOR}>
                {getHumanReadableOrganizationRelationType(
                  OrganizationRelationType.SPONSOR,
                )}
              </option>
            </Select>
          </FormField>

          <FormField name="dateStart" label={t('Started at')}>
            <DateInput />
          </FormField>

          <FormField name="dateEnd" label={t('Ended at')}>
            <DateInput />
          </FormField>

          <FormField name="organization.vatNumber" label={t('VAT number')}>
            <Input />
          </FormField>

          <FormField
            name="organization.registryCode"
            label={t('Registry code')}
          >
            <Input />
          </FormField>

          <FormField
            name="organization.email"
            label={t('Contact e-mail address')}
            validate={(value) => !value || isEmail(value) || 'Invalid email'}
          >
            <Input type="email" />
          </FormField>

          <FormField
            name="organization.phone"
            label={t('Contact phone number')}
          >
            <Input />
          </FormField>

          <FormField name="organization.address" label={t('Physical address')}>
            <Input />
          </FormField>

          <FormField name="priority">
            <Checkbox>{t('High priority')}</Checkbox>
          </FormField>

          <ConnectedFormDynamicFields
            tags={[Resources.ORGANIZATION_RELATIONS]}
            formContext={formContext}
          />

          {allCustomTagContexts.map((tagContext) => (
            <TagsField
              key={tagContext}
              name={`tags.${tagContext}`}
              label={tagContext}
              type={TagType.CUSTOM}
              availableTags={availableTags.filter(
                (t) => t.context === tagContext,
              )}
              context={tagContext}
              assignedTags={
                organizationRelation?.tags
                  ?.map((tagId) =>
                    availableTags.find((tag) => tag.id === tagId),
                  )
                  .filter(Boolean) || []
              }
            />
          ))}

          <Button
            leftIcon={<PlusIcon />}
            onClick={() => {
              const value = prompt(t('Enter name of custom field'));

              if (!value || allCustomTagContexts.includes(value)) {
                return;
              }

              setNewCustomTagContexts([...newCustomTagContexts, value]);
            }}
          >
            {t('Add custom field')}
          </Button>
        </Stack>
      )}
    </DrawerForm>
  );
};

ManagedOrganizationFormDrawer.defaultProps = {
  name: 'managed-organization',
};

export default ManagedOrganizationFormDrawer;
