import React, { useEffect } from 'react';
import useResolver from '../hooks/useResolver';
import ConnectedAuthenticationGuard from '../containers/ConnectedAuthenticationGuard';
import AccountStore from '../store/AccountStore';
import { Redirect } from 'react-router-dom';
import RootStore from '../store/RootStore';
import useQueryValue from '../hooks/useQueryValue';

const Logout: React.FC = () => {
  const accountStore = useResolver(AccountStore);
  const rootStore = useResolver(RootStore);
  const redirect = useQueryValue('redirect') ?? '/login';

  useEffect(() => {
    accountStore.logout().then(() => {
      rootStore.clearAll();
    });
  }, [accountStore]);

  return (
    <ConnectedAuthenticationGuard
      forbiddenAction={<Redirect to={redirect} />}
    />
  );
};

export default Logout;
