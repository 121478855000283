import PaymentMethod from '../domain/PaymentMethod';
import { injectable } from 'tsyringe';
import AbstractRestfulHttpService from './AbstractRestfulHttpService';
import ApiService from './ApiService';

@injectable()
export default class PaymentMethodService extends AbstractRestfulHttpService<PaymentMethod> {
  public constructor(apiService: ApiService) {
    super(PaymentMethod, apiService);
  }

  public getAllByOrganization(
    organizationId: string,
  ): Promise<PaymentMethod[]> {
    return this.getMany({ organizationId });
  }
}
