import React from 'react';
import Person, { Role } from '../domain/Person';
import User, { UserProps } from '../ui/User';
import Link from '../ui/Link';

export interface PersonUserProps
  extends Partial<Omit<UserProps, 'name' | 'image'>> {
  person: Person;
  linkToProfile?: boolean;
  hideOrganization?: boolean;
}

const PersonUser: React.FC<PersonUserProps> = ({
  person,
  linkToProfile,
  hideOrganization,
  ...props
}) => {
  if (!person) {
    return null;
  }

  const user = (
    <User
      data-role={person.role}
      name={person.name}
      inactive={
        (person.isDeleted() && 'Deleted') ||
        (person.role === Role.DISABLED && 'Inactive')
      }
      subTitle={hideOrganization ? person.position : person.subTitle}
      image={person.image}
      isLink={!!linkToProfile}
      {...props}
    />
  );

  if (!linkToProfile) {
    return user;
  }

  return <Link to={`/profile/view/${person.id}`}>{user}</Link>;
};

export default PersonUser;
