import React from 'react';
import RichSelect, { RichSelectOption } from '../ui/RichSelect';
import Organization from '../domain/Organization';

export interface OrganizationSelectProps {
  organizations: Organization[];
  value?: string;
  onChange?: (organizationId: string) => void;
  clearable?: boolean;
  placeholder?: string;
  onCreate?: (name: string) => void;
}

const organizationToOption = (organization: Organization): RichSelectOption => {
  return {
    value: organization.id,
    label: organization.name,
  };
};

const OrganizationSelect: React.FC<OrganizationSelectProps> = ({
  organizations,
  value,
  onChange,
  clearable,
  placeholder,
  ...props
}) => {
  const options = organizations.map(organizationToOption);
  options.unshift({ value: null, label: 'None' });

  return (
    <RichSelect
      {...props}
      options={options}
      onChange={(option) => {
        onChange?.((option as RichSelectOption).value);
      }}
      value={value && options.find((o) => o.value === value)}
    />
  );
};

export default OrganizationSelect;
