import React from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import useQueryValue from '../hooks/useQueryValue';
import { parse } from '../util/json';
import Tag, { TagType } from '../domain/Tag';
import TagFormDrawer, { TagFormDrawerProps } from '../components/TagFormDrawer';
import TagStore from '../store/TagStore';
import OrganizationStore from '../store/OrganizationStore';

export interface ConnectedTagFormDrawerProps
  extends Omit<TagFormDrawerProps, 'onSubmit' | 'tag'> {}

const ConnectedTagFormDrawer: React.FC<ConnectedTagFormDrawerProps> = ({
  children,
  name,
}) => {
  const tagStore = useResolver(TagStore);
  const organizationStore = useResolver(OrganizationStore);
  const tagId = useQueryValue(name);
  const type = useQueryValue('type', TagType.POSITION) as TagType;

  const handleSubmit = async (values: Partial<Tag>) => {
    const tag = parse(values, Tag);
    tag.organizationId = organizationStore.currentOrganizationId;

    if (tagId) {
      tag.id = tagId;
      await tagStore.update(tag);
    } else {
      await tagStore.create(tag);
    }
  };

  return (
    <TagFormDrawer
      name={name}
      onSubmit={handleSubmit}
      tag={tagId ? tagStore.getById(tagId) : { type }}
    />
  );
};

ConnectedTagFormDrawer.defaultProps = {
  name: TagFormDrawer.defaultProps.name,
};

export default observer(ConnectedTagFormDrawer);
