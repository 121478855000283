import React from 'react';
import { observer } from 'mobx-react';
import AppearanceForm from '../components/AppearanceForm';

export interface ConnectedAppearanceFormProps {}

const ConnectedAppearanceForm: React.FC<ConnectedAppearanceFormProps> = () => {
  return (
    <AppearanceForm
      onSubmit={(values: { language: string }) => {
        localStorage.setItem('language', values.language);
        window.location.reload();
      }}
    />
  );
};
export default observer(ConnectedAppearanceForm);
