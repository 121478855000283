import React from 'react';
import { FiUserPlus } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface AddUserIconProps extends IconProps {}

const AddUserIcon: React.FC<AddUserIconProps> = ({ ...props }) => {
  return <Icon as={FiUserPlus} {...props} />;
};

export default AddUserIcon;
