import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import PersonSkillStore from '../store/PersonSkillStore';
import TagStore from '../store/TagStore';
import isEmpty from 'lodash/isEmpty';
import { ResourceStoreStatus } from '../store/AbstractResourceStore';
import ConnectedOrganizationCreateSkills from './ConnectedOrganizationCreateSkills';
import Person from '../domain/Person';
import OrganizationTags from '../components/OrganizationTags';
import OrganizationStore from '../store/OrganizationStore';

export interface ConnectedOrganizationTagsProps {
  organizationId?: string;
}

const ConnectedOrganizationTags: React.FC<ConnectedOrganizationTagsProps> = ({
  organizationId: suppliedOrganizationId,
}) => {
  const organizationStore = useResolver(OrganizationStore);
  const personStore = useResolver(PersonStore);
  const personSkillStore = useResolver(PersonSkillStore);
  const tagStore = useResolver(TagStore);
  const editable = personStore.currentPerson.hasEditPermission();
  const organizationId =
    suppliedOrganizationId || organizationStore.currentOrganizationId;

  useEffect(() => {
    tagStore
      .loadByOrganization(organizationId)
      .then(() => personStore.loadByOrganization(organizationId))
      .then(() => personSkillStore.loadByOrganization(organizationId));
  }, [personStore]);

  if (
    isEmpty(tagStore.getFilteredItems({ organizationId })) &&
    tagStore.status === ResourceStoreStatus.RESOLVED
  ) {
    return (
      <ConnectedOrganizationCreateSkills organizationId={organizationId} />
    );
  }

  const tags = tagStore.getFilteredItems({ organizationId });
  const personsByTagId: { [key: string]: Person[] } = {};

  tags.forEach((tag) => {
    personsByTagId[tag.id] = personStore
      .getFilteredItems({ organizationId })
      .filter(
        (person) =>
          personSkillStore.getFilteredItems({
            tagId: tag.id,
            personId: person.id,
          }).length > 0,
      );
  });

  return (
    <OrganizationTags
      key={tagStore.status}
      personsByTagId={personsByTagId}
      tags={tags}
      editable={editable}
    />
  );
};

export default observer(ConnectedOrganizationTags);
