import { ReactNode } from 'react';

export interface TimelineProps {
  children: ReactNode;
}

export default function Timeline({ children }: TimelineProps) {
  return (
    <div style={{ overflow: 'hidden' }}>
      <table width="100%" style={{ tableLayout: 'fixed' }}>
        {children}
      </table>
    </div>
  );
}
