import React from 'react';
import { FiMinus } from 'react-icons/fi';
import Icon, { IconProps } from '../Icon';

export interface MinusIconProps extends IconProps {}

const MinusIcon: React.FC<MinusIconProps> = ({ ...props }) => {
  return <Icon as={FiMinus} {...props} />;
};

export default MinusIcon;
