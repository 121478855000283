import React from 'react';
import DynamicFieldForm, { DynamicFieldFormPayload } from './DynamicFieldForm';
import useResolver from '../../../hooks/useResolver';
import DynamicFieldStore from '../store/DynamicFieldStore';
import useQueryValue from '../../../hooks/useQueryValue';
import { EDIT_FORM_QUERY_PARAM_NAME } from '../constants';

export interface ConnectedDynamicFieldFormProps {
  organizationId: string;
}

const ConnectedDynamicFieldForm: React.FC<ConnectedDynamicFieldFormProps> = ({
  organizationId,
}) => {
  const dynamicFieldStore = useResolver(DynamicFieldStore);
  const dynamicFieldId = useQueryValue(EDIT_FORM_QUERY_PARAM_NAME);
  const dynamicField = dynamicFieldId
    ? dynamicFieldStore.getById(dynamicFieldId)
    : null;

  if (dynamicFieldId && !dynamicField) {
    return null;
  }

  const handleSubmit = async (payload: DynamicFieldFormPayload) => {
    await dynamicFieldStore.save({
      ...(dynamicField || {}),
      organizationId,
      ...payload,
    });
  };

  return (
    <DynamicFieldForm dynamicField={dynamicField} onSubmit={handleSubmit} />
  );
};

export default ConnectedDynamicFieldForm;
