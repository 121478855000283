import React from 'react';
import Project from '../domain/Project';
import Button from '../ui/Button';
import { useTranslation } from 'react-i18next';

export interface ProjectPublishButtonProps {
  project: Project;
  onPublish: () => void;
}

const ProjectPublishButton: React.FC<ProjectPublishButtonProps> = ({
  project,
  onPublish,
}) => {
  const { t } = useTranslation();

  return (
    <Button
      size="sm"
      minWidth="80px"
      colorScheme="brand"
      disabled={project.isPublished()}
      onClick={onPublish}
    >
      {t('Publish')}
    </Button>
  );
};

export default ProjectPublishButton;
