import React, { ReactNode } from 'react';

export interface TimelineCellProps {
  children?: ReactNode;
  align?: 'left' | 'center' | 'right';
  width?: string | number;
  height?: string | number;
  colSpan?: number;
}

export default function TimelineCell({
  children,
  align,
  width,
  height,
  colSpan,
}: TimelineCellProps) {
  return (
    <td
      align={align}
      style={{ width, position: 'relative', height }}
      colSpan={colSpan}
    >
      {children}
    </td>
  );
}
