import React from 'react';

export enum ValueFormat {
  PLAIN,
  MONEY,
  NUMBER,
  PERCENT,
}

export interface ValueProps {
  value: string | number | any;
  format?: ValueFormat;
  digits?: number;
}

const Value: React.FC<ValueProps> = ({ value, format, digits }) => {
  if (format === ValueFormat.MONEY) {
    return (
      <>
        {!!value || value === 0
          ? (+value).toFixed(digits === undefined ? 2 : digits)
          : '--.--'}
      </>
    );
  }

  if (format === ValueFormat.PERCENT) {
    return (
      <>
        {!!value || value === 0
          ? +(+value * 100).toFixed(digits === undefined ? 0 : digits)
          : null}
      </>
    );
  }

  if (format === ValueFormat.NUMBER) {
    return (
      <>
        {!!value || value === 0
          ? +(+value).toFixed(digits === undefined ? 8 : digits)
          : null}
      </>
    );
  }

  return <>{value}</>;
};

Value.defaultProps = {
  format: ValueFormat.PLAIN,
};

export default Value;
