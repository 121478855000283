import Participation from '../domain/Participation';
import useQueryValue from './useQueryValue';
import { calculateMonthCapacity } from '../util/capacity';
import useMonthPicker from './useMonthPicker';

type Operator = '<' | '>' | '<=' | '>=';

function compare(value1: number, operator: Operator, value2: number) {
  switch (operator) {
    case '<':
      return value1 < value2;
    case '>':
      return value1 > value2;
    case '<=':
      return value1 <= value2;
    case '>=':
      return value1 >= value2;
  }

  return true;
}

export default function useAllocationFilter() {
  const allocationFilter = useQueryValue('allocation');
  const month = useMonthPicker();

  const match =
    allocationFilter && allocationFilter.match(/^(>|<|<=|>=)([0-9.]+)$/);

  return {
    apply: (participations: Participation[]) => {
      if (!match) {
        return true;
      }

      const [, operator, value] = match;
      const capacity = calculateMonthCapacity(participations, month);

      return compare(capacity, operator as Operator, +value);
    },
    parameters: {
      allocationFilter,
    },
  };
}
