import React, { useEffect } from 'react';
import useSWR from 'swr';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import ParticipationStore from '../store/ParticipationStore';
import PriceStore from '../store/PriceStore';
import ProjectBudget from '../components/ProjectBudget';
import ProjectTagStore from '../store/ProjectTagStore';
import useMonthPicker from '../hooks/useMonthPicker';
import { endOfMonth, startOfMonth } from 'date-fns';
import ProjectStore from '../store/ProjectStore';
import FinanceService from '../services/FinanceService';
import Alert from '../ui/Alert';
import SkeletonLoader from '../ui/SkeletonLoader';
import { formatDate } from '../util/date';
import ConnectedAdditionalCostDrawer from './ConnectedAdditionalCostDrawer';
import PaymentScheduleStore from '../store/PaymentScheduleStore';
import TimesheetService from '../services/TimesheetService';

export interface ConnectedProjectBudgetProps {
  projectId: string;
}

const ConnectedProjectBudget: React.FC<ConnectedProjectBudgetProps> = ({
  projectId,
}) => {
  const month = useMonthPicker();
  const financeService = useResolver(FinanceService);
  const projectStore = useResolver(ProjectStore);
  const paymentScheduleStore = useResolver(PaymentScheduleStore);
  const timesheetService = useResolver(TimesheetService);

  const project = projectStore.getById(projectId);

  const { data, error, mutate } = useSWR(
    project ? `ConnectedProjectBudget:${projectId}|${formatDate(month)}` : null,
    () =>
      financeService.getFinances({
        organizationId: project.organizationId,
        projectId,
        dateStart: startOfMonth(month),
        dateEnd: endOfMonth(month),
      }),
  );

  const { data: timesheet } = useSWR(
    project
      ? `ConnectedProjectTimesheet:${projectId}|${formatDate(month)}`
      : null,
    () =>
      timesheetService.getDaily({
        organizationId: project.organizationId,
        projectId,
        dateStart: startOfMonth(month),
        dateEnd: endOfMonth(month),
      }),
  );

  const personStore = useResolver(PersonStore);
  const participationStore = useResolver(ParticipationStore);
  const priceStore = useResolver(PriceStore);
  const projectTagStore = useResolver(ProjectTagStore);

  useEffect(() => {
    personStore.loadCurrentOrganizationPersons();
    participationStore.loadByProject(projectId);
    paymentScheduleStore.loadByProjectId(projectId);

    priceStore.clear();
    priceStore.loadForCurrentOrganization();

    projectTagStore.loadByProject(projectId);
    projectStore.loadOne(projectId);
  }, [projectId]);

  if (error) {
    return <Alert status="error">{String(error)}</Alert>;
  }

  if (!data || !timesheet) {
    return <SkeletonLoader />;
  }

  return (
    <>
      <ProjectBudget
        project={project}
        persons={personStore.items}
        finances={data}
        showCashflow={
          !!paymentScheduleStore.getItemByProjectId(
            projectId,
            endOfMonth(month),
          )
        }
        positions={projectTagStore.getProjectPositions(projectId)}
        onPriceChange={() => mutate()}
        onPriceRemove={async (id) => {
          await priceStore.delete(id);
          await mutate();
        }}
        timesheet={timesheet}
      />
      <ConnectedAdditionalCostDrawer
        externalId={projectId}
        onSuccess={() => mutate()}
      />
    </>
  );
};

export default observer(ConnectedProjectBudget);
