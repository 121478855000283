import { Role } from '../domain/Person';
import { t } from '../i18n';

export function humanReadableRole(role: Role): string {
  const texts: {
    [key in Role]: any;
  } = {
    [Role.ADMIN]: t('Admin'),
    [Role.FINANCIAL_MANAGER]: t('Financial Manager'),
    [Role.OPERATIONAL_MANAGER]: t('Operational Manager'),
    [Role.USER]: t('User'),
    [Role.DISABLED]: t('Disabled'),
  };

  return texts[role] || 'Invalid role';
}
