import React from 'react';
import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
} from '@chakra-ui/react';
import Link from './Link';
import Tooltip from './Tooltip';

export interface IconButtonProps extends ChakraIconButtonProps {
  to?: string;
  darkenBg?: boolean;
  tooltip?: boolean | string;
  preserveQuery?: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({
  darkenBg,
  tooltip,
  ...props
}) => {
  if (props.to) {
    props.as = Link;
  }

  const button = (
    <ChakraIconButton bg={darkenBg ? 'rgba(0, 0, 0, 0.05)' : null} {...props} />
  );

  if (!tooltip) {
    return button;
  }

  return (
    <Tooltip label={tooltip === true ? props['aria-label'] : tooltip}>
      <span>{button}</span>
    </Tooltip>
  );
};

IconButton.defaultProps = {};

export default IconButton;
