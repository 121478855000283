import React from 'react';
import Person from '../domain/Person';
import { PriceType } from '../domain/Price';
import ContentBox from '../ui/ContentBox';
import Stack from '../ui/Stack';
import ConnectedTagWithIcon from '../containers/ConnectedTagWithIcon';
import HStack from '../ui/HStack';
import ResponsiveGrid from '../ui/ResponsiveGrid';
import RouterMonthPicker from './RouterMonthPicker';
import useMonthPicker from '../hooks/useMonthPicker';
import Box from '../ui/Box';
import BudgetRow from './BudgetRow';
import BudgetRowCalculatedValues from './BudgetRowCalculatedValues';
import ConnectedPricePopover from '../containers/ConnectedPricePopover';
import Text from '../ui/Text';
import Divider from '../ui/Divider';
import ConnectedLabeledPriceValue from '../containers/ConnectedLabeledPriceValue';
import Project, { PricingType } from '../domain/Project';
import PlusIcon from '../ui/icons/PlusIcon';
import AutoGrid from '../ui/AutoGrid';
import ProjectTag from '../domain/ProjectTag';
import NoContent from '../ui/NoContent';
import MoreActionsMenu from '../ui/MoreActionsMenu';
import MenuItem from '../ui/MenuItem';
import { calculateTotals } from '../util/budget';
import { FinanceResult } from '../services/FinanceService';
import { formatDate } from '../util/date';
import { t } from '../i18n';
import ConnectedProjectCashflowValue from '../containers/ConnectedProjectCashflowValue';
import { endOfMonth, startOfMonth } from 'date-fns';
import Participation from '../domain/Participation';
import { calculateHoursFromCapacity } from '../util/capacity';
import { getAllocationHours, getUtilizationHours } from '../util/timesheet';
import IconButton from '../ui/IconButton';
import { DeleteIcon } from '../ui';
import ConnectedPriceDeleteDrawer from '../containers/ConnectedPriceDeleteDrawer';
import { getProjectPricingTypeLabel } from '../util/project';

export interface ProjectBudgetProps {
  project: Project;
  persons: Person[];
  positions: ProjectTag[];
  onPriceRemove?: (priceId: string) => void;
  onPriceChange?: () => void;
  finances: FinanceResult[];
  showCashflow: boolean;
  timesheet: Array<{
    participation?: Participation;
    entries?: Array<{
      date: string;
      loggedTimeInSeconds: number;
      predictedTimeInSeconds: number;
      timeInSeconds: number;
      workday?: boolean;
    }>;
  }>;
}

const ProjectBudget: React.FC<ProjectBudgetProps> = ({
  project,
  persons,
  finances,
  positions,
  onPriceRemove,
  onPriceChange,
  showCashflow,
  timesheet,
}) => {
  const month = useMonthPicker();
  const totals = calculateTotals(finances);

  const additionalFinances = finances.filter(
    ({ project, participation }) => project && !participation,
  );

  const showBudget =
    project.pricingType === PricingType.BUDGET ||
    project.pricingType === PricingType.POTENTIAL_COST;

  return (
    <>
      <ConnectedPriceDeleteDrawer
        name={'price.delete'}
        onChange={onPriceChange}
      />
      <HStack justifyContent="center">
        <RouterMonthPicker />
      </HStack>
      <Box py={12}>
        <ResponsiveGrid
          columns={3 + Number(showCashflow) + Number(showBudget)}
          textAlign="center"
          rowGap={8}
        >
          {showBudget && (
            <ConnectedLabeledPriceValue
              label={getProjectPricingTypeLabel(project.pricingType)}
              value={project.budget}
              centered
            />
          )}
          {showCashflow && (
            <ConnectedProjectCashflowValue
              projectId={project.id}
              dateStart={startOfMonth(month)}
              dateEnd={endOfMonth(month)}
              centered
            />
          )}
          <BudgetRowCalculatedValues centered {...totals} />
        </ResponsiveGrid>
      </Box>
      <Stack spacing={4}>
        {positions.map((position) => {
          return (
            <ContentBox key={position.id}>
              <Stack spacing={6}>
                <ResponsiveGrid
                  templateColumns="220px 50px 1fr"
                  alignItems="center"
                  spacing={4}
                >
                  <Text
                    color="gray.500"
                    fontSize="xs"
                    fontWeight="500"
                    casing="uppercase"
                  >
                    <ConnectedTagWithIcon tagId={position.tagId} />
                  </Text>

                  <div />

                  <div>
                    {!showBudget && (
                      <ConnectedPricePopover
                        date={endOfMonth(month)}
                        onSuccess={onPriceChange}
                        externalId={position?.id}
                        handle={(positionPrice) => (
                          <Box p={2} mx={-2} borderRadius="md" bg="gray.50">
                            <ConnectedLabeledPriceValue
                              label={t('Revenue')}
                              price={positionPrice}
                              defaultType={PriceType.HOURLY}
                            />
                          </Box>
                        )}
                      />
                    )}
                  </div>
                </ResponsiveGrid>
                <Divider />
                {finances.map(
                  (
                    { revenuePrognosis, costPrognosis, participation, person },
                    index,
                  ) => {
                    if (
                      !participation ||
                      participation.tagId !== position.tagId
                    ) {
                      return null;
                    }

                    const personTimesheets = timesheet.filter(
                      (t) => t.participation?.personId === person.id,
                    );

                    const utilizationHours =
                      getUtilizationHours(personTimesheets);
                    const allocationHours =
                      getAllocationHours(personTimesheets);

                    const capacityHours = calculateHoursFromCapacity(
                      person.capacity,
                      month,
                    );

                    return (
                      <BudgetRow
                        key={index}
                        hidePositionPrice
                        person={person}
                        position={position}
                        positionPrice={null}
                        personCost={null}
                        capacity={participation.capacity}
                        revenuePrognosis={revenuePrognosis}
                        costPrognosis={costPrognosis}
                        marginPrognosis={revenuePrognosis - costPrognosis}
                        showMultiRings
                        allocation={allocationHours / capacityHours}
                        utilization={utilizationHours / capacityHours}
                      />
                    );
                  },
                )}
              </Stack>
            </ContentBox>
          );
        })}
        <ContentBox
          title="Additional revenues/costs"
          actions={
            <MoreActionsMenu label="Additional actions">
              <MenuItem
                icon={<PlusIcon />}
                preserveQuery
                to="?add.additional&additionalType=revenue"
              >
                {t('Add additional revenue')}
              </MenuItem>
              <MenuItem
                icon={<PlusIcon />}
                preserveQuery
                to="?add.additional&additionalType=cost"
              >
                {t('Add additional cost')}
              </MenuItem>
            </MoreActionsMenu>
          }
        >
          <Stack spacing={6} mt={2}>
            <Divider />
            {additionalFinances.length <= 0 && (
              <NoContent>
                {t('No additional revenues or costs in selected period')}
              </NoContent>
            )}
            {additionalFinances.map(
              (
                {
                  priceId,
                  description,
                  revenuePrognosis,
                  costPrognosis,
                  dateStart,
                  dateEnd,
                },
                index,
              ) => {
                return (
                  <ResponsiveGrid
                    key={index}
                    templateColumns="220px 50px 1fr 50px"
                    alignItems="center"
                    spacing={4}
                  >
                    <div>
                      {description}
                      <Text fontSize="xs">
                        {`${dateStart && formatDate(dateStart)} ${
                          dateEnd ? ` - ${formatDate(dateEnd)}` : ''
                        }`}
                      </Text>
                    </div>
                    <div />
                    <AutoGrid childWidth="150px" rowGap={2}>
                      <div />
                      <BudgetRowCalculatedValues
                        revenuePrognosis={revenuePrognosis}
                        costPrognosis={costPrognosis}
                        marginPrognosis={revenuePrognosis - costPrognosis}
                      />
                    </AutoGrid>
                    {priceId && (
                      <IconButton
                        icon={<DeleteIcon />}
                        variant="ghost"
                        tooltip
                        aria-label={`Delete`}
                        to={`?price.delete=${priceId}`}
                      />
                    )}
                  </ResponsiveGrid>
                );
              },
            )}
          </Stack>
        </ContentBox>
      </Stack>
    </>
  );
};

export default ProjectBudget;
