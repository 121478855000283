import React from 'react';
import SimpleGrid from '../ui/SimpleGrid';
import Stack from '../ui/Stack';
import ContentBox from '../ui/ContentBox';
import Participation from '../domain/Participation';
import NoContent from '../ui/NoContent';
import HStack from '../ui/HStack';
import AddUserIcon from '../ui/icons/AddUserIcon';
import DatePeriod from '../ui/DatePeriod';
import CapacityLabel from './CapacityLabel';
import MoreActionsMenu from '../ui/MoreActionsMenu';
import { MenuItem } from '../ui/Menu';
import EditIcon from '../ui/icons/EditIcon';
import { Link } from 'react-router-dom';
import ConnectedPersonUser from '../containers/ConnectedPersonUser';
import DeleteIcon from '../ui/icons/DeleteIcon';
import ConnectedParticipationDeleteDrawer from '../containers/ConnectedParticipationDeleteDrawer';
import orderBy from 'lodash/orderBy';
import useQueryValue from '../hooks/useQueryValue';
import RouterDropdownSelect from './RouterDropdownSelect';
import Project from '../domain/Project';
import IconButton from '../ui/IconButton';
import PositionCapacity from './PositionCapacity';
import { getTextsByType } from '../util/project';
import { useTranslation } from 'react-i18next';

interface Position {
  id: string;
  projectTagId?: string;
  name: string;
  capacity: number;
  participations: Participation[];
}

export interface ProjectParticipantsProps {
  positions: Position[];
  editable?: boolean;
  project: Project;
}

export enum Visiblity {
  ACTIVE = 'active',
  ENDED = 'ended',
  ALL = 'all',
}

const ProjectParticipants: React.FC<ProjectParticipantsProps> = ({
  project,
  positions,
  editable,
}) => {
  const visibilityQueryParam = 'visibility';
  const visibility = useQueryValue(visibilityQueryParam);
  const { t } = useTranslation();

  if (!positions.length) {
    return (
      <NoContent>
        {getTextsByType(project.type).name} has no{' '}
        {getTextsByType(project.type).team}
      </NoContent>
    );
  }

  return (
    <>
      <HStack>
        <RouterDropdownSelect
          name={visibilityQueryParam}
          withChevron
          fastSwitchOnSingleOption={false}
          defaultValue={project.hasEnded() ? Visiblity.ALL : Visiblity.ACTIVE}
          options={[
            {
              label: t('Active and upcoming'),
              value: Visiblity.ACTIVE,
            },
            {
              label: t('All'),
              value: Visiblity.ALL,
            },
            {
              label: t('Ended'),
              value: Visiblity.ENDED,
            },
          ]}
        />
      </HStack>
      <SimpleGrid columns={1} spacing={4} mt={4}>
        {positions.map((position) => {
          let filteredParticipations = position.participations;

          if (visibility === Visiblity.ACTIVE) {
            filteredParticipations = filteredParticipations.filter((p) => {
              return p.isOngoing() || !p.hasStarted();
            });
          }

          if (visibility === Visiblity.ENDED) {
            filteredParticipations = filteredParticipations.filter((p) => {
              return p.hasEnded();
            });
          }

          if (!position.capacity && filteredParticipations.length <= 0) {
            return null;
          }

          const orderedParticipations = orderBy(
            filteredParticipations,
            'person.name',
          );
          let previousParticipation: Participation = null;

          return (
            <div key={position.name}>
              <ContentBox
                title={
                  <HStack>
                    <div>{position.name}</div>
                    <PositionCapacity
                      position={position}
                      participations={filteredParticipations}
                    />
                  </HStack>
                }
                actions={
                  <HStack spacing={2}>
                    {editable && position.id && (
                      <IconButton
                        to={`?participation&tagId=${position.id}&projectId=${project.id}`}
                        icon={<AddUserIcon />}
                        variant="ghost"
                        aria-label={`Add participant to ${position.name}`}
                      />
                    )}
                  </HStack>
                }
              >
                <Stack spacing={4}>
                  {orderedParticipations.map((p, i) => {
                    const isSameAsPrevious =
                      previousParticipation?.personId === p.personId;
                    previousParticipation = p;

                    return (
                      <SimpleGrid
                        key={i}
                        columns={{ md: 4, sm: 1 }}
                        alignItems="center"
                        spacing={4}
                      >
                        {!isSameAsPrevious ? (
                          <ConnectedPersonUser
                            personId={p.personId}
                            linkToProfile
                          />
                        ) : (
                          <div />
                        )}
                        <CapacityLabel capacity={p.capacity} />
                        <DatePeriod
                          withTime={project.datesIncludeTime}
                          dateStart={p.dateStart}
                          dateEnd={p.dateEnd}
                        />
                        <HStack flexGrow={1} justifyContent="flex-end">
                          <MoreActionsMenu>
                            {editable && (
                              <>
                                <MenuItem
                                  preserveQuery
                                  icon={<EditIcon />}
                                  as={Link}
                                  to={`?participation=${p.id}`}
                                >
                                  {t('Edit')}
                                </MenuItem>
                                <MenuItem
                                  icon={<DeleteIcon />}
                                  as={Link}
                                  to={`?participation.delete=${p.id}`}
                                >
                                  {t('Delete')}
                                </MenuItem>
                              </>
                            )}
                          </MoreActionsMenu>
                        </HStack>
                      </SimpleGrid>
                    );
                  })}
                </Stack>
              </ContentBox>
            </div>
          );
        })}
      </SimpleGrid>

      {editable && (
        <>
          <ConnectedParticipationDeleteDrawer name="participation.delete" />
        </>
      )}
    </>
  );
};

export default ProjectParticipants;
