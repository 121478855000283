import React, { ReactNode } from 'react';
import Button, { ButtonProps } from '../ui/Button';
import Menu, { MenuItem, MenuProps } from '../ui/Menu';
import { t } from '../i18n';
import { TaskState } from '../domain/Task';
import {
  ActivityIcon,
  CheckIcon,
  ChevronIcon,
  IconedText,
  SwitchIcon,
} from '../ui';
import ClipboardIcon from '../ui/icons/CliboardIcon';

export interface TaskStateSelectProps {
  value?: TaskState;
  onChange?: (value: TaskState) => void;
  fontSize?: string;
  placement?: MenuProps['placement'];
}

export const humanReadableStates: { [key in TaskState]: string } = {
  [TaskState.OPEN]: t('Open'),
  [TaskState.IN_PROGRESS]: t('In progress'),
  [TaskState.DONE]: t('Done'),
  [TaskState.ON_HOLD]: t('On hold'),
};

const stateIcons: { [key in TaskState]: ReactNode } = {
  [TaskState.OPEN]: <ClipboardIcon />,
  [TaskState.IN_PROGRESS]: <ActivityIcon />,
  [TaskState.DONE]: <CheckIcon />,
  [TaskState.ON_HOLD]: <SwitchIcon />,
};

const stateButtonProps: { [key in TaskState]: Partial<ButtonProps> } = {
  [TaskState.OPEN]: {
    variant: 'solid',
  },
  [TaskState.IN_PROGRESS]: {
    colorScheme: 'brand',
  },
  [TaskState.DONE]: {
    colorScheme: 'green',
  },
  [TaskState.ON_HOLD]: {
    colorScheme: 'yellow',
  },
};

const TaskStateSelect: React.FC<TaskStateSelectProps> = ({
  value,
  onChange,
  fontSize,
  placement,
}) => {
  const handle = (
    <Button
      {...stateButtonProps[value]}
      rightIcon={<ChevronIcon direction="down" />}
    >
      <IconedText icon={stateIcons[value]} fontSize={fontSize}>
        {humanReadableStates[value]}
      </IconedText>
    </Button>
  );

  return (
    <Menu handle={handle} placement={placement}>
      {[
        TaskState.OPEN,
        TaskState.IN_PROGRESS,
        TaskState.DONE,
        TaskState.ON_HOLD,
      ]
        .filter((v) => v !== value)
        .map((v) => (
          <MenuItem onClick={onChange && (() => onChange(v))} key={v}>
            <IconedText icon={stateIcons[v]} fontSize={fontSize}>
              {humanReadableStates[v]}
            </IconedText>
          </MenuItem>
        ))}
    </Menu>
  );
};

export default TaskStateSelect;
