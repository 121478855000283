import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Requests from '../components/Requests';
import useResolver from '../hooks/useResolver';
import RequestStore from '../store/RequestStore';
import PersonStore from '../store/PersonStore';
import ParticipationStore from '../store/ParticipationStore';

export interface ConnectedProjectRequestsProps {
  projectId: string;
}

const ConnectedProjectRequests: React.FC<ConnectedProjectRequestsProps> = ({
  projectId,
}) => {
  const requestStore = useResolver(RequestStore);
  const personStore = useResolver(PersonStore);
  const participationStore = useResolver(ParticipationStore);

  useEffect(() => {
    requestStore.loadByProject(projectId);
    participationStore.loadByProject(projectId);
  }, [projectId]);

  return (
    <Requests
      requests={requestStore.getProjectRequests(projectId)}
      viewer={personStore.currentPerson}
    />
  );
};

export default observer(ConnectedProjectRequests);
