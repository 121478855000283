import React from 'react';
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react';
import Link from './Link';

export interface ButtonProps extends ChakraButtonProps {
  to?: string;
}

const Button: React.FC<ButtonProps> = ({ ...props }) => {
  if (props.to && !props.disabled) {
    props.as = Link;
  }

  return (
    <ChakraButton
      role="button"
      fontSize="sm"
      fontWeight="500"
      iconSpacing="28px"
      {...props}
    />
  );
};

Button.defaultProps = {};

export default Button;
