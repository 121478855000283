import React from 'react';
import Menu from './Menu';
import IconButton from './IconButton';
import { Box } from '@chakra-ui/react';
import { FaEllipsisV } from 'react-icons/fa';

export interface MoreActionsMenuProps {
  color?: string;
  float?: boolean;
  label?: string;
  darkBackground?: boolean;
}

const MoreActionsMenu: React.FC<MoreActionsMenuProps> = ({
  children,
  color,
  float,
  label,
  darkBackground,
}) => {
  const validChildren = React.Children.map(children, (c) =>
    React.isValidElement(c),
  ).filter(Boolean);

  if (validChildren.length <= 0) {
    return null;
  }

  const content = (
    <Menu
      handle={
        <IconButton
          as="span"
          darkenBg={darkBackground}
          variant="ghost"
          icon={<FaEllipsisV />}
          aria-label={label}
          color={color}
        />
      }
    >
      {children}
    </Menu>
  );

  if (!float) {
    return content;
  }

  return (
    <Box position="absolute" top={1} right={1}>
      {content}
    </Box>
  );
};

MoreActionsMenu.defaultProps = {
  label: 'More actions',
};

export default MoreActionsMenu;
