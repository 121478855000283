import React, { FC, useEffect } from 'react';
import useResolver from '../../../hooks/useResolver';
import DynamicFieldStore from '../store/DynamicFieldStore';
import DynamicFieldCardValues from './DynamicFieldCardValues';
import OrganizationStore from '../../../store/OrganizationStore';
import { observer } from 'mobx-react';

export interface ConnectedDynamicFieldCardValuesProps {
  dynamic: Record<string, unknown>;
  tags: string[];
  organizationId?: string;
}

const ConnectedDynamicFieldCardValues: FC<
  ConnectedDynamicFieldCardValuesProps
> = ({ dynamic, organizationId: suppliedOrganizationId, tags }) => {
  const dynamicFieldStore = useResolver(DynamicFieldStore);
  const organizationStore = useResolver(OrganizationStore);

  const organizationId =
    suppliedOrganizationId || organizationStore.currentOrganizationId;

  useEffect(() => {
    dynamicFieldStore.loadByOrganization(organizationId);
  }, [organizationId]);

  return (
    <DynamicFieldCardValues
      dynamic={dynamic}
      dynamicFields={dynamicFieldStore.organizationItemsByTags(
        organizationId,
        tags,
      )}
    />
  );
};

export default observer(ConnectedDynamicFieldCardValues);
