import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './ui';

const Providers: React.FC = ({ children }) => {
  return (
    <ThemeProvider>
      <BrowserRouter>{children}</BrowserRouter>
    </ThemeProvider>
  );
};

export default Providers;
