import React from 'react';
import Text from '../ui/Text';
import ProjectTag from '../domain/ProjectTag';
import Participation from '../domain/Participation';
import { getTotalCapacity } from '../util/participation';

export interface PositionCapacityProps {
  position: Pick<ProjectTag, 'capacity'>;
  participations: Participation[];
}

const PositionCapacity: React.FC<PositionCapacityProps> = ({
  position,
  participations,
}) => {
  const capacity = getTotalCapacity(participations);

  return (
    <Text color="brand.500" fontSize="xs">
      {+capacity.toFixed(3)}
      {!!position?.capacity && ` / ${position.capacity}`}
    </Text>
  );
};

export default PositionCapacity;
