import AbstractResourceStore from './AbstractResourceStore';
import Worklog from '../domain/Worklog';
import WorklogService from '../services/WorklogService';
import AccountStore from './AccountStore';
import { singleton } from 'tsyringe';
import OrganizationStore from './OrganizationStore';

@singleton()
export default class WorklogStore extends AbstractResourceStore<Worklog> {
  public constructor(
    private readonly worklogService: WorklogService,
    private readonly organizationStore: OrganizationStore,
    accountStore: AccountStore,
  ) {
    super(worklogService, accountStore);
  }

  public async loadByPerson(personId: string, dateStart: Date, dateEnd: Date) {
    await this.setManyEventually(
      this.worklogService.getAllByPerson(personId, dateStart, dateEnd),
    );
  }

  public async loadByProject(
    projectId: string,
    dateStart: Date,
    dateEnd: Date,
  ) {
    await this.setManyEventually(
      this.worklogService.getAllByProject(projectId, dateStart, dateEnd),
    );
  }

  public async loadByProjects(
    projectIds: string[],
    dateStart: Date,
    dateEnd: Date,
  ) {
    if (!projectIds.length) {
      return;
    }

    await this.setManyEventually(
      this.worklogService.getAllByProjects(projectIds, dateStart, dateEnd),
    );
  }

  public getItemsByProjectAndPeriod(
    projectId: string,
    dateStart: Date,
    dateEnd: Date,
  ): Worklog[] {
    return WorklogStore.applyPeriodFilter(
      this.getFilteredItems({ projectId }),
      dateStart,
      dateEnd,
    );
  }

  public getItemsByPeriod(dateStart: Date, dateEnd: Date): Worklog[] {
    return WorklogStore.applyPeriodFilter(this.items, dateStart, dateEnd);
  }

  public getItemsByPersonAndPeriod(
    personId: string,
    dateStart: Date,
    dateEnd: Date,
  ): Worklog[] {
    return WorklogStore.applyPeriodFilter(
      this.getFilteredItems({ personId }),
      dateStart,
      dateEnd,
    );
  }

  private static applyPeriodFilter(
    worklogs: Worklog[],
    dateStart: Date,
    dateEnd: Date,
  ): Worklog[] {
    return worklogs.filter((item) => {
      return item.date >= dateStart && item.date <= dateEnd;
    });
  }
}
