import React from 'react';
import {
  Text as ChakraText,
  TextProps as ChakraTextProps,
} from '@chakra-ui/react';

export interface TextProps extends ChakraTextProps {}

const Text: React.FC<TextProps> = ({ ...props }) => {
  return <ChakraText as="div" {...props} />;
};

Text.defaultProps = {};

export default Text;
