import React from 'react';
import ConnectedProjectList from '../containers/ConnectedProjectList';
import { ProjectType } from '../domain/Project';

const AllTrainings: React.FC = () => {
  return (
    <>
      <ConnectedProjectList filters={{ type: ProjectType.TRAINING }} />
    </>
  );
};

export default AllTrainings;
