import React from 'react';
import { observer } from 'mobx-react';
import PersonFormDrawer, {
  PersonFormDrawerProps,
} from '../components/PersonFormDrawer';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import { Role } from '../domain/Person';

export interface ConnectedPersonFormDrawerProps extends PersonFormDrawerProps {}

const ConnectedPersonFormDrawer: React.FC<ConnectedPersonFormDrawerProps> = ({
  ...props
}) => {
  const { currentPerson } = useResolver(PersonStore);

  return (
    <PersonFormDrawer
      simple={!currentPerson.hasPermission(Role.ADMIN)}
      {...props}
    />
  );
};

ConnectedPersonFormDrawer.defaultProps = {
  name: PersonFormDrawer.defaultProps.name,
};

export default observer(ConnectedPersonFormDrawer);
